import React, { useState } from 'react';
import './complementry.css'
import { Link } from "react-router-dom";
import arrowimg from '../image/forward.png';
import secondimg from '../image/logo-2.png';
import logoimg from '../image/circle.png';
import thirdimg from '../image/zodiac.png';
import fourimg from '../image/puzzle.png';
import fiveimg from '../image/calendar.png';
import siximg from '../image/tarot.png'
import sevenimg from '../image/esoteric.png';
import secondimg1 from '../image/g1215.png';
import girlsimg from '../image/girls.png';
import plusimg from '../image/right-icon.png'
// import { Pagination, Autoplay } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import { Pagination, Autoplay } from 'swiper/modules';

const Copmplementry = () => {

    return (
        <>
            <section className='complementry desktop-view-complementry'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='heading-holder text-center'>
                                <h2>Complimentary<span className='ms-2'>Services</span> </h2>
                            </div>
                            <div className="main-heading mt-1">
                                <div class="line me-2"></div>
                                <div class="square"></div>
                                <div class="line ms-2"></div>
                            </div>
                            <div className='text-holder text-center mt-3'>
                                <p>Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum<br /> auctor, nisi elit consequat hello Aenean world.</p>
                            </div>
                        </div>
                    </div>

                    <div className='row mt-md-4 mt-sm-0 mt-0'>

                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                                <div className='row mt-4'>
                                    <div className=' col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12'>
                                        <Link to='/today_Horoscope'>
                                            <div class="card mb-4" >
                                                <div class="card-body">
                                                    <div className='imag-holder mb-4'>
                                                        <img src={logoimg} className='' />
                                                        <div className='second-holder'>
                                                            <img src={secondimg} className='color-img' />
                                                            {/* <img src={secondimg1} className='color-img2' /> */}
                                                        </div>
                                                    </div>
                                                    <div className='name-holder text-center'>
                                                        <h4>Today's Horoscope</h4>
                                                        <div className='border-line mb-2'></div>
                                                        <p>Consectetur adipiscing elit sed do eiusmod tempor incididunt.</p>
                                                    </div>

                                                    <div className='text-center'>
                                                        <button className='btn btn-read'>Read More<img src={arrowimg} className='arrow_img ms-3' alt='' /></button>
                                                    </div>

                                                </div>
                                            </div>
                                        </Link>
                                    </div>

                                    <div className='col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12'>
                                        <Link to='/Compatibility'> <div class="card mb-4" >
                                            <div class="card-body">
                                                <div className='imag-holder'>
                                                    <div className='imag-holder mb-4'>
                                                        <img src={logoimg} className='' />
                                                        <div className='second-holder1'>
                                                            <img src={fourimg} />

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='name-holder text-center'>
                                                    <h4>Compatibility</h4>
                                                    <div className='border-line mb-2'></div>
                                                    <p>Consectetur adipiscing elit sed do eiusmod tempor incididunt.</p>
                                                </div>

                                                <div className='text-center'>
                                                    <button className='btn btn-read'>Read More<img src={arrowimg} className='arrow_img ms-2' alt='' /></button>
                                                </div>

                                            </div>
                                        </div></Link>

                                    </div>
                                    <div className='col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12'>
                                        <Link to='/freekundali'> <div class="card mb-4" >
                                            <div class="card-body">
                                                <div className='imag-holder'>
                                                    <div className='imag-holder mb-4'>
                                                        <img src={logoimg} className='' />
                                                        <div className='second-holder6'>
                                                            <img src={thirdimg} />

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='name-holder text-center'>
                                                    <h4>Free Kundali</h4>
                                                    <div className='border-line mb-2'></div>
                                                    <p>Consectetur adipiscing elit sed do eiusmod tempor incididunt.</p>
                                                </div>

                                                <div className='text-center'>
                                                    <button className='btn btn-read'>Read More<img src={arrowimg} className='arrow_img ms-2' alt='' /></button>
                                                </div>

                                            </div>
                                        </div></Link>
                                    </div>
                                    <div className='col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12'>
                                        <Link to='/festival-calender'><div class="card mb-4" >
                                            <div class="card-body">
                                                <div className='imag-holder'>
                                                    <div className='imag-holder mb-4'>
                                                        <img src={logoimg} className='' />
                                                        <div className='second-holder2'>
                                                            <img src={fiveimg} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='name-holder text-center'>
                                                    <h4>Festival Calendar</h4>
                                                    <div className='border-line mb-2'></div>
                                                    <p>Consectetur adipiscing elit sed do eiusmod tempor incididunt.</p>
                                                </div>

                                                <div className='text-center'>
                                                    <button className='btn btn-read'>Read More<img src={arrowimg} className='arrow_img ms-2' alt='' /></button>
                                                </div>

                                            </div>
                                        </div></Link>
                                    </div>
                                    <div className='col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12'>
                                        <Link to='/Kundali'>  <div class="card mb-4" >
                                            <div class="card-body">
                                                <div className='imag-holder'>
                                                    <div className='imag-holder mb-4'>
                                                        <img src={logoimg} className='' />
                                                        <div className='second-holder3'>
                                                            <img src={siximg} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='name-holder text-center'>
                                                    <h4>Kundali Matching</h4>
                                                    <div className='border-line mb-2'></div>
                                                    <p>Consectetur adipiscing elit sed do eiusmod tempor incididunt.</p>
                                                </div>

                                                <div className='text-center'>
                                                    <button className='btn btn-read'>Read More<img src={arrowimg} className='arrow_img ms-2' alt='' /></button>
                                                </div>

                                            </div>
                                        </div></Link>
                                    </div>
                                    <div className='col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12'>
                                        <Link to='/chinese_Horoscope'>
                                            <div class="card mb-4" >
                                                <div class="card-body">
                                                    <div className='imag-holder'>
                                                        <div className='imag-holder mb-4'>
                                                            <img src={logoimg} className='' />
                                                            <div className='second-holder4'>
                                                                <img src={sevenimg} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='name-holder text-center'>
                                                        <h4>Chinese Horoscope</h4>
                                                        <div className='border-line mb-2'></div>
                                                        <p>Consectetur adipiscing elit sed do eiusmod tempor incididunt.</p>
                                                    </div>

                                                    <div className='text-center'>
                                                        <button className='btn btn-read'>Read More<img src={arrowimg} className='arrow_img ms-2' alt='' /></button>
                                                    </div>

                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className='col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12'>
                                        <Link to='/today-panchang'>
                                            <div class="card mb-4" >
                                                <div class="card-body">
                                                    <div className='imag-holder'>
                                                        <div className='imag-holder mb-4'>
                                                            <img src={logoimg} className='' />
                                                            <div className='second-holder6'>
                                                                <img src={secondimg} />

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='name-holder text-center'>
                                                        <h4>Today Panchang</h4>
                                                        <div className='border-line mb-2'></div>
                                                        <p>Consectetur adipiscing elit sed do eiusmod tempor incididunt.</p>
                                                    </div>

                                                    <div className='text-center'>
                                                        <button className='btn btn-read'>Read More<img src={arrowimg} className='arrow_img ms-2' alt='' /></button>
                                                    </div>

                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className='col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12'>
                                        <Link to='/today_Horoscope'>
                                            <div class="card mb-4" >
                                                <div class="card-body">
                                                    <div className='imag-holder'>
                                                        <div className='imag-holder mb-4'>
                                                            <img src={logoimg} className='' />
                                                            <div className='second-holder6'>
                                                                <img src={thirdimg} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='name-holder text-center'>
                                                        <h4>Today's Horoscope</h4>
                                                        <div className='border-line mb-2'></div>
                                                        <p>Consectetur adipiscing elit sed do eiusmod tempor incididunt.</p>
                                                    </div>

                                                    <div className='text-center'>
                                                        <button className='btn btn-read'>Read More<img src={arrowimg} className='arrow_img ms-2' alt='' /></button>
                                                    </div>

                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>



            {/* mobile view start */}
            <section className='complementry swiper-mobile-app-complementry'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='heading-holder text-center mt-4'>
                                <h2>Complimentary<span className='ms-2'>Services</span> </h2>
                            </div>
                            <div className="main-heading mt-1">
                                <div class="line me-1"></div>
                                <div class="square"></div>
                                <div class="line ms-1"></div>
                            </div>
                            <div className='text-holder text-center mt-2'>
                                <p>Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat hello Aenean world.</p>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <Swiper className=''

                            // spaceBetween={10}

                            pagination={{ clickable: true }}
                            modules={[Autoplay, Pagination]}
                            navigation={false}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                    // spaceBetween: 10,
                                },
                                360: {
                                    slidesPerView: 2,
                                    // spaceBetween: 10,
                                },
                                567: {
                                    slidesPerView: 2,
                                    // spaceBetween: 10,
                                },
                                640: {
                                    slidesPerView: 2,
                                    // spaceBetween: 10,
                                },
                                768: {
                                    slidesPerView: 2,
                                    // spaceBetween: 10,
                                },

                            }}
                        >

                            <SwiperSlide >
                                <div class="card mb-4" >
                                    <div class="card-body pb-0">
                                        <div className='imag-holder mb-2'>
                                            <img src={logoimg} className='circle-img' />
                                            <div className='second-holder'>
                                                <img src={secondimg} className='color-img-circle' />
                                                {/* <img src={secondimg1} className='color-img2' /> */}
                                            </div>
                                        </div>
                                        <div className='name-holder text-center'>
                                            <h4>Today's Horoscope</h4>
                                            <div className='border-line '></div>
                                            <p>Consectetur adipiscing elit sed do eiusmod tempor incididunt.</p>
                                        </div>

                                        <div className='text-center'>
                                            <Link to='/today_Horoscope'> <button className='btn btn-read '>Read More<img src={arrowimg} className='arrow_img ms-1' /></button></Link>
                                        </div>

                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide >
                                <div class="card mb-4" >
                                    <div class="card-body pb-0">
                                        <div className='imag-holder'>
                                            <div className='imag-holder mb-2'>
                                                <img src={logoimg} className='circle-img' />
                                                <div className='second-holder1'>
                                                    <img src={fourimg} className='color-img-circle' />
                                                    {/* <img src={secondimg1} className='color-img2'/> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='name-holder text-center'>
                                            <h4>Compatibility</h4>
                                            <div className='border-line '></div>
                                            <p>Consectetur adipiscing elit sed do eiusmod tempor incididunt.</p>
                                        </div>

                                        <div className='text-center'>
                                            <Link to='/Compatibility'> <button className='btn btn-read '>Read More<img src={arrowimg} className='arrow_img ms-1' /></button></Link>
                                        </div>

                                    </div>
                                </div>


                            </SwiperSlide>
                            <SwiperSlide >
                                <div class="card mb-4" >
                                    <div class="card-body pb-0">
                                        <div className='imag-holder'>
                                            <div className='imag-holder mb-2'>
                                                <img src={logoimg} className='circle-img' />
                                                <div className='second-holder'>
                                                    <img src={thirdimg} className='color-img-circle' />

                                                </div>
                                            </div>
                                        </div>
                                        <div className='name-holder text-center'>
                                            <h4>Free Kundali</h4>
                                            <div className='border-line '></div>
                                            <p>Consectetur adipiscing elit sed do eiusmod tempor incididunt.</p>
                                        </div>

                                        <div className='text-center'>
                                            <Link to='/freekundali'>  <button className='btn btn-read'>Read More<img src={arrowimg} className='arrow_img ms-1' /></button></Link>
                                        </div>

                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide >
                                <div class="card mb-4" >
                                    <div class="card-body pb-0">
                                        <div className='imag-holder'>
                                            <div className='imag-holder mb-2'>
                                                <img src={logoimg} className='circle-img' />
                                                <div className='second-holder2'>
                                                    <img src={fiveimg} className='color-img-circle' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='name-holder text-center'>
                                            <h4>Festival Calendar</h4>
                                            <div className='border-line '></div>
                                            <p>Consectetur adipiscing elit sed do eiusmod tempor incididunt.</p>
                                        </div>

                                        <div className='text-center'>
                                            <Link to='/festival-calender'>  <button className='btn btn-read'>Read More<img src={arrowimg} className='arrow_img ms-1' /></button></Link>
                                        </div>

                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide >
                                <div class="card mb-4" >
                                    <div class="card-body pb-0">
                                        <div className='imag-holder'>
                                            <div className='imag-holder mb-2'>
                                                <img src={logoimg} className='circle-img' />
                                                <div className='second-holder3'>
                                                    <img src={siximg} className='color-img-circle' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='name-holder text-center'>
                                            <h4>Kundali Matching</h4>
                                            <div className='border-line '></div>
                                            <p>Consectetur adipiscing elit sed do eiusmod tempor incididunt.</p>
                                        </div>

                                        <div className='text-center'>
                                            <Link to='/kundali'><button className='btn btn-read'>Read More<img src={arrowimg} className='arrow_img ms-1' /></button></Link>
                                        </div>

                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide >
                                <div class="card mb-4" >
                                    <div class="card-body pb-0">
                                        <div className='imag-holder'>
                                            <div className='imag-holder mb-2'>
                                                <img src={logoimg} className='circle-img' />
                                                <div className='second-holder4'>
                                                    <img src={sevenimg} className='color-img-circle' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='name-holder text-center'>
                                            <h4>Chinese Horoscope</h4>
                                            <div className='border-line '></div>
                                            <p>Consectetur adipiscing elit sed do eiusmod tempor incididunt.</p>
                                        </div>

                                        <div className='text-center'>
                                            <Link to='/chinese_Horoscope'><button className='btn btn-read'>Read More<img src={arrowimg} className='arrow_img ms-1' /></button></Link>
                                        </div>

                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide >
                                <div class="card mb-4" >
                                    <div class="card-body pb-0">
                                        <div className='imag-holder'>
                                            <div className='imag-holder mb-2'>
                                                <img src={logoimg} className='circle-img' />
                                                <div className='second-holder'>
                                                    <img src={secondimg} className='color-img-circle' />
                                                    {/* <img src={secondimg1} className='color-img2' /> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='name-holder text-center'>
                                            <h4>Today Panchang</h4>
                                            <div className='border-line '></div>
                                            <p>Consectetur adipiscing elit sed do eiusmod tempor incididunt.</p>
                                        </div>

                                        <div className='text-center'>
                                            <Link to='/today-panchang'> <button className='btn btn-read'>Read More<img src={arrowimg} className='arrow_img ms-1' /></button></Link>
                                        </div>

                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide >
                                <div class="card mb-4" >
                                    <div class="card-body pb-0">
                                        <div className='imag-holder'>
                                            <div className='imag-holder mb-2'>
                                                <img src={logoimg} className='circle-img' />
                                                <div className='second-holder'>
                                                    <img src={thirdimg} className='color-img-circle' />
                                                    {/* <img src={secondimg1} className='color-img2' /> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='name-holder text-center'>
                                            <h4>Today's Horoscope</h4>
                                            <div className='border-line '></div>
                                            <p>Consectetur adipiscing elit sed do eiusmod tempor incididunt.</p>
                                        </div>

                                        <div className='text-center'>
                                            <Link to='/today_Horoscope'> <button className='btn btn-read'>Read More<img src={arrowimg} className='arrow_img ms-1' /></button></Link>
                                        </div>

                                    </div>
                                </div>
                            </SwiperSlide>

                        </Swiper>
                    </div>
                </div>

            </section>



        </>
    )
}

export default Copmplementry