import React from 'react'
import { Link } from 'react-router-dom'
import CompabilitySlider from './CompabilitySlider'
const Compatibility = () => {
    return (
        <>
            <section className="Horoscope2023 desk-top-view-horoscope">
                <div className="container-fluid">
                    <div className='row'>
                        <div className='col-xxl-9 col-xl-10 col-lg-11 col-md-11 mx-auto'>
                            <div className="row">
                                <div className="col-md-12">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mt-4">
                                            <li className="breadcrumb-item">
                                                <Link to="/">Home</Link>
                                            </li>
                                            <li className="breadcrumb-item active">
                                                <Link >Compatibility</Link>
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        {/* <div className="card-body"> */}
                                        <div className="Horoscope_info p-4">
                                            <div className="Horoscope_heading mb-2">
                                                Check your love compatibility
                                            </div>
                                            <p>You don't always get along like a blaze on flames with people, but when you're with that "special person,"
                                                you feel happy and in control of the situaton. We encounter numerous people throughout life. One person would be your life partner out of all
                                                those who may be terrific friends or mentors for you. You must make the appropriate choice for that person. They must make you feel at home,
                                                never depressed or too uncared for.
                                            </p>

                                            <p> Do you believe your heart might have jumped a beat if you had met that particular someone? If so,
                                                find out what your Sun sign conspires to have you do by checking your zodiac sign love compatibility.  </p>

                                            <p>Zodiac sign compatibility reveal  just compatibility in romantic relationships. You can also find information on your partner's and your
                                                own zodiac love and sexual compatibility. This can ensure a long-lasting relationship with shared understanding while also assisting you
                                                in learning further about your mate
                                                and your bond.
                                            </p>
                                            <p>
                                                Love compatibility can also forecast your relationship will develop in the future, in addition to letting you know how things stand right now. More,  it reveals
                                                the strength of your current bond, what makes it successful, and if you and your loved one are about to experience harmony or conflict in the future.
                                                Hence, you may determine whether your connection is likely to progress in the ways you want by simply entering the appropriate zodiac sign. Kudos if your sign and your partner's sign align! Seamless times
                                                are predictable in advance.</p>
                                        </div>
                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CompabilitySlider />
            </section>

            {/* mobile-view-start */}
            <section className="Horoscope2023 mobile-view-horoscope">
                <div className="container-fluid">
                    <div className='row'>
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mt-4">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Home {'>'}</Link>
                                    </li>
                                    <li className="breadcrumb-item active">
                                        <Link >Compatibility</Link>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        {/* <div className="row"> */}
                        <div className="col-md-12">
                            <div className="card">
                                {/* <div className="card-body"> */}
                                <div className="Horoscope_info p-2">
                                    <div className="Horoscope_heading my-2">
                                        Check your love compatibility
                                    </div>
                                    <p>You don't always get along like a blaze on flames with people, but when you're with that "special person,"
                                        you feel happy and in control of the situaton. We encounter numerous people throughout life. One person would be your life partner out of all
                                        those who may be terrific friends or mentors for you. You must make the appropriate choice for that person. They must make you feel at home,
                                        never depressed or too uncared for.
                                    </p>

                                    <p> Do you believe your heart might have jumped a beat if you had met that particular someone? If so,
                                        find out what your Sun sign conspires to have you do by checking your zodiac sign love compatibility.  </p>

                                    <p>Zodiac sign compatibility reveal  just compatibility in romantic relationships. You can also find information on your partner's and your
                                        own zodiac love and sexual compatibility. This can ensure a long-lasting relationship with shared understanding while also assisting you
                                        in learning further about your mate
                                        and your bond.
                                    </p>
                                    <p>
                                        Love compatibility can also forecast your relationship will develop in the future, in addition to letting you know how things stand right now. More,  it reveals
                                        the strength of your current bond, what makes it successful, and if you and your loved one are about to experience harmony or conflict in the future.
                                        Hence, you may determine whether your connection is likely to progress in the ways you want by simply entering the appropriate zodiac sign. Kudos if your sign and your partner's sign align! Seamless times
                                        are predictable in advance.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CompabilitySlider />
            </section>
        </>
    )
}

export default Compatibility