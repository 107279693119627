import React from 'react'
import '../../../Horoscopes/Horoscope.css';
import tigerimg from '../../Images/tiger.png'
import { Link } from 'react-router-dom'

const Tiger = () => {
    return (
        <>
            <section className="Horoscope2023 desk-top-view-horoscope">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mt-4">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/chinese_Horoscope">Chinese Zodiac </Link>
                                    </li>
                                    <li className="breadcrumb-item active">
                                        <Link >Tiger Animal Sign</Link>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="row pb-3">
                        <div className="col-md-12">
                            <div className="card mb-5">
                                <div className='card-body'>
                                    <div className="row">
                                        <div className='col-md-9'>
                                            <div className="Horoscope_info">
                                                <div className="Horoscope_heading1 mb-2">
                                                    TIGER
                                                </div>
                                                <p>
                                                    Tiger is the third Chinese zodiac sign. 2022 will be the year of Tiger. For one wondering what a Tiger personality is like, people under the tiger Chinese zodiac sign can be trusted with diligence, dependability, strength and determination. These people are said to be very brave and love to take challenges head first.
                                                    However, this brave nature of them also becomes a weakness as they sometimes get too stubborn with things and life.
                                                </p>
                                                <p>
                                                    Meanwhile, if one has to pinpoint more weaknesses of the Tiger, the Chinese horoscope of Tiger tells us that these people can get too confident in some instances. Moreover, apart from the tough core they flaunt, they also have an emotional personality, which they try to hide as they see it as a weakness. Tiger people care about the relationship and find it tough to let go of people. Read the Chinese Horoscope of Tiger to know more about the sign.
                                                </p>
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            <div className='image-holder text-end'>
                                                <img src={tigerimg} className='area-img me-4' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <p>
                                            In love, the Chinese Zodiac Tiger likes to find themselves in dominating roles. These people have a small circle and despite the fact that they are acquainted with a lot of people, they do not further the relationships to deep friendship.
                                            If a Tiger loves you truly, they can get very caring and protective.
                                        </p>
                                        <p>
                                            If it excites you, Leonardo DiCaprio and Tom Cruise belong to the Tiger zodiac as per Chinese astrology.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* mobile-view-aries */}

            <section className="Horoscope2023 mobile-view-horoscope">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mt-4">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Home {'>'} </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/chinese_Horoscope">Chinese Zodiac {'>'} </Link>
                                    </li>
                                    <li className="breadcrumb-item active">
                                        <Link >Tiger Animal Sign</Link>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="row pb-3">
                        <div className="col-md-12">
                            <div className="card mt-3 mb-5">
                                <div className='card-body'>
                                    <div className="row">
                                        <div className="Horoscope_info">
                                            <div className="Horoscope_heading1 my-3">
                                                TIGER
                                            </div>
                                            <div className='row'>
                                                <div className='col-sm-9 col-8'>
                                                    <p>
                                                        Tiger is the third Chinese zodiac sign. 2022 will be the year of Tiger. For one wondering what a Tiger personality is like, people under the tiger Chinese zodiac sign can be trusted with diligence, dependability, strength and determination. These people are said to be very brave and love to take challenges head first.
                                                        However, this brave nature of them also becomes a weakness as they sometimes get too stubborn with things and life.
                                                    </p>
                                                </div>
                                                <div className='col-sm-3 col-4'>
                                                    <div className='image-holder text-center'>
                                                        <img src={tigerimg} className='area-img ' />
                                                    </div>
                                                </div>
                                            </div>
                                            <p>
                                                Meanwhile, if one has to pinpoint more weaknesses of the Tiger, the Chinese horoscope of Tiger tells us that these people can get too confident in some instances. Moreover, apart from the tough core they flaunt, they also have an emotional personality, which they try to hide as they see it as a weakness. Tiger people care about the relationship and find it tough to let go of people. Read the Chinese Horoscope of Tiger to know more about the sign.
                                            </p>
                                            <p>
                                                In love, the Chinese Ox horoscope tells us how these people like spending alone time with their partner. The Ox are always up for long term relations and flings don't entertain them.
                                                Though it takes a while for the Ox to fall in love, however, once they have, there is no love like the Ox could give you.
                                            </p>
                                            <p>
                                                If it excites you, Cristiano Ronaldo and Barack Obama too belongs to the Ox zodiac as per Chinese astrology.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Tiger