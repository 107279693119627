import React from 'react'
import "../Free_Kundli_tabs/Free_Kundli.css";
import { Link } from "react-router-dom";
import SecondTabKundali from '../Free_Kundli_tabs/second-tab-kundali/SecondTabKundali'
import ConnectAstrologer from '../../Kundali/connect-astrologer/ConnectAstrologer';
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
const SecondKundali = () => {
    return (
        <>
            <section className="Free_Kundli desktop-view-free-kundali">
                <div className="container">
                    <div className="row ">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                                <li className="breadcrumb-item"><Link to='/freekundali'>Free Kundali </Link></li>
                                <li className="breadcrumb-item active" ><Link to=''>kundli</Link></li>
                            </ol>
                        </nav>
                        <div className="col-lg-12   mt-4">
                            <ul className="nav nav-pills mb-3 " id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <Link to='/Basic-Kundali'><button className="nav-link " id="pills-basic-tab" data-bs-toggle="pill" data-bs-target="#pills-basic"
                                        type="button" role="tab" aria-controls="pills-basic" aria-selected="true"> Basic </button></Link>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <Link to='/second-Kundali'> <button className="nav-link active" id="pills-kundli-tab" data-bs-toggle="pill" data-bs-target="#pills-kundli" type="button"
                                        role="tab" aria-controls="pills-kundli" aria-selected="false">
                                        Kundli
                                    </button></Link>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <Link to='/kp-Kundali'> <button className="nav-link " id="pills-kp-tab" data-bs-toggle="pill" data-bs-target="#pills-kp"
                                        type="button" role="tab" aria-controls="pills-kp" aria-selected="false">
                                        KP
                                    </button></Link>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <Link to="/free-ashtkvarga">
                                        <button className="nav-link" id="pills-ashtakvarga-tab" data-bs-toggle="pill" data-bs-target="#pills-ashtakvarga"
                                            type="button" role="tab" aria-controls="pills-ashtakvarga" aria-selected="false">
                                            Ashtakvarga
                                        </button></Link>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <Link to="/free-chart">
                                        <button className="nav-link" id="pills-charts-tab" data-bs-toggle="pill" data-bs-target="#pills-charts"
                                            type="button" role="tab" aria-controls="pills-charts" aria-selected="false">
                                            Charts
                                        </button></Link>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <Link to="/free-dasha">
                                        <button className="nav-link" id="pills-dasha-tab" data-bs-toggle="pill" data-bs-target="#pills-dasha"
                                            type="button" role="tab" aria-controls="pills-dasha" aria-selected="false">
                                            Dasha
                                        </button></Link>
                                </li>
                                <li className="nav-item free-nav" role="presentation">
                                    <Link to={"/free_Report"}>
                                        <button className="nav-link" id="pills-report-tab" data-bs-toggle="pill" data-bs-target="#pills-report"
                                            type="button" role="tab" aria-controls="pills-report" aria-selected="false">
                                            Free Report
                                        </button> </Link>
                                </li>
                            </ul>
                            <div className="col-md-12">
                                <div className="row">
                                    <SecondTabKundali />
                                    <div className="row   mt-5">
                                        <div className="col-md-12">
                                            <div className="table-responsive">
                                                <table className="table table-bordered   ">
                                                    <thead>
                                                        <tr className="table-heading1">
                                                            <th>Planet</th>
                                                            <th>Sign</th>
                                                            <th className='sign-of'>Sign Lord</th>
                                                            <th>Nakshatra</th>
                                                            <th className='sign-of'>Naksh Lord</th>
                                                            <th>Degree</th>
                                                            <th>Retro(R)</th>
                                                            <th>Combust</th>
                                                            <th>Avastha</th>
                                                            <th>House</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr className="table-warning1">
                                                            <td className="first-child">Ascendent</td>
                                                            <td >Capricorn</td>
                                                            <td>Saturn</td>
                                                            <td>Dhanishta</td>
                                                            <td>Mars</td>
                                                            <td>2454'31"</td>
                                                            <td>Direct</td>
                                                            <td>No</td>
                                                            <td>---</td>
                                                            <td>1</td>
                                                            <td>---</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="first-child">Sun</td>
                                                            <td>Capricorn</td>
                                                            <td>Saturn</td>
                                                            <td>Dhanishta</td>
                                                            <td>Mars</td>
                                                            <td>2454'31"</td>
                                                            <td>Direct</td>
                                                            <td>No</td>
                                                            <td>Yuva</td>
                                                            <td>1</td>
                                                            <td>Exalted</td>
                                                        </tr>
                                                        <tr className="table-warning1">
                                                            <td className="first-child">Moon</td>
                                                            <td>Capricorn</td>
                                                            <td>Saturn</td>
                                                            <td>Dhanishta</td>
                                                            <td>Mars</td>
                                                            <td>2454'31"</td>
                                                            <td>Direct</td>
                                                            <td>No</td>
                                                            <td>Yuva</td>
                                                            <td>1</td>
                                                            <td>Exalted</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="first-child">Mercury</td>
                                                            <td>Capricorn</td>
                                                            <td>Saturn</td>
                                                            <td>Dhanishta</td>
                                                            <td>Mars</td>
                                                            <td>2454'31"</td>
                                                            <td>Direct</td>
                                                            <td>No</td>
                                                            <td>Yuva</td>
                                                            <td>1</td>
                                                            <td>Exalted</td>
                                                        </tr>
                                                        <tr className="table-warning1">
                                                            <td className="first-child">Venus</td>
                                                            <td>Capricorn</td>
                                                            <td>Saturn</td>
                                                            <td>Dhanishta</td>
                                                            <td>Mars</td>
                                                            <td>2454'31"</td>
                                                            <td>Direct</td>
                                                            <td>No</td>
                                                            <td>Yuva</td>
                                                            <td>1</td>
                                                            <td>Exalted</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="first-child">Mars</td>
                                                            <td>Capricorn</td>
                                                            <td>Saturn</td>
                                                            <td>Dhanishta</td>
                                                            <td>Mars</td>
                                                            <td>2454'31"</td>
                                                            <td>Direct</td>
                                                            <td>No</td>
                                                            <td>Yuva</td>
                                                            <td>1</td>
                                                            <td>Exalted</td>
                                                        </tr>
                                                        <tr className="table-warning1">
                                                            <td className="first-child">Jupiter</td>
                                                            <td>Capricorn</td>
                                                            <td>Saturn</td>
                                                            <td>Dhanishta</td>
                                                            <td>Mars</td>
                                                            <td>2454'31"</td>
                                                            <td>Direct</td>
                                                            <td>No</td>
                                                            <td>Yuva</td>
                                                            <td>1</td>
                                                            <td>Exalted</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="first-child">Saturn</td>
                                                            <td>Capricorn</td>
                                                            <td>Saturn</td>
                                                            <td>Dhanishta</td>
                                                            <td>Mars</td>
                                                            <td>2454'31"</td>
                                                            <td>Direct</td>
                                                            <td>No</td>
                                                            <td>Yuva</td>
                                                            <td>1</td>
                                                            <td>Exalted</td>
                                                        </tr>
                                                        <tr className="table-warning1">
                                                            <td className="first-child">Rahu</td>
                                                            <td>Capricorn</td>
                                                            <td>Saturn</td>
                                                            <td>Dhanishta</td>
                                                            <td>Mars</td>
                                                            <td>2454'31"</td>
                                                            <td>Direct</td>
                                                            <td>No</td>
                                                            <td>Yuva</td>
                                                            <td>1</td>
                                                            <td>Exalted</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="first-child">Ketu</td>
                                                            <td>Capricorn</td>
                                                            <td>Saturn</td>
                                                            <td>Dhanishta</td>
                                                            <td>Mars</td>
                                                            <td>2454'31"</td>
                                                            <td>Direct</td>
                                                            <td>No</td>
                                                            <td>Yuva</td>
                                                            <td>1</td>
                                                            <td>Exalted</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {/* <div className="col-lg-10 mx-auto">
                            <div className="connnect-astrologer py-4">
                                <div className="astrology-heading text-center">
                                    <h6>
                                        Connect with an Astrologer on Call or Chat for more
                                        personalised detailed predictions.
                                    </h6>
                                </div>
                                <div className="talk-chat-button text-center mt-3">
                                    <Link to='/chat-astrologer'> <button className="btn btn-chat me-2 mb-2" > Chat to Astrologers </button></Link>
                                    <Link to='/talk-astrologer'> <button className="btn btn-talk mb-2"> Talk to Astrologers</button></Link>
                                </div>
                            </div>
                        </div> */}
                        <ConnectAstrologer />
                    </div>
                </div>
            </section>

            {/* mobile-view-start */}
            <section className="Free_Kundli mobile-view-free-kundali">
                <div className="container-fluid ">
                    <div className="row ">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb my-3">
                                <li className="breadcrumb-item"><Link to='/'>Home {'>'} </Link></li>
                                <li className="breadcrumb-item"><Link to='/freekundali'>Free Kundali {'>'} </Link></li>
                                <li className="breadcrumb-item active" ><Link to=''>kundli</Link></li>
                            </ol>
                        </nav>
                        <div className="col-lg-12 p-0">
                            <ul className="nav nav-pills  " id="pills-tab" role="tablist">
                                <Swiper
                                    modules={[Navigation, Autoplay]}
                                    pagination={false}
                                    navigation={false}
                                    // loop={true}
                                    breakpoints={{
                                        0: {
                                            slidesPerView: 3,
                                            // spaceBetween: 10,
                                        },
                                        360: {
                                            slidesPerView: 3,
                                            // spaceBetween: 10,
                                        },
                                        567: {
                                            slidesPerView: 4,
                                            // spaceBetween: 10,
                                        },
                                        640: {
                                            slidesPerView: 4,
                                            // spaceBetween: 10,
                                        },
                                        768: {
                                            slidesPerView: 4,
                                            // spaceBetween: 10,
                                        },

                                    }}

                                    className="suportres-slider" >
                                    <SwiperSlide className="swiper-slide1">
                                        <li className="nav-item " role="presentation">
                                            <Link to='/Basic-Kundali'> <button className="nav-link " id="pills-basic-tab" data-bs-toggle="pill" data-bs-target="#pills-basic"
                                                type="button" role="tab" aria-controls="pills-basic" aria-selected="true"> Basic </button></Link>
                                        </li>
                                    </SwiperSlide>

                                    <SwiperSlide className="swiper-slide1">
                                        <li className="nav-item" role="presentation">
                                            <Link to='/second-Kundali'>   <button className="nav-link active" id="pills-kundli-tab" data-bs-toggle="pill" data-bs-target="#pills-kundli" type="button"
                                                role="tab" aria-controls="pills-kundli" aria-selected="false">
                                                Kundli
                                            </button></Link>
                                        </li>
                                    </SwiperSlide>

                                    <SwiperSlide className="swiper-slide1">
                                        <li className="nav-item" role="presentation">
                                            <Link to='/kp-Kundali'>   <button className="nav-link " id="pills-kp-tab" data-bs-toggle="pill" data-bs-target="#pills-kp"
                                                type="button" role="tab" aria-controls="pills-kp" aria-selected="false">
                                                KP
                                            </button></Link>
                                        </li>
                                    </SwiperSlide>

                                    <SwiperSlide className="swiper-slide1">
                                        <li className="nav-item" role="presentation">
                                            <Link to="/free-ashtkvarga">
                                                <button className="nav-link" id="pills-ashtakvarga-tab" data-bs-toggle="pill" data-bs-target="#pills-ashtakvarga"
                                                    type="button" role="tab" aria-controls="pills-ashtakvarga" aria-selected="false">
                                                    Ashtakvarga
                                                </button></Link>
                                        </li>
                                    </SwiperSlide>

                                    <SwiperSlide className="swiper-slide1">
                                        <li className="nav-item" role="presentation">
                                            <Link to="/free-chart">
                                                <button className="nav-link" id="pills-charts-tab" data-bs-toggle="pill" data-bs-target="#pills-charts"
                                                    type="button" role="tab" aria-controls="pills-charts" aria-selected="false">
                                                    Charts
                                                </button></Link>
                                        </li>
                                    </SwiperSlide>

                                    <SwiperSlide className="swiper-slide1">
                                        <li className="nav-item" role="presentation">
                                            <Link to="/free-dasha">
                                                <button className="nav-link" id="pills-dasha-tab" data-bs-toggle="pill" data-bs-target="#pills-dasha"
                                                    type="button" role="tab" aria-controls="pills-dasha" aria-selected="false">
                                                    Dasha
                                                </button></Link>
                                        </li>
                                    </SwiperSlide>

                                    <SwiperSlide className="swiper-slide1">
                                        <li className="nav-item free-nav" role="presentation">
                                            <Link to={'/free_Report'}>
                                                <button className="nav-link" id="pills-report-tab" data-bs-toggle="pill" data-bs-target="#pills-report"
                                                    type="button" role="tab" aria-controls="pills-report" aria-selected="false">
                                                    Free Report
                                                </button>
                                            </Link>
                                        </li>
                                    </SwiperSlide>
                                </Swiper>
                            </ul>


                        </div>

                        <div className="col-md-12 ">

                            <SecondTabKundali />

                            <div className="row   mt-5">
                                <div className="col-md-12 p-0">
                                    <div className="table-responsive">
                                        <table className="table table-dashed-bordered  ruling-table ">
                                            <thead>
                                                <tr className="table-heading1">
                                                    <th>Planet</th>
                                                    <th>Sign</th>
                                                    <th className='sign-of'>Sign Lord</th>
                                                    <th>Nakshatra</th>
                                                    <th className='sign-of'>Naksh Lord</th>
                                                    <th>Degree</th>
                                                    <th>Retro(R)</th>
                                                    <th>Combust</th>
                                                    <th>Avastha</th>
                                                    <th>House</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="table-warning1">
                                                    <td className="first-child">Ascendent</td>
                                                    <td >Capricorn</td>
                                                    <td>Saturn</td>
                                                    <td>Dhanishta</td>
                                                    <td>Mars</td>
                                                    <td>2454'31"</td>
                                                    <td>Direct</td>
                                                    <td>No</td>
                                                    <td>---</td>
                                                    <td>1</td>
                                                    <td>---</td>
                                                </tr>
                                                <tr>
                                                    <td className="first-child">Sun</td>
                                                    <td>Capricorn</td>
                                                    <td>Saturn</td>
                                                    <td>Dhanishta</td>
                                                    <td>Mars</td>
                                                    <td>2454'31"</td>
                                                    <td>Direct</td>
                                                    <td>No</td>
                                                    <td>Yuva</td>
                                                    <td>1</td>
                                                    <td>Exalted</td>
                                                </tr>
                                                <tr className="table-warning1">
                                                    <td className="first-child">Moon</td>
                                                    <td>Capricorn</td>
                                                    <td>Saturn</td>
                                                    <td>Dhanishta</td>
                                                    <td>Mars</td>
                                                    <td>2454'31"</td>
                                                    <td>Direct</td>
                                                    <td>No</td>
                                                    <td>Yuva</td>
                                                    <td>1</td>
                                                    <td>Exalted</td>
                                                </tr>
                                                <tr>
                                                    <td className="first-child">Mercury</td>
                                                    <td>Capricorn</td>
                                                    <td>Saturn</td>
                                                    <td>Dhanishta</td>
                                                    <td>Mars</td>
                                                    <td>2454'31"</td>
                                                    <td>Direct</td>
                                                    <td>No</td>
                                                    <td>Yuva</td>
                                                    <td>1</td>
                                                    <td>Exalted</td>
                                                </tr>
                                                <tr className="table-warning1">
                                                    <td className="first-child">Venus</td>
                                                    <td>Capricorn</td>
                                                    <td>Saturn</td>
                                                    <td>Dhanishta</td>
                                                    <td>Mars</td>
                                                    <td>2454'31"</td>
                                                    <td>Direct</td>
                                                    <td>No</td>
                                                    <td>Yuva</td>
                                                    <td>1</td>
                                                    <td>Exalted</td>
                                                </tr>
                                                <tr>
                                                    <td className="first-child">Mars</td>
                                                    <td>Capricorn</td>
                                                    <td>Saturn</td>
                                                    <td>Dhanishta</td>
                                                    <td>Mars</td>
                                                    <td>2454'31"</td>
                                                    <td>Direct</td>
                                                    <td>No</td>
                                                    <td>Yuva</td>
                                                    <td>1</td>
                                                    <td>Exalted</td>
                                                </tr>
                                                <tr className="table-warning1">
                                                    <td className="first-child">Jupiter</td>
                                                    <td>Capricorn</td>
                                                    <td>Saturn</td>
                                                    <td>Dhanishta</td>
                                                    <td>Mars</td>
                                                    <td>2454'31"</td>
                                                    <td>Direct</td>
                                                    <td>No</td>
                                                    <td>Yuva</td>
                                                    <td>1</td>
                                                    <td>Exalted</td>
                                                </tr>
                                                <tr>
                                                    <td className="first-child">Saturn</td>
                                                    <td>Capricorn</td>
                                                    <td>Saturn</td>
                                                    <td>Dhanishta</td>
                                                    <td>Mars</td>
                                                    <td>2454'31"</td>
                                                    <td>Direct</td>
                                                    <td>No</td>
                                                    <td>Yuva</td>
                                                    <td>1</td>
                                                    <td>Exalted</td>
                                                </tr>
                                                <tr className="table-warning1">
                                                    <td className="first-child">Rahu</td>
                                                    <td>Capricorn</td>
                                                    <td>Saturn</td>
                                                    <td>Dhanishta</td>
                                                    <td>Mars</td>
                                                    <td>2454'31"</td>
                                                    <td>Direct</td>
                                                    <td>No</td>
                                                    <td>Yuva</td>
                                                    <td>1</td>
                                                    <td>Exalted</td>
                                                </tr>
                                                <tr>
                                                    <td className="first-child">Ketu</td>
                                                    <td>Capricorn</td>
                                                    <td>Saturn</td>
                                                    <td>Dhanishta</td>
                                                    <td>Mars</td>
                                                    <td>2454'31"</td>
                                                    <td>Direct</td>
                                                    <td>No</td>
                                                    <td>Yuva</td>
                                                    <td>1</td>
                                                    <td>Exalted</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="row">
                        <ConnectAstrologer />
                    </div>
                </div>
            </section>


        </>
    )
}

export default SecondKundali