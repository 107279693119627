import React, { useEffect, useRef } from "react";
import './banner.css'
import "swiper/css";
import { Swiper, SwiperSlide } from 'swiper/react';
import videoimg from '../image/video/Untitled-design.mp4';
import previmg from '../image/prev-2.png';
import nextimg from '../image/2arrow-back.png';
import { Navigation, Autoplay } from "swiper/modules";
import fixed_img from "../image/fixed_img.png"

const Banner = () => {
    const vidRef = useRef();

    useEffect(() => { vidRef.current.play(); }, []);
    return (
        <>
            <section class="banner-1">
                <div className="container-fluid">
                    <div class="row ">
                        {/* <div className="col-md-12 p-0"> */}

                        <Swiper
                            // install Swiper modules
                            modules={[Navigation, Autoplay,]}
                            spaceBetween={10}
                            slidesPerView={1}
                            navigation={true}
                            onSwiper={(swiper) => console.log(swiper)}
                            onSlideChange={() => console.log('slide change')}
                            autoplay={{
                                delay: 10000,
                                disableOnInteraction: false,
                                loop: true,
                            }}                    >
                            <SwiperSlide>
                                <div className="carousel-item banner-slider active">
                                    <video src={videoimg} ref={vidRef} muted loop className=" video-class-grid" />
                                    <div class="img-overlay col-lg-5 col-md-6 col-sm-12">
                                        <div class="text-holder ">
                                            <h2 className="mb-0">Bring More</h2>
                                            <h4 className="my-lg-4 my-md-2 my-sm-0 my-0"> Positivity</h4>
                                            <h3 className="my-lg-4 my-md-2 my-sm-2 my-1">to Your Life with Astro Booster</h3>
                                        </div>
                                        {/* <div className="my-lg-4 my-md-2 my-sm-2 my-1">
                                            <button className="btn btn-read">Read More</button>
                                        </div> */}
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="carousel-item banner-slider active">
                                    <video src={videoimg} ref={vidRef} muted loop className=" video-class-grid" />
                                    <div class="img-overlay col-lg-5 col-md-6 col-sm-12">
                                        <div class="text-holder ">
                                        <h2 className="mb-0">Bring More</h2>
                                            <h4 className="my-lg-4 my-md-2 my-sm-0 my-0"> Positivity</h4>
                                            <h3 className="my-lg-4 my-md-2 my-sm-2 my-1">to Your Life with Astro Booster</h3>
                                        </div>
                                        {/* <div className="my-lg-4 my-md-2 my-sm-2 my-1">
                                            <button className="btn btn-read">Read More</button>
                                        </div> */}
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="carousel-item banner-slider active">
                                    <video src={videoimg} ref={vidRef} muted loop className=" video-class-grid" />
                                    <div class="img-overlay col-lg-5 col-md-6 col-sm-12">
                                        <div class="text-holder ">
                                        <h2 className="mb-0">Bring More</h2>
                                            <h4 className="my-lg-4 my-md-2 my-sm-0 my-0"> Positivity</h4>
                                            <h3 className="my-lg-4 my-md-2 my-sm-2 my-1">to Your Life with Astro Booster</h3>
                                        </div>
                                        {/* <div className="my-lg-4 my-md-2 my-sm-2 my-1">
                                            <button className="btn btn-read">Read More</button>
                                        </div> */}
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>

                    </div>

                    <div className="row">
                        <div>
                            <img src={fixed_img} alt="fixed-img" className="fixed_image" />
                        </div>
                    </div>

                    {/* <div className="slide-down-div">
                        <button type="button" className="btn topdown">
                        <img src={fixed_img} alt="fixed-img" className="fixed_image" />
                        </button>
                    </div> */}
                </div>
            </section >
        </>
    )
}

export default Banner