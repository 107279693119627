import React from 'react'
import '../../../Horoscopes/Horoscope.css';
import dragonimg from '../../Chinese_Horoscope/Image/dragon1.png'
import { Link } from 'react-router-dom'
const Dragon = () => {
  return (
    <>
      <section className="Horoscope2023 desk-top-view-horoscope">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mt-4">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/chinese_Horoscope">Chinese Zodiac </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link to="">Dragon</Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>

          <div className="row pb-3">
            <div className="col-md-12">
              <div className="card mb-5">
                <div className='card-body'>
                  <div className="row">
                    <div className='col-md-9'>
                      <div className="Horoscope_info">
                        <div className="Horoscope_heading1 mb-2">
                          DRAGON
                        </div>
                        <p>
                          Ox is the second Chinese zodiac sign. 2021 is the year of the Ox Chinese zodiac sign and going ahead, 2033 will be the year of the Ox. People born with their Chinese zodiac sign as Ox are said to be very loyal, responsible, diligent and determined in nature. Oxen people are a bit old school in their approach and respect traditional ideas and ways of doing things in life.
                          These people try to be as much honest as possible as they tend to fear God and its ways.
                        </p>
                        <p>
                          Meanwhile, the Oxen personality can also get a bit cautious, less talkative and conservative in attitude. This is due to the introverted nature that they have always cherished in life. The conservative nature, however, is also a boon for the Ox as it is usually alleged to dip the confidence of the Oxen. Read the Chinese Horoscope of Ox to find remedies for the same.
                        </p>
                      </div>
                    </div>
                    <div className='col-md-3'>
                      <div className='image-holder text-end'>
                        <img src={dragonimg} className='area-img me-4' />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <p  className='mt-2'>
                      In love, the Chinese Ox horoscope tells us how these people like spending alone time with their partner. The Ox are always up for long term relations and flings don't entertain them.
                      Though it takes a while for the Ox to fall in love, however, once they have, there is no love like the Ox could give you.
                    </p>
                    <p>
                      If it excites you, Cristiano Ronaldo and Barack Obama too belongs to the Ox zodiac as per Chinese astrology.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* mobile-view-aries */}

      <section className="Horoscope2023 mobile-view-horoscope">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mt-4">
                  <li className="breadcrumb-item">
                    <Link to="/">Home {'>'} </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/chinese_Horoscope">Chinese Zodiac {'>'}</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link to="">  DRAGON</Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>

          <div className="row pb-3">
            <div className="col-md-12">
              <div className="card mt-3 mb-5">
                <div className='card-body'>
                  <div className="row">
                    <div className="Horoscope_info">
                      <div className="Horoscope_heading1 my-3">
                      DRAGON
                      </div>
                      <div className='row'>
                        <div className='col-sm-9 col-8'>
                          <p>
                            Ox is the second Chinese zodiac sign. 2021 is the year of the Ox Chinese zodiac sign and going ahead, 2033 will be the year of the Ox. People born with their Chinese zodiac sign as Ox are said to be very loyal, responsible, diligent and determined in nature. Oxen people are a bit old school in their approach and respect traditional ideas and ways of doing things in life.
                            These people try to be as much honest as possible as they tend to fear God and its ways.
                          </p>
                        </div>
                        <div className='col-sm-3 col-4'>
                          <div className='image-holder text-center'>
                            <img src={dragonimg} className='area-img ' />
                          </div>
                        </div>
                      </div>
                      <p>
                        Meanwhile, the Oxen personality can also get a bit cautious, less talkative and conservative in attitude. This is due to the introverted nature that they have always cherished in life. The conservative nature, however, is also a boon for the Ox as it is usually alleged to dip the confidence of the Oxen. Read the Chinese Horoscope of Ox to find remedies for the same.
                      </p>
                      <p>
                        In love, the Chinese Ox horoscope tells us how these people like spending alone time with their partner. The Ox are always up for long term relations and flings don't entertain them.
                        Though it takes a while for the Ox to fall in love, however, once they have, there is no love like the Ox could give you.
                      </p>
                      <p>
                        If it excites you, Cristiano Ronaldo and Barack Obama too belongs to the Ox zodiac as per Chinese astrology.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Dragon