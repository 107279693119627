import React from 'react'
import rateimg1 from '../../Astrologer-profile/Image/star-outline1.png';
import './dishaReview.css'
const DishaReview = () => {
    return (
        <>
            <section className='submit-modal'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xl-8 col-lg-9 col-md-10 mx-auto'>
                            <div className='content'>
                                <div className="text-holder text-center">
                                    <h5>Your chat with Paresh just ended</h5>
                                    <p>Rate your experience</p>
                                    <div className='rating-comment1 px-2'>
                                        <img src={rateimg1} className='rating-image me-lg-1 me-md-1 me-sm-1 me-0' />
                                        <img src={rateimg1} className='rating-image me-lg-1 me-md-1 me-sm-1 me-0' />
                                        <img src={rateimg1} className='rating-image me-lg-1 me-md-1 me-sm-1 me-0' />
                                        <img src={rateimg1} className='rating-image me-lg-1 me-md-1 me-sm-1 me-0' />
                                        <img src={rateimg1} className='rating-image me-lg-1 me-md-1 me-sm-1 me-0' />
                                    </div>
                                </div>
                                <div className="my-3 ">
                                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder='Write your review here......'></textarea>
                                    <label for="exampleFormControlTextarea1" className="form-label ">150/150</label>
                                </div>
                                <div className='mx-5 mt-5 '>
                                    <div className='box-rating-number p-3'>
                                        <div className='expert-heading text-center'>
                                            <p>How likely are you to promote the expert?</p>
                                        </div>
                                        <ul className="nav nav-pills justify-content-center mb-3" id="pills-tab" role="tablist">
                                            <li className="nav-item me-1" role="presentation">
                                                <button className="nav-link active" id="pills-one-tab" data-bs-toggle="pill" data-bs-target="#pills-one" type="button" role="tab" aria-controls="pills-one" aria-selected="true">1</button>
                                                <span>Not LIkely</span>
                                            </li>
                                            <li className="nav-item me-1" role="presentation">
                                                <button className="nav-link" id="pills-two-tab" data-bs-toggle="pill" data-bs-target="#pills-two" type="button" role="tab" aria-controls="pills-two" aria-selected="false">2</button>
                                            </li>
                                            <li className="nav-item me-1" role="presentation">
                                                <button className="nav-link" id="pills-three-tab" data-bs-toggle="pill" data-bs-target="#pills-three" type="button" role="tab" aria-controls="pills-three" aria-selected="false">3</button>
                                            </li>
                                            <li className="nav-item me-1" role="presentation">
                                                <button className="nav-link" id="pills-four-tab" data-bs-toggle="pill" data-bs-target="#pills-four" type="button" role="tab" aria-controls="pills-four" aria-selected="false" >4</button>
                                            </li>
                                            <li className="nav-item me-1" role="presentation">
                                                <button className="nav-link " id="pills-five-tab" data-bs-toggle="pill" data-bs-target="#pills-five" type="button" role="tab" aria-controls="pills-five" aria-selected="false">5</button>
                                            </li>
                                            <li className="nav-item me-1" role="presentation">
                                                <button className="nav-link" id="pills-six-tab" data-bs-toggle="pill" data-bs-target="#pills-six" type="button" role="tab" aria-controls="pills-six" aria-selected="false">6</button>
                                            </li>
                                            <li className="nav-item me-1" role="presentation">
                                                <button className="nav-link" id="pills-seven-tab" data-bs-toggle="pill" data-bs-target="#pills-seven" type="button" role="tab" aria-controls="pills-seven" aria-selected="false">7</button>
                                            </li>
                                            <li className="nav-item me-1" role="presentation">
                                                <button className="nav-link" id="pills-eight-tab" data-bs-toggle="pill" data-bs-target="#pills-eight" type="button" role="tab" aria-controls="pills-eight" aria-selected="false" >8</button>
                                            </li>
                                            <li className="nav-item me-1" role="presentation">
                                                <button className="nav-link" id="pills-nine-tab" data-bs-toggle="pill" data-bs-target="#pills-nine" type="button" role="tab" aria-controls="pills-nine" aria-selected="false">9</button>
                                            </li>
                                            <li className="nav-item me-1" role="presentation">
                                                <button className="nav-link" id="pills-ten-tab" data-bs-toggle="pill" data-bs-target="#pills-ten" type="button" role="tab" aria-controls="pills-ten" aria-selected="false" >10</button>
                                                <span>very LIkely</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='data-astrologer mx-5 mt-4 text-center'>
                                        <p>This data is confidential & your rating is not shown to the Astrologer.</p>
                                    </div>
                                </div>
                                <div className='button-leave text-center'>
                                    <button className='btn leave-review'>Leave a Review</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DishaReview