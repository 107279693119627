import React from 'react'
import './CallHistory.css';
import { Link } from "react-router-dom";
import deleteimg from '../wallet-tranction/image/delete-sweep.png'
import CheckLottey from '../../wallet/check-lottey/CheckLottey';
const CallHistory = () => {
    return (
        <>
            <section className='call-history'>
                <div className='container'>
                    <div className='row'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mt-3">
                                <li className="breadcrumb-item"><Link to='/'>Home {'>'} </Link></li>
                                <li className="breadcrumb-item"><Link to=''>Profile {'>'} </Link></li>
                                <li className="breadcrumb-item active" aria-current="page"> Call History</li>
                            </ol>
                        </nav>
                    </div>

                    <div className='row'>
                        <div className='col-md-11 mx-auto'>
                            <div className='row mb-5'>
                                <div className='total-call'>
                                    <h5>Total Call Count : 3</h5>
                                </div>
                                <div className='col-lg-6 col-md-12 '>
                                    <div className='card mb-4'>
                                        <div className='card-body'>
                                            <div className='row'>
                                                <div className='col-md-10 col-sm-10 col-10'>
                                                    <div className='d-flex'>
                                                        <div className='name-holder me-2'>
                                                            <h6 className=' name-identity'>SK</h6>
                                                        </div>

                                                        <div className='address-holder ps-md-3 ps-sm-1 ps-1 pt-2'>
                                                            <h6>Me</h6>
                                                            <p>Call Duration : 15min 20 Sec</p>
                                                            <p>Pune, Maharashtra, India</p>
                                                            <p> 23-7-23</p>
                                                            <p>09:40 PM </p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="vl"></div>
                                                    </div>
                                                    <div className='d-flex mt-md-3 mt-sm-4 mt-4'>
                                                        <div className='name-holder2 me-2'>
                                                            <h6 className=' name-identity'>PK</h6>
                                                        </div>

                                                        <div className='address-holder ps-md-3 ps-sm-1 ps-1 pt-2'>
                                                            <h6>Paresh Kumar</h6>
                                                            <span>Pune, Maharashtra, India</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-2 col-sm-2 col-2 '>
                                                    <div className='delete-icon '>
                                                        <img src={deleteimg} className='delete-img me-md-3 me-0' type="button" data-bs-toggle="modal"
                                                            data-bs-target="#deleteModal" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-12'>
                                    <div className='card mb-4'>
                                        <div className='card-body'>
                                            <div className='row'>
                                            <div className='col-md-10 col-sm-10 col-10'>
                                                    <div className='d-flex'>
                                                        <div className='name-holder me-2'>
                                                            <h6 className=' name-identity'>SK</h6>
                                                        </div>

                                                        <div className='address-holder ps-md-3 ps-sm-1 ps-1 pt-2'>
                                                            <h6>Me</h6>
                                                            <p>Call Duration : 15min 20 Sec</p>
                                                            <p>Pune, Maharashtra, India</p>
                                                            <p> 23-7-23</p>
                                                            <p>09:40 PM </p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="vl"></div>
                                                    </div>
                                                    <div className='d-flex mt-md-3 mt-sm-4 mt-4'>
                                                        <div className='name-holder2 me-2'>
                                                            <h6 className=' name-identity'>PK</h6>
                                                        </div>

                                                        <div className='address-holder ps-md-3 ps-sm-1 ps-1 pt-2'>
                                                            <h6>Paresh Kumar</h6>
                                                            <span>Pune, Maharashtra, India</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-2 col-sm-2 col-2'>
                                                    <div className='delete-icon '>
                                                        <img src={deleteimg} className='delete-img me-md-3 me-0' type="button" data-bs-toggle="modal"
                                                            data-bs-target="#deleteModal" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-12'>
                                    <div className='card mb-4'>
                                        <div className='card-body'>
                                            <div className='row'>
                                            <div className='col-md-10 col-sm-10 col-10'>
                                                    <div className='d-flex'>
                                                        <div className='name-holder me-2'>
                                                            <h6 className=' name-identity'>SK</h6>
                                                        </div>

                                                        <div className='address-holder ps-md-3 ps-sm-1 ps-1 pt-2'>
                                                            <h6>Me</h6>
                                                            <p>Call Duration : 15min 20 Sec</p>
                                                            <p>Pune, Maharashtra, India</p>
                                                            <p> 23-7-23</p>
                                                            <p>09:40 PM </p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="vl"></div>
                                                    </div>
                                                    <div className='d-flex mt-md-3 mt-sm-4 mt-4'>
                                                        <div className='name-holder2 me-2'>
                                                            <h6 className=' name-identity'>PK</h6>
                                                        </div>

                                                        <div className='address-holder ps-md-3 ps-sm-1 ps-1 pt-2'>
                                                            <h6>Paresh Kumar</h6>
                                                            <span>Pune, Maharashtra, India</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-2 col-sm-2 col-2'>
                                                    <div className='delete-icon '>
                                                        <img src={deleteimg} className='delete-img me-md-3 me-0' type="button" data-bs-toggle="modal"
                                                            data-bs-target="#deleteModal" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* <!--delete Modal start--> */}
            <section className="modal-delete">
                <div className="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div className="modal-body">
                                <div className='delete-text text-center'>
                                    <h5>Are you sure you want to permanently delete this history?</h5>
                                </div>
                                <div className='delete-button text-center my-3'>
                                    <button className='btn btn-cancle me-2'>Cancel</button>
                                    <button className='btn btn-ok' type="button" data-bs-toggle="modal"
                                        data-bs-target="#checkModal">Ok</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            {/* <!---delete-modal-end */}


            {/* <!-- Modal --> */}
            <div className='procced-modal'>
                <div className="modal fade" id="checkModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            {/* <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div> */}
                            <div className="modal-body">
                                <CheckLottey />
                                <div className='successfully text-center'>
                                    <button className='btn btn-success1'>Deleted Successfully</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CallHistory