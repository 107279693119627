import React from 'react'

const BasicFirstTab = () => {
  return (
    <>
      <div className="row mt-lg-5 mt-md-5  mt-4">
        <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12">
          <div className="basicDetails-txt mb-2">Basic Details</div>
          <div className="table-responsive">
            <table className="table table-bordered  ">
              <tbody>
                <tr className="table-warning1">
                  <td>Name</td>
                  <td>Shubham Yadav</td>
                </tr>
                <tr>
                  <td>Date</td>
                  <td>27/04/1997</td>
                </tr>
                <tr className="table-warning1">
                  <td>Time</td>
                  <td>01:35 AM</td>
                </tr>
                <tr>
                  <td>Place</td>
                  <td>Sultanpur, Uttar Pradesh, India</td>
                </tr>
                <tr className="table-warning1">
                  <td>Latitude</td>
                  <td>26.26</td>
                </tr>
                <tr>
                  <td>Longitude</td>
                  <td>82.07</td>
                </tr>
                <tr className="table-warning1">
                  <td>Timezone</td>
                  <td>GMT+5.5</td>
                </tr>
                <tr>
                  <td>Sunrise</td>
                  <td>5:27:46</td>
                </tr>
                <tr className="table-warning1">
                  <td>Sunset</td>
                  <td>18:31:20</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="basicDetails-txt mt-4 mb-3">Panchang Details</div>
          <div className="table-responsive ">
            <table className="table table-bordered  ">
              <tbody>
                <tr className="table-warning1">
                  <td>Tithi</td>
                  <td>KrishnaPanchami</td>
                </tr>
                <tr>
                  <td>Karan</td>
                  <td>Kaulav</td>
                </tr>
                <tr className="table-warning1">
                  <td>Yog</td>
                  <td>Shiva</td>
                </tr>
                <tr>
                  <td>Nakshatra</td>
                  <td>Mula</td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
        <div className='col-xxl-1 col-xl-1 col-lg-1'></div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
          <div className="basicDetails-txt my-2">Avakhada Details</div>
          <div className="table-responsive">
            <table className="table table-bordered  ">
              <tbody>
                <tr className="table-warning1">
                  <td>Varna</td>
                  <td>Kshattriya Yadav</td>
                </tr>
                <tr>
                  <td>Vashya</td>
                  <td>Nara</td>
                </tr>
                <tr className="table-warning1">
                  <td>Yoni</td>
                  <td>Sahawan</td>
                </tr>
                <tr>
                  <td>Gan</td>
                  <td>Rakshasa</td>
                </tr>
                <tr className="table-warning1">
                  <td>Nadi</td>
                  <td>Adhya</td>
                </tr>
                <tr>
                  <td>Sign</td>
                  <td>Sagittarus</td>
                </tr>
                <tr className="table-warning1">
                  <td>Sign Lord</td>
                  <td>Jupiter</td>
                </tr>
                <tr>
                  <td>Nakshatra-Charan</td>
                  <td>5:27:46</td>
                </tr>
                <tr className="table-warning1">
                  <td>Yog</td>
                  <td>Shiva</td>
                </tr>
                <tr>
                  <td>Karan</td>
                  <td>Kaulav</td>
                </tr>
                <tr className="table-warning1">
                  <td>Tithi</td>
                  <td>KrishnaPanchami</td>
                </tr>
                <tr>
                  <td>Yunja</td>
                  <td>Antya</td>
                </tr>
                <tr className="table-warning1">
                  <td>Tatva</td>
                  <td>Fire</td>
                </tr>
                <tr >
                  <td>Name alphabet</td>
                  <td>Ye</td>
                </tr>
                <tr className="table-warning1">
                  <td>Paya</td>
                  <td>Copper</td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default BasicFirstTab