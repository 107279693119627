import React from 'react'
import '../../../Horoscopes/Horoscope.css';
import rabbitimg from '../../Images/rabbit.png'
import { Link } from 'react-router-dom'
const Rabbit = () => {
    return (
        <>
            <section className="Horoscope2023 desk-top-view-horoscope">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mt-4">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/">Chinese Zodiac </Link>
                                    </li>
                                    <li className="breadcrumb-item active">
                                        <Link to="/freekundali">Rabbit Animal Sign</Link>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="row pb-3">
                        <div className="col-md-12">
                            <div className="card mb-5">
                                <div className='card-body'>
                                    <div className="row">
                                        <div className='col-md-9'>
                                            <div className="Horoscope_info">
                                                <div className="Horoscope_heading1 mb-2">
                                                    RABBIT
                                                </div>
                                                <p>
                                                    Rabbit is the fourth Chinese zodiac sign. 2023 will be the year of Rabbit. People belonging to the Chinese zodiac rabbit are said to be very optimistic, gracious, generous, kind-hearted and witty in nature. The rabbit sign is also said to be super alert about the changes in someone's attitude or feelings towards them. They are usually polite, however, that way of them shouldn't be mistaken for their incapability of showing you your place when needed.
                                                </p>
                                                <p>
                                                    Apart from these qualities, the Rabbit horoscope also lists some weaknesses that Rabbit possess. The Rabbit personality usually settles for less than what they could achieve in life. This is usually because of their lethargic attitude.
                                                </p>
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            <div className='image-holder text-end'>
                                                <img src={rabbitimg} className='area-img me-4' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <p>
                                            The Rabbit is also very emotional-kind. This quality, sometimes, makes them weak and prone to people who would use that dilemma to get their work done.
                                            Read the Chinese Horoscope of Rabbit to learn how you can fight such people using your intellect.
                                        </p>
                                        <p>
                                            In love, the Chinese Zodiac, Rabbit, likes it physical. The sign craves touch and is always up to steam up things between the sheets. The Rabbit can be trusted with being a great partner who would stand by you through thick and thin.
                                        </p>
                                        <p>
                                            If it excites you, Lionel Messi and Angelina Jolie belong to the Rabbit zodiac as per Chinese astrology.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* mobile-view-aries */}
            <section className="Horoscope2023 mobile-view-horoscope">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mt-4">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Home  {'>'} </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/chinese_Horoscope">Chinese Zodiac  {'>'} </Link>
                                    </li>
                                    <li className="breadcrumb-item active">
                                        <Link >Rabbit Animal Sign</Link>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="row pb-3">
                        <div className="col-md-12">
                            <div className="card mt-3 mb-5">
                                <div className='card-body'>
                                    <div className="row">
                                        <div className="Horoscope_info">
                                            <div className="Horoscope_heading1 my-3">
                                                RABBIT
                                            </div>
                                            <div className='row'>
                                                <div className='col-sm-9 col-8'>
                                                    <p>
                                                        Rabbit is the fourth Chinese zodiac sign. 2023 will be the year of Rabbit. People belonging to the Chinese zodiac rabbit are said to be very optimistic, gracious, generous, kind-hearted and witty in nature. The rabbit sign is also said to be super alert about the changes in someone's attitude or feelings towards them. They are usually polite, however, that way of them shouldn't be mistaken for their incapability of showing you your place when needed.
                                                    </p>
                                                </div>
                                                <div className='col-sm-3 col-4'>
                                                    <div className='image-holder text-center'>
                                                        <img src={rabbitimg} className='area-img ' />
                                                    </div>
                                                </div>
                                            </div>
                                            <p>
                                                Apart from these qualities, the Rabbit horoscope also lists some weaknesses that Rabbit possess. The Rabbit personality usually settles for less than what they could achieve in life. This is usually because of their lethargic attitude.
                                            </p>
                                            <p>
                                                The Rabbit is also very emotional-kind. This quality, sometimes, makes them weak and prone to people who would use that dilemma to get their work done.
                                                Read the Chinese Horoscope of Rabbit to learn how you can fight such people using your intellect.
                                            </p>
                                            <p>
                                                In love, the Chinese Zodiac, Rabbit, likes it physical. The sign craves touch and is always up to steam up things between the sheets. The Rabbit can be trusted with being a great partner who would stand by you through thick and thin.
                                            </p>
                                            <p>
                                                If it excites you, Lionel Messi and Angelina Jolie belong to the Rabbit zodiac as per Chinese astrology.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Rabbit