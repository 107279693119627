import React from 'react'
import '../second-tab-kundali/SecondTabKundali.css';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import 'react-tabs/style/react-tabs.css';
const ThirdTabKp = () => {
    return (
        <>
            <div className='row south-north-tabs desktop-view-free-kundali'>
                <ul className="nav nav-pills justify-content-end  mb-3" id="pills-tab" role="tablist">
                    <li className="nav-item mb-md-0 mb-sm-2 mb-2 north_indian_txt me-2" role="presentation">
                        <button className="nav-link active" id="pills-North1-tab" data-bs-toggle="pill" data-bs-target="#pills-North1" type="button" role="tab" aria-controls="pills-North1" aria-selected="true">North Indian</button>
                    </li>
                    <li className="nav-item north_indian_txt" role="presentation">
                        <button className="nav-link" id="pills-South1-tab" data-bs-toggle="pill" data-bs-target="#pills-South1" type="button" role="tab" aria-controls="pills-South1" aria-selected="false">South Indian</button>
                    </li>

                </ul>
                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-North1" role="tabpanel" aria-labelledby="pills-North1-tab" tabindex="0">
                        <div className='row mt-3 mb-md-4 mb-2'>
                            <div className='col-xl-6 col-lg-12 col-md-12'>
                                <div className="basicDetails-txt  text-md-center mb-2">
                                    Bhav Chalit Chart
                                </div>
                                <div class="table-responsive ">
                                    <table className='mx-auto'>
                                        <div className="main_Kundli">
                                            <div className="sub"></div>
                                            <div className="line1"></div>
                                            <div className="line2"></div>
                                            <p className="p1">AA <br />01</p>
                                            <p className="p2">AA<br />02</p>
                                            <p className="p3">AA<br />03</p>
                                            <p className="p4">AA<br />04</p>
                                            <p className="p5">AA<br />05</p>
                                            <p className="p6">AA<br />06</p>
                                            <p className="p7">AA<br />07</p>
                                            <p className="p8">AA<br />08</p>
                                            <p className="p9">AA<br />09</p>
                                            <p className="p10">AA<br />10</p>
                                            <p className="p11">AA<br />11</p>
                                            <p className="p12">AA<br />12</p>
                                        </div>
                                    </table>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-8 col-md-12 mx-auto">
                                <div className="basicDetails-txt text-center mb-2  mt-5">
                                    Ruling Planets
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-bordered  ruling-table">
                                        <tbody>
                                            <tr className="table-warning1">
                                                <th>----</th>
                                                <th>Sign Lord</th>
                                                <th>Sign</th>
                                                <th>Sign Lord</th>
                                            </tr>
                                            <tr>
                                                <td>Mo</td>
                                                <td>Venus</td>
                                                <td>Moon</td>
                                                <td>Rahu</td>
                                            </tr>
                                            <tr className="table-warning1">
                                                <td>Asc</td>
                                                <td>Mercury</td>
                                                <td>Moon</td>
                                                <td>rahu</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">Day Lord</td>
                                                <td colspan="2">Mercury</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-South1" role="tabpanel" aria-labelledby="pills-South1-tab" tabindex="0">
                        <div className='row mt-3 mb-4'>
                            <div className='col-xl-6 col-lg-12 col-md-12 '>
                                <div className="basicDetails-txt text-center mb-2">Lagna / Ascendant / Basic Birth Chart</div>
                                <div className="table-responsive">
                                    <table className="table table-bordered  lagna-table mx-auto">
                                        <tbody>
                                            <tr >
                                                <td>Name</td>
                                                <td>Shubham Yadav</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr >
                                                <td >Time</td>
                                                <td colSpan={2} rowSpan={2}></td>
                                                <td  ></td>
                                            </tr>
                                            <tr >
                                                <td >Time</td>
                                                <td></td>
                                            </tr>
                                            <tr >
                                                <td>Timezone</td>
                                                <td>GMT+5.5</td>
                                                <td>Sunrise</td>
                                                <td>5:27:46</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            <div className=" col-xl-6 col-lg-8  col-md-12 mx-auto">
                                <div className="basicDetails-txt text-center mb-2 mt-5">
                                    Ruling Planets
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-bordered  ruling-table">
                                        <thead>
                                            <tr >
                                                <th>----</th>
                                                <th>Sign Lord</th>
                                                <th>Sign</th>
                                                <th>Sign Lord</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="table-warning1">
                                                <td >Mo</td>
                                                <td>Venus</td>
                                                <td>Moon</td>
                                                <td>Rahu</td>
                                            </tr>
                                            <tr >
                                                <td>Asc</td>
                                                <td>Mercury</td>
                                                <td>Moon</td>
                                                <td>rahu</td>
                                            </tr>
                                            <tr className="table-warning1">
                                                <td colspan="2">Day Lord</td>
                                                <td colspan="2">Mercury</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/* mobile-view-start */}
            <div className=' south-north-tabs mobile-view-free-kundali'>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                    <Nav variant="pills" className=" nav-pills justify-content-end  mt-md-0 mt-4 mb-3" id="pills-tab" role="tablist">
                        <Nav.Item className="nav-item mb-md-0 mb-sm-2 mb-2 north_indian_txt me-2" role="presentation">
                            <Nav.Link eventKey="first" className="nav-link me-2 pt-1">North Indian </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="nav-item north_indian_txt" role="presentation">
                            <Nav.Link eventKey="second" className="nav-link pt-1">South Indian</Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <Tab.Content>

                        <Tab.Pane eventKey="first">
                            <div className=' mt-3 mb-md-4 mb-2'>
                                <div className='col-sm-12'>
                                    <div className="basicDetails-txt  text-center mb-2">
                                        Bhav Chalit Chart
                                    </div>
                                    <div class="main-kundali  mx-auto">
                                        <div class="sub"></div>
                                        <div class="line1"></div>
                                        <div class="line2"></div>

                                        <p class="p1">01</p>
                                        <p class="p2">02</p>
                                        <p class="p3">03</p>
                                        <p class="p4">04</p>
                                        <p class="p5">05</p>
                                        <p class="p6">06</p>
                                        <p class="p7">07</p>
                                        <p class="p8">08</p>
                                        <p class="p9">09</p>
                                        <p class="p10">10</p>
                                        <p class="p11">11</p>
                                        <p class="p12">12</p>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="basicDetails-txt mt-4 mb-2">
                                        Ruling Planets
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-bordered  ruling-table">
                                            <tbody>
                                                <tr className="table-warning1">
                                                    <th>----</th>
                                                    <th>Sign Lord</th>
                                                    <th>Sign</th>
                                                    <th>Sign Lord</th>
                                                </tr>
                                                <tr>
                                                    <td>Mo</td>
                                                    <td>Venus</td>
                                                    <td>Moon</td>
                                                    <td>Rahu</td>
                                                </tr>
                                                <tr className="table-warning1">
                                                    <td>Asc</td>
                                                    <td>Mercury</td>
                                                    <td>Moon</td>
                                                    <td>rahu</td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2">Day Lord</td>
                                                    <td colspan="2">Mercury</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            <div className='row mt-3 mb-4'>
                                <div className='col-sm-12  '>
                                    <div className="basicDetails-txt text-center mb-2">Lagna / Ascendant / Basic Birth Chart</div>
                                    <div className="table-responsive ">
                                        <table className="table table-bordered mx-auto lagna-table">
                                            <tbody>
                                                <tr >
                                                    <td>Name</td>
                                                    <td>Shubham Yadav</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr >
                                                    <td >Time</td>
                                                    <td colSpan={2} rowSpan={2}></td>
                                                    <td  ></td>
                                                </tr>
                                                <tr >
                                                    <td >Time</td>
                                                    <td></td>
                                                </tr>
                                                <tr >
                                                    <td>Timezone</td>
                                                    <td>GMT+5.5</td>
                                                    <td>Sunrise</td>
                                                    <td>5:27:46</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                                <div className="col-sm-12 mx-auto">
                                    <div className="basicDetails-txt mb-2">
                                        Ruling Planets
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-bordered  ruling-table">
                                            <thead>
                                                <tr >
                                                    <th>----</th>
                                                    <th>Sign Lord</th>
                                                    <th>Sign</th>
                                                    <th>Sign Lord</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="table-warning1">
                                                    <td >Mo</td>
                                                    <td>Venus</td>
                                                    <td>Moon</td>
                                                    <td>Rahu</td>
                                                </tr>
                                                <tr >
                                                    <td>Asc</td>
                                                    <td>Mercury</td>
                                                    <td>Moon</td>
                                                    <td>rahu</td>
                                                </tr>
                                                <tr className="table-warning1">
                                                    <td colspan="2">Day Lord</td>
                                                    <td colspan="2">Mercury</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </>
    )
}

export default ThirdTabKp