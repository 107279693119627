import React from 'react'
import { Link } from "react-router-dom";
import homeimg from '../image/home-icon.png';
import searchimg from '../image/search.png';
import deleteimg from '../image/delete-sweep.png';
const Kundli_History = () => {
    return (
        <>
            <section class="kundali-modal">
                <div className='container'>
                    <div class="row">
                        <div className='col-md-12'>
                            <nav aria-label="breadcrumb ">
                                <ol class="breadcrumb mt-3">
                                    <li class="breadcrumb-item"><Link to='/'>Home {'>'}</Link></li>
                                    <li class="breadcrumb-item active" aria-current="page"><Link to='/Kundali-history'>Kundali History</Link></li>
                                </ol>
                            </nav>
                        </div>
                        <div className='col-xxl-6 col-xl-6 col-md-8  mt-4   mx-auto mb-5'  >
                            {/* <div className='kundali-history text-center mb-5 mt-5'>
                                <h6 className=''>Kundali History</h6>
                            </div> */}
                            <div class="input-group mb-3">
                                <span class="input-group-text" id="basic-addon1"><img src={searchimg} className='search-img' /></span>
                                <input type="text" class="form-control" placeholder="Search" aria-label="Username" aria-describedby="basic-addon1" />
                            </div>

                            <div className='card mb-4'>
                                <div className='card-body'>
                                    <div className='d-flex'>
                                        <Link to='/kundali-match'>
                                            <div className='d-flex'>
                                                <div className='name-holder me-2'>
                                                    <h6 className='pt-2 name-identity'>SK</h6>
                                                </div>

                                                <div className='address-holder'>
                                                    <h6>Shubham Kumar</h6>
                                                    <p>21 Aug 2023, 12:45 PM</p>
                                                    <span>Pune, Maharashtra. India.</span>
                                                </div>
                                            </div></Link>

                                        <div className='delete-icon '>
                                            <img src={deleteimg} className='delete-img me-md-3 me-0' type="button" data-bs-toggle="modal"
                                                data-bs-target="#deleteModal" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='card mb-4'>
                                <div className='card-body'>
                                    <div className='d-flex'>
                                    <Link to='/kundali-match'>
                                            <div className='d-flex'>
                                        <div className='Kiran-holder me-2'>
                                            <h6 className='pt-2 name-identity'>KK</h6>
                                        </div>

                                        <div className='address-holder'>
                                            <h6>Kiran Kumar</h6>
                                            <p>21 Aug 2023, 12:45 PM</p>
                                            <span>Pune, Maharashtra. India.</span>
                                        </div>
                                         </div></Link>

                                        <div className='delete-icon '>
                                            <img src={deleteimg} className='delete-img me-md-3 me-0' />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='card mb-5'>
                                <div className='card-body'>
                                    <div className='d-flex'>
                                    <Link to='/kundali-match'>
                                            <div className='d-flex'>
                                        <div className='arjun-holder me-2'>
                                            <h6 className='pt-2 name-identity'>AM</h6>
                                        </div>

                                        <div className='address-holder'>
                                            <h6>Arjun Mane</h6>
                                            <p>21 Aug 2023, 12:45 PM</p>
                                            <span>Pune, Maharashtra. India.</span>
                                        </div>
                                        </div></Link>
                                        <div className='delete-icon '>
                                            <img src={deleteimg} className='delete-img me-md-3 me-0' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='home-icon'>
                            <img src={homeimg} className='home-img' />
                        </div> */}
                    </div>
                </div>
            </section>
            {/* <!--Kundali-history Modal end--> */}

            {/* <!--delete Modal start--> */}
            <section class="modal-delete">
                <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div class="modal-body">
                                <div className='delete-text text-center'>
                                    <h5>Are you sure you want to permanently delete this Kundali history?</h5>
                                </div>
                                <div className='delete-button text-center my-3'>
                                    <button className='btn btn-cancle me-2'>Cancel</button>
                                    <button className='btn btn-ok'>Ok</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            {/* <!---delete-modal-end */}

        </>
    )
}

export default Kundli_History