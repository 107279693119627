import React, { useEffect, useState } from 'react'
import '../chat-astrologer/Chatastrolger.css';
import { Link } from "react-router-dom";
import sortimg from '../chat-astrologer/image/sort-amount-down.png';
import filterimg from '../chat-astrologer/image/awesome-filter.png';
import approved from '../chat-astrologer/image/approve.png';
import profileimg from '../chat-astrologer/image/profile.png';
import starimg from '../chat-astrologer/image/awesome-star.png';
import chatmodalimg from '../Astrologer-profile/Image/phone-call.png'
import SortTalkModal from './sort-modal-talk/SortTalkModal';
import FilterModalTalk from './filter-modal-talk/FilterModalTalk';
import TalkToAStrolger from '../talk-to-astrologer/Animation/talkToAstrolger/TalkToAstrolger'
import CallScreenDisha from './call-screen-disha-1/CallScreenDisha';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Date_icon from "../Images/Date_icon.png";
import CallLotteyastro from './call-screen-disha-1/call-lottey/CallLotteyastro';
import CallModalparesh from './call-modal-paresh/CallModalparesh';
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

const TalkAstrologer = () => {
    const [startdate, setStartDate] = useState(new Date());
    return (
        <>
            <section className="chat-astrologer desktop-view-talk-astrologer">
                <div className="container">
                    <div className='row'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page"> Talk to Astrologers</li>
                            </ol>
                        </nav>

                        <div className='col-md-12'>
                            <div className='heading-holder text-center'>
                                <h2>Our<span className='ms-2'>Expert</span> </h2>
                            </div>
                            <div className="main-heading mt-1">
                                <div className="line me-2"></div>
                                <div className="square"></div>
                                <div className="line ms-2"></div>
                            </div>
                        </div>
                    </div>

                    <div className='row mt-4'>
                        {/* <ul className="nav justify-content-center  nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item mb-2" role="presentation">
                                <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">All</button>
                            </li>
                            <li className="nav-item mb-2" role="presentation">
                                <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">PAID Services</button>
                            </li>
                            <li className="nav-item mb-2" role="presentation">
                                <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">FREE Services</button>
                            </li>

                        </ul> */}
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">

                                <div className="row product-icon">
                                    <div className="d-flex justify-content-end mb-4">
                                        <div className="filter-holder pe-2" type="button" data-bs-toggle="modal" data-bs-target="#sortModaltalk">
                                            <img src={sortimg} className="me-2 sort-img" /><span>Sort By</span>
                                        </div>
                                        <div className="border-filter ps-3" type="button" data-bs-toggle="modal" data-bs-target="#filterModaltalk">
                                            <img src={filterimg} className="me-2 sort-img" /><span>Filter</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">

                                        <div className="card mb-3">
                                            <div className="card-body">
                                                <Link to='/astrologer-profile'>  <div className="img-holder text-end">
                                                    <img src={approved} className="right-img" />
                                                </div>
                                                    <div className="second-img text-center">
                                                        {/* <img src={profileimg} className="profile-img" /> */}
                                                        <TalkToAStrolger />
                                                    </div>
                                                    <div className="address-holder">
                                                        <h5 className="mt-1">PARESH</h5>
                                                        <div className="yellow-boder mb-3"></div>
                                                        <p>Vedic, Palmistry, Face Reading English, Hindi & Marathi</p>
                                                        <span>Exp : 15 Years</span>
                                                    </div>
                                                    <div className="free-delivery">
                                                        <p>FREE<span>30 min</span></p>
                                                    </div></Link>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="star-img">
                                                            <img src={starimg} className='' />
                                                            <img src={starimg} />
                                                            <img src={starimg} />
                                                            <img src={starimg} />
                                                            <img src={starimg} />
                                                        </div>
                                                        <div className="order">
                                                            <p>34528 orders</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="button-holder text-end">
                                                            <button type="button" className="btn btn-chat-available" data-bs-toggle="modal"
                                                                data-bs-target="#talkModal2">Call</button>
                                                            <p className='available '>Available</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                                        <div className="card mb-3">
                                            <div className="card-body">
                                                <div className="img-holder text-end">
                                                    <img src={approved} className="right-img" />
                                                </div>
                                                <div className="second-img text-center">
                                                    {/* <img src={profileimg} className="profile-img" /> */}
                                                    <TalkToAStrolger />
                                                </div>
                                                <div className="address-holder">
                                                    <h5 className="mt-1">DISHA</h5>
                                                    <div className="yellow-boder mb-3"></div>
                                                    <p>Vedic, Palmistry, Face Reading English, Hindi & Marathi</p>
                                                    <span>Exp : 15 Years</span>
                                                </div>
                                                <div className="free-delivery">
                                                    <p>FREE<span>30 min</span></p>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="star-img">
                                                            <img src={starimg} className='' />
                                                            <img src={starimg} />
                                                            <img src={starimg} />
                                                            <img src={starimg} />
                                                            <img src={starimg} />
                                                        </div>
                                                        <div className="order">
                                                            <p>34528 orders</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="button-holder text-end">
                                                            <button type="button" className="btn btn-chat-available" data-bs-toggle="modal"
                                                                data-bs-target="#callscreendisha">Call</button>
                                                            <p className='available'>Available</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                                        <div className="card mb-3">
                                            <div className="card-body">
                                                <div className="img-holder text-end">
                                                    <img src={approved} className="right-img" />
                                                </div>
                                                <div className="second-img text-center">
                                                    {/* <img src={profileimg} className="profile-img" /> */}
                                                    <TalkToAStrolger />
                                                </div>
                                                <div className="address-holder">
                                                    <h5 className="mt-1">PARESH</h5>
                                                    <div className="yellow-boder mb-3"></div>
                                                    <p>Vedic, Palmistry, Face Reading English, Hindi & Marathi</p>
                                                    <span>Exp : 15 Years</span>
                                                </div>
                                                <div className="free-delivery">
                                                    <p>FREE<span>30 min</span></p>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="star-img">
                                                            <img src={starimg} className='' />
                                                            <img src={starimg} />
                                                            <img src={starimg} />
                                                            <img src={starimg} />
                                                            <img src={starimg} />
                                                        </div>
                                                        <div className="order">
                                                            <p>34528 orders</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="button-holder text-end">
                                                            <button type="button" className="btn btn-chat" data-bs-toggle="modal"
                                                                data-bs-target="#talkModal2">Call</button>
                                                            <p className='wait'>Wait - 15 min</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                                        <div className="card mb-3">
                                            <div className="card-body">
                                                <div className="img-holder text-end">
                                                    <img src={approved} className="right-img" />
                                                </div>
                                                <div className="second-img text-center">
                                                    {/* <img src={profileimg} className="profile-img" /> */}
                                                    <TalkToAStrolger />
                                                </div>
                                                <div className="address-holder">
                                                    <h5 className="mt-1">PARESH</h5>
                                                    <div className="yellow-boder mb-3"></div>
                                                    <p>Vedic, Palmistry, Face Reading English, Hindi & Marathi</p>
                                                    <span>Exp : 15 Years</span>
                                                </div>
                                                <div className="free-delivery">
                                                    <p>FREE<span>30 min</span></p>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="star-img">
                                                            <img src={starimg} className='' />
                                                            <img src={starimg} />
                                                            <img src={starimg} />
                                                            <img src={starimg} />
                                                            <img src={starimg} />
                                                        </div>
                                                        <div className="order">
                                                            <p>34528 orders</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="button-holder text-end">
                                                            <button type="button" className="btn btn-chat-available" data-bs-toggle="modal"
                                                                data-bs-target="#talkModal2">Call</button>
                                                            <p className='available'>Available</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                                        <div className="card mb-3">
                                            <div className="card-body">
                                                <div className="img-holder text-end">
                                                    <img src={approved} className="right-img" />
                                                </div>
                                                <div className="second-img text-center">
                                                    {/* <img src={profileimg} className="profile-img" /> */}
                                                    <TalkToAStrolger />
                                                </div>
                                                <div className="address-holder">
                                                    <h5 className="mt-1">PARESH</h5>
                                                    <div className="yellow-boder mb-3"></div>
                                                    <p>Vedic, Palmistry, Face Reading English, Hindi & Marathi</p>
                                                    <span>Exp : 15 Years</span>
                                                </div>
                                                <div className="free-delivery">
                                                    <p>FREE<span>30 min</span></p>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="star-img">
                                                            <img src={starimg} className='' />
                                                            <img src={starimg} />
                                                            <img src={starimg} />
                                                            <img src={starimg} />
                                                            <img src={starimg} />
                                                        </div>
                                                        <div className="order">
                                                            <p>34528 orders</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="button-holder text-end">
                                                            <button type="button" className="btn btn-chat-available" data-bs-toggle="modal"
                                                                data-bs-target="#talkModal2">Call</button>
                                                            <p className='available'>Available</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                                        <div className="card mb-3">
                                            <div className="card-body">
                                                <div className="img-holder text-end">
                                                    <img src={approved} className="right-img" />
                                                </div>
                                                <div className="second-img text-center">
                                                    {/* <img src={profileimg} className="profile-img" /> */}
                                                    <TalkToAStrolger />
                                                </div>
                                                <div className="address-holder">
                                                    <h5 className="mt-1">PARESH</h5>
                                                    <div className="yellow-boder mb-3"></div>
                                                    <p>Vedic, Palmistry, Face Reading English, Hindi & Marathi</p>
                                                    <span>Exp : 15 Years</span>
                                                </div>
                                                <div className="free-delivery">
                                                    <p>FREE<span>30 min</span></p>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="star-img">
                                                            <img src={starimg} className='' />
                                                            <img src={starimg} />
                                                            <img src={starimg} />
                                                            <img src={starimg} />
                                                            <img src={starimg} />
                                                        </div>
                                                        <div className="order">
                                                            <p>34528 orders</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="button-holder text-end">
                                                            <button type="button" className="btn btn-chat" data-bs-toggle="modal"
                                                                data-bs-target="#talkModal2">Call</button>
                                                            <p className='wait'>Wait - 15 min</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                                        <div className="card mb-3">
                                            <div className="card-body">
                                                <div className="img-holder text-end">
                                                    <img src={approved} className="right-img" />
                                                </div>
                                                <div className="second-img text-center">
                                                    {/* <img src={profileimg} className="profile-img" /> */}
                                                    <TalkToAStrolger />
                                                </div>
                                                <div className="address-holder">
                                                    <h5 className="mt-1">PARESH</h5>
                                                    <div className="yellow-boder mb-3"></div>
                                                    <p>Vedic, Palmistry, Face Reading English, Hindi & Marathi</p>
                                                    <span>Exp : 15 Years</span>
                                                </div>
                                                <div className="free-delivery">
                                                    <p>₹50min</p>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="star-img">
                                                            <img src={starimg} className='' />
                                                            <img src={starimg} />
                                                            <img src={starimg} />
                                                            <img src={starimg} />
                                                            <img src={starimg} />
                                                        </div>
                                                        <div className="order">
                                                            <p>34528 orders</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="button-holder text-end">
                                                            <button type="button" className="btn btn-chat" data-bs-toggle="modal"
                                                                data-bs-target="#talkModal2">Call</button>
                                                            <p className='wait ms-3'>Wait - 15 min</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                                        <div className="card mb-3">
                                            <div className="card-body">
                                                <div className="img-holder text-end">
                                                    <img src={approved} className="right-img" />
                                                </div>
                                                <div className="second-img text-center">
                                                    {/* <img src={profileimg} className="profile-img" /> */}
                                                    <TalkToAStrolger />
                                                </div>
                                                <div className="address-holder">
                                                    <h5 className="mt-1">PARESH</h5>
                                                    <div className="yellow-boder mb-3"></div>
                                                    <p>Vedic, Palmistry, Face Reading English, Hindi & Marathi</p>
                                                    <span>Exp : 15 Years</span>
                                                </div>
                                                <div className="free-delivery">
                                                    <p>₹50min</p>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="star-img">
                                                            <img src={starimg} className='' />
                                                            <img src={starimg} />
                                                            <img src={starimg} />
                                                            <img src={starimg} />
                                                            <img src={starimg} />
                                                        </div>
                                                        <div className="order">
                                                            <p>34528 orders</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="button-holder text-end">
                                                            <button type="button" className="btn btn-chat-available" data-bs-toggle="modal"
                                                                data-bs-target="#talkModal2">Call</button>
                                                            <p className='available'>Available</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='button-holder text-center my-5'>
                                        <button className='btn btn-view'> View More</button>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>
            </section>
            <FilterModalTalk />
            <SortTalkModal />
            <CallScreenDisha />
            {/* **********<!--call Modal start--> ************/}
            <section className="modal-chat">
                <div className="modal fade" id="talkModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="text-holder text-center">
                                    <h5>Continue with Phone</h5>
                                    <p>You will receive a 4 digit code<br />for verification</p>
                                </div>
                                <form>
                                    <div className="form ">
                                        <p className='ms-md-4 ms-sm-5 ms-5'>Enter your phone number</p>
                                        <div className="input-group mb-4">
                                            <span className="input-group-text" id="basic-addon1"><img src={chatmodalimg} className='modal-chat-img' /></span>
                                            <input type="text" className="form-control" placeholder="Enter mobile no."
                                                aria-label="Username" aria-describedby="basic-addon1" />
                                        </div>
                                        <div className="button-holder text-center">
                                            <button type='button' className="btn btn-otp" data-bs-toggle="modal" data-bs-target="#phoneotpmodal">GET OTP</button>
                                        </div>

                                    </div>
                                </form>
                                <div className="term-condition text-center my-3">
                                    <p>By Signing up, you agree to our<br /> <span>Terms of Use and Privacy Policy</span></p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            {/* **********<!--call Modal end--> ************/}
            {/***************  phone-otp-madal start *********** */}
            <section className="modal-chat">
                <div className="modal fade" id="phoneotpmodal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="text-holder text-center">
                                    <h5 className='mb-4'>Verify Phone</h5>
                                    <p className='mb-4'>OTP sent to +91-9096486896</p>
                                </div>
                                <form>
                                    <div className="form ">
                                        <div className='d-flex justify-content-center mb-3'>
                                            <input type="text" class="form-control otp-form me-3" id="formGroupExampleInput1" />
                                            <input type="text" class="form-control otp-form me-3" id="formGroupExampleInput2" />
                                            <input type="text" class="form-control otp-form me-3" id="formGroupExampleInput3" />
                                            <input type="text" class="form-control otp-form" id="formGroupExampleInput4" />
                                        </div>
                                        <div className="button-holder text-center">
                                            <button type='button' className="btn btn-otp" data-bs-toggle="modal" data-bs-target="#phoneformmodal">LOGIN</button>
                                        </div>

                                    </div>
                                </form>
                                <div className="term-condition1 text-center my-3">
                                    <p>Resend OTP available in<span className='second'> 30s</span></p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            {/***************  phone-otp-madal end*********** */}
            {/***************  phone-form-madal start *********** */}
            <section className="get-kundali-modal1">
                <div className="modal fade" id="phoneformmodal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
                            <div className="modal-header pb-0">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body pt-0">
                                <div className='form-bg ps-3 pe-3'>
                                    <form className="row justify-content-center ">
                                        <div className="col-md-4 col-sm-4 col-4">
                                            <div className="border-dott mt-md-3 mt-2"></div>
                                        </div>
                                        <div className="col-md-2 col-sm-2 col-4">
                                            <div className="heading-holder">
                                                <h6>Register</h6>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-4 col-4">
                                            <div className="border-dott mt-md-3 mt-2"></div>
                                        </div>
                                        <div className='col-12 '>
                                            <div className='text-center text-holder my-0'>
                                                <p>Create your account, It's only takes a minute.</p>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <label for="inputEmail4" className="form-label">
                                                Name*
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="inputEmail4"
                                            />
                                        </div>
                                        <div className="col-6">
                                            <label for="inputemail" className="form-label ">
                                                Gender*
                                            </label>
                                            <select
                                                className="form-select "
                                                aria-label="Default select example"
                                            >
                                                <option selected>Male</option>
                                                <option value="1">Female</option>
                                            </select>
                                        </div>
                                        <div className="col-6">
                                            <label for="inputtext4" className="form-label"> Birth Place* </label>
                                            <input type="text" className="form-control" id="inputtext4" />
                                        </div>
                                        <div className="col-6">
                                            <label for="inputemail" className="form-label ">
                                                Email
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="inputEmail4"
                                            />
                                        </div>

                                        {/* <div className="row"> */}
                                        <div className="col-lg-6 col-md-6 col-sm-8 col-8 pe-0">
                                            <div className="row">
                                                <div className="col-lg-7 col-md-7 col-sm-7 col-8 ">
                                                    <div className="mt-1">
                                                        <label for="inputAddress2" className="form-label">
                                                            Date of Birth*
                                                        </label>
                                                        <div className="input-group">
                                                            <span
                                                                class="input-group-text"
                                                                id="basic-addon1"
                                                            >
                                                                <img src={Date_icon} className="Date_icon" />
                                                            </span>

                                                            <div className="DatePick">
                                                                <DatePicker
                                                                    selected={startdate}
                                                                    onChange={(date) => setStartDate(date)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-5 col-md-5 col-sm-5 col-4 ">
                                                    <label
                                                        for="inputCity"
                                                        className="form-label mt-3"
                                                    ></label>
                                                    <input
                                                        type="time"
                                                        className="form-control mt-2"
                                                        id="inputCity"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-4 col-4 ps-0">
                                            <div className="form-radio1 ">
                                                <div className="form-check hours-time">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="flexRadioDefault"
                                                        id="flexRadioDefault1"
                                                        checked
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        for="flexRadioDefault1"
                                                    >
                                                        AM
                                                    </label>
                                                </div>
                                                <div className="form-check  hours-time">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="flexRadioDefault"
                                                        id="flexRadioDefault2"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        for="flexRadioDefault2"
                                                    >
                                                        PM
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        {/* </div> */}
                                    </form>
                                    <div className="button-holder text-center mt-3">
                                        <button type='button' className="btn btn-otp" data-bs-toggle="modal" data-bs-target="#leavemodal">Start call with Paresh</button>
                                    </div>
                                    <div className="term-condition1 text-center my-3">
                                        <p>Already have an account? Login in to your account.</p>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
            {/* *************phone-form-madal end ************/}

            {/***************  leave-otp-madal start *********** */}
            <section className="modal-leave">
                <div className="modal fade" id="leavemodal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            {/* <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div> */}
                            <div className="modal-body">
                                <div className='d-flex'>
                                    <div className='call-name'>
                                        <TalkToAStrolger />
                                        <p>Call</p>
                                    </div>
                                    <div className='time-holder mt-4'>
                                        <p>Paresh</p>
                                        <span>₹ 0/min</span>
                                        <p>Wait Time - 1 min</p>
                                    </div>
                                    <div className='leave-button mt-md-5 mt-sm-5 mt-5'>
                                        <button type='button' className='btn btn-leave' data-bs-toggle="modal" data-bs-target="#leavechatmodal">Leave</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            {/***************  leave-otp-madal end*********** */}
            {/***************  leave-chat-madal start*********** */}
            <section class="modal-delete">
                <div class="modal fade" id="leavechatmodal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div class="modal-body">
                                <div className='delete-text text-center'>
                                    <h5>Are you sure you want to Leave this Chat</h5>
                                </div>
                                <div className='delete-button text-center my-4'>
                                    <button type='button' className='btn btn-cancle me-3' data-bs-toggle="modal" data-bs-target="#callModalparesh">Cancel</button>
                                    <button className='btn btn-ok'>Ok</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <CallModalparesh />
            {/***************  leave-chat-madal end*********** */}

            {/* desktop-view-end */}


            {/* mobile-view-start */}
            <section className="chat-astrologer mobile-view-talk-astrologer">
                <div className="container">
                    <div className='row'>
                        <div className='col-6'>
                            <nav aria-label="breadcrumb ">
                                <ol className="breadcrumb mt-3">
                                    <li className="breadcrumb-item"><Link to='/'>Home {'>'} </Link></li>
                                    <li className="breadcrumb-item active" aria-current="page"> Talk to Astrologers</li>
                                </ol>
                            </nav>
                        </div>
                        <div className='col-6'>
                            <div className="product-icon mt-3">
                                <div className="d-flex justify-content-end mb-4">
                                    <div className="filter-holder pe-1" type="button" data-bs-toggle="modal" data-bs-target="#sortModaltalk">
                                        <img src={sortimg} className="me-1 sort-img" /><span className='pe-2'>Sort By</span>
                                    </div>
                                    <div className="border-filter ps-1" type="button" data-bs-toggle="modal" data-bs-target="#filterModaltalk">
                                        <img src={filterimg} className="me-1 sort-img" /><span>Filter</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row '>
                        <div className='col-md-12 mb-3'>
                            <div className='heading-holder text-center'>
                                <h2>Our<span className='ms-2'>Experts</span> </h2>
                            </div>
                            <div className="main-heading mt-1">
                                <div className="line me-2"></div>
                                <div className="square"></div>
                                <div className="line ms-2"></div>
                            </div>
                        </div>

                        <div className='col-md-12'>
                            <Swiper
                                modules={[Navigation, Autoplay]}
                                pagination={false}
                                navigation={true}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                        spaceBetween: 10,
                                    },
                                    640: {
                                        slidesPerView: 1,
                                        spaceBetween: 10,
                                    },
                                    768: {
                                        slidesPerView: 1,
                                        spaceBetween: 10,
                                    },

                                }}
                                onSwiper={(swiper) => console.log(swiper)}
                                onSlideChange={() => console.log("slide change")}
                                // autoplay={{
                                //   delay: 2500,
                                //   disableOnInteraction: false,
                                //   loop: true,
                                //   pauseOnMouseEnter: true,
                                // }}
                                className="suportres-slider" >
                                <SwiperSlide>
                                    <div className="row mb-5">
                                        <div className=" col-sm-6 col-12">
                                            <div className="card mb-1">
                                                <div className="card-body">
                                                    <Link to='/astrologer-profile'>
                                                        <div className="img-holder text-end">
                                                            <img src={approved} className="right-img" />
                                                        </div>
                                                        <div className="second-img text-center">

                                                            <TalkToAStrolger />
                                                        </div>
                                                        <div className="address-holder">
                                                            <h5 className="">PARESH</h5>
                                                            <div className="yellow-boder mb-2"></div>
                                                            <p>Vedic, Palmistry, Face Reading English, Hindi & Marathi</p>
                                                            <span>Exp : 15 Years</span>
                                                        </div>
                                                        <div className="free-delivery">
                                                            <p>FREE<span>30 min</span></p>
                                                        </div></Link>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="star-img">
                                                                <img src={starimg} className='' />
                                                                <img src={starimg} />
                                                                <img src={starimg} />
                                                                <img src={starimg} />
                                                                <img src={starimg} />
                                                            </div>
                                                            <div className="order">
                                                                <p>34528 orders</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="button-holder text-end">
                                                                <button type="button" className="btn btn-chat-available" data-bs-toggle="modal"
                                                                    data-bs-target="#talkModal2">Call</button>
                                                                <p className='available '>Available</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className=" col-sm-6 col-12">
                                            <div className="card mb-1">
                                                <div className="card-body">
                                                    <div className="img-holder text-end">
                                                        <img src={approved} className="right-img" />
                                                    </div>
                                                    <div className="second-img text-center">
                                                        {/* <img src={profileimg} className="profile-img" /> */}
                                                        <TalkToAStrolger />
                                                    </div>
                                                    <div className="address-holder">
                                                        <h5 className="mt-1">PARESH</h5>
                                                        <div className="yellow-boder mb-2"></div>
                                                        <p>Vedic, Palmistry, Face Reading English, Hindi & Marathi</p>
                                                        <span>Exp : 15 Years</span>
                                                    </div>
                                                    <div className="free-delivery">
                                                        <p>FREE<span>30 min</span></p>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="star-img">
                                                                <img src={starimg} className='' />
                                                                <img src={starimg} />
                                                                <img src={starimg} />
                                                                <img src={starimg} />
                                                                <img src={starimg} />
                                                            </div>
                                                            <div className="order">
                                                                <p>34528 orders</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="button-holder text-end">
                                                                <button type="button" className="btn btn-chat-available" data-bs-toggle="modal"
                                                                    data-bs-target="#phoneform-modalmob">Call</button>
                                                                <p className='available'>Available</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" col-sm-6 col-12">
                                            <div className="card mb-1">
                                                <div className="card-body">
                                                    <div className="img-holder text-end">
                                                        <img src={approved} className="right-img" />
                                                    </div>
                                                    <div className="second-img text-center">
                                                        {/* <img src={profileimg} className="profile-img" /> */}
                                                        <TalkToAStrolger />
                                                    </div>
                                                    <div className="address-holder ">
                                                        <h5 className="mt-1">PARESH</h5>
                                                        <div className="yellow-boder mb-2"></div>
                                                        <p>Vedic, Palmistry, Face Reading English, Hindi & Marathi</p>
                                                        <span>Exp : 15 Years</span>
                                                    </div>
                                                    <div className="free-delivery">
                                                        <p>FREE<span>30 min</span></p>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="star-img">
                                                                <img src={starimg} className='' />
                                                                <img src={starimg} />
                                                                <img src={starimg} />
                                                                <img src={starimg} />
                                                                <img src={starimg} />
                                                            </div>
                                                            <div className="order">
                                                                <p>34528 orders</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="button-holder text-end">
                                                                <button type="button" className="btn btn-chat" data-bs-toggle="modal"
                                                                    data-bs-target="#talkModal2">Call</button>
                                                                <p className='wait'>Wait - 15 min</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" col-sm-6 col-12">
                                            <div className="card mb-1">
                                                <div className="card-body">
                                                    <div className="img-holder text-end">
                                                        <img src={approved} className="right-img" />
                                                    </div>
                                                    <div className="second-img text-center">
                                                        {/* <img src={profileimg} className="profile-img" /> */}
                                                        <TalkToAStrolger />
                                                    </div>
                                                    <div className="address-holder">
                                                        <h5 className="mt-1">PARESH</h5>
                                                        <div className="yellow-boder mb-2"></div>
                                                        <p>Vedic, Palmistry, Face Reading English, Hindi & Marathi</p>
                                                        <span>Exp : 15 Years</span>
                                                    </div>
                                                    <div className="free-delivery">
                                                        <p>FREE<span>30 min</span></p>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="star-img">
                                                                <img src={starimg} className='' />
                                                                <img src={starimg} />
                                                                <img src={starimg} />
                                                                <img src={starimg} />
                                                                <img src={starimg} />
                                                            </div>
                                                            <div className="order">
                                                                <p>34528 orders</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="button-holder text-end">
                                                                <button type="button" className="btn btn-chat-available" data-bs-toggle="modal"
                                                                    data-bs-target="#talkModal2">Call</button>
                                                                <p className='available'>Available</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>


                                <SwiperSlide>
                                    <div className='row mb-5'>
                                        <div className=" col-sm-6 col-12">
                                            <div className="card mb-1">
                                                <div className="card-body">
                                                    <div className="img-holder text-end">
                                                        <img src={approved} className="right-img" />
                                                    </div>
                                                    <div className="second-img text-center">
                                                        {/* <img src={profileimg} className="profile-img" /> */}
                                                        <TalkToAStrolger />
                                                    </div>
                                                    <div className="address-holder">
                                                        <h5 className="mt-1">PARESH</h5>
                                                        <div className="yellow-boder mb-2"></div>
                                                        <p>Vedic, Palmistry, Face Reading English, Hindi & Marathi</p>
                                                        <span>Exp : 15 Years</span>
                                                    </div>
                                                    <div className="free-delivery">
                                                        <p>FREE<span>30 min</span></p>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="star-img">
                                                                <img src={starimg} className='' />
                                                                <img src={starimg} />
                                                                <img src={starimg} />
                                                                <img src={starimg} />
                                                                <img src={starimg} />
                                                            </div>
                                                            <div className="order">
                                                                <p>34528 orders</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="button-holder text-end">
                                                                <button type="button" className="btn btn-chat-available" data-bs-toggle="modal"
                                                                    data-bs-target="#talkModal2">Call</button>
                                                                <p className='available'>Available</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" col-sm-6 col-12">
                                            <div className="card mb-1">
                                                <div className="card-body">
                                                    <div className="img-holder text-end">
                                                        <img src={approved} className="right-img" />
                                                    </div>
                                                    <div className="second-img text-center">
                                                        {/* <img src={profileimg} className="profile-img" /> */}
                                                        <TalkToAStrolger />
                                                    </div>
                                                    <div className="address-holder">
                                                        <h5 className="mt-1">PARESH</h5>
                                                        <div className="yellow-boder mb-2"></div>
                                                        <p>Vedic, Palmistry, Face Reading English, Hindi & Marathi</p>
                                                        <span>Exp : 15 Years</span>
                                                    </div>
                                                    <div className="free-delivery">
                                                        <p>FREE<span>30 min</span></p>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="star-img">
                                                                <img src={starimg} className='' />
                                                                <img src={starimg} />
                                                                <img src={starimg} />
                                                                <img src={starimg} />
                                                                <img src={starimg} />
                                                            </div>
                                                            <div className="order">
                                                                <p>34528 orders</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="button-holder text-end">
                                                                <button type="button" className="btn btn-chat-available" data-bs-toggle="modal"
                                                                    data-bs-target="#talkModal2">Call</button>
                                                                <p className='available'>Available</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" col-sm-6 col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="img-holder text-end">
                                                        <img src={approved} className="right-img" />
                                                    </div>
                                                    <div className="second-img text-center">
                                                        {/* <img src={profileimg} className="profile-img" /> */}
                                                        <TalkToAStrolger />
                                                    </div>
                                                    <div className="address-holder">
                                                        <h5 className="mt-1">PARESH</h5>
                                                        <div className="yellow-boder mb-2"></div>
                                                        <p>Vedic, Palmistry, Face Reading English, Hindi & Marathi</p>
                                                        <span>Exp : 15 Years</span>
                                                    </div>
                                                    <div className="free-delivery">
                                                        <p>FREE<span>30 min</span></p>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="star-img">
                                                                <img src={starimg} className='' />
                                                                <img src={starimg} />
                                                                <img src={starimg} />
                                                                <img src={starimg} />
                                                                <img src={starimg} />
                                                            </div>
                                                            <div className="order">
                                                                <p>34528 orders</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="button-holder text-end">
                                                                <button type="button" className="btn btn-chat-available" data-bs-toggle="modal"
                                                                    data-bs-target="#talkModal2">Call</button>
                                                                <p className='available'>Available</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" col-sm-6 col-12">
                                            <div className="card mb-5">
                                                <div className="card-body">
                                                    <div className="img-holder text-end">
                                                        <img src={approved} className="right-img" />
                                                    </div>
                                                    <div className="second-img text-center">
                                                        {/* <img src={profileimg} className="profile-img" /> */}
                                                        <TalkToAStrolger />
                                                    </div>
                                                    <div className="address-holder">
                                                        <h5 className="mt-1">PARESH</h5>
                                                        <div className="yellow-boder mb-2"></div>
                                                        <p>Vedic, Palmistry, Face Reading English, Hindi & Marathi</p>
                                                        <span>Exp : 15 Years</span>
                                                    </div>
                                                    <div className="free-delivery">
                                                        <p>FREE<span>30 min</span></p>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="star-img">
                                                                <img src={starimg} className='' />
                                                                <img src={starimg} />
                                                                <img src={starimg} />
                                                                <img src={starimg} />
                                                                <img src={starimg} />
                                                            </div>
                                                            <div className="order">
                                                                <p>34528 orders</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="button-holder text-end">
                                                                <button type="button" className="btn btn-chat-available" data-bs-toggle="modal"
                                                                    data-bs-target="#talkModal2">Call</button>
                                                                <p className='available'>Available</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section >

            {/***************  phone-form-madal start *********** */}
            <section className="get-kundali-modal1">
                <div className="modal fade" id="phoneform-modalmob" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
                            <div className="modal-header pb-0">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body pt-0">
                                <div className='form-bg ps-3 pe-3'>
                                    <form className="row justify-content-center ">
                                        <div className="col-md-4 col-sm-4 col-4">
                                            <div className="border-dott mt-md-3 mt-2"></div>
                                        </div>
                                        <div className="col-md-2 col-sm-2 col-4">
                                            <div className="heading-holder">
                                                <h6>Register</h6>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-4 col-4">
                                            <div className="border-dott mt-md-3 mt-2"></div>
                                        </div>
                                        <div className='col-12 '>
                                            <div className='text-center text-holder my-0'>
                                                <p>Create your account, It's only takes a minute.</p>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <label for="inputEmail4" className="form-label">
                                                Name*
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="inputEmail4"
                                            />
                                        </div>
                                        <div className="col-6">
                                            <label for="inputemail" className="form-label ">
                                                Gender*
                                            </label>
                                            <select
                                                className="form-select "
                                                aria-label="Default select example"
                                            >
                                                <option selected>Male</option>
                                                <option value="1">Female</option>
                                            </select>
                                        </div>
                                        <div className="col-6">
                                            <label for="inputtext4" className="form-label"> Birth Place* </label>
                                            <input type="text" className="form-control" id="inputtext4" />
                                        </div>
                                        <div className="col-6">
                                            <label for="inputemail" className="form-label ">
                                                Email
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="inputEmail4"
                                            />
                                        </div>

                                        {/* <div className="row"> */}
                                        <div className="col-lg-6 col-md-6 col-sm-8 col-8 pe-0">
                                            <div className="row">
                                                <div className="col-lg-7 col-md-7 col-sm-7 col-8 ">
                                                    <div className="mt-1">
                                                        <label for="inputAddress2" className="form-label">
                                                            Date of Birth*
                                                        </label>
                                                        <div className="input-group">
                                                            <span
                                                                class="input-group-text"
                                                                id="basic-addon1"
                                                            >
                                                                <img src={Date_icon} className="Date_icon" />
                                                            </span>

                                                            <div className="DatePick">
                                                                <DatePicker
                                                                    selected={startdate}
                                                                    onChange={(date) => setStartDate(date)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-5 col-md-5 col-sm-5 col-4 ">
                                                    <label
                                                        for="inputCity"
                                                        className="form-label mt-3"
                                                    ></label>
                                                    <input
                                                        type="time"
                                                        className="form-control mt-2"
                                                        id="inputCity"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-4 col-4 ps-0">
                                            <div className="form-radio1 ">
                                                <div className="form-check hours-time">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="flexRadioDefault"
                                                        id="flexRadioDefault1"
                                                        checked
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        for="flexRadioDefault1"
                                                    >
                                                        AM
                                                    </label>
                                                </div>
                                                <div className="form-check  hours-time">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="flexRadioDefault"
                                                        id="flexRadioDefault2"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        for="flexRadioDefault2"
                                                    >
                                                        PM
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        {/* </div> */}
                                    </form>
                                    <div className='row'>
                                        <div class="form-check " type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                            <input class="form-check-input formcheck-input1" type="checkbox" value="" id="flexCheckDefault" />
                                            <label class="form-check-lavel mt-2" for="flexCheckDefault">
                                                Enter Partner Details
                                            </label>
                                        </div>
                                        <div class="collapse" id="collapseExample">
                                            <div class="card card-body">
                                                <form className="row justify-content-center ">
                                                    <div className="col-6">
                                                        <label for="inputEmail4" className="form-label">
                                                            Name*
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="inputEmail4"
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <label for="inputemail" className="form-label ">
                                                            Gender*
                                                        </label>
                                                        <select
                                                            className="form-select "
                                                            aria-label="Default select example"
                                                        >
                                                            <option selected>Male</option>
                                                            <option value="1">Female</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-6">
                                                        <label for="inputtext4" className="form-label"> Birth Place* </label>
                                                        <input type="text" className="form-control" id="inputtext4" />
                                                    </div>
                                                    <div className="col-6">
                                                        <label for="inputemail" className="form-label ">
                                                            Email
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="inputEmail4"
                                                        />
                                                    </div>

                                                    {/* <div className="row"> */}
                                                    <div className="col-lg-6 col-md-6 col-sm-8 col-8 pe-0">
                                                        <div className="row">
                                                            <div className="col-lg-7 col-md-7 col-sm-7 col-8 ">
                                                                <div className="mt-1">
                                                                    <label for="inputAddress2" className="form-label">
                                                                        Date of Birth*
                                                                    </label>
                                                                    <div className="input-group">
                                                                        <span
                                                                            class="input-group-text"
                                                                            id="basic-addon1"
                                                                        >
                                                                            <img src={Date_icon} className="Date_icon" />
                                                                        </span>

                                                                        <div className="DatePick">
                                                                            <DatePicker
                                                                                selected={startdate}
                                                                                onChange={(date) => setStartDate(date)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-5 col-md-5 col-sm-5 col-4 ">
                                                                <label
                                                                    for="inputCity"
                                                                    className="form-label mt-3"
                                                                ></label>
                                                                <input
                                                                    type="time"
                                                                    className="form-control mt-2"
                                                                    id="inputCity"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-4 col-4 ps-0">
                                                        <div className="form-radio1 ">
                                                            <div className="form-check hours-time">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="flexRadioDefault"
                                                                    id="flexRadioDefault1"
                                                                    checked
                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    for="flexRadioDefault1"
                                                                >
                                                                    AM
                                                                </label>
                                                            </div>
                                                            <div className="form-check  hours-time">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="flexRadioDefault"
                                                                    id="flexRadioDefault2"
                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    for="flexRadioDefault2"
                                                                >
                                                                    PM
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="mb-1">
                                                        <label for="exampleFormControlTextarea1" class="form-label">Question*</label>
                                                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="button-holder text-center mt-3">
                                        <button type='button' className="btn btn-otp" data-bs-toggle="modal"
                                            data-bs-target="#callscreendisha">Start call with disha</button>
                                    </div>
                                    <div className="term-condition1 text-center my-3">
                                        <p>Already have an account? Login in to your account.</p>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
            {/* *************phone-form-madal end ************/}
        </>
    )
}

export default TalkAstrologer