import React from 'react';
import '../complementry/complementry.css';
import girlsimg from '../image/girls.png';
import plusimg from '../image/right-icon.png'
// import {Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import { Navigation, Autoplay } from 'swiper/modules';
import OurAstrologer_2 from './OurAstrologer-2/OurAstrologer-2';
import OurAstrolger1 from './OurAstrologer-1/OurAstrologer-1';
const OurAstrologer = () => {
    return (
        <>
            <section className='our-astrology desk-top-vew-our-astrology'>
                <div className='container-fluid'>
                    <div className='row'>

                        <div className='col-md-12'>
                            <div className='heading-holder text-center pt-5'>
                                <h2>Our<span className='ms-2'>Astrologers</span> </h2>
                            </div>
                            <div className="main-heading mt-1">
                                <div class="line me-2"></div>
                                <div class="square"></div>
                                <div class="line ms-2"></div>
                            </div>
                            <div className='text-holder text-center mt-3'>
                                <p>13000+ Best Astrologers from India for Online Consultation</p>
                            </div>
                        </div>

                        <Swiper

                            spaceBetween={10}
                            // autoplay={{
                            //     delay: 2500,
                            //     disableOnInteraction: false,
                            //     loop: true,
                            // }}

                            Navigation={{ clickable: true }}
                            modules={[Autoplay, Navigation]}
                            navigation={true}
                            breakpoints={{
                                640: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                768: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                1024: {
                                    slidesPerView: 1,
                                    spaceBetween: 15,
                                },
                            }}
                        >

                            <SwiperSlide >
                                <div className='row justify-content-center padd-set'>
                                    <div className='col-lg-6 '>
                                        <div class="card mb-5" >
                                            <div className='image-holder'>
                                                <img src={girlsimg} className='girls-img' />
                                                <div className='image-holder2'>
                                                    <img src={plusimg} className='coma-orange' />
                                                </div>
                                            </div>
                                            <div className='text-holder mt-5'>
                                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,<br /> sed diam nonumy eirmod tempor invidunt ut labore et</p>
                                            </div>
                                            <div className='text-holder mb-5'>
                                                <h5>Sujata</h5>
                                                <h6>Vedic Astrology</h6>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-lg-6 mobile-hide-card'>
                                        <div class="card  mb-5" >
                                            <div className='image-holder'>
                                                <img src={girlsimg} className='girls-img' />
                                                <div className='image-holder2'>
                                                    <img src={plusimg} className='coma-orange' />
                                                </div>
                                            </div>
                                            <div className='text-holder mt-5'>
                                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,<br /> sed diam nonumy eirmod tempor invidunt ut labore et</p>
                                            </div>
                                            <div className='text-holder mb-5'>
                                                <h5>Sujata</h5>
                                                <h6>Vedic Astrology</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide >
                                <div className='row justify-content-center padd-set'>
                                    <div className='col-lg-6 '>
                                        <div class="card mb-5" >
                                            <div className='image-holder'>
                                                <img src={girlsimg} className='girls-img' />
                                                <div className='image-holder2'>
                                                    <img src={plusimg} className='coma-orange' />
                                                </div>
                                            </div>
                                            <div className='text-holder mt-5'>
                                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,<br /> sed diam nonumy eirmod tempor invidunt ut labore et</p>
                                            </div>
                                            <div className='text-holder mb-5'>
                                                <h5>Sujata</h5>
                                                <h6>Vedic Astrology</h6>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-lg-6 mobile-hide-card'>
                                        <div class="card mb-5" >
                                            <div className='image-holder'>
                                                <img src={girlsimg} className='girls-img' />
                                                <div className='image-holder2'>
                                                    <img src={plusimg} className='coma-orange' />
                                                </div>
                                            </div>
                                            <div className='text-holder mt-5'>
                                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,<br /> sed diam nonumy eirmod tempor invidunt ut labore et</p>
                                            </div>
                                            <div className='text-holder mb-5'>
                                                <h5>Sujata</h5>
                                                <h6>Vedic Astrology</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide >
                                <div className='row justify-content-center padd-set'>
                                    <div className='col-lg-6 '>
                                        <div class="card mb-5" >
                                            <div className='image-holder'>
                                                <img src={girlsimg} className='girls-img' />
                                                <div className='image-holder2'>
                                                    <img src={plusimg} className='coma-orange' />
                                                </div>
                                            </div>
                                            <div className='text-holder mt-5'>
                                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,<br /> sed diam nonumy eirmod tempor invidunt ut labore et</p>
                                            </div>
                                            <div className='text-holder mb-5'>
                                                <h5>Sujata</h5>
                                                <h6>Vedic Astrology</h6>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-lg-6 mobile-hide-card'>
                                        <div class="card mb-5" >
                                            <div className='image-holder'>
                                                <img src={girlsimg} className='girls-img' />
                                                <div className='image-holder2'>
                                                    <img src={plusimg} className='coma-orange' />
                                                </div>
                                            </div>
                                            <div className='text-holder mt-5'>
                                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,<br /> sed diam nonumy eirmod tempor invidunt ut labore et</p>
                                            </div>
                                            <div className='text-holder mb-5'>
                                                <h5>Sujata</h5>
                                                <h6>Vedic Astrology</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide >
                                <div className='row justify-content-center padd-set'>
                                    <div className='col-lg-6 '>
                                        <div class="card mb-5" >
                                            <div className='image-holder'>
                                                <img src={girlsimg} className='girls-img' />
                                                <div className='image-holder2'>
                                                    <img src={plusimg} className='coma-orange' />
                                                </div>
                                            </div>
                                            <div className='text-holder mt-5'>
                                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,<br /> sed diam nonumy eirmod tempor invidunt ut labore et</p>
                                            </div>
                                            <div className='text-holder mb-5'>
                                                <h5>Sujata</h5>
                                                <h6>Vedic Astrology</h6>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-lg-6 mobile-hide-card'>
                                        <div class="card mb-5" >
                                            <div className='image-holder'>
                                                <img src={girlsimg} className='girls-img' />
                                                <div className='image-holder2'>
                                                    <img src={plusimg} className='coma-orange' />
                                                </div>
                                            </div>
                                            <div className='text-holder mt-5'>
                                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,<br /> sed diam nonumy eirmod tempor invidunt ut labore et</p>
                                            </div>
                                            <div className='text-holder mb-5'>
                                                <h5>Sujata</h5>
                                                <h6>Vedic Astrology</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide >
                                <div className='row justify-content-center padd-set'>
                                    <div className='col-lg-6 '>
                                        <div class="card mb-5" >
                                            <div className='image-holder'>
                                                <img src={girlsimg} className='girls-img' />
                                                <div className='image-holder2'>
                                                    <img src={plusimg} className='coma-orange' />
                                                </div>
                                            </div>
                                            <div className='text-holder mt-5'>
                                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,<br /> sed diam nonumy eirmod tempor invidunt ut labore et</p>
                                            </div>
                                            <div className='text-holder mb-5'>
                                                <h5>Sujata</h5>
                                                <h6>Vedic Astrology</h6>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-lg-6 mobile-hide-card'>
                                        <div class="card mb-5" >
                                            <div className='image-holder'>
                                                <img src={girlsimg} className='girls-img' />
                                                <div className='image-holder2'>
                                                    <img src={plusimg} className='coma-orange' />
                                                </div>
                                            </div>
                                            <div className='text-holder mt-5'>
                                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,<br /> sed diam nonumy eirmod tempor invidunt ut labore et</p>
                                            </div>
                                            <div className='text-holder mb-5'>
                                                <h5>Sujata</h5>
                                                <h6>Vedic Astrology</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>

                    <div>
                        <OurAstrologer_2 />
                    </div>
                    <div>
                        <OurAstrolger1 />
                    </div>
                </div>
            </section>


            {/* mobile-view-start */}

            <section className='our-astrology mobile-view-our-astrology'>
                <div className='container-fluid'>
                    <div className='row'>

                        <div className='col-md-12'>
                            <div className='heading-holder text-center mt-3'>
                                <h2>Our<span className='ms-2'>Astrologers</span> </h2>
                            </div>
                            <div className="main-heading mt-1">
                                <div class="line me-2"></div>
                                <div class="square"></div>
                                <div class="line ms-2"></div>
                            </div>
                            <div className='text-holder1 text-center mt-1'>
                                <p>13000+ Best Astrologers from India for Online Consultation</p>
                            </div>
                        </div>

                        <Swiper

                            spaceBetween={10}
                            // autoplay={{
                            //     delay: 2500,
                            //     disableOnInteraction: false,
                            //     loop: true,
                            // }}

                            Navigation={{ clickable: true }}
                            modules={[Autoplay, Navigation]}
                            navigation={true}
                            breakpoints={{
                                640: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                768: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                1024: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                            }}
                        >

                            <SwiperSlide >
                                <div class="card mb-5" >
                                    <div className='image-holder'>
                                        <img src={girlsimg} className='girls-img' />
                                        <div className='image-holder2'>
                                            <img src={plusimg} className='coma-orange' />
                                        </div>
                                    </div>
                                    <div className='text-holder mt-5 px-3'>
                                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                                            sed diam nonumy eirmod tempor invidunt ut labore et</p>
                                    </div>
                                    <div className='text-holder mb-2'>
                                        <h5>Sujata</h5>
                                        <h6>Vedic Astrology</h6>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide >
                                <div class="card mb-5" >
                                    <div className='image-holder'>
                                        <img src={girlsimg} className='girls-img' />
                                        <div className='image-holder2'>
                                            <img src={plusimg} className='coma-orange' />
                                        </div>
                                    </div>
                                    <div className='text-holder mt-5 px-3'>
                                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                                            sed diam nonumy eirmod tempor invidunt ut labore et</p>
                                    </div>
                                    <div className='text-holder mb-2'>
                                        <h5>Sujata</h5>
                                        <h6>Vedic Astrology</h6>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide >
                                <div class="card mb-5" >
                                    <div className='image-holder'>
                                        <img src={girlsimg} className='girls-img' />
                                        <div className='image-holder2'>
                                            <img src={plusimg} className='coma-orange' />
                                        </div>
                                    </div>
                                    <div className='text-holder mt-5 px-3'>
                                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                                            sed diam nonumy eirmod tempor invidunt ut labore et</p>
                                    </div>
                                    <div className='text-holder mb-2'>
                                        <h5>Sujata</h5>
                                        <h6>Vedic Astrology</h6>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide >
                                <div class="card mb-5" >
                                    <div className='image-holder'>
                                        <img src={girlsimg} className='girls-img' />
                                        <div className='image-holder2'>
                                            <img src={plusimg} className='coma-orange' />
                                        </div>
                                    </div>
                                    <div className='text-holder mt-5 px-3'>
                                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                                            sed diam nonumy eirmod tempor invidunt ut labore et</p>
                                    </div>
                                    <div className='text-holder mb-2'>
                                        <h5>Sujata</h5>
                                        <h6>Vedic Astrology</h6>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide >
                                <div class="card mb-5" >
                                    <div className='image-holder'>
                                        <img src={girlsimg} className='girls-img' />
                                        <div className='image-holder2'>
                                            <img src={plusimg} className='coma-orange' />
                                        </div>
                                    </div>
                                    <div className='text-holder mt-5 px-3'>
                                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                                            sed diam nonumy eirmod tempor invidunt ut labore et</p>
                                    </div>
                                    <div className='text-holder mb-2'>
                                        <h5>Sujata</h5>
                                        <h6>Vedic Astrology</h6>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>

                    <div>
                        <OurAstrologer_2 />
                    </div>
                    <div>
                        <OurAstrolger1 />
                    </div>
                </div>
            </section>


        </>
    )
}

export default OurAstrologer