import React from 'react'
import './home.css'
import Header from './header/Header'
import Banner from './banner/Banner'
import Copmplementry from './complementry/Copmplementry'
import ClientReview from './Client_review/ClientReview';
import Faq from './Faq/Faq'
import CounterServices from './counter-services/CounterServices'
import OurAstrologer from './our-astrologer/OurAstrologer'
import LatestBlog from './LatestBlog/LatestBlog'
const Home = () => {

  return (
    <>
      {/* <Header/>    */}
      <Banner />
      <Copmplementry />
      <CounterServices />
      <OurAstrologer />
      <LatestBlog/>
      <ClientReview />
      <Faq />
      
    </>
  )
}

export default Home;