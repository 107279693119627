import React, { useState } from 'react'
import '../../chat-astrologer/filter-modal/FilterModal.css';
const SortTalkModal = () => {




    const [checkboxes, setCheckboxes] = useState([
        { id: 1, text: 'Popularity', isChecked: true },
        { id: 2, text: 'Experience : High to Low', isChecked: false },
        { id: 3, text: 'Experience : Low to High', isChecked: false },
        { id: 4, text: ' Total orders : High to Low', isChecked: false },
        { id: 5, text: ' Total orders : Low to High', isChecked: false },
        { id: 6, text: ' Total orders : Low to High', isChecked: false },
        { id: 7, text: ' Price : High to Low', isChecked: false },
        { id: 8, text: ' Price : Low to High', isChecked: false },
        { id: 9, text: ' Rating : High to Low', isChecked: false },
    ]);

    const toggleCheckbox = (id) => {
        setCheckboxes((prevState) =>
            prevState.map((checkbox) =>
                checkbox.id === id ? { ...checkbox, isChecked: !checkbox.isChecked } : checkbox
            )
        );
    };
    const [isChecked, setIsChecked] = useState(false);

    // const handleCheckboxChange = () => {
    //   setIsChecked(!isChecked);
    // };

    const labelStyle = {
        color: isChecked ? '#F5770A' : 'black', // Change color when checked
    };
    return (
        <>
            {/* <!-- Modal --> */}

            <div className='filtermodal'>
                <div className="modal fade" id="sortModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header">

                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body ps-md-5 ps-sm-2 ps-2">
                                <div className='d-flex mb-3'>
                                    <div className='sort'>
                                        <h5>Sort By</h5>
                                    </div>
                                    <div className='radio-icon me-md-5 me-sm-2 me-0'>
                                        <div className="form-check me-1">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked />
                                            <label className="form-check-label" for="flexRadioDefault1">
                                                Select all
                                            </label>
                                        </div>
                                        <div className="form-check ms-2">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                            <label className="form-check-label" for="flexRadioDefault2">
                                                Clear all
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='check-icon-start'>
                                    <div className="form-check mb-2">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" 
        />
                                        <label className="form-check-label" for="flexCheckDefault" style={labelStyle}>
                                            Popularity
                                        </label>
                                    </div>
                                    <div className="form-check mb-2">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked11" checked={isChecked}
          onChange={handleCheckboxChange}/>
                                        <label className="form-check-label" for="flexCheckChecked11" style={labelStyle} >
                                            Experience : High to Low
                                        </label>
                                    </div>
                                    <div className="form-check mb-2">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked12" checked={isChecked}
          onChange={handleCheckboxChange}/>
                                        <label className="form-check-label" for="flexCheckChecked12" style={labelStyle}>
                                            Experience : Low to High
                                        </label>
                                    </div>
                                    <div className="form-check mb-2">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked13" checked={isChecked}
          onChange={handleCheckboxChange}/>
                                        <label className="form-check-label" for="flexCheckChecked13" style={labelStyle}>
                                            Total orders : High to Low
                                        </label>
                                    </div>
                                    <div className="form-check mb-2">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked14" checked={isChecked}
          onChange={handleCheckboxChange}/>
                                        <label className="form-check-label" for="flexCheckChecked14" style={labelStyle}>
                                            Total orders : Low to High
                                        </label>
                                    </div>
                                    <div className="form-check mb-2">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked15" checked={isChecked}
          onChange={handleCheckboxChange}/>
                                        <label className="form-check-label" for="flexCheckChecked15" style={labelStyle}>
                                            Price : High to Low
                                        </label>
                                    </div>
                                    <div className="form-check mb-2">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked16" checked={isChecked}
          onChange={handleCheckboxChange}/>
                                        <label className="form-check-label" for="flexCheckChecked16" style={labelStyle}>
                                            Price : Low to High
                                        </label>
                                    </div>
                                    <div className="form-check mb-2">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked17" checked={isChecked}
          onChange={handleCheckboxChange}/>
                                        <label className="form-check-label" for="flexCheckChecked17" style={labelStyle}>
                                            Rating : High to Low
                                        </label>
                                    </div>

                                </div> */}

                                {checkboxes.map((checkbox) => (
                                    <div key={checkbox.id} className="checkbox-container mb-md-2 mb-sm-2 mb-0">
                                        <label
                                            className={`form-check-label checkbox-label ${checkbox.isChecked ? 'checked' : ''}`}
                                            onClick={() => toggleCheckbox(checkbox.id)}
                                        >
                                            <input type="checkbox" checked={checkbox.isChecked} className="form-check-input me-2" readOnly />
                                            {checkbox.text}
                                        </label>
                                    </div>
                                ))}
                                <div className="py-2">
                                    <button type="button" className="btn btn-apply me-3" >Apply</button>
                                    <button type="button" className="btn btn-apply">Cancel</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SortTalkModal