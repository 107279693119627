import React from 'react'
import '../complementry/complementry.css'
import CountUp from 'react-countup'
const CounterServices = () => {
    return (
        <>

            <section className='counter-services desktop-view'>
                <div className='container'>
                    <div className='row '>
                        <div className='col-lg-4 col-md-4'>
                            <div className='total-holder text-holder'>
                                <h4><CountUp start={0} end={1000} duration={4} decimals={0} /> +</h4>
                                <p>Total Astrologers</p>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4'>
                            <div className='total-holder text-holder'>
                                <h4><CountUp start={0} end={1000} duration={4} decimals={0} />  Million Minutes</h4>
                                <p>Total Chat/Call Minutes</p>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4'>
                            <div className='total-holder1 text-holder'>
                                <h4><CountUp start={0} end={1000} duration={4} decimals={0} /> Million</h4>
                                <p>Total Customers</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* mobile-app-view-start    */}
            <section className='counter-services-app'>
                <div className='container'>
                    <div className='row mobile-app-view'>
                        <div className='col-sm-6 col-6'>
                            <div className='total-holder text-holder'>
                                <h4><CountUp start={0} end={1000} duration={4} decimals={0} /> +</h4>
                                <p>Total Astrologers</p>
                            </div>
                        </div>
                        <div className='col-sm-6 col-6'>
                            <div className='total-holder1 text-holder'>
                                <h4><CountUp start={0} end={1000} duration={4} decimals={0} />  Million </h4>
                                <p>Total Customers</p>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='total-holder2 text-holder pt-3'>
                                <h4><CountUp start={0} end={1000} duration={4} decimals={0} /> Million Minutes</h4>
                              
                                <p>Total Chat/Call Minutes</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* mobile-app-view-end    */}
        </>
    )
}

export default CounterServices