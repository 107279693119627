import React from 'react'
import Lottie from "react-lottie";
import circle_json from "../../animation_ll0khw6x (1).json"
import "./Footer2.css"
const Footer2 = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: circle_json,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };
  return (
    <>
     <section className='lottie-animation-div'>
        <div className='Footer-lottie-1'>
          <Lottie 
            options={defaultOptions} 
             />
        </div>
      </section>
    </>
  )
}

export default Footer2