import React from 'react'

import { Link } from "react-router-dom";
import nashkimg from '../Free_Report/Images/image-1.png'
import nashkimg1 from '../Free_Report/Images/image-2.png'
import ConnectAstrologer from '../../Kundali/connect-astrologer/ConnectAstrologer';
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
const FreeChart = () => {
    return (
        <>
            <section className="Free_Kundli desktop-view-free-kundali">
                <div className="container">
                    <div className="row ">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                                <li className="breadcrumb-item"><Link to='/freekundali'>Free Kundali </Link></li>
                                <li className="breadcrumb-item active" ><Link to=''>Astakvarga</Link></li>
                            </ol>
                        </nav>
                        <div className="col-lg-12   mt-4">
                            <ul className="nav nav-pills mb-3 " id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <Link to='/Basic-Kundali'><button className="nav-link " id="pills-basic-tab" data-bs-toggle="pill" data-bs-target="#pills-basic"
                                        type="button" role="tab" aria-controls="pills-basic" aria-selected="true"> Basic </button></Link>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <Link to='/second-Kundali'> <button className="nav-link " id="pills-kundli-tab" data-bs-toggle="pill" data-bs-target="#pills-kundli" type="button"
                                        role="tab" aria-controls="pills-kundli" aria-selected="false">
                                        Kundli
                                    </button></Link>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <Link to='/kp-Kundali'> <button className="nav-link " id="pills-kp-tab" data-bs-toggle="pill" data-bs-target="#pills-kp"
                                        type="button" role="tab" aria-controls="pills-kp" aria-selected="false">
                                        KP
                                    </button></Link>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <Link to="/free-ashtkvarga">
                                        <button className="nav-link " id="pills-ashtakvarga-tab" data-bs-toggle="pill" data-bs-target="#pills-ashtakvarga"
                                            type="button" role="tab" aria-controls="pills-ashtakvarga" aria-selected="false">
                                            Ashtakvarga
                                        </button></Link>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <Link to="/free-chart">
                                        <button className="nav-link active" id="pills-charts-tab" data-bs-toggle="pill" data-bs-target="#pills-charts"
                                            type="button" role="tab" aria-controls="pills-charts" aria-selected="false">
                                            Charts
                                        </button></Link>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <Link to="/free-dasha">
                                        <button className="nav-link" id="pills-dasha-tab" data-bs-toggle="pill" data-bs-target="#pills-dasha"
                                            type="button" role="tab" aria-controls="pills-dasha" aria-selected="false">
                                            Dasha
                                        </button></Link>
                                </li>
                                <li className="nav-item free-nav" role="presentation">
                                    <Link to={"/free_Report"}>
                                        <button className="nav-link" id="pills-report-tab" data-bs-toggle="pill" data-bs-target="#pills-report"
                                            type="button" role="tab" aria-controls="pills-report" aria-selected="false">
                                            Free Report
                                        </button> </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className='row south-north-tabs'>
                        <ul className="nav nav-pills justify-content-md-end justity-content-start mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item mb-md-0 mb-sm-2 mb-2 north_indian_txt me-2" role="presentation">
                                <button className="nav-link active " id="pills-North-tab" data-bs-toggle="pill" data-bs-target="#pills-North" type="button" role="tab" aria-controls="pills-North" aria-selected="true">North Indian</button>
                            </li>
                            <li className="nav-item north_indian_txt" role="presentation">
                                <button className="nav-link" id="pills-South-tab" data-bs-toggle="pill" data-bs-target="#pills-South" type="button" role="tab" aria-controls="pills-South" aria-selected="false">South Indian</button>
                            </li>

                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-North" role="tabpanel" aria-labelledby="pills-North-tab" tabindex="0">
                                <div className='row ashtakvarga-kundali mt-5'>
                                    <div className='col-xl-2 col-lg-4 col-md-6 '>
                                        <div class="main-kundali mx-auto">
                                            <div class="sub"></div>
                                            <div class="line1"></div>
                                            <div class="line2"></div>

                                            <p class="p1">01</p>
                                            <p class="p2">02</p>
                                            <p class="p3">03</p>
                                            <p class="p4">04</p>
                                            <p class="p5">05</p>
                                            <p class="p6">06</p>
                                            <p class="p7">07</p>
                                            <p class="p8">08</p>
                                            <p class="p9">09</p>
                                            <p class="p10">10</p>
                                            <p class="p11">11</p>
                                            <p class="p12">12</p>
                                        </div>
                                        <div className='name-holder text-center mt-3 mb-5'>
                                            <h5>Chalit</h5>
                                        </div>
                                    </div>
                                    <div className='col-xl-2 col-lg-4 col-md-6'>
                                        <div class="main-kundali mx-auto">
                                            <div class="sub"></div>
                                            <div class="line1"></div>
                                            <div class="line2"></div>

                                            <p class="p1">01</p>
                                            <p class="p2">02</p>
                                            <p class="p3">03</p>
                                            <p class="p4">04</p>
                                            <p class="p5">05</p>
                                            <p class="p6">06</p>
                                            <p class="p7">07</p>
                                            <p class="p8">08</p>
                                            <p class="p9">09</p>
                                            <p class="p10">10</p>
                                            <p class="p11">11</p>
                                            <p class="p12">12</p>
                                        </div>
                                        <div className='name-holder text-center mt-3 mb-5'>
                                            <h5>Sun</h5>
                                        </div>
                                    </div>
                                    <div className='col-xl-2 col-lg-4 col-md-6'>
                                        <div class="main-kundali mx-auto">
                                            <div class="sub"></div>
                                            <div class="line1"></div>
                                            <div class="line2"></div>

                                            <p class="p1">01</p>
                                            <p class="p2">02</p>
                                            <p class="p3">03</p>
                                            <p class="p4">04</p>
                                            <p class="p5">05</p>
                                            <p class="p6">06</p>
                                            <p class="p7">07</p>
                                            <p class="p8">08</p>
                                            <p class="p9">09</p>
                                            <p class="p10">10</p>
                                            <p class="p11">11</p>
                                            <p class="p12">12</p>
                                        </div>
                                        <div className='name-holder text-center mt-3 mb-5'>
                                            <h5>Moon</h5>
                                        </div>
                                    </div>
                                    <div className='col-xl-2 col-lg-4 col-md-6'>
                                        <div class="main-kundali mx-auto">
                                            <div class="sub"></div>
                                            <div class="line1"></div>
                                            <div class="line2"></div>

                                            <p class="p1">01</p>
                                            <p class="p2">02</p>
                                            <p class="p3">03</p>
                                            <p class="p4">04</p>
                                            <p class="p5">05</p>
                                            <p class="p6">06</p>
                                            <p class="p7">07</p>
                                            <p class="p8">08</p>
                                            <p class="p9">09</p>
                                            <p class="p10">10</p>
                                            <p class="p11">11</p>
                                            <p class="p12">12</p>
                                        </div>
                                        <div className='name-holder text-center mt-3 mb-5'>
                                            <h5>Lagna / Ascendant</h5>
                                        </div>
                                    </div>
                                    <div className='col-xl-2 col-lg-4 col-md-6'>
                                        <div class="main-kundali mx-auto">
                                            <div class="sub"></div>
                                            <div class="line1"></div>
                                            <div class="line2"></div>

                                            <p class="p1">01</p>
                                            <p class="p2">02</p>
                                            <p class="p3">03</p>
                                            <p class="p4">04</p>
                                            <p class="p5">05</p>
                                            <p class="p6">06</p>
                                            <p class="p7">07</p>
                                            <p class="p8">08</p>
                                            <p class="p9">09</p>
                                            <p class="p10">10</p>
                                            <p class="p11">11</p>
                                            <p class="p12">12</p>
                                        </div>
                                        <div className='name-holder text-center mt-3 mb-5'>
                                            <h5>Hora</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className='row ashtakvarga-kundali mt-5'>
                                    <div className='col-xl-2 col-lg-4 col-md-6 '>
                                        <div class="main-kundali mx-auto">
                                            <div class="sub"></div>
                                            <div class="line1"></div>
                                            <div class="line2"></div>

                                            <p class="p1">01</p>
                                            <p class="p2">02</p>
                                            <p class="p3">03</p>
                                            <p class="p4">04</p>
                                            <p class="p5">05</p>
                                            <p class="p6">06</p>
                                            <p class="p7">07</p>
                                            <p class="p8">08</p>
                                            <p class="p9">09</p>
                                            <p class="p10">10</p>
                                            <p class="p11">11</p>
                                            <p class="p12">12</p>
                                        </div>
                                        <div className='name-holder text-center mt-3 mb-5'>
                                            <h5>Drekkana</h5>
                                        </div>
                                    </div>
                                    <div className='col-xl-2 col-lg-4 col-md-6'>
                                        <div class="main-kundali mx-auto">
                                            <div class="sub"></div>
                                            <div class="line1"></div>
                                            <div class="line2"></div>

                                            <p class="p1">01</p>
                                            <p class="p2">02</p>
                                            <p class="p3">03</p>
                                            <p class="p4">04</p>
                                            <p class="p5">05</p>
                                            <p class="p6">06</p>
                                            <p class="p7">07</p>
                                            <p class="p8">08</p>
                                            <p class="p9">09</p>
                                            <p class="p10">10</p>
                                            <p class="p11">11</p>
                                            <p class="p12">12</p>
                                        </div>
                                        <div className='name-holder text-center mt-3 mb-5'>
                                            <h5>Chaturthamsa</h5>
                                        </div>
                                    </div>
                                    <div className='col-xl-2 col-lg-4 col-md-6'>
                                        <div class="main-kundali mx-auto">
                                            <div class="sub"></div>
                                            <div class="line1"></div>
                                            <div class="line2"></div>

                                            <p class="p1">01</p>
                                            <p class="p2">02</p>
                                            <p class="p3">03</p>
                                            <p class="p4">04</p>
                                            <p class="p5">05</p>
                                            <p class="p6">06</p>
                                            <p class="p7">07</p>
                                            <p class="p8">08</p>
                                            <p class="p9">09</p>
                                            <p class="p10">10</p>
                                            <p class="p11">11</p>
                                            <p class="p12">12</p>
                                        </div>
                                        <div className='name-holder text-center mt-3 mb-5'>
                                            <h5>Saptamsa</h5>
                                        </div>
                                    </div>
                                    <div className='col-xl-2 col-lg-4 col-md-6'>
                                        <div class="main-kundali mx-auto">
                                            <div class="sub"></div>
                                            <div class="line1"></div>
                                            <div class="line2"></div>

                                            <p class="p1">01</p>
                                            <p class="p2">02</p>
                                            <p class="p3">03</p>
                                            <p class="p4">04</p>
                                            <p class="p5">05</p>
                                            <p class="p6">06</p>
                                            <p class="p7">07</p>
                                            <p class="p8">08</p>
                                            <p class="p9">09</p>
                                            <p class="p10">10</p>
                                            <p class="p11">11</p>
                                            <p class="p12">12</p>
                                        </div>
                                        <div className='name-holder text-center mt-3 mb-5'>
                                            <h5>Navamsa</h5>
                                        </div>
                                    </div>
                                    <div className='col-xl-2 col-lg-4 col-md-6'>
                                        <div class="main-kundali mx-auto">
                                            <div class="sub"></div>
                                            <div class="line1"></div>
                                            <div class="line2"></div>

                                            <p class="p1">01</p>
                                            <p class="p2">02</p>
                                            <p class="p3">03</p>
                                            <p class="p4">04</p>
                                            <p class="p5">05</p>
                                            <p class="p6">06</p>
                                            <p class="p7">07</p>
                                            <p class="p8">08</p>
                                            <p class="p9">09</p>
                                            <p class="p10">10</p>
                                            <p class="p11">11</p>
                                            <p class="p12">12</p>
                                        </div>
                                        <div className='name-holder text-center mt-3 mb-5'>
                                            <h5>Dasamsa</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className='row ashtakvarga-kundali mt-5'>
                                    <div className='col-xl-2 col-lg-4 col-md-6 '>
                                        <div class="main-kundali mx-auto">
                                            <div class="sub"></div>
                                            <div class="line1"></div>
                                            <div class="line2"></div>

                                            <p class="p1">01</p>
                                            <p class="p2">02</p>
                                            <p class="p3">03</p>
                                            <p class="p4">04</p>
                                            <p class="p5">05</p>
                                            <p class="p6">06</p>
                                            <p class="p7">07</p>
                                            <p class="p8">08</p>
                                            <p class="p9">09</p>
                                            <p class="p10">10</p>
                                            <p class="p11">11</p>
                                            <p class="p12">12</p>
                                        </div>
                                        <div className='name-holder text-center mt-3 mb-5'>
                                            <h5>Dwadasamsa</h5>
                                        </div>
                                    </div>
                                    <div className='col-xl-2 col-lg-4 col-md-6'>
                                        <div class="main-kundali mx-auto ">
                                            <div class="sub"></div>
                                            <div class="line1"></div>
                                            <div class="line2"></div>

                                            <p class="p1">01</p>
                                            <p class="p2">02</p>
                                            <p class="p3">03</p>
                                            <p class="p4">04</p>
                                            <p class="p5">05</p>
                                            <p class="p6">06</p>
                                            <p class="p7">07</p>
                                            <p class="p8">08</p>
                                            <p class="p9">09</p>
                                            <p class="p10">10</p>
                                            <p class="p11">11</p>
                                            <p class="p12">12</p>
                                        </div>
                                        <div className='name-holder text-center mt-3 mb-5'>
                                            <h5>Shodasamsa</h5>
                                        </div>
                                    </div>
                                    <div className='col-xl-2 col-lg-4 col-md-6'>
                                        <div class="main-kundali mx-auto">
                                            <div class="sub"></div>
                                            <div class="line1"></div>
                                            <div class="line2"></div>

                                            <p class="p1">01</p>
                                            <p class="p2">02</p>
                                            <p class="p3">03</p>
                                            <p class="p4">04</p>
                                            <p class="p5">05</p>
                                            <p class="p6">06</p>
                                            <p class="p7">07</p>
                                            <p class="p8">08</p>
                                            <p class="p9">09</p>
                                            <p class="p10">10</p>
                                            <p class="p11">11</p>
                                            <p class="p12">12</p>
                                        </div>
                                        <div className='name-holder text-center mt-3 mb-5'>
                                            <h5>Vimsamsa</h5>
                                        </div>
                                    </div>
                                    <div className='col-xl-2 col-lg-4 col-md-6'>
                                        <div class="main-kundali mx-auto">
                                            <div class="sub"></div>
                                            <div class="line1"></div>
                                            <div class="line2"></div>

                                            <p class="p1">01</p>
                                            <p class="p2">02</p>
                                            <p class="p3">03</p>
                                            <p class="p4">04</p>
                                            <p class="p5">05</p>
                                            <p class="p6">06</p>
                                            <p class="p7">07</p>
                                            <p class="p8">08</p>
                                            <p class="p9">09</p>
                                            <p class="p10">10</p>
                                            <p class="p11">11</p>
                                            <p class="p12">12</p>
                                        </div>
                                        <div className='name-holder text-center mt-3 mb-5'>
                                            <h5>Chaturvimsamsa</h5>
                                        </div>
                                    </div>
                                    <div className='col-xl-2 col-lg-4 col-md-6'>
                                        <div class="main-kundali mx-auto">
                                            <div class="sub"></div>
                                            <div class="line1"></div>
                                            <div class="line2"></div>

                                            <p class="p1">01</p>
                                            <p class="p2">02</p>
                                            <p class="p3">03</p>
                                            <p class="p4">04</p>
                                            <p class="p5">05</p>
                                            <p class="p6">06</p>
                                            <p class="p7">07</p>
                                            <p class="p8">08</p>
                                            <p class="p9">09</p>
                                            <p class="p10">10</p>
                                            <p class="p11">11</p>
                                            <p class="p12">12</p>
                                        </div>
                                        <div className='name-holder text-center mt-3 mb-5'>
                                            <h5>Saptavimsamsa</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className='row ashtakvarga-kundali mt-5'>
                                    <div className='col-xl-2 col-lg-4 col-md-6 '>
                                        <div class="main-kundali mx-auto">
                                            <div class="sub"></div>
                                            <div class="line1"></div>
                                            <div class="line2"></div>

                                            <p class="p1">01</p>
                                            <p class="p2">02</p>
                                            <p class="p3">03</p>
                                            <p class="p4">04</p>
                                            <p class="p5">05</p>
                                            <p class="p6">06</p>
                                            <p class="p7">07</p>
                                            <p class="p8">08</p>
                                            <p class="p9">09</p>
                                            <p class="p10">10</p>
                                            <p class="p11">11</p>
                                            <p class="p12">12</p>
                                        </div>
                                        <div className='name-holder text-center mt-3 mb-5'>
                                            <h5>Trimsamsa</h5>
                                        </div>
                                    </div>
                                    <div className='col-xl-2 col-lg-4 col-md-6'>
                                        <div class="main-kundali mx-auto">
                                            <div class="sub"></div>
                                            <div class="line1"></div>
                                            <div class="line2"></div>

                                            <p class="p1">01</p>
                                            <p class="p2">02</p>
                                            <p class="p3">03</p>
                                            <p class="p4">04</p>
                                            <p class="p5">05</p>
                                            <p class="p6">06</p>
                                            <p class="p7">07</p>
                                            <p class="p8">08</p>
                                            <p class="p9">09</p>
                                            <p class="p10">10</p>
                                            <p class="p11">11</p>
                                            <p class="p12">12</p>
                                        </div>
                                        <div className='name-holder text-center mt-3 mb-5'>
                                            <h5>Khavedamsa</h5>
                                        </div>
                                    </div>
                                    <div className='col-xl-2 col-lg-4 col-md-6'>
                                        <div class="main-kundali mx-auto">
                                            <div class="sub"></div>
                                            <div class="line1"></div>
                                            <div class="line2"></div>

                                            <p class="p1">01</p>
                                            <p class="p2">02</p>
                                            <p class="p3">03</p>
                                            <p class="p4">04</p>
                                            <p class="p5">05</p>
                                            <p class="p6">06</p>
                                            <p class="p7">07</p>
                                            <p class="p8">08</p>
                                            <p class="p9">09</p>
                                            <p class="p10">10</p>
                                            <p class="p11">11</p>
                                            <p class="p12">12</p>
                                        </div>
                                        <div className='name-holder text-center mt-3 mb-5'>
                                            <h5>Akshavedamsa</h5>
                                        </div>
                                    </div>
                                    <div className='col-xl-2 col-lg-4 col-md-6'>
                                        <div class="main-kundali mx-auto">
                                            <div class="sub"></div>
                                            <div class="line1"></div>
                                            <div class="line2"></div>

                                            <p class="p1">01</p>
                                            <p class="p2">02</p>
                                            <p class="p3">03</p>
                                            <p class="p4">04</p>
                                            <p class="p5">05</p>
                                            <p class="p6">06</p>
                                            <p class="p7">07</p>
                                            <p class="p8">08</p>
                                            <p class="p9">09</p>
                                            <p class="p10">10</p>
                                            <p class="p11">11</p>
                                            <p class="p12">12</p>
                                        </div>
                                        <div className='name-holder text-center mt-3 mb-5'>
                                            <h5>Shastiamsa</h5>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-South" role="tabpanel" aria-labelledby="pills-South-tab" tabindex="0">
                                <div className='row ashtakvarga-kundali mt-5'>
                                    <div className='col-xl-2 col-lg-4 col-md-6'>
                                        <div className="table-responsive">
                                            <table className="table table-bordered  chart-table">
                                                <tbody>
                                                    <tr >
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr >
                                                        <td >1</td>
                                                        <td colSpan={2} rowSpan={2}></td>
                                                        <td  >1</td>
                                                    </tr>
                                                    <tr >
                                                        <td >1</td>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr >
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='name-holder text-center mt-3 mb-3'>
                                            <h5>Chalit</h5>
                                        </div>
                                    </div>
                                    <div className='col-xl-2 col-lg-4 col-md-6'>
                                        <div className="table-responsive">
                                            <table className="table table-bordered  chart-table">
                                                <tbody>
                                                    <tr >
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr >
                                                        <td >1</td>
                                                        <td colSpan={2} rowSpan={2}></td>
                                                        <td  >1</td>
                                                    </tr>
                                                    <tr >
                                                        <td >1</td>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr >
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='name-holder text-center mt-3 mb-3'>
                                            <h5>Sun</h5>
                                        </div>
                                    </div>
                                    <div className='col-xl-2 col-lg-4 col-md-6'>
                                        <div className="table-responsive">
                                            <table className="table table-bordered  chart-table">
                                                <tbody>
                                                    <tr >
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr >
                                                        <td >1</td>
                                                        <td colSpan={2} rowSpan={2}></td>
                                                        <td  >1</td>
                                                    </tr>
                                                    <tr >
                                                        <td >1</td>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr >
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='name-holder text-center mt-3 mb-3'>
                                            <h5>Moon</h5>
                                        </div>
                                    </div>
                                    <div className='col-xl-2 col-lg-4 col-md-6'>
                                        <div className="table-responsive">
                                            <table className="table table-bordered  chart-table">
                                                <tbody>
                                                    <tr >
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr >
                                                        <td >1</td>
                                                        <td colSpan={2} rowSpan={2}></td>
                                                        <td  >1</td>
                                                    </tr>
                                                    <tr >
                                                        <td >1</td>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr >
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='name-holder text-center mt-3 mb-3'>
                                            <h5>Lagna / Ascendant</h5>
                                        </div>
                                    </div>
                                    <div className='col-xl-2 col-lg-4 col-md-6'>
                                        <div className="table-responsive">
                                            <table className="table table-bordered  chart-table">
                                                <tbody>
                                                    <tr >
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr >
                                                        <td >1</td>
                                                        <td colSpan={2} rowSpan={2}></td>
                                                        <td  >1</td>
                                                    </tr>
                                                    <tr >
                                                        <td >1</td>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr >
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='name-holder text-center mt-3 mb-3'>
                                            <h5>Hora</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className='row ashtakvarga-kundali mt-5'>
                                    <div className='col-xl-2 col-lg-4 col-md-6'>
                                        <div className="table-responsive">
                                            <table className="table table-bordered  chart-table">
                                                <tbody>
                                                    <tr >
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr >
                                                        <td >1</td>
                                                        <td colSpan={2} rowSpan={2}></td>
                                                        <td  >1</td>
                                                    </tr>
                                                    <tr >
                                                        <td >1</td>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr >
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='name-holder text-center mt-3 mb-3'>
                                            <h5>Drekkana</h5>
                                        </div>
                                    </div>
                                    <div className='col-xl-2 col-lg-4 col-md-6'>
                                        <div className="table-responsive">
                                            <table className="table table-bordered  chart-table">
                                                <tbody>
                                                    <tr >
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr >
                                                        <td >1</td>
                                                        <td colSpan={2} rowSpan={2}></td>
                                                        <td  >1</td>
                                                    </tr>
                                                    <tr >
                                                        <td >1</td>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr >
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='name-holder text-center mt-3 mb-3'>
                                            <h5>Chaturthamsa</h5>
                                        </div>
                                    </div>
                                    <div className='col-xl-2 col-lg-4 col-md-6'>
                                        <div className="table-responsive">
                                            <table className="table table-bordered  chart-table">
                                                <tbody>
                                                    <tr >
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr >
                                                        <td >1</td>
                                                        <td colSpan={2} rowSpan={2}></td>
                                                        <td  >1</td>
                                                    </tr>
                                                    <tr >
                                                        <td >1</td>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr >
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='name-holder text-center mt-3 mb-3'>
                                            <h5>Saptamsa</h5>
                                        </div>
                                    </div>
                                    <div className='col-xl-2 col-lg-4 col-md-6'>
                                        <div className="table-responsive">
                                            <table className="table table-bordered  chart-table">
                                                <tbody>
                                                    <tr >
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr >
                                                        <td >1</td>
                                                        <td colSpan={2} rowSpan={2}></td>
                                                        <td  >1</td>
                                                    </tr>
                                                    <tr >
                                                        <td >1</td>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr >
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='name-holder text-center mt-3 mb-3'>
                                            <h5>Navamsa</h5>
                                        </div>
                                    </div>
                                    <div className='col-xl-2 col-lg-4 col-md-6'>
                                        <div className="table-responsive">
                                            <table className="table table-bordered  chart-table">
                                                <tbody>
                                                    <tr >
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr >
                                                        <td >1</td>
                                                        <td colSpan={2} rowSpan={2}></td>
                                                        <td  >1</td>
                                                    </tr>
                                                    <tr >
                                                        <td >1</td>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr >
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='name-holder text-center mt-3 mb-3'>
                                            <h5>Dasamsa</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className='row ashtakvarga-kundali mt-5'>
                                    <div className='col-xl-2 col-lg-4 col-md-6'>
                                        <div className="table-responsive">
                                            <table className="table table-bordered  chart-table">
                                                <tbody>
                                                    <tr >
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr >
                                                        <td >1</td>
                                                        <td colSpan={2} rowSpan={2}></td>
                                                        <td  >1</td>
                                                    </tr>
                                                    <tr >
                                                        <td >1</td>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr >
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='name-holder text-center mt-3 mb-3'>
                                            <h5>Dwadasamsa</h5>
                                        </div>
                                    </div>
                                    <div className='col-xl-2 col-lg-4 col-md-6'>
                                        <div className="table-responsive">
                                            <table className="table table-bordered  chart-table">
                                                <tbody>
                                                    <tr >
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr >
                                                        <td >1</td>
                                                        <td colSpan={2} rowSpan={2}></td>
                                                        <td  >1</td>
                                                    </tr>
                                                    <tr >
                                                        <td >1</td>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr >
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='name-holder text-center mt-3 mb-3'>
                                            <h5>Shodasamsa</h5>
                                        </div>
                                    </div>
                                    <div className='col-xl-2 col-lg-4 col-md-6'>
                                        <div className="table-responsive">
                                            <table className="table table-bordered  chart-table">
                                                <tbody>
                                                    <tr >
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr >
                                                        <td >1</td>
                                                        <td colSpan={2} rowSpan={2}></td>
                                                        <td  >1</td>
                                                    </tr>
                                                    <tr >
                                                        <td >1</td>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr >
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='name-holder text-center mt-3 mb-3'>
                                            <h5>Vimsamsa</h5>
                                        </div>
                                    </div>
                                    <div className='col-xl-2 col-lg-4 col-md-6'>
                                        <div className="table-responsive">
                                            <table className="table table-bordered  chart-table">
                                                <tbody>
                                                    <tr >
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr >
                                                        <td >1</td>
                                                        <td colSpan={2} rowSpan={2}></td>
                                                        <td  >1</td>
                                                    </tr>
                                                    <tr >
                                                        <td >1</td>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr >
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='name-holder text-center mt-3 mb-3'>
                                            <h5>Chaturvimsamsa</h5>
                                        </div>
                                    </div>
                                    <div className='col-xl-2 col-lg-4 col-md-6'>
                                        <div className="table-responsive">
                                            <table className="table table-bordered  chart-table">
                                                <tbody>
                                                    <tr >
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr >
                                                        <td >1</td>
                                                        <td colSpan={2} rowSpan={2}></td>
                                                        <td  >1</td>
                                                    </tr>
                                                    <tr >
                                                        <td >1</td>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr >
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='name-holder text-center mt-3 mb-3'>
                                            <h5>Saptavimsamsa</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className='row ashtakvarga-kundali mt-5'>
                                    <div className='col-xl-2 col-lg-4 col-md-6'>
                                        <div className="table-responsive">
                                            <table className="table table-bordered  chart-table">
                                                <tbody>
                                                    <tr >
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr >
                                                        <td >1</td>
                                                        <td colSpan={2} rowSpan={2}></td>
                                                        <td  >1</td>
                                                    </tr>
                                                    <tr >
                                                        <td >1</td>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr >
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='name-holder text-center mt-3 mb-3'>
                                            <h5>Trimsamsa</h5>
                                        </div>
                                    </div>
                                    <div className='col-xl-2 col-lg-4 col-md-6'>
                                        <div className="table-responsive">
                                            <table className="table table-bordered  chart-table">
                                                <tbody>
                                                    <tr >
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr >
                                                        <td >1</td>
                                                        <td colSpan={2} rowSpan={2}></td>
                                                        <td  >1</td>
                                                    </tr>
                                                    <tr >
                                                        <td >1</td>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr >
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='name-holder text-center mt-3 mb-3'>
                                            <h5>Khavedamsa</h5>
                                        </div>
                                    </div>
                                    <div className='col-xl-2 col-lg-4 col-md-6'>
                                        <div className="table-responsive">
                                            <table className="table table-bordered  chart-table">
                                                <tbody>
                                                    <tr >
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr >
                                                        <td >1</td>
                                                        <td colSpan={2} rowSpan={2}></td>
                                                        <td  >1</td>
                                                    </tr>
                                                    <tr >
                                                        <td >1</td>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr >
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='name-holder text-center mt-3 mb-3'>
                                            <h5>Akshavedamsa</h5>
                                        </div>
                                    </div>
                                    <div className='col-xl-2 col-lg-4 col-md-6'>
                                        <div className="table-responsive">
                                            <table className="table table-bordered  chart-table">
                                                <tbody>
                                                    <tr >
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr >
                                                        <td >1</td>
                                                        <td colSpan={2} rowSpan={2}></td>
                                                        <td  >1</td>
                                                    </tr>
                                                    <tr >
                                                        <td >1</td>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr >
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='name-holder text-center mt-3 mb-3'>
                                            <h5>Shastiamsa</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <ConnectAstrologer />
                    </div>

                    <div className="image-naskhtra">
                        <img src={nashkimg} className="nakshtra-img" />
                    </div>
                    <div className="image-naskhtra2">
                        <img src={nashkimg1} className="nakshtra-img" />
                    </div>

                </div>
            </section>

            {/* mobile-view-start */}
            <section className="Free_Kundli mobile-view-free-kundali">
                <div className="container">
                    <div className="row ">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb my-3">
                                <li className="breadcrumb-item"><Link to='/'>Home {'>'} </Link></li>
                                <li className="breadcrumb-item"><Link to='/freekundali'>Free Kundali {'>'} </Link></li>
                                <li className="breadcrumb-item active" ><Link to=''>Astakvarga</Link></li>
                            </ol>
                        </nav>
                        <div className="col-lg-12 p-0">
                            <ul className="nav nav-pills  " id="pills-tab" role="tablist">
                                <Swiper
                                    modules={[Navigation, Autoplay]}
                                    pagination={false}
                                    navigation={false}
                                    // loop={true}
                                    breakpoints={{
                                        0: {
                                            slidesPerView: 3,
                                            // spaceBetween: 10,
                                        },
                                        360: {
                                            slidesPerView: 3,
                                            // spaceBetween: 10,
                                        },
                                        567: {
                                            slidesPerView: 4,
                                            // spaceBetween: 10,
                                        },
                                        640: {
                                            slidesPerView: 4,
                                            // spaceBetween: 10,
                                        },
                                        768: {
                                            slidesPerView: 4,
                                            // spaceBetween: 10,
                                        },

                                    }}

                                    className="suportres-slider" >
                                    <SwiperSlide className="swiper-slide1">
                                        <li className="nav-item " role="presentation">
                                            <Link to='/Basic-Kundali'> <button className="nav-link " id="pills-basic-tab" data-bs-toggle="pill" data-bs-target="#pills-basic"
                                                type="button" role="tab" aria-controls="pills-basic" aria-selected="true"> Basic </button></Link>
                                        </li>
                                    </SwiperSlide>

                                    <SwiperSlide className="swiper-slide1">
                                        <li className="nav-item" role="presentation">
                                            <Link to='/second-Kundali'>   <button className="nav-link" id="pills-kundli-tab" data-bs-toggle="pill" data-bs-target="#pills-kundli" type="button"
                                                role="tab" aria-controls="pills-kundli" aria-selected="false">
                                                Kundli
                                            </button></Link>
                                        </li>
                                    </SwiperSlide>

                                    <SwiperSlide className="swiper-slide1">
                                        <li className="nav-item" role="presentation">
                                            <Link to='/kp-Kundali'>   <button className="nav-link " id="pills-kp-tab" data-bs-toggle="pill" data-bs-target="#pills-kp"
                                                type="button" role="tab" aria-controls="pills-kp" aria-selected="false">
                                                KP
                                            </button></Link>
                                        </li>
                                    </SwiperSlide>

                                    <SwiperSlide className="swiper-slide1">
                                        <li className="nav-item" role="presentation">
                                            <Link to="/free-ashtkvarga">
                                                <button className="nav-link" id="pills-ashtakvarga-tab" data-bs-toggle="pill" data-bs-target="#pills-ashtakvarga"
                                                    type="button" role="tab" aria-controls="pills-ashtakvarga" aria-selected="false">
                                                    Ashtakvarga
                                                </button></Link>
                                        </li>
                                    </SwiperSlide>

                                    <SwiperSlide className="swiper-slide1">
                                        <li className="nav-item" role="presentation">
                                            <Link to="/free-chart">
                                                <button className="nav-link active" id="pills-charts-tab" data-bs-toggle="pill" data-bs-target="#pills-charts"
                                                    type="button" role="tab" aria-controls="pills-charts" aria-selected="false">
                                                    Charts
                                                </button></Link>
                                        </li>
                                    </SwiperSlide>

                                    <SwiperSlide className="swiper-slide1">
                                        <li className="nav-item" role="presentation">
                                            <Link to="/free-dasha">
                                                <button className="nav-link" id="pills-dasha-tab" data-bs-toggle="pill" data-bs-target="#pills-dasha"
                                                    type="button" role="tab" aria-controls="pills-dasha" aria-selected="false">
                                                    Dasha
                                                </button></Link>
                                        </li>
                                    </SwiperSlide>

                                    <SwiperSlide className="swiper-slide1">
                                        <li className="nav-item free-nav" role="presentation">
                                            <Link to={'/free_Report'}>
                                                <button className="nav-link" id="pills-report-tab" data-bs-toggle="pill" data-bs-target="#pills-report"
                                                    type="button" role="tab" aria-controls="pills-report" aria-selected="false">
                                                    Free Report
                                                </button>
                                            </Link>
                                        </li>
                                    </SwiperSlide>
                                </Swiper>
                            </ul>
                        </div>
                    </div>

                    <div className='row south-north-tabs'>

                        <Tabs defaultActiveKey="north" id="uncontrolled-tab-example">
                            <TabList className="nav nav-pills justify-content-end mt-4 mb-3">
                                <Tab eventKey="north" title="north" className="nav-item mb-md-0 mb-sm-2 mb-2 north_indian_txt me-2"><button className="nav-link "> North Indian</button></Tab>
                                <Tab eventKey="south" title="south" className="nav-item mb-md-0 mb-sm-2 mb-2 north_indian_txt me-2" > <button className="nav-link">South Indian</button></Tab>
                            </TabList>

                            <TabPanel className="tab-content">
                                <div >
                                    <div className='row ashtakvarga-kundali '>
                                        <div className=' col-sm-6'>
                                            <div class="main-kundali mx-auto">
                                                <div class="sub"></div>
                                                <div class="line1"></div>
                                                <div class="line2"></div>

                                                <p class="p1">01</p>
                                                <p class="p2">02</p>
                                                <p class="p3">03</p>
                                                <p class="p4">04</p>
                                                <p class="p5">05</p>
                                                <p class="p6">06</p>
                                                <p class="p7">07</p>
                                                <p class="p8">08</p>
                                                <p class="p9">09</p>
                                                <p class="p10">10</p>
                                                <p class="p11">11</p>
                                                <p class="p12">12</p>
                                            </div>
                                            <div className='name-holder text-center mb-4 mt-2'>
                                                <h5>Chalit</h5>
                                            </div>
                                        </div>
                                        <div className=' col-sm-6'>
                                            <div class="main-kundali mx-auto">
                                                <div class="sub"></div>
                                                <div class="line1"></div>
                                                <div class="line2"></div>

                                                <p class="p1">01</p>
                                                <p class="p2">02</p>
                                                <p class="p3">03</p>
                                                <p class="p4">04</p>
                                                <p class="p5">05</p>
                                                <p class="p6">06</p>
                                                <p class="p7">07</p>
                                                <p class="p8">08</p>
                                                <p class="p9">09</p>
                                                <p class="p10">10</p>
                                                <p class="p11">11</p>
                                                <p class="p12">12</p>
                                            </div>
                                            <div className='name-holder text-center mb-4  mt-2'>
                                                <h5>Sun</h5>
                                            </div>
                                        </div>
                                        <div className=' col-sm-6'>
                                            <div class="main-kundali mx-auto">
                                                <div class="sub"></div>
                                                <div class="line1"></div>
                                                <div class="line2"></div>

                                                <p class="p1">01</p>
                                                <p class="p2">02</p>
                                                <p class="p3">03</p>
                                                <p class="p4">04</p>
                                                <p class="p5">05</p>
                                                <p class="p6">06</p>
                                                <p class="p7">07</p>
                                                <p class="p8">08</p>
                                                <p class="p9">09</p>
                                                <p class="p10">10</p>
                                                <p class="p11">11</p>
                                                <p class="p12">12</p>
                                            </div>
                                            <div className='name-holder text-center mb-4  mt-2'>
                                                <h5>Moon</h5>
                                            </div>
                                        </div>
                                        <div className=' col-sm-6'>
                                            <div class="main-kundali mx-auto">
                                                <div class="sub"></div>
                                                <div class="line1"></div>
                                                <div class="line2"></div>

                                                <p class="p1">01</p>
                                                <p class="p2">02</p>
                                                <p class="p3">03</p>
                                                <p class="p4">04</p>
                                                <p class="p5">05</p>
                                                <p class="p6">06</p>
                                                <p class="p7">07</p>
                                                <p class="p8">08</p>
                                                <p class="p9">09</p>
                                                <p class="p10">10</p>
                                                <p class="p11">11</p>
                                                <p class="p12">12</p>
                                            </div>
                                            <div className='name-holder text-center mb-4  mt-2'>
                                                <h5>Lagna / Ascendant</h5>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div class="main-kundali mx-auto">
                                                <div class="sub"></div>
                                                <div class="line1"></div>
                                                <div class="line2"></div>

                                                <p class="p1">01</p>
                                                <p class="p2">02</p>
                                                <p class="p3">03</p>
                                                <p class="p4">04</p>
                                                <p class="p5">05</p>
                                                <p class="p6">06</p>
                                                <p class="p7">07</p>
                                                <p class="p8">08</p>
                                                <p class="p9">09</p>
                                                <p class="p10">10</p>
                                                <p class="p11">11</p>
                                                <p class="p12">12</p>
                                            </div>
                                            <div className='name-holder text-center mb-4  mt-2'>
                                                <h5>Hora</h5>
                                            </div>
                                        </div>


                                        <div className='col-sm-6 '>
                                            <div class="main-kundali mx-auto">
                                                <div class="sub"></div>
                                                <div class="line1"></div>
                                                <div class="line2"></div>

                                                <p class="p1">01</p>
                                                <p class="p2">02</p>
                                                <p class="p3">03</p>
                                                <p class="p4">04</p>
                                                <p class="p5">05</p>
                                                <p class="p6">06</p>
                                                <p class="p7">07</p>
                                                <p class="p8">08</p>
                                                <p class="p9">09</p>
                                                <p class="p10">10</p>
                                                <p class="p11">11</p>
                                                <p class="p12">12</p>
                                            </div>
                                            <div className='name-holder text-center mb-4  mt-2'>
                                                <h5>Drekkana</h5>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div class="main-kundali mx-auto">
                                                <div class="sub"></div>
                                                <div class="line1"></div>
                                                <div class="line2"></div>

                                                <p class="p1">01</p>
                                                <p class="p2">02</p>
                                                <p class="p3">03</p>
                                                <p class="p4">04</p>
                                                <p class="p5">05</p>
                                                <p class="p6">06</p>
                                                <p class="p7">07</p>
                                                <p class="p8">08</p>
                                                <p class="p9">09</p>
                                                <p class="p10">10</p>
                                                <p class="p11">11</p>
                                                <p class="p12">12</p>
                                            </div>
                                            <div className='name-holder text-center mb-4  mt-2'>
                                                <h5>Chaturthamsa</h5>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div class="main-kundali mx-auto">
                                                <div class="sub"></div>
                                                <div class="line1"></div>
                                                <div class="line2"></div>

                                                <p class="p1">01</p>
                                                <p class="p2">02</p>
                                                <p class="p3">03</p>
                                                <p class="p4">04</p>
                                                <p class="p5">05</p>
                                                <p class="p6">06</p>
                                                <p class="p7">07</p>
                                                <p class="p8">08</p>
                                                <p class="p9">09</p>
                                                <p class="p10">10</p>
                                                <p class="p11">11</p>
                                                <p class="p12">12</p>
                                            </div>
                                            <div className='name-holder text-center mb-4  mt-2'>
                                                <h5>Saptamsa</h5>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div class="main-kundali mx-auto">
                                                <div class="sub"></div>
                                                <div class="line1"></div>
                                                <div class="line2"></div>

                                                <p class="p1">01</p>
                                                <p class="p2">02</p>
                                                <p class="p3">03</p>
                                                <p class="p4">04</p>
                                                <p class="p5">05</p>
                                                <p class="p6">06</p>
                                                <p class="p7">07</p>
                                                <p class="p8">08</p>
                                                <p class="p9">09</p>
                                                <p class="p10">10</p>
                                                <p class="p11">11</p>
                                                <p class="p12">12</p>
                                            </div>
                                            <div className='name-holder text-center mb-4  mt-2'>
                                                <h5>Navamsa</h5>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div class="main-kundali mx-auto">
                                                <div class="sub"></div>
                                                <div class="line1"></div>
                                                <div class="line2"></div>

                                                <p class="p1">01</p>
                                                <p class="p2">02</p>
                                                <p class="p3">03</p>
                                                <p class="p4">04</p>
                                                <p class="p5">05</p>
                                                <p class="p6">06</p>
                                                <p class="p7">07</p>
                                                <p class="p8">08</p>
                                                <p class="p9">09</p>
                                                <p class="p10">10</p>
                                                <p class="p11">11</p>
                                                <p class="p12">12</p>
                                            </div>
                                            <div className='name-holder text-center mb-4  mt-2'>
                                                <h5>Dasamsa</h5>
                                            </div>
                                        </div>


                                        <div className='col-sm-6'>
                                            <div class="main-kundali mx-auto">
                                                <div class="sub"></div>
                                                <div class="line1"></div>
                                                <div class="line2"></div>

                                                <p class="p1">01</p>
                                                <p class="p2">02</p>
                                                <p class="p3">03</p>
                                                <p class="p4">04</p>
                                                <p class="p5">05</p>
                                                <p class="p6">06</p>
                                                <p class="p7">07</p>
                                                <p class="p8">08</p>
                                                <p class="p9">09</p>
                                                <p class="p10">10</p>
                                                <p class="p11">11</p>
                                                <p class="p12">12</p>
                                            </div>
                                            <div className='name-holder text-center mb-4  mt-2'>
                                                <h5>Dwadasamsa</h5>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div class="main-kundali mx-auto">
                                                <div class="sub"></div>
                                                <div class="line1"></div>
                                                <div class="line2"></div>

                                                <p class="p1">01</p>
                                                <p class="p2">02</p>
                                                <p class="p3">03</p>
                                                <p class="p4">04</p>
                                                <p class="p5">05</p>
                                                <p class="p6">06</p>
                                                <p class="p7">07</p>
                                                <p class="p8">08</p>
                                                <p class="p9">09</p>
                                                <p class="p10">10</p>
                                                <p class="p11">11</p>
                                                <p class="p12">12</p>
                                            </div>
                                            <div className='name-holder text-center mb-4  mt-2'>
                                                <h5>Shodasamsa</h5>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div class="main-kundali mx-auto">
                                                <div class="sub"></div>
                                                <div class="line1"></div>
                                                <div class="line2"></div>

                                                <p class="p1">01</p>
                                                <p class="p2">02</p>
                                                <p class="p3">03</p>
                                                <p class="p4">04</p>
                                                <p class="p5">05</p>
                                                <p class="p6">06</p>
                                                <p class="p7">07</p>
                                                <p class="p8">08</p>
                                                <p class="p9">09</p>
                                                <p class="p10">10</p>
                                                <p class="p11">11</p>
                                                <p class="p12">12</p>
                                            </div>
                                            <div className='name-holder text-center mb-4  mt-2'>
                                                <h5>Vimsamsa</h5>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div class="main-kundali mx-auto">
                                                <div class="sub"></div>
                                                <div class="line1"></div>
                                                <div class="line2"></div>

                                                <p class="p1">01</p>
                                                <p class="p2">02</p>
                                                <p class="p3">03</p>
                                                <p class="p4">04</p>
                                                <p class="p5">05</p>
                                                <p class="p6">06</p>
                                                <p class="p7">07</p>
                                                <p class="p8">08</p>
                                                <p class="p9">09</p>
                                                <p class="p10">10</p>
                                                <p class="p11">11</p>
                                                <p class="p12">12</p>
                                            </div>
                                            <div className='name-holder text-center mb-4  mt-2'>
                                                <h5>Chaturvimsamsa</h5>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div class="main-kundali mx-auto">
                                                <div class="sub"></div>
                                                <div class="line1"></div>
                                                <div class="line2"></div>

                                                <p class="p1">01</p>
                                                <p class="p2">02</p>
                                                <p class="p3">03</p>
                                                <p class="p4">04</p>
                                                <p class="p5">05</p>
                                                <p class="p6">06</p>
                                                <p class="p7">07</p>
                                                <p class="p8">08</p>
                                                <p class="p9">09</p>
                                                <p class="p10">10</p>
                                                <p class="p11">11</p>
                                                <p class="p12">12</p>
                                            </div>
                                            <div className='name-holder text-center mb-4  mt-2'>
                                                <h5>Saptavimsamsa</h5>
                                            </div>
                                        </div>


                                        <div className='col-sm-6'>
                                            <div class="main-kundali mx-auto">
                                                <div class="sub"></div>
                                                <div class="line1"></div>
                                                <div class="line2"></div>

                                                <p class="p1">01</p>
                                                <p class="p2">02</p>
                                                <p class="p3">03</p>
                                                <p class="p4">04</p>
                                                <p class="p5">05</p>
                                                <p class="p6">06</p>
                                                <p class="p7">07</p>
                                                <p class="p8">08</p>
                                                <p class="p9">09</p>
                                                <p class="p10">10</p>
                                                <p class="p11">11</p>
                                                <p class="p12">12</p>
                                            </div>
                                            <div className='name-holder text-center mb-4  mt-2'>
                                                <h5>Trimsamsa</h5>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div class="main-kundali mx-auto">
                                                <div class="sub"></div>
                                                <div class="line1"></div>
                                                <div class="line2"></div>

                                                <p class="p1">01</p>
                                                <p class="p2">02</p>
                                                <p class="p3">03</p>
                                                <p class="p4">04</p>
                                                <p class="p5">05</p>
                                                <p class="p6">06</p>
                                                <p class="p7">07</p>
                                                <p class="p8">08</p>
                                                <p class="p9">09</p>
                                                <p class="p10">10</p>
                                                <p class="p11">11</p>
                                                <p class="p12">12</p>
                                            </div>
                                            <div className='name-holder text-center mb-4  mt-2'>
                                                <h5>Khavedamsa</h5>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div class="main-kundali mx-auto">
                                                <div class="sub"></div>
                                                <div class="line1"></div>
                                                <div class="line2"></div>

                                                <p class="p1">01</p>
                                                <p class="p2">02</p>
                                                <p class="p3">03</p>
                                                <p class="p4">04</p>
                                                <p class="p5">05</p>
                                                <p class="p6">06</p>
                                                <p class="p7">07</p>
                                                <p class="p8">08</p>
                                                <p class="p9">09</p>
                                                <p class="p10">10</p>
                                                <p class="p11">11</p>
                                                <p class="p12">12</p>
                                            </div>
                                            <div className='name-holder text-center mb-4  mt-2'>
                                                <h5>Akshavedamsa</h5>
                                            </div>
                                        </div>
                                        <div className='col-sm-12'>
                                            <div class="main-kundali mx-auto">
                                                <div class="sub"></div>
                                                <div class="line1"></div>
                                                <div class="line2"></div>

                                                <p class="p1">01</p>
                                                <p class="p2">02</p>
                                                <p class="p3">03</p>
                                                <p class="p4">04</p>
                                                <p class="p5">05</p>
                                                <p class="p6">06</p>
                                                <p class="p7">07</p>
                                                <p class="p8">08</p>
                                                <p class="p9">09</p>
                                                <p class="p10">10</p>
                                                <p class="p11">11</p>
                                                <p class="p12">12</p>
                                            </div>
                                            <div className='name-holder text-center mb-4  mt-2'>
                                                <h5>Shastiamsa</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div  >
                                    <div className='row ashtakvarga-kundali'>
                                        <div className='col-sm-6 '>
                                            <div className="table-responsive">
                                                <table className="table table-bordered  chart-table mx-auto">
                                                    <tbody>
                                                        <tr >
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr >
                                                            <td >1</td>
                                                            <td colSpan={2} rowSpan={2}></td>
                                                            <td  >1</td>
                                                        </tr>
                                                        <tr >
                                                            <td >1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr >
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className='name-holder text-center mb-4  mt-0'>
                                                <h5>Chalit</h5>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className="table-responsive">
                                                <table className="table table-bordered  chart-table mx-auto">
                                                    <tbody>
                                                        <tr >
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr >
                                                            <td >1</td>
                                                            <td colSpan={2} rowSpan={2}></td>
                                                            <td  >1</td>
                                                        </tr>
                                                        <tr >
                                                            <td >1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr >
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className='name-holder text-center mb-4  mt-0'>
                                                <h5>Sun</h5>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className="table-responsive">
                                                <table className="table table-bordered  chart-table mx-auto">
                                                    <tbody>
                                                        <tr >
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr >
                                                            <td >1</td>
                                                            <td colSpan={2} rowSpan={2}></td>
                                                            <td  >1</td>
                                                        </tr>
                                                        <tr >
                                                            <td >1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr >
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className='name-holder text-center mb-4  mt-0'>
                                                <h5>Moon</h5>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className="table-responsive">
                                                <table className="table table-bordered  chart-table mx-auto">
                                                    <tbody>
                                                        <tr >
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr >
                                                            <td >1</td>
                                                            <td colSpan={2} rowSpan={2}></td>
                                                            <td  >1</td>
                                                        </tr>
                                                        <tr >
                                                            <td >1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr >
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className='name-holder text-center mb-4  mt-0'>
                                                <h5>Lagna / Ascendant</h5>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className="table-responsive">
                                                <table className="table table-bordered  chart-table mx-auto">
                                                    <tbody>
                                                        <tr >
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr >
                                                            <td >1</td>
                                                            <td colSpan={2} rowSpan={2}></td>
                                                            <td  >1</td>
                                                        </tr>
                                                        <tr >
                                                            <td >1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr >
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className='name-holder text-center mb-4  mt-0'>
                                                <h5>Hora</h5>
                                            </div>
                                        </div>

                                        <div className='col-sm-6'>
                                            <div className="table-responsive">
                                                <table className="table table-bordered  chart-table mx-auto">
                                                    <tbody>
                                                        <tr >
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr >
                                                            <td >1</td>
                                                            <td colSpan={2} rowSpan={2}></td>
                                                            <td  >1</td>
                                                        </tr>
                                                        <tr >
                                                            <td >1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr >
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className='name-holder text-center mb-4  mt-0'>
                                                <h5>Drekkana</h5>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className="table-responsive">
                                                <table className="table table-bordered  chart-table mx-auto">
                                                    <tbody>
                                                        <tr >
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr >
                                                            <td >1</td>
                                                            <td colSpan={2} rowSpan={2}></td>
                                                            <td  >1</td>
                                                        </tr>
                                                        <tr >
                                                            <td >1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr >
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className='name-holder text-center mb-4  mt-0'>
                                                <h5>Chaturthamsa</h5>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className="table-responsive">
                                                <table className="table table-bordered  chart-table mx-auto">
                                                    <tbody>
                                                        <tr >
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr >
                                                            <td >1</td>
                                                            <td colSpan={2} rowSpan={2}></td>
                                                            <td  >1</td>
                                                        </tr>
                                                        <tr >
                                                            <td >1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr >
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className='name-holder text-center mb-4  mt-0'>
                                                <h5>Saptamsa</h5>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className="table-responsive">
                                                <table className="table table-bordered  chart-table mx-auto">
                                                    <tbody>
                                                        <tr >
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr >
                                                            <td >1</td>
                                                            <td colSpan={2} rowSpan={2}></td>
                                                            <td  >1</td>
                                                        </tr>
                                                        <tr >
                                                            <td >1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr >
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className='name-holder text-center mb-4  mt-0'>
                                                <h5>Navamsa</h5>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className="table-responsive">
                                                <table className="table table-bordered  chart-table mx-auto">
                                                    <tbody>
                                                        <tr >
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr >
                                                            <td >1</td>
                                                            <td colSpan={2} rowSpan={2}></td>
                                                            <td  >1</td>
                                                        </tr>
                                                        <tr >
                                                            <td >1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr >
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className='name-holder text-center mb-4  mt-0'>
                                                <h5>Dasamsa</h5>
                                            </div>
                                        </div>

                                        <div className='col-sm-6'>
                                            <div className="table-responsive">
                                                <table className="table table-bordered  chart-table mx-auto">
                                                    <tbody>
                                                        <tr >
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr >
                                                            <td >1</td>
                                                            <td colSpan={2} rowSpan={2}></td>
                                                            <td  >1</td>
                                                        </tr>
                                                        <tr >
                                                            <td >1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr >
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className='name-holder text-center mb-4  mt-0'>
                                                <h5>Dwadasamsa</h5>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className="table-responsive">
                                                <table className="table table-bordered  chart-table mx-auto">
                                                    <tbody>
                                                        <tr >
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr >
                                                            <td >1</td>
                                                            <td colSpan={2} rowSpan={2}></td>
                                                            <td  >1</td>
                                                        </tr>
                                                        <tr >
                                                            <td >1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr >
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className='name-holder text-center mb-4  mt-0'>
                                                <h5>Shodasamsa</h5>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className="table-responsive">
                                                <table className="table table-bordered  chart-table mx-auto">
                                                    <tbody>
                                                        <tr >
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr >
                                                            <td >1</td>
                                                            <td colSpan={2} rowSpan={2}></td>
                                                            <td  >1</td>
                                                        </tr>
                                                        <tr >
                                                            <td >1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr >
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className='name-holder text-center mb-4  mt-0'>
                                                <h5>Vimsamsa</h5>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className="table-responsive">
                                                <table className="table table-bordered  chart-table mx-auto">
                                                    <tbody>
                                                        <tr >
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr >
                                                            <td >1</td>
                                                            <td colSpan={2} rowSpan={2}></td>
                                                            <td  >1</td>
                                                        </tr>
                                                        <tr >
                                                            <td >1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr >
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className='name-holder text-center mb-4  mt-0'>
                                                <h5>Chaturvimsamsa</h5>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className="table-responsive">
                                                <table className="table table-bordered  chart-table mx-auto">
                                                    <tbody>
                                                        <tr >
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr >
                                                            <td >1</td>
                                                            <td colSpan={2} rowSpan={2}></td>
                                                            <td  >1</td>
                                                        </tr>
                                                        <tr >
                                                            <td >1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr >
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className='name-holder text-center mb-4  mt-0'>
                                                <h5>Saptavimsamsa</h5>
                                            </div>
                                        </div>

                                        <div className='col-sm-6'>
                                            <div className="table-responsive">
                                                <table className="table table-bordered  chart-table mx-auto">
                                                    <tbody>
                                                        <tr >
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr >
                                                            <td >1</td>
                                                            <td colSpan={2} rowSpan={2}></td>
                                                            <td  >1</td>
                                                        </tr>
                                                        <tr >
                                                            <td >1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr >
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className='name-holder text-center mb-4  mt-0'>
                                                <h5>Trimsamsa</h5>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className="table-responsive">
                                                <table className="table table-bordered  chart-table mx-auto">
                                                    <tbody>
                                                        <tr >
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr >
                                                            <td >1</td>
                                                            <td colSpan={2} rowSpan={2}></td>
                                                            <td  >1</td>
                                                        </tr>
                                                        <tr >
                                                            <td >1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr >
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className='name-holder text-center mb-4  mt-0'>
                                                <h5>Khavedamsa</h5>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className="table-responsive">
                                                <table className="table table-bordered  chart-table mx-auto">
                                                    <tbody>
                                                        <tr >
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr >
                                                            <td >1</td>
                                                            <td colSpan={2} rowSpan={2}></td>
                                                            <td  >1</td>
                                                        </tr>
                                                        <tr >
                                                            <td >1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr >
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className='name-holder text-center mb-4  mt-0'>
                                                <h5>Akshavedamsa</h5>
                                            </div>
                                        </div>
                                        <div className='col-sm-6 mx-auto'>
                                            <div className="table-responsive">
                                                <table className="table table-bordered  chart-table mx-auto">
                                                    <tbody>
                                                        <tr >
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr >
                                                            <td >1</td>
                                                            <td colSpan={2} rowSpan={2}></td>
                                                            <td  >1</td>
                                                        </tr>
                                                        <tr >
                                                            <td >1</td>
                                                            <td>1</td>
                                                        </tr>
                                                        <tr >
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className='name-holder text-center mb-4  mt-0'>
                                                <h5>Shastiamsa</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </Tabs>

                    </div>


                    <div className="row">
                        <ConnectAstrologer />
                    </div>

                    <div className="image-naskhtra">
                        <img src={nashkimg} className="nakshtra-img" />
                    </div>
                    <div className="image-naskhtra2">
                        <img src={nashkimg1} className="nakshtra-img" />
                    </div>

                </div>
            </section>
        </>
    )
}

export default FreeChart