import React from 'react'
import { Link } from 'react-router-dom'
import HoroscopeSlider from '../HoroscopeSlider/HoroscopeSlider'

const Today_Horoscope = () => {
  return (
    <>
      <section className="Horoscope2023 desk-top-view-horoscope">
        <div className="container-fluid">
          <div className='row'>
            <div className='col-xxl-9 col-xl-10 col-lg-11 col-md-11 mx-auto'>
              <div className="row">
                <div className="col-md-12">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mt-4">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        <Link >Today's Horoscope</Link>
                      </li>
                    </ol>
                  </nav>


                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    {/* <div className="card-body"> */}
                    <div className="Horoscope_info p-4">
                      <div className="Horoscope_heading mb-2">
                        Today’s horoscope for zodiac signs
                      </div>
                      <p>
                        Reading your Today’s horoscope is one of the best ways to predict your future. The foretelling of the future through the Daily horoscope is an ancient practice and finds relevance even today. The horoscope prediction is about predicting the individual's future based on various astrological aspects such as the position of the planets, Nakshatras, Tithi, and much more. However, it’s predominantly the movement of planets from one house of the Kundli to another that influences the life of the native and thus his Daily horoscope. As planets are in motion, their position in the chart of the native on a daily basis virtually decides the course of his life and fortune
                      </p>

                      <p>
                        Of all the ancient Vedic practices that we are aware of, horoscope reading is one of the most accepted and popular. Horoscope reading transgress boundaries and is a tea-time read for not just the astrology-loving Indians but also people residing in western countries. In fact, these days there are numerous mediums to bring you your today's horoscope, such as TV, newspapers, magazines, and much more. Whatever the medium, the of the Daily horoscope remains the same, which is to prepare you for life and make you aware of all the upcoming events, so you don’t go blank into life as most people do.
                      </p>


                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <HoroscopeSlider />
      </section>

      <section className="Horoscope2023 mobile-view-horoscope">
        <div className="container-fluid">
          <div className='row'>
            <div className="col-md-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mt-3">
                  <li className="breadcrumb-item">
                    <Link to="/">Home {'>'}</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link >Today's Horoscope</Link>
                  </li>
                </ol>
              </nav>
            </div>

            {/* <div className="row"> */}
            <div className="col-md-12">
              <div className="card mt-3">
                {/* <div className="card-body"> */}
                <div className="Horoscope_info p-2">
                  <div className="Horoscope_heading my-2">
                    Today’s horoscope for zodiac signs
                  </div>
                  <p>
                    Reading your Today’s horoscope is one of the best ways to predict your future. The foretelling of the future through the Daily horoscope is an ancient practice and finds relevance even today. The horoscope prediction is about predicting the individual's future based on various astrological aspects such as the position of the planets, Nakshatras, Tithi, and much more. However, it’s predominantly the movement of planets from one house of the Kundli to another that influences the life of the native and thus his Daily horoscope. As planets are in motion, their position in the chart of the native on a daily basis virtually decides the course of his life and fortune
                  </p>

                  <p>
                    Of all the ancient Vedic practices that we are aware of, horoscope reading is one of the most accepted and popular. Horoscope reading transgress boundaries and is a tea-time read for not just the astrology-loving Indians but also people residing in western countries. In fact, these days there are numerous mediums to bring you your today's horoscope, such as TV, newspapers, magazines, and much more. Whatever the medium, the of the Daily horoscope remains the same, which is to prepare you for life and make you aware of all the upcoming events, so you don’t go blank into life as most people do.
                  </p>

                </div>
                {/* </div> */}
              </div>
            </div>
            {/* </div> */}

          </div>
        </div>
        <HoroscopeSlider />
      </section>
    </>
  )
}

export default Today_Horoscope