import React, { useEffect, useState } from 'react'
import './Kundali.css';
import { Link } from "react-router-dom";
import '../chat-astrologer/Chatastrolger.css';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Date_icon from "../Images/Date_icon.png";
import ConnectAstrologer from './connect-astrologer/ConnectAstrologer';
const Kundali = () => {
    const [startdate, setStartDate] = useState(new Date());
    return (
        <>
            <section className='Kundali desktop-view-kundali'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><Link to='/'>Home</Link></li>
                                    <li class="breadcrumb-item active" aria-current="page"><Link to='/kundali-match'>Kundali Matching</Link></li>
                                </ol>
                            </nav>
                            <Link to='/Kundali-history'>
                                <div className='kundali-history text-md-end text-center mb-3'>
                                    <button className='btn btn-history'>Kundali History</button>
                                </div>
                            </Link>
                        </div>


                        <div className='col-lg-12'>
                            <div className='heading-holder '>
                                <h2>Kundali </h2>
                            </div>
                            <div className="main-heading mb-5">
                                <div class="line me-3 mt-2"></div>
                                <div class="square"></div>
                                <div class="line ms-3 mt-2"></div>
                            </div>
                        </div>


                        <div className='row get-kundali-modal'>
                            <div className='col-lg-6 col-md-12 mb-lg-0 mb-md-3 mb-3'>
                                <div className='form-bg p-4'>
                                    <form className="row g-3">
                                        <div className="col-md-4">
                                            <div className="border-dott mt-3"></div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="heading-holder">
                                                <h6>Boy's Details</h6>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="border-dott mt-md-3 mt-0"></div>
                                        </div>
                                        <div className="col-12">
                                            <label for="inputEmail4" className="form-label">
                                                Name*
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="inputEmail4"
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <label for="inputtext4" className="form-label">
                                                Birth Place*
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="inputtext4"
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <label for="inputemail" className="form-label ">
                                                Email
                                            </label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="inputEmail4"
                                            />
                                        </div>

                                        <div className="row">
                                            <div className="col-xl-6 col-lg-7  col-md-6 col-xs-6 ">
                                                <div className="row">
                                                    <div className="col-lg-7 col-md-7 col-sm-7">
                                                        <div className="mt-1">
                                                            <label for="inputAddress2" className="form-label">
                                                                Date of Birth*
                                                            </label>
                                                            <div className="input-group">
                                                                <span
                                                                    class="input-group-text"
                                                                    id="basic-addon1"
                                                                >
                                                                    <img src={Date_icon} className="Date_icon" />
                                                                </span>

                                                                <div className="DatePick">
                                                                    <DatePicker
                                                                        selected={startdate}
                                                                        onChange={(date) => setStartDate(date)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-5 col-md-5 col-sm-5 ">
                                                        <label
                                                            for="inputCity"
                                                            className="form-label mt-3"
                                                        ></label>
                                                        <input
                                                            type="time"
                                                            className="form-control mt-2"
                                                            id="inputCity"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" col-xl-6 col-lg-5 col-md-6 col-xs-6 ">
                                                <div className="form-radio1 ">
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="flexRadioDefault"
                                                            id="flexRadioDefault1"
                                                            checked
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            for="flexRadioDefault1"
                                                        >
                                                            AM
                                                        </label>
                                                    </div>
                                                    <div className="form-check  ">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="flexRadioDefault"
                                                            id="flexRadioDefault2"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            for="flexRadioDefault2"
                                                        >
                                                            PM
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-12 '>
                                <div className='form-bg p-4'>
                                    <form className="row g-3">
                                        <div className="col-md-4">
                                            <div className="border-dott mt-3"></div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="heading-holder">
                                                <h6>Girl's Details</h6>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="border-dott mt-md-3 mt-0"></div>
                                        </div>
                                        <div className="col-12">
                                            <label for="inputEmail4" className="form-label">
                                                Name*
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="inputEmail4"
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <label for="inputtext4" className="form-label">
                                                Birth Place*
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="inputtext4"
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <label for="inputemail" className="form-label ">
                                                Email
                                            </label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="inputEmail4"
                                            />
                                        </div>

                                        <div className="row">
                                            <div className="col-xl-6 col-lg-7 col-md-6 col-xs-6 ">
                                                <div className="row">
                                                    <div className="col-lg-7 col-md-7 col-sm-7">
                                                        <div className="mt-1">
                                                            <label for="inputAddress2" className="form-label">
                                                                Date of Birth*
                                                            </label>
                                                            <div className="input-group">
                                                                <span
                                                                    class="input-group-text"
                                                                    id="basic-addon1"
                                                                >
                                                                    <img src={Date_icon} className="Date_icon" />
                                                                </span>

                                                                <div className="DatePick">
                                                                    <DatePicker
                                                                        selected={startdate}
                                                                        onChange={(date) => setStartDate(date)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-5 col-md-5 col-sm-5 ">
                                                        <label
                                                            for="inputCity"
                                                            className="form-label mt-3"
                                                        ></label>
                                                        <input
                                                            type="time"
                                                            className="form-control mt-2"
                                                            id="inputCity"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" col-xl-6 col-lg-5 col-md-6 col-xs-6 ">
                                                <div className="form-radio1 ">
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="flexRadioDefault"
                                                            id="flexRadioDefault1"
                                                            checked
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            for="flexRadioDefault1"
                                                        >
                                                            AM
                                                        </label>
                                                    </div>
                                                    <div className="form-check  ">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="flexRadioDefault"
                                                            id="flexRadioDefault2"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            for="flexRadioDefault2"
                                                        >
                                                            PM
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="col-11 mx-auto ">
                                <Link to='/kundali-match'><button class="btn btn-match mt-4">Get Your Kundali</button></Link>
                            </div>
                        </div>
                        <ConnectAstrologer />
                    </div>
                </div>
            </section>


            {/* mobile-view-start */}

            <section className='Kundali mobile-view-kundali'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-6'>
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><Link to='/'>Home {'>'}</Link></li>
                                    <li class="breadcrumb-item active" aria-current="page"><Link to='/kundali-match'>Kundali Matching</Link></li>
                                </ol>
                            </nav>
                        </div>
                        <div className='col-6'>
                            <Link to='/Kundali-history'>
                                <div className='kundali-history  text-end mb-3'>
                                    <button className='btn btn-history'>Kundali History</button>
                                </div>
                            </Link>
                        </div>


                        <div className='col-lg-12'>
                            <div className='heading-holder '>
                                <h2>Kundali </h2>
                            </div>
                            <div className="main-heading mb-3">
                                <div class="line me-1 mt-0"></div>
                                <div class="square"></div>
                                <div class="line ms-1 mt-0"></div>
                            </div>
                        </div>


                        <div className=' get-kundali-modal'>
                            <div className='col-md-12'>
                                <div className='form-bg mb-4 p-3'>
                                    <form className="row ">
                                        <div className="col-sm-4 col-3">
                                            <div className="border-dott mt-3"></div>
                                        </div>
                                        <div className="col-sm-4 col-6">
                                            <div className="heading-holder">
                                                <h6>Boy's Details</h6>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 col-3">
                                            <div className="border-dott mt-3"></div>
                                        </div>
                                        <div className="col-12">
                                            <label for="inputEmail4" className="form-label">
                                                Name*
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="inputEmail4"
                                            />
                                        </div>
                                        <div className="col-sm-6 ">
                                            <label for="inputtext4" className="form-label">
                                                Birth Place*
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="inputtext4"
                                            />
                                        </div>
                                        <div className="col-sm-6 ">
                                            <label for="inputemail" className="form-label ">
                                                Email
                                            </label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="inputEmail4"
                                            />
                                        </div>

                                        <div className=" col-sm-8 ">
                                            <div className="row">
                                                <div className=" col-sm-7 col-7 ">
                                                    <div className="mt-1">
                                                        <label for="inputAddress2" className="form-label">
                                                            Date of Birth*
                                                        </label>
                                                        <div className="input-group">
                                                            <span
                                                                class="input-group-text"
                                                                id="basic-addon1"
                                                            >
                                                                <img src={Date_icon} className="Date_icon" />
                                                            </span>

                                                            <div className="DatePick">
                                                                <DatePicker
                                                                    selected={startdate}
                                                                    onChange={(date) => setStartDate(date)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-5 col-5 ">
                                                    <label
                                                        for="inputCity"
                                                        className="form-label mt-3"
                                                    ></label>
                                                    <input
                                                        type="time"
                                                        className="form-control mt-2"
                                                        id="inputCity"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" col-sm-4  ps-0">
                                            <div className="form-radio1 ">
                                                <div className="form-check ">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="flexRadioDefault"
                                                        id="flexRadioDefault1"
                                                        checked
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        for="flexRadioDefault1"
                                                    >
                                                        AM
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="flexRadioDefault"
                                                        id="flexRadioDefault2"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        for="flexRadioDefault2"
                                                    >
                                                        PM
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-12 '>
                                <div className='form-bg  p-3'>
                                    <form className="row ">
                                        <div className="col-sm-4 col-3">
                                            <div className="border-dott mt-3"></div>
                                        </div>
                                        <div className="col-sm-4 col-6">
                                            <div className="heading-holder">
                                                <h6>Girl's Details</h6>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 col-3">
                                            <div className="border-dott mt-3"></div>
                                        </div>
                                        <div className="col-12">
                                            <label for="inputEmail4" className="form-label">
                                                Name*
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="inputEmail4"
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <label for="inputtext4" className="form-label">
                                                Birth Place*
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="inputtext4"
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <label for="inputemail" className="form-label ">
                                                Email
                                            </label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="inputEmail4"
                                            />
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-8 ">
                                                <div className="row">
                                                    <div className="col-sm-7 col-7 ">
                                                        <div className="mt-1">
                                                            <label for="inputAddress2" className="form-label">
                                                                Date of Birth*
                                                            </label>
                                                            <div className="input-group">
                                                                <span
                                                                    class="input-group-text"
                                                                    id="basic-addon1"
                                                                >
                                                                    <img src={Date_icon} className="Date_icon" />
                                                                </span>

                                                                <div className="DatePick">
                                                                    <DatePicker
                                                                        selected={startdate}
                                                                        onChange={(date) => setStartDate(date)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" col-sm-5 col-5">
                                                        <label
                                                            for="inputCity"
                                                            className="form-label mt-3"
                                                        ></label>
                                                        <input
                                                            type="time"
                                                            className="form-control mt-2"
                                                            id="inputCity"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" col-sm-4 ps-0">
                                                <div className="form-radio1 ">
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="flexRadioDefault"
                                                            id="flexRadioDefault1"
                                                            checked
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            for="flexRadioDefault1"
                                                        >
                                                            AM
                                                        </label>
                                                    </div>
                                                    <div className="form-check  ">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="flexRadioDefault"
                                                            id="flexRadioDefault2"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            for="flexRadioDefault2"
                                                        >
                                                            PM
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="col-12 mt-4 mb-5 ">
                                <Link to='/kundali-match'><button class="btn btn-match ">Get Your Kundali</button></Link>
                            </div>
                        </div>
                        <ConnectAstrologer />
                    </div>
                </div>
            </section>



        </>
    )
}

export default Kundali