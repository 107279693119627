import React, { useEffect, useState } from 'react'
import './CallModalparesh.css'
import CallLotteyastro from './call-lottey/CallLotteyastro';
import soundimg from '../../Astrologer-profile/Image/laoud.png';
import recordimg from '../../Astrologer-profile/Image/audio.png';
import circleimg from '../../Astrologer-profile/Image/circle.png';
import callingimg from '../../Astrologer-profile/Image/calling-icon.png'
import { Link, useLocation } from 'react-router-dom';
const CallModalparesh = () => {
    const location = useLocation();
    const [message, setMessage] = useState('Remaining wallet balance is 100 Rs');
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        const timer1 = setTimeout(() => {
            setMessage('');
        }, 7000);

        const timer2 = setTimeout(() => {
            setMessage('Please recharge you wallet to stay on call');
            setShowButton(true);
        }, 5000);

        return () => {
            clearTimeout(timer1);
            clearTimeout(timer2);
        };
    }, []);

    useEffect(() => {

        if (location.pathname === '/talk-astrologer') {
            setMessage('Remaining wallet balance is 100 Rs');
            setShowButton(false);
        }
    }, [location]);
    return (
        <>
            <section className='call-modal-astro'>
                <div class="modal fade" id="callModalparesh" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-fullscreen">
                        <div class="modal-content">
                            <div class="modal-header">
                                {/* <h1 class="modal-title fs-5" id="exampleModalLabel">Modal title</h1> */}
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div className='call-identity'>
                                    <CallLotteyastro />
                                    <div className='name-holder text-center my-3'>
                                        <p>Paresh</p>
                                    </div>
                                    <div className='time-holder text-center my-3'>
                                        <p>10:14</p>
                                        <p >{message}</p>
                                        {showButton && <button className='btn btn-recharge'>Recharge</button>}
                                    </div>
                                    <div className='recording-icon text-center my-3'>
                                        <div className='img-holder'>
                                            <img src={soundimg} className='img-record me-4' />
                                            <img src={recordimg} className='img-record me-4' />
                                            <img src={circleimg} className='img-record' />
                                        </div>
                                    </div>
                                    <div className='recording-icon text-center mt-5'>
                                        <div className='img-holder '>

                                            <img src={callingimg} className='img-calling' />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CallModalparesh