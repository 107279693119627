import React from 'react'
import Lottie from "react-lottie";
import './check.css';
import * as animationData from './check-out.json';
const CheckLottey = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
         preserveAspectRatio: "xMidYMid slice"
        }
       };
  return (
    <>
       <section className='main-lottey'>
        <div className='check'>
            <Lottie
            options={defaultOptions}
              
            />    
        </div>
    </section>
    </>
  )
}

export default CheckLottey