import React from 'react'
import { Link } from 'react-router-dom'
import { Autoplay, Navigation } from 'swiper/modules'
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react"
import Snake from "./Image/Snake.png"
import Cow from "./Image/Cow.png"
import Dragon from "./Image/Dragon.png"
import Mouse from "./Image/Mouse.png"
import rabbit from "./Image/rabbit.png"
import tiger from "./Image/tiger.png";
import horseimg from '../Images/horse.png';
import ramimg from '../Images/ran.png';
import monkeyimg from '../Images/monkey.png';
import roosterimg from '../Images/rooster.png';
import dogimg from '../Images/dog.png';
import pigimg from '../Images/pig.png'
const ChineseSlider = () => {
  return (
    <>
      <section className="ChineseSlider desktop-view-chinese-slider">
        <div className="container-fluid">
          <div className="row">
            <Swiper
              modules={[Navigation, Autoplay]}
              spaceBetween={10}
              slidesPerView={6}
              pagination={false}
              navigation={true}
              breakpoints={{

                768: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
                992: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 10,
                },
                1200: {
                  slidesPerView: 6,
                  spaceBetween: 15,
                },
              }}
              onSwiper={(swiper) => console.log(swiper)}
              onSlideChange={() => console.log("slide change")}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
                loop: true,
                pauseOnMouseEnter: true,
              }}
            >
              <SwiperSlide>
                <Link to={'/rat-chinese'}>  <div className="card my-2">
                  <img src={Mouse}
                    className="card-img-top Mouse_img mx-auto"
                    alt="Mouse_img"
                  />
                  <div className="card-body">
                    <div className="card-title text-center">
                      RAT
                    </div>
                  </div>
                </div></Link>
              </SwiperSlide>

              <SwiperSlide>
                <Link to={'/ox-animal'}> <div className="card my-2">
                  <img
                    src={Cow}
                    className="card-img-top Cow_img mx-auto"
                    alt="Card_2_img"
                  />
                  <div className="card-body">
                    <div className="card-title text-center">
                      OX (OR COW)
                    </div>

                  </div>
                </div></Link>
              </SwiperSlide>

              <SwiperSlide>
                <Link to={'/tiger'}>  <div className="card my-2">
                  <img
                    src={tiger}
                    className="card-img-top tiger_img mx-auto"
                    alt="Card_3_img"
                  />
                  <div className="card-body">
                    <div className="card-title text-center">
                      TIGER
                    </div>

                  </div>
                </div></Link>
              </SwiperSlide>

              <SwiperSlide>
                <Link to={'/rabbit'}>  <div className="card my-2">
                  <img
                    src={rabbit}
                    className="card-img-top rabbit_img mx-auto"
                    alt="..."
                  />
                  <div className="card-body">
                    <div className="card-title text-center">
                      RABBIT
                    </div>


                  </div>
                </div></Link>
              </SwiperSlide>

              <SwiperSlide>
                <Link to={'/dragon-chinese'}>  <div className="card my-2">
                  <img
                    src={Dragon}
                    className="card-img-top Dragon_img mx-auto"
                    alt="Card_4_img"
                  />
                  <div className="card-body">
                    <div className="card-title text-center">
                      DRAGON
                    </div>

                  </div>
                </div></Link>
              </SwiperSlide>

              <SwiperSlide>
                <Link to={'/snake-chinese'}> <div className="card my-2">
                  <img
                    src={Snake}
                    className="card-img-top Snake_img mx-auto"
                    alt="..."
                  />
                  <div className="card-body">
                    <div className="card-title text-center">
                      SNAKE
                    </div>

                  </div>
                </div></Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link to={'/horse-chinese'}>
                  <div className="card my-2">
                    <img
                      src={horseimg}
                      className="card-img-top Snake_img mx-auto"
                      alt="..."
                    />
                    <div className="card-body">
                      <div className="card-title text-center">
                        HORSE
                      </div>

                    </div>
                  </div></Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link to={'/ram-chinese'}>  <div className="card my-2">
                  <img
                    src={ramimg}
                    className="card-img-top Snake_img mx-auto"
                    alt="..."
                  />
                  <div className="card-body">
                    <div className="card-title text-center">
                      RAM
                    </div>

                  </div>
                </div></Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link to={'/monkey-chinese'}>  <div className="card my-2">
                  <img
                    src={monkeyimg}
                    className="card-img-top Snake_img mx-auto"
                    alt="..."
                  />
                  <div className="card-body">
                    <div className="card-title text-center">
                      MONKEY
                    </div>

                  </div>
                </div></Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link to={'/rooster-chinese'}>  <div className="card my-2">
                  <img
                    src={roosterimg}
                    className="card-img-top Snake_img mx-auto"
                    alt="..."
                  />
                  <div className="card-body">
                    <div className="card-title text-center ">
                      ROOSTER
                    </div>

                  </div>
                </div></Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link to={'/dog-chinese'}>  <div className="card my-2">
                  <img
                    src={dogimg}
                    className="card-img-top Snake_img mx-auto"
                    alt="..."
                  />
                  <div className="card-body">
                    <div className="card-title text-center ">
                      DOG
                    </div>

                  </div>
                </div></Link>
              </SwiperSlide>

              <SwiperSlide>
                <Link to={'/pig-chinese'}>  <div className="card my-2">
                  <img
                    src={pigimg}
                    className="card-img-top Snake_img mx-auto"
                    alt="..."
                  />
                  <div className="card-body">
                    <div className="card-title text-center ">
                      Pig (or Boar)
                    </div>

                  </div>
                </div></Link>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>


      {/* mobile-view-start */}
      <section className="ChineseSlider mobile-view-chinese-slider">
        <div className="container-fluid">
          <div className="row">

            <Swiper
              modules={[Navigation, Autoplay]}
              spaceBetween={20}
              slidesPerView={2}
              pagination={false}
              navigation={true}
              loop={true}
              breakpoints={{
                0: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                360: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                486: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                567: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },

              }}
              // onSwiper={(swiper) => console.log(swiper)}
              // onSlideChange={() => console.log("slide change")}
              // autoplay={{
              //   delay: 2500,
              //   disableOnInteraction: false,
              //   loop: true,
              //   pauseOnMouseEnter: true,
              // }}
              className="suportres-slider" >
              <SwiperSlide className='card1-swiper'>
                <Link to={'/rat-chinese'}>  <div className="card my-2">
                  <img src={Mouse}
                    className="card-img-top Mouse_img mx-auto"
                    alt="Mouse_img"
                  />
                  <div className="card-body">
                    <div className="card-title text-center mt-3">
                      RAT
                    </div>
                  </div>
                </div></Link>
              </SwiperSlide>

              <SwiperSlide className='card1-swiper'>
                <Link to={'/ox-animal'}> <div className="card my-2">
                  <img
                    src={Cow}
                    className="card-img-top Cow_img mx-auto"
                    alt="Card_2_img"
                  />
                  <div className="card-body">
                    <div className="card-title text-center mt-3">
                      OX (OR COW)
                    </div>
                  </div>
                </div></Link>
              </SwiperSlide>

              <SwiperSlide className='card1-swiper'>
                <Link to={'/tiger'}>  <div className="card my-2">
                  <img
                    src={tiger}
                    className="card-img-top tiger_img mx-auto"
                    alt="Card_3_img"
                  />
                  <div className="card-body">
                    <div className="card-title text-center mt-3">
                      TIGER
                    </div>
                  </div>
                </div></Link>
              </SwiperSlide>

              <SwiperSlide className='card1-swiper'>
                <Link to={'/rabbit'}>  <div className="card my-2">
                  <img
                    src={rabbit}
                    className="card-img-top rabbit_img mx-auto"
                    alt="..."
                  />
                  <div className="card-body">
                    <div className="card-title text-center mt-3">
                      RABBIT
                    </div>
                  </div>
                </div></Link>
              </SwiperSlide>

              <SwiperSlide className='card1-swiper'>
                <Link to={'/dragon-chinese'}> <div className="card my-2">
                  <img
                    src={Dragon}
                    className="card-img-top Dragon_img mx-auto"
                    alt="Card_4_img"
                  />
                  <div className="card-body">
                    <div className="card-title text-center mt-3">
                      DRAGON
                    </div>
                  </div>
                </div></Link>
              </SwiperSlide>

              <SwiperSlide className='card1-swiper'>
                <Link to={'/snake-chinese'}><div className="card my-2">
                  <img
                    src={Snake}
                    className="card-img-top Snake_img mx-auto"
                    alt="..."
                  />
                  <div className="card-body">
                    <div className="card-title text-center mt-3">
                      SNAKE
                    </div>
                  </div>
                </div></Link>
              </SwiperSlide>
              <SwiperSlide className='card1-swiper'>
                <Link to={'/horse-chinese'}>  <div className="card my-2">
                  <img
                    src={horseimg}
                    className="card-img-top Snake_img mx-auto"
                    alt="..."
                  />
                  <div className="card-body">
                    <div className="card-title text-center mt-3">
                      HORSE
                    </div>
                  </div>
                </div></Link>
              </SwiperSlide>

              <SwiperSlide className='card1-swiper'>
                <Link to={'/ram-chinese'}>  <div className="card my-2">
                  <img
                    src={ramimg}
                    className="card-img-top Snake_img mx-auto"
                    alt="..."
                  />
                  <div className="card-body">
                    <div className="card-title text-center mt-3">
                      RAM
                    </div>
                  </div>
                </div></Link>
              </SwiperSlide>

              <SwiperSlide>
                <Link to={'/monkey-chinese'}>  <div className="card my-2">
                  <img
                    src={monkeyimg}
                    className="card-img-top Snake_img mx-auto"
                    alt="..."
                  />
                  <div className="card-body">
                    <div className="card-title text-center mt-3">
                      MONKEY
                    </div>

                  </div>
                </div></Link>
              </SwiperSlide>

              <SwiperSlide>
                <Link to={'/rooster-chinese'}>  <div className="card my-2">
                  <img
                    src={roosterimg}
                    className="card-img-top Snake_img mx-auto"
                    alt="..."
                  />
                  <div className="card-body">
                    <div className="card-title text-center mt-3">
                      ROOSTER
                    </div>

                  </div>
                </div></Link>
              </SwiperSlide>

              <SwiperSlide>
                <Link to={'/dog-chinese'}>  <div className="card my-2">
                  <img
                    src={dogimg}
                    className="card-img-top Snake_img mx-auto"
                    alt="..."
                  />
                  <div className="card-body">
                    <div className="card-title text-center mt-3">
                      DOG
                    </div>

                  </div>
                </div></Link>
              </SwiperSlide>

              <SwiperSlide>
                <Link to={'/pig-chinese'}>  <div className="card my-2">
                  <img
                    src={pigimg}
                    className="card-img-top Snake_img mx-auto"
                    alt="..."
                  />
                  <div className="card-body">
                    <div className="card-title text-center mt-3">
                      Pig (or Boar)
                    </div>

                  </div>
                </div></Link>
              </SwiperSlide>
            </Swiper>

          </div>
        </div>
      </section>
    </>
  )
}

export default ChineseSlider