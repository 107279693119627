import React from 'react'
import './ChatHistory.css';
import { Link } from "react-router-dom";
import deleteimg from '../wallet-tranction/image/delete-sweep.png'
import CheckLottey from '../../wallet/check-lottey/CheckLottey';
const ChatHistory = () => {
    return (
        <>
            <section className='chat-history'>
                <div className='container'>
                    <div className='row'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mt-3">
                                <li className="breadcrumb-item"><Link to='/'>Home {'>'}</Link></li>
                                <li className="breadcrumb-item"><Link to=''>Profile {'>'}</Link></li>
                                <li className="breadcrumb-item active" aria-current="page"> Chat History</li>
                            </ol>
                        </nav>
                    </div>
                    <div className='row'>
                        <div className='col-xxl-6 col-xl-6 col-md-8  mt-4   mx-auto mb-5'  >
                            <div className='card mb-4'>
                                <div className='card-body'>
                                    <div className='d-flex'>
                                        <Link to={'/ChatHistory-profile'}> <div className='d-flex'>
                                            <div className='name-holder ms-2 me-2'>
                                                <h6 className=' name-identity'>SK</h6>
                                            </div>

                                            <div className='address-holder ps-1 pt-xxl-2 pt-xl-2 pt-md-2 pt-0'>
                                                <h6>Shubham Kumar</h6>
                                            </div>
                                        </div></Link>
                                        <div className='delete-icon pt-md-0 pt-2'>
                                            <img src={deleteimg} className='delete-img me-md-3 me-0' type="button" data-bs-toggle="modal"
                                                data-bs-target="#deleteModal" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card mb-4'>
                                <div className='card-body'>
                                    <div className='d-flex'>
                                        <Link to={'/ChatHistory-profile'}>  <div className='d-flex'>
                                            <div className='name-holder2 ms-2 me-2'>
                                                <h6 className=' name-identity'>AJ</h6>
                                            </div>

                                            <div className='address-holder ps-1 pt-xxl-2 pt-xl-2 pt-md-2 pt-0'>
                                                <h6>Avinash Jadhav</h6>
                                            </div>
                                        </div></Link>
                                        <div className='delete-icon pt-2'>
                                            <img src={deleteimg} className='delete-img me-md-3 me-0' type="button" data-bs-toggle="modal"
                                                data-bs-target="#deleteModal" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card mb-4'>
                                <div className='card-body'>
                                    <div className='d-flex'>
                                        <Link to={'/ChatHistory-profile'}>  <div className='d-flex'>
                                            <div className='name-holder3 ms-2 me-2'>
                                                <h6 className=' name-identity'>VR</h6>
                                            </div>

                                            <div className='address-holder ps-1 pt-xxl-2 pt-xl-2 pt-md-2 pt-0'>
                                                <h6>Vipul Raut</h6>
                                            </div>
                                        </div></Link>

                                        <div className='delete-icon pt-2'>
                                            <img src={deleteimg} className='delete-img me-md-3 me-0' type="button" data-bs-toggle="modal"
                                                data-bs-target="#deleteModal" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card mb-4'>
                                <div className='card-body'>
                                    <div className='d-flex'>
                                        <Link to={'/ChatHistory-profile'}>  <div className='d-flex'>
                                            <div className='name-holder4 ms-2 me-2'>
                                                <h6 className=' name-identity'>SP</h6>
                                            </div>

                                            <div className='address-holder ps-1 pt-xxl-2 pt-xl-2 pt-md-2 pt-0'>
                                                <h6>Santosh Patil</h6>
                                            </div>
                                        </div></Link>

                                        <div className='delete-icon pt-2'>
                                            <img src={deleteimg} className='delete-img me-md-3 me-0' type="button" data-bs-toggle="modal"
                                                data-bs-target="#deleteModal" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* <!--delete Modal start--> */}
            <section class="modal-delete">
                <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div class="modal-body">
                                <div className='delete-text text-center'>
                                    <h5>Are you sure you want to permanently delete this history?</h5>
                                </div>
                                <div className='delete-button text-center my-3'>
                                    <button className='btn btn-cancle me-2'>Cancel</button>
                                    <button className='btn btn-ok' type="button" data-bs-toggle="modal"
                                        data-bs-target="#checkModal">Ok</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            {/* <!---delete-modal-end */}


            {/* <!-- Modal --> */}
            <div className='procced-modal'>
                <div class="modal fade" id="checkModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            {/* <div class="modal-header">
                                <h1 class="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div> */}
                            <div class="modal-body">
                                <CheckLottey />
                                <div className='successfully text-center'>
                                    <button className='btn btn-success1'>Deleted Successfully</button>
                                </div>
                            </div>
                            {/* <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" class="btn btn-primary">Save changes</button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChatHistory