import React from 'react'
import { Link } from 'react-router-dom'
import ConnectAstrologer from '../../../Kundali/connect-astrologer/ConnectAstrologer'

const Tarus_horoscope = () => {
  return (
    <>
      <section className="Horoscope2023 desk-top-view-horoscope">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mt-4">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/freekundali">Today's Horoscope</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link to="/freekundali">Taurus Today's Horoscope</Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="Horoscope_info p-4">
                  <div className="Horoscope_heading mb-2">
                    Taurus Today's Horoscope (Apr 20 - May 20)

                  </div>
                  <h6 className="subHeading_txt">Personal :</h6>
                  <p>
                    Single Aries signs are going to flirt with someone really extroverted. Aries signs who are married or have been together for a long time but don’t have children are going to open up the topic of having kids.
                  </p>
                  <h6 className="subHeading_txt">Travel :</h6>
                  <p>
                    The ideal place for you to travel to is going to be Germany. Whatever you decide to do there – it will definitely be fun.
                  </p>
                  <h6 className="subHeading_txt">Money :</h6>
                  <p>
                    Jupiter, the planet that governs luck, is sending some lucky energy your way. Your lucky numbers are going to be 5, 99, 32, and 97 today.
                  </p>
                  <h6 className="subHeading_txt">Career :</h6>
                  <p>
                    Financially, you are well off right now. Have you been thinking about taking a course? Or maybe even enrolling in college? Educating yourself further is always going to be a good decision.
                  </p>
                  <h6 className="subHeading_txt">Health :</h6>
                  <p>When it comes to your workout regime, it’s likely that you’re getting a bit bored. Get out of your comfort zone today. For example, try to work out outside, or with friends!</p>

                  <h6 className="subHeading_txt">Emotions :</h6>
                  <p>Keep on getting up and try over and over again. Just because something doesn’t work out in the first try means that you should give up.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <ConnectAstrologer />
          </div>
        </div>
      </section>

      {/* mobile-view-start */}
      <section className="Horoscope2023 mobile-view-horoscope">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mt-3">
                  <li className="breadcrumb-item">
                    <Link to="/">Home {'>'}</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/today_Horoscope">Today's Horoscope{'>'}</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link >Taurus Today's Horoscope</Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card mt-3">
                <div className="Horoscope_info p-2">
                  <div className="Horoscope_heading mb-2">
                    Taurus Today's Horoscope (Apr 20 - May 20)

                  </div>
                  <h6 className="subHeading_txt">Personal :</h6>
                  <p className="mt-2">
                    Single Aries signs are going to flirt with someone really extroverted. Aries signs who are married or have been together for a long time but don’t have children are going to open up the topic of having kids.
                  </p>
                  <h6 className="subHeading_txt">Travel :</h6>
                  <p className="mt-2">
                    The ideal place for you to travel to is going to be Germany. Whatever you decide to do there – it will definitely be fun.
                  </p>
                  <h6 className="subHeading_txt">Money :</h6>
                  <p className="mt-2">
                    Jupiter, the planet that governs luck, is sending some lucky energy your way. Your lucky numbers are going to be 5, 99, 32, and 97 today.
                  </p>
                  <h6 className="subHeading_txt">Career :</h6>
                  <p className="mt-2">
                    Financially, you are well off right now. Have you been thinking about taking a course? Or maybe even enrolling in college? Educating yourself further is always going to be a good decision.
                  </p>
                  <h6 className="subHeading_txt">Health :</h6>
                  <p>When it comes to your workout regime, it’s likely that you’re getting a bit bored. Get out of your comfort zone today. For example, try to work out outside, or with friends!</p>

                  <h6 className="subHeading_txt">Emotions :</h6>
                  <p className="mt-2">Keep on getting up and try over and over again. Just because something doesn’t work out in the first try means that you should give up.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <ConnectAstrologer />
          </div>
        </div>
      </section>
    </>
  )
}

export default Tarus_horoscope