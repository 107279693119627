import { useState } from "react";
import "./Free_Report.css";
// import '../../free-kundali/Free_Kundli_tabs/Free_Kundli.css'
import { Link, useSearchParams } from "react-router-dom";
import name_img from "../../home-page/image/Ellipse 242.png";
import down_arrow from "./Images/down_arrow.png";
import uper_arrow from "./Images/uper_arrow.png";
import nashkimg from './Images/image-1.png'
import nashkimg1 from './Images/image-2.png'

import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import 'react-tabs/style/react-tabs.css';
import ConnectAstrologer from "../../Kundali/connect-astrologer/ConnectAstrologer";
const Free_Report = () => {
  const [arrow, setArrow] = useState(false)
  const [arrow1, setArrow1] = useState(false)
  const changeClick = () => {
    setArrow(!arrow)
  }
  const changeClick1 = () => {
    setArrow1(!arrow1)
  }
  return (
    <>
      <section className="Free_Kundli desktop-view-free-kundali">
        <div className="container">
          <div className="row ">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"> <Link to="/">Home</Link> </li>
                <li className="breadcrumb-item"><Link to="/freekundali">Free Kundali </Link> </li>
                <li className="breadcrumb-item active"> <Link to="">Free Report</Link> </li>
              </ol>
            </nav>
            <div className="col-lg-12 mt-4">
              <ul className="nav nav-pills mb-3 " id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <Link to="/Basic-Kundali">
                    <button className="nav-link " id="pills-basic-tab" data-bs-toggle="pill" data-bs-target="#pills-basic" type="button" role="tab" aria-controls="pills-basic"
                      aria-selected="true" > Basic </button>
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link to="/second-Kundali">
                    <button className="nav-link" id="pills-kundli-tab" data-bs-toggle="pill" data-bs-target="#pills-kundli" type="button" role="tab"
                      aria-controls="pills-kundli" aria-selected="false">Kundli</button>
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link to="/kp-Kundali">
                    <button className="nav-link " id="pills-kp-tab" data-bs-toggle="pill" data-bs-target="#pills-kp" type="button" role="tab"
                      aria-controls="pills-kp" aria-selected="false">KP</button>
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link to="/free-ashtkvarga">
                    <button className="nav-link" id="pills-ashtakvarga-tab" data-bs-toggle="pill" data-bs-target="#pills-ashtakvarga" type="button" role="tab"
                      aria-controls="pills-ashtakvarga" aria-selected="false">Ashtakvarga</button>
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link to="/free-chart">
                    <button className="nav-link" id="pills-charts-tab" data-bs-toggle="pill" data-bs-target="#pills-charts" type="button" role="tab"
                      aria-controls="pills-charts" aria-selected="false"> Charts </button></Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link to="/free-dasha">
                    <button className="nav-link" id="pills-dasha-tab" data-bs-toggle="pill" data-bs-target="#pills-dasha" type="button" role="tab"
                      aria-controls="pills-dasha" aria-selected="false"> Dasha </button></Link>
                </li>
                <li className="nav-item free-nav" role="presentation">
                  <Link to={"/free_Report"}>
                    <button className="nav-link active" id="pills-report-tab" data-bs-toggle="pill" data-bs-target="#pills-report" type="button"
                      role="tab" aria-controls="pills-report" aria-selected="false" >
                      Free Report
                    </button>
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="row mt-4">
            <div className="FreeReport">
              <ul className="nav FreeReport_navtab nav-pills tab-list justify-content-center " id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    General
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    Remedies
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-contact-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-contact"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="false"
                  >
                    Dasha
                  </button>
                </li>
              </ul>

              <div className="main-heading">
                <div className="line me-2"></div>
                <div className="square"></div>
                <div className="line ms-2"></div>
              </div>

              <div className="tab-content mt-4" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                  {/* General Side tabpane start */}

                  <div className="General_sidepane align-items-start">
                    <div className="row">
                      <div className="col-xl-3 col-lg-4">
                        <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical" >
                          <button className="nav-link active mb-3" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button"
                            role="tab" aria-controls="v-pills-home" aria-selected="true" >
                            General
                          </button>
                          <button className="nav-link mb-3" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button"
                            role="tab" aria-controls="v-pills-profile" aria-selected="false">
                            Planetary
                          </button>
                          <button className="nav-link mb-3" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Ketu"
                            type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false" >
                            Vimshottari Dasha
                          </button>
                          <button className="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages"
                            type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false" >
                            Yoga
                          </button>
                        </div>
                      </div>

                      <div className="col-xl-9 col-lg-8">
                        <div className="tab-content" id="v-pills-tabContent">
                          <div className="tab-pane GeneralfirstTab_Section fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab" tabindex="0" >
                            <div className="GeneralfirstTab mt-2 p-3">
                              <h6 className="mb-2">Description</h6>
                              <p className="mb-2 paragraph-holder">
                                Ascendant is one of the most sought concepts in
                                astrology when it comes to predicting the minute
                                events in your life. At <br /> the time of
                                birth, the sign that rises in the sky is the
                                person's ascendant. It helps in making
                                predictions about the minute <br /> events,
                                unlike your Moon or Sun sign that help in making
                                weekly, monthly or yearly predictions for you.{" "}
                              </p>
                              <p>Your ascendant is Capricorn</p>
                            </div>

                            <div className="GeneralfirstTab mt-4 p-3">
                              <h6 className="mb-2">Personality</h6>
                              <p className="mb-2 paragraph-holder">
                                Ascendant is one of the most sought concepts in
                                astrology when it comes to predicting the minute
                                events in your life. At <br /> the time of
                                birth, the sign that rises in the sky is the
                                person's ascendant. It helps in making
                                predictions about the minute <br /> events,
                                unlike your Moon or Sun sign that help in making
                                weekly, monthly or yearly predictions for you.{" "}
                              </p>
                              <p>Your ascendant is Capricorn</p>
                            </div>

                            <div className="GeneralfirstTab mt-4 p-3">
                              <h6 className="mb-2">Physical </h6>
                              <p className="mb-2 paragraph-holder">
                                Ascendant is one of the most sought concepts in
                                astrology when it comes to predicting the minute
                                events in your life. At <br /> the time of
                                birth, the sign that rises in the sky is the
                                person's ascendant. It helps in making
                                predictions about the minute <br /> events,
                                unlike your Moon or Sun sign that help in making
                                weekly, monthly or yearly predictions for you.{" "}
                              </p>
                              <p>Your ascendant is Capricorn</p>
                            </div>

                            <div className="GeneralfirstTab mt-4 p-3">
                              <h6 className="mb-2">Health</h6>
                              <p className="mb-2 paragraph-holder">
                                Ascendant is one of the most sought concepts in
                                astrology when it comes to predicting the minute
                                events in your life. At <br /> the time of
                                birth, the sign that rises in the sky is the
                                person's ascendant. It helps in making
                                predictions about the minute <br /> events,
                                unlike your Moon or Sun sign that help in making
                                weekly, monthly or yearly predictions for you.{" "}
                              </p>
                              <p>Your ascendant is Capricorn</p>
                            </div>

                            <div className="GeneralfirstTab mt-4 p-3">
                              <h6 className="mb-2">Career</h6>
                              <p className="mb-2 paragraph-holder">
                                Ascendant is one of the most sought concepts in
                                astrology when it comes to predicting the minute
                                events in your life. At <br /> the time of
                                birth, the sign that rises in the sky is the
                                person's ascendant. It helps in making
                                predictions about the minute <br /> events,
                                unlike your Moon or Sun sign that help in making
                                weekly, monthly or yearly predictions for you.{" "}
                              </p>
                              <p>Your ascendant is Capricorn</p>
                            </div>

                            <div className="GeneralfirstTab mt-4 p-3">
                              <h6 className="mb-2">Relationship</h6>
                              <p className="mb-2 paragraph-holder">
                                Ascendant is one of the most sought concepts in
                                astrology when it comes to predicting the minute
                                events in your life. At <br /> the time of
                                birth, the sign that rises in the sky is the
                                person's ascendant. It helps in making
                                predictions about the minute <br /> events,
                                unlike your Moon or Sun sign that help in making
                                weekly, monthly or yearly predictions for you.{" "}
                              </p>
                              <p>Your ascendant is Capricorn</p>
                            </div>
                          </div>

                          <div className="tab-pane fade GeneralfirstTab_Section" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab" tabindex="0">
                            <div className="GeneralfirstTab mt-2 p-4">
                              <h6 className="mb-2">Sun Consideration</h6>
                              <p className="mb-2 paragraph-holder">
                                Sun is in the fourth house of your natal chart
                                with the Aries <br /> sign. The presence of the
                                Sun in conjunction with Aries suggests that the
                                native finds wealth. You will possess secret
                                wealth which can give you the financial freedom
                                you had
                                <br /> always desired. There can be some
                                negativity in family matters. You might suffer
                                from losses in your mother’s house and face a
                                lot of distress when it comes to your father's
                                house.
                              </p>

                              <p className='paragraph-holder'>
                                When it comes to your professional life, you
                                might experience weakness in government and
                                society. With the presence of the Sun with
                                Aries, you will, however, spend your daily life
                                in pleasure. Further, you might be somewhat
                                lazy, which is why you are inactive in your work
                                most of the time. Your inactivity can make you
                                procrastinate and you might end up doing your
                                job in haste.
                              </p>
                              <p>Your ascendant is Capricorn</p>
                            </div>

                            <div className="GeneralfirstTab mt-4 p-4">
                              <h6 className="mb-2">Moon Consideration</h6>
                              <p className="mb-2 paragraph-holder">
                                Sun is in the fourth house of your natal chart
                                with the Aries <br /> sign. The presence of the
                                Sun in conjunction with Aries suggests that the
                                native finds wealth. You will possess secret
                                wealth which can give you the financial freedom
                                you had
                                <br /> always desired. There can be some
                                negativity in family matters. You might suffer
                                from losses in your mother’s house and face a
                                lot of distress when it comes to your father's
                                house.
                              </p>

                              <p className='paragraph-holder'>
                                When it comes to your professional life, you
                                might experience weakness in government and
                                society. With the presence of the Sun with
                                Aries, you will, however, spend your daily life
                                in pleasure. Further, you might be somewhat
                                lazy, which is why you are inactive in your work
                                most of the time. Your inactivity can make you
                                procrastinate and you might end up doing your
                                job in haste.
                              </p>
                              <p>Your ascendant is Capricorn</p>
                            </div>

                            <div className="GeneralfirstTab mt-4 p-4">
                              <h6 className="mb-2">Mercury Consideration</h6>
                              <p className="mb-2 paragraph-holder">
                                Sun is in the fourth house of your natal chart
                                with the Aries <br /> sign. The presence of the
                                Sun in conjunction with Aries suggests that the
                                native finds wealth. You will possess secret
                                wealth which can give you the financial freedom
                                you had
                                <br /> always desired. There can be some
                                negativity in family matters. You might suffer
                                from losses in your mother’s house and face a
                                lot of distress when it comes to your father's
                                house.
                              </p>

                              <p className='paragraph-holder'>
                                When it comes to your professional life, you
                                might experience weakness in government and
                                society. With the presence of the Sun with
                                Aries, you will, however, spend your daily life
                                in pleasure. Further, you might be somewhat
                                lazy, which is why you are inactive in your work
                                most of the time. Your inactivity can make you
                                procrastinate and you might end up doing your
                                job in haste.
                              </p>
                              <p>Your ascendant is Capricorn</p>
                            </div>

                            <div className="GeneralfirstTab mt-4 p-4">
                              <h6 className="mb-2">Venus Consideration</h6>
                              <p className="mb-2 paragraph-holder">
                                Sun is in the fourth house of your natal chart
                                with the Aries <br /> sign. The presence of the
                                Sun in conjunction with Aries suggests that the
                                native finds wealth. You will possess secret
                                wealth which can give you the financial freedom
                                you had
                                <br /> always desired. There can be some
                                negativity in family matters. You might suffer
                                from losses in your mother’s house and face a
                                lot of distress when it comes to your father's
                                house.
                              </p>

                              <p className='paragraph-holder'>
                                When it comes to your professional life, you
                                might experience weakness in government and
                                society. With the presence of the Sun with
                                Aries, you will, however, spend your daily life
                                in pleasure. Further, you might be somewhat
                                lazy, which is why you are inactive in your work
                                most of the time. Your inactivity can make you
                                procrastinate and you might end up doing your
                                job in haste.
                              </p>
                              <p>Your ascendant is Capricorn</p>
                            </div>
                          </div>

                          <div className="tab-pane fade GeneralfirstTab_Section" id="v-pills-Ketu" role="tabpanel" aria-labelledby="v-pills-profile-tab" tabindex="0" >
                            <div className="GeneralfirstTab mb-4 mt-2 p-4">
                              <h6 className="date_keto">
                                (14-11-1996 - 15-11-2003)
                              </h6>
                              <h6 className="mb-2">Ketu Mahadasha</h6>
                              <p className="mb-2 paragraph-holder">
                                If Ketu is camping in the third house during the
                                birth of the native, then he or she will be
                                blessed with extremely fortuitous circumstances.
                                They will successfully conquer their enemies and
                                succeed in undertakings. Their income will also
                                receive a considerable boost. However, there are
                                a few downsides to Ketu’s presence in the first
                                house. These could present themselves in the
                                native’s life in the form of disputes and
                                disagreements with the siblings. Owing to his
                                troubles, he or she might rely on occult
                                sciences and/or philosophy.
                              </p>

                              <p className='paragraph-holder'>
                                The planet Ketu is in the Pisces sign of the
                                Kundli. This placement bodes well to the native
                                to some degree. The placement of Ketu in Pisces
                                indicates that you will be bestowed with honour
                                and happiness during this Dasha period. You will
                                influence properties such as land and vehicles.
                                You will also have a grip over various
                                attendants. However, the- se are liable to be
                                taken away towards the end of the Dasha period
                                if you are not careful or acquire greed. You
                                will have a child, probably a boy, during this
                                period if you are married. The Dasha period,
                                however, wants you to be thankful for all that
                                you garner. Doing charity during this period
                                will be further helpful for you.
                              </p>
                            </div>

                            <div className="GeneralfirstTab mb-4 mt-2 p-4">
                              <h6 className="date_keto">
                                (14-11-1996 - 15-11-2003)
                              </h6>
                              <h6 className="mb-2">Venus Mahadasha</h6>
                              <p className="mb-2 paragraph-holder">
                                If Ketu is camping in the third house during the
                                birth of the native, then he or she will be
                                blessed with extremely fortuitous circumstances.
                                They will successfully conquer their enemies and
                                succeed in undertakings. Their income will also
                                receive a considerable boost. However, there are
                                a few downsides to Ketu’s presence in the first
                                house. These could present themselves in the
                                native’s life in the form of disputes and
                                disagreements with the siblings. Owing to his
                                troubles, he or she might rely on occult
                                sciences and/or philosophy.
                              </p>

                              <p className='paragraph-holder'>
                                The planet Ketu is in the Pisces sign of the
                                Kundli. This placement bodes well to the native
                                to some degree. The placement of Ketu in Pisces
                                indicates that you will be bestowed with honour
                                and happiness during this Dasha period. You will
                                influence properties such as land and vehicles.
                                You will also have a grip over various
                                attendants. However, the- se are liable to be
                                taken away towards the end of the Dasha period
                                if you are not careful or acquire greed. You
                                will have a child, probably a boy, during this
                                period if you are married. The Dasha period,
                                however, wants you to be thankful for all that
                                you garner. Doing charity during this period
                                will be further helpful for you.
                              </p>
                            </div>

                            <div className="GeneralfirstTab mb-4 mt-2 p-4">
                              <h6 className="date_keto">
                                (14-11-1996 - 15-11-2003)
                              </h6>
                              <h6 className="mb-2">Sun Mahadasha</h6>
                              <p className="mb-2 paragraph-holder">
                                If Ketu is camping in the third house during the
                                birth of the native, then he or she will be
                                blessed with extremely fortuitous circumstances.
                                They will successfully conquer their enemies and
                                succeed in undertakings. Their income will also
                                receive a considerable boost. However, there are
                                a few downsides to Ketu’s presence in the first
                                house. These could present themselves in the
                                native’s life in the form of disputes and
                                disagreements with the siblings. Owing to his
                                troubles, he or she might rely on occult
                                sciences and/or philosophy.
                              </p>

                              <p className='paragraph-holder'>
                                The planet Ketu is in the Pisces sign of the
                                Kundli. This placement bodes well to the native
                                to some degree. The placement of Ketu in Pisces
                                indicates that you will be bestowed with honour
                                and happiness during this Dasha period. You will
                                influence properties such as land and vehicles.
                                You will also have a grip over various
                                attendants. However, the- se are liable to be
                                taken away towards the end of the Dasha period
                                if you are not careful or acquire greed. You
                                will have a child, probably a boy, during this
                                period if you are married. The Dasha period,
                                however, wants you to be thankful for all that
                                you garner. Doing charity during this period
                                will be further helpful for you.
                              </p>
                            </div>

                            <div className="GeneralfirstTab mb-4 mt-2 p-4">
                              <h6 className="date_keto">
                                (14-11-1996 - 15-11-2003)
                              </h6>
                              <h6 className="mb-2">Moon Mahadasha</h6>
                              <p className="mb-2 paragraph-holder">
                                If Ketu is camping in the third house during the
                                birth of the native, then he or she will be
                                blessed with extremely fortuitous circumstances.
                                They will successfully conquer their enemies and
                                succeed in undertakings. Their income will also
                                receive a considerable boost. However, there are
                                a few downsides to Ketu’s presence in the first
                                house. These could present themselves in the
                                native’s life in the form of disputes and
                                disagreements with the siblings. Owing to his
                                troubles, he or she might rely on occult
                                sciences and/or philosophy.
                              </p>

                              <p className='paragraph-holder'>
                                The planet Ketu is in the Pisces sign of the
                                Kundli. This placement bodes well to the native
                                to some degree. The placement of Ketu in Pisces
                                indicates that you will be bestowed with honour
                                and happiness during this Dasha period. You will
                                influence properties such as land and vehicles.
                                You will also have a grip over various
                                attendants. However, the- se are liable to be
                                taken away towards the end of the Dasha period
                                if you are not careful or acquire greed. You
                                will have a child, probably a boy, during this
                                period if you are married. The Dasha period,
                                however, wants you to be thankful for all that
                                you garner. Doing charity during this period
                                will be further helpful for you.
                              </p>
                            </div>
                          </div>

                          <div className="tab-pane fade GeneralfirstTab_Section" id="v-pills-messages"
                            role="tabpanel" aria-labelledby="v-pills-messages-tab" tabindex="0">
                            <div className="GeneralfirstTab mb-4 mt-2 p-4">
                              <h6 className="mb-3">Sunapha Yoga</h6>
                              <h6 className="mb-3">
                                Any planets, except Sun, in the second house
                                from the Moon.
                              </h6>

                              <p className='paragraph-holder'>
                                Sun apha Yoga indicates that you will be the
                                proud owner of several properties which will be
                                earned by you through your perseverance and good
                                decisions. You will be extremely rich and have
                                the luxury of living the life of a king. When it
                                comes to your personality, you are intelligent
                                and rarely make bad decisions. Your reputation
                                will be on par with that of a reputed ruler.
                              </p>
                            </div>

                            <div className="GeneralfirstTab mb-4 mt-2 p-4">
                              <h6 className="mb-3">Anapha Yoga</h6>
                              <h6 className="mb-3">
                                Any planets, except Sun, in the second house
                                from the Moon.{" "}
                              </h6>

                              <p className='paragraph-holder'>
                                Sun apha Yoga indicates that you will be the
                                proud owner of several properties which will be
                                earned by you through your perseverance and good
                                decisions. You will be extremely rich and have
                                the luxury of living the life of a king. When it
                                comes to your personality, you are intelligent
                                and rarely make bad decisions. Your reputation
                                will be on par with that of a reputed ruler.
                              </p>
                            </div>

                            <div className="GeneralfirstTab mb-4 mt-2 p-4">
                              <h6 className="mb-3">
                                Planets on either side of Moon
                              </h6>
                              <h6 className="mb-3">
                                Any planets, except Sun, in the second house
                                from the Moon.{" "}
                              </h6>

                              <p className='paragraph-holder'>
                                Sun apha Yoga indicates that you will be the
                                proud owner of several properties which will be
                                earned by you through your perseverance and good
                                decisions. You will be extremely rich and have
                                the luxury of living the life of a king. When it
                                comes to your personality, you are intelligent
                                and rarely make bad decisions. Your reputation
                                will be on par with that of a reputed ruler.
                              </p>
                            </div>

                            <div className="GeneralfirstTab mb-4 mt-2 p-4">
                              <h6 className="mb-3">Vasumathi Yoga</h6>
                              <h6 className="mb-3">
                                Any planets, except Sun, in the second house
                                from the Moon.
                              </h6>

                              <p className='paragraph-holder'>
                                Sun apha Yoga indicates that you will be the
                                proud owner of several properties which will be
                                earned by you through your perseverance and good
                                decisions. You will be extremely rich and have
                                the luxury of living the life of a king. When it
                                comes to your personality, you are intelligent
                                and rarely make bad decisions. Your reputation
                                will be on par with that of a reputed ruler.
                              </p>
                            </div>

                            <div className="GeneralfirstTab mb-4 mt-2 p-4">
                              <h6 className="mb-3">Budha Aditya Yoga</h6>
                              <h6 className="mb-3">
                                Any planets, except Sun, in the second house
                                from the Moon.{" "}
                              </h6>

                              <p className='paragraph-holder'>
                                Sun apha Yoga indicates that you will be the
                                proud owner of several properties which will be
                                earned by you through your perseverance and good
                                decisions. You will be extremely rich and have
                                the luxury of living the life of a king. When it
                                comes to your personality, you are intelligent
                                and rarely make bad decisions. Your reputation
                                will be on par with that of a reputed ruler.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* General Side tabpane end */}

                {/* Remedies navtab starts */}
                <div className="Remedies_navtab_section tab-pane fade" id="pills-profile"
                  role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0" >

                  <ul className="Remedies_navtab nav nav-pills pb-0" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className="Rudraksha_txt nav-link active" id="pills-Rudraksha-tab" data-bs-toggle="pill"
                        data-bs-target="#pills-Rudraksha" type="button" role="tab" aria-controls="pills-Rudraksha"
                        aria-selected="true" onClick={() => changeClick()}>
                        Rudraksha &nbsp;
                        {
                          arrow ? <img src={uper_arrow} className="upper_arrow" /> : <img src={down_arrow} className="upper_arrow" />
                        }

                      </button>
                    </li>

                    <li className="nav-item" role="presentation">
                      <button className="Gemstones_txt nav-link" id="pills-Gemstones-tab"
                        data-bs-toggle="pill" data-bs-target="#pills-Gemstones"
                        type="button" role="tab" aria-controls="pills-Gemstones" aria-selected="false" onClick={() => changeClick1()}>
                        Gemstones &nbsp;
                        {
                          arrow1 ? <img src={uper_arrow} className="upper_arrow" /> : <img src={down_arrow} className="upper_arrow" />
                        }
                      </button>
                    </li>

                  </ul>

                  <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-Rudraksha"
                      role="tabpanel" aria-labelledby="pills-Rudraksha-tab" tabindex="0">

                      <div className="GeneralfirstTab mt-2">
                        <h6 className="mb-3">Rudraksha Suggestion Report</h6>
                        <p className="mb-4">
                          This Rudraksha suggestion report aims to help you choose
                          the most suitable Rudraksha that you can wear to have the
                          blessings of Lord Shiva. This report considers the
                          Nakshatra that you were born in to suggest the most
                          appropriate Rudraksha for you. Wearing the recommended
                          Rudraksha shall shield you against the negative energies
                          and help retain positivity in your life.{" "}
                        </p>
                        <h6 className="mb-3">Rudraksha & its importance</h6>
                        <p className="mb-2">
                          Rudraksha beads are produced by the 'Rudraksha Tree'
                          scientifically called Elaeocarpus. The tree grows at a
                          certain altitude in the mountains, especially the
                          Himalayas - the home to Lord Shiva. The seeds are known to
                          have very unique vibrations that aid the spiritual and
                          physical growth of the wearer. In Hindu scriptures, it is
                          mentioned that the one who wears Rudraksha is blessed with
                          the virtues of Dharma, Artha, Kama, and Moksha. Wearing
                          Rudraksha, in fact, allows the wearer to connect with Lord
                          Shiva and his divine energies.
                        </p>

                        <p className="mb-2">
                          It is a belief that the wearer of Rudraksha not only
                          pleases Lord Shiva but also Goddess Durga, Lord Indra,
                          Lord Brahma, Lord Vishnu, Lord Ganesh, Lord Kartikeya,
                          Lord Aditya, and many other deities. If you want to
                          condemn your sins or faults and purify your life, a
                          Rudraksha is a good tool to begin with. It is said that a
                          personâ€™s sins burn down to ashes by merely looking at a
                          Rudraksha. On the other hand, wearing a Rudraksha helps in
                          the fulfilment of the desires of the wearer.
                        </p>

                        <p className="mb-2 pb-4">
                          The number of faces a Rudraksha has varies from 1-Mukhi to
                          21-Mukhi, all of which are used for different purposes.
                          Henceforth, it is not advisable to buy any Rudraksha of
                          your choice, wear it, and expect it to work. In fact,
                          wearing the wrong Rudraksha can disturb a nativeâ€™s life
                          by exposing him to wrong energies or simply overpowering a
                          certain planet or leaving it strengthless. Therefore, it
                          is very important that you get a consultation from an
                          astrologer to astrologically decide the most suitable
                          Rudraksha for yourself.
                        </p>
                      </div>

                      <div className="GeneralfirstTab mt-5">
                        <h6 className="mb-3">Recommendation</h6>
                        <p className="pb-3">
                          Having been born in the Mula nakshatra, the most suitable
                          Rudraksha for you is the 8-Mukhi Rudraksha. As per
                          astrology, the 8 Mukhi is ruled by the planet Ketu and
                          influenced by Lord Ganesha. Before opting for this
                          Rudraksha, it is recommended that you consult an
                          astrologer as there might be planetary combinations in
                          your chart in the current scenario based on which the
                          Rudraksha recommendation might change for you.
                        </p>
                      </div>

                      <button className="btn mukhi_btn mt-5 ms-2">8-Mukhi</button>
                      <div className="GeneralfirstTab mt-5">
                        <h6 className="mb-3">Details</h6>
                        <p className="mb-3">
                          8-Mukhi Rudraksha is the symbol of Lord Ganesha who is the
                          God of knowledge, wisdom, and wealth. Lord Ganesha
                          facilitates new beginnings and is the remover of obstacles
                          (Vighna Harta). Hence, if the wearer wears the eight-Mukhi
                          Rudraksha after conducting proper rituals, it will help
                          him remove obstacles across various aspects of life such
                          as marriage, love, career and more.
                        </p>

                        <p className="pb-3">
                          The Aath-Mukhi Rudraksha enhances the willpower of the
                          wearer. Hence, whenever starting something new, you must
                          at least see an eight-Mukhi Rudraksha. The eight-Mukhi
                          Rudraksha is used by many business professionals as it
                          aids business growth and lessens hurdles in professional
                          life. When it comes to physical benefits, the daily Dharan
                          of eight-Mukhi Rudraksha aids blood circulation and
                          controls negative thoughts.
                        </p>
                      </div>
                    </div>

                    <div className="tab-pane fade" id="pills-Gemstones"
                      role="tabpanel" aria-labelledby="pills-Gemstones-tab"
                      tabindex="0" >

                      <div className="GeneralfirstTab ">
                        <h6 className="Life_Stone_txt ">Life Stone</h6>
                        <div className="border-dot mb-2"></div>
                        <h6 className="mb-2">Life stone for Capricorn (Makara Lagna)</h6>
                        <p className="mb-2">
                          A life stone is a gem for the Lagna lord, which the native can wear throughout his or her life. A life stone collectively influences everything that makes your self-image, i.e. your wealth, education, health, business, spouse, intellect, etc. The lord of the Capricorn ascendant/Lagna is Saturn, and to please Saturn, the person born with Capricorn Ascendant (Makara Lagna) must wear Blue Sapphire.
                        </p>
                        <div className="Gemstones_firstDiv row">
                          <div className="Gemstones row">
                            <div className="col-lg-6">
                              <p>Life stone for Capricorn (Makara Lagna)</p>
                            </div>
                            <div className="col-lg-6">
                              <p>Blue Sapphire (Neelam)</p>
                            </div>
                          </div>
                          <div className="Gemstones row">
                            <div className="col-lg-6">
                              <p>How to wear</p>
                            </div>
                            <div className="col-lg-6">
                              <p>Gold, on middle finger</p>
                            </div>
                          </div>
                          <div className="Gemstones row">
                            <div className="col-lg-6">
                              <p>Mantra</p>
                            </div>
                            <div className="col-lg-6">
                              <p>Om pram prim praum sah shanaisharaya namah</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="GeneralfirstTab mt-5">
                        <h6 className="Life_Stone_txt ">Lucky Stone</h6>
                        <div className="border-dot mb-2"></div>
                        <h6 className="mb-2">Lucky Gemstone for Capricorn (Makara)</h6>
                        <p className="mb-2">
                          A lucky gemstone is worn to enhance the native's luck and open new doors to success for him. An individual's lucky stone is one that keeps luck ticking for him while ensuring the blessing of favourable planets upon him. As Venus and Mercury are beneficial planets for Capricorn, hence the Lucky gemstone for the Capricorn Ascendant is: Diamond (Heera)
                        </p>
                        <div className="Gemstones_firstDiv row">
                          <div className="Gemstones row">
                            <div className="col-lg-6">
                              <p>Life stone for Capricorn (Makara Lagna)</p>
                            </div>
                            <div className="col-lg-6">
                              <p>Diamond (Heera)</p>
                            </div>
                          </div>
                          <div className="Gemstones row">
                            <div className="col-lg-6">
                              <p>How to wear</p>
                            </div>
                            <div className="col-lg-6">
                              <p>Gold or silver on middle finger</p>
                            </div>
                          </div>
                          <div className="Gemstones row">
                            <div className="col-lg-6">
                              <p>Mantra</p>
                            </div>
                            <div className="col-lg-6">
                              <p>Om dram drim draum sah shukraya namah</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="GeneralfirstTab mt-5">
                        <h6 className="Life_Stone_txt ">Fortune Stone</h6>
                        <div className="border-dot mb-2"></div>
                        <h6 className="mb-2">Fortune Stone for Capricorn (Makara)</h6>
                        <p className="mb-2">
                          The Bhagya stone is suggested by the astrologers based on the Lord governing the 9th house of the native's birth chart. The Bhagya stone helps the native attract fortune when s/he needs it the most. Wearing Bhagya stone as per ascendant sign helps in fighting obstacles and enhances prosperity both in personal and professional life
                        </p>
                        <div className="Gemstones_firstDiv row">
                          <div className="Gemstones row">
                            <div className="col-lg-6">
                              <p>Life stone for Capricorn (Makara Lagna)</p>
                            </div>
                            <div className="col-lg-6">
                              <p>Emerald (Panna)</p>
                            </div>
                          </div>
                          <div className="Gemstones row">
                            <div className="col-lg-6">
                              <p>How to wear</p>
                            </div>
                            <div className="col-lg-6">
                              <p>Gold, on ring or little finger</p>
                            </div>
                          </div>
                          <div className="Gemstones row">
                            <div className="col-lg-6">
                              <p>Mantra</p>
                            </div>
                            <div className="col-lg-6">
                              <p>Om bram brim braum sah budhaya namah</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                {/* Remedies navtab ends */}

                {/* Dasha Side tabpane starts */}
                <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab" tabindex="0" >
                  <div className="General_sidepane ">
                    <div className="row">
                      <ul className="nav FreeReport_navtab nav-pills tab-list justify-content-center " id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                          <button className="nav-link active mb-3" id="v-pills-Manglik-tab" data-bs-toggle="pill"
                            data-bs-target="#v-pills-Manglik" type="button" role="tab" aria-controls="v-pills-Manglik" aria-selected="true">
                            Manglik
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button className="nav-link mb-3" id="v-pills-Kalsarpa-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Kalsarpa"
                            type="button" role="tab" aria-controls="v-pills-Kalsarpa" aria-selected="false">
                            Kalsarpa
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">

                          <button className="nav-link mb-3" id="v-pills-Sadesati-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Sadesati"
                            type="button" role="tab" aria-controls="v-pills-Sadesati" aria-selected="false" >
                            Sadesati
                          </button>
                        </li>
                      </ul>
                      {/* <div className="col-md-12">
                        <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                      

                        

                        </div>
                      </div> */}

                      <div className="col-md-12">
                        <div className="tab-content" id="v-pills-tabContent">
                          <div className="tab-pane GeneralfirstTab_Section fade show active" id="v-pills-Manglik" role="tabpanel"
                            aria-labelledby="v-pills-Manglik-tab" tabindex="0" >

                            <div className="GeneralfirstTab mt-2">
                              <h6 className="">Manglik Analysis</h6>
                              <div className="underline mb-4"></div>
                              <div className="row">
                                <div className="d-flex">
                                  {/* <img src={name_img} className="name_img" /> */}
                                  <div className='name-holder1 me-3'>
                                    <h6 className='pt-3 name-identity'>SK</h6>
                                  </div>

                                  <div className="">
                                    <h6 className="mb-3">Shubham Kumar</h6>
                                    <p className="my-2 paragraph-holder">
                                      Since mars is in eigth house and in leo sign
                                      person is Non Manglik.
                                    </p>
                                    <p className="paragraph-holder">
                                      However the person's Mangal dosha has been
                                      cancelled due to following reasons:
                                    </p>
                                    <p className="mb-2"> ■ Mars
                                      is in eigth house and Leo sign.</p>
                                    <p className="pb-4 paragraph-holder">
                                      [This is a computer generated result. Please
                                      consult an Astrologer to confirm &
                                      understand this in detail.]
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="tab-pane GeneralfirstTab_Section " id="v-pills-Kalsarpa" role="tabpanel"
                            aria-labelledby="v-pills-Kalsarpa-tab" tabindex="0" >
                            <div className="GeneralfirstTab mt-2 p-4">
                              <h6 className="">Kalsarpa Analysis</h6>
                              <div className="underline mb-3"></div>
                              <div className="row">
                                <div className="d-flex mb-4">
                                  {/* <img src={name_img} className="name_img" /> */}
                                  <div className='name-holder1 me-3'>
                                    <h6 className='pt-3 name-identity'>SK</h6>
                                  </div>

                                  <div className="">
                                    <p className="pt-2 paragraph-holder">
                                      Kundli Is Free From Kalsharpa Dosha.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="tab-pane fade " id="v-pills-Sadesati" role="tabpanel"
                            aria-labelledby="v-pills-Sadesati-tab" tabindex="0">
                            <div className="GeneralfirstTab mt-2 p-4">
                              <h6 className="">Sadesati Analysis</h6>
                              <div className="underline mb-4"></div>
                              <div className="row">
                                <div className="d-flex mb-4">
                                  {/* <img src={name_img} className="name_img" /> */}
                                  <div className='name-holder1 me-3 '>
                                    <h6 className='pt-3 name-identity'>SK</h6>
                                  </div>

                                  <div className="">
                                    <p className="">Current Sadesati Status</p>
                                    <p>
                                      Your next sadesati starts from 11-Dec-2043
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="table-responsive mt-5">
                                <table className="table table-bordered  text-center">
                                  <thead>
                                    <tr>
                                      <th>Start</th>
                                      <th>End</th>
                                      <th>Sign Name</th>
                                      <th>Type</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    <tr className="table-warning1">
                                      <td>2-11-2014</td>
                                      <td>26-1-2017</td>
                                      <td>Scorpio</td>
                                      <td>Rising</td>
                                    </tr>
                                    <tr>
                                      <td>2-11-2014</td>
                                      <td>26-1-2017</td>
                                      <td>Scorpio</td>
                                      <td>Rising</td>
                                    </tr>
                                    <tr className="table-warning1">
                                      <td>2-11-2014</td>
                                      <td>26-1-2017</td>
                                      <td>Scorpio</td>
                                      <td>Rising</td>
                                    </tr>
                                    <tr>
                                      <td>2-11-2014</td>
                                      <td>26-1-2017</td>
                                      <td>Scorpio</td>
                                      <td>Rising</td>
                                    </tr>
                                    <tr className="table-warning1">
                                      <td>2-11-2014</td>
                                      <td>26-1-2017</td>
                                      <td>Scorpio</td>
                                      <td>Rising</td>
                                    </tr>
                                    <tr>
                                      <td>2-11-2014</td>
                                      <td>26-1-2017</td>
                                      <td>Scorpio</td>
                                      <td>Rising</td>
                                    </tr>
                                    <tr className="table-warning1">
                                      <td>2-11-2014</td>
                                      <td>26-1-2017</td>
                                      <td>Scorpio</td>
                                      <td>Rising</td>
                                    </tr>
                                    <tr>
                                      <td>2-11-2014</td>
                                      <td>26-1-2017</td>
                                      <td>Scorpio</td>
                                      <td>Rising</td>
                                    </tr>
                                    <tr className="table-warning1">
                                      <td>2-11-2014</td>
                                      <td>26-1-2017</td>
                                      <td>Scorpio</td>
                                      <td>Rising</td>
                                    </tr>
                                    <tr>
                                      <td>2-11-2014</td>
                                      <td>26-1-2017</td>
                                      <td>Scorpio</td>
                                      <td>Rising</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>

                              <div className="GeneralfirstTab1 mb-5 p-4">

                                <h6 className="mb-2">Shani Sade Sati: Rising Phase</h6>
                                <p className="mb-2 paragraph-holder">
                                  With Saturn transiting in the 12th house of your Kundli
                                  from the Moon, the movement marks the beginning of the
                                  Shani Sade Sati phase for you. During this period, you
                                  need to be cautious of instances that can lead to
                                  financial losses, accidents, relationship woes, bad health
                                  and even deprivation. During the Shani Sade Sati phase,
                                  you need to rein your actions and words, especially at
                                  your workplace, or else you will have to face challenges
                                  in adjusting to the professional scenario. On the domestic
                                  front, family and finance-related tensions may prevail. If
                                  trying to get married, Saturn's nature of dealy may
                                  overpower that desire of yours. In such instances, being
                                  patient will come to your emotional aid. Consider the
                                  Shani Sade Sati phase for working hard towards your
                                  dreams, as Shani rewards you based on your karma and hard
                                  work. You are advised not to take high risks in business
                                  matters in this period.
                                </p>
                              </div>

                              <div className="GeneralfirstTab1 mb-5 p-4">
                                <h6 className="mb-2">Shani Sade Sati: Peak Phase</h6>
                                <p className="mb-2 paragraph-holder">
                                  As you tread into the peak phase of Shani Sade Sati,
                                  things might get a bit tough for you hereafter. Yet,
                                  acknowledging the tough as a challenge is advised. Saturn
                                  transiting over the natal Moon from here may complicate
                                  certain prevalent health woes. Your immune system may show
                                  unprecedented weakness. Hence, do follow a proper diet and
                                  an active lifestyle. A teen or working adult may suffer
                                  from depression, unknown fear or phobia, especially of
                                  losing their source of income during this phase. You also
                                  need to be cautious about keeping your societal image
                                  intact during this phase. On the bright side, the peak
                                  Shani Sade Sati period will incline you towards your
                                  spiritual side. You will feel more peaceful if you
                                  surrender to God. Take spiritual tours during this period
                                  with your spouse.
                                </p>
                              </div>

                              <div className="GeneralfirstTab1  p-4">
                                <h6 className="mb-2">Shani Sade Sati: Setting Phase</h6>
                                <p className="mb-2 paragraph-holder">
                                  The setting period of Shani's Sade Sati has begun for you
                                  with Saturn moving into the 2nd house from the natal Moon.
                                  The period marks financial and domestic woes but less
                                  severe in form. However, professional decisions should be
                                  made after careful consultation with expert stakeholders.
                                  Also, you need to keep your hands tight when it comes to
                                  spending money, as expenses might soar for you in this
                                  phase of Shani Sade Sati. Sudden financial losses and fear
                                  of theft are also a possibility. A lot of attention needs
                                  to be diverted towards family and your own self, as if you
                                  don't do so, small problems will turn into bigger
                                  problems. There will be some delay in getting the results
                                  you desire, but good things will surely happen for you. If
                                  possible, give up any bad habits such as smoking and
                                  drinking to keep Saturn happy.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                  {/* Dasha Side tabpane ends */}


                </div>
              </div>
            </div>
          </div>


          <div className="row mt-3">
            <ConnectAstrologer />
          </div>
          <div className="image-naskhtra">
            <img src={nashkimg} className="nakshtra-img" />
          </div>
          <div className="image-naskhtra2">
            <img src={nashkimg1} className="nakshtra-img" />
          </div>
        </div>
      </section>

      {/* mobile-view-start */}
      <section className='Free_Kundli mobile-view-free-kundali'>
        <div className='container-fluid'>
          <div className="row ">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb my-3">
                <li className="breadcrumb-item"> <Link to="/">Home {'>'} </Link> </li>
                <li className="breadcrumb-item"><Link to="/freekundali">Free Kundali {'>'} </Link></li>
                <li className="breadcrumb-item active"><Link to="">Free Report</Link></li>
              </ol>
            </nav>
            <div className="col-lg-12 p-0">
              <ul className="nav nav-pills  " id="pills-tab" role="tablist">
                <Swiper
                  modules={[Navigation, Autoplay]}
                  pagination={false}
                  navigation={false}
                  // loop={true}
                  breakpoints={{
                    0: {
                      slidesPerView: 3,
                      // spaceBetween: 10,
                    },
                    360: {
                      slidesPerView: 3,
                      // spaceBetween: 10,
                    },
                    567: {
                      slidesPerView: 4,
                      // spaceBetween: 10,
                    },
                    640: {
                      slidesPerView: 4,
                      // spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 4,
                      // spaceBetween: 10,
                    },

                  }}

                  className="suportres-slider" >
                  <SwiperSlide className="swiper-slide1">
                    <li className="nav-item " role="presentation">
                      <Link to='/Basic-Kundali'> <button className="nav-link " id="pills-basic-tab" data-bs-toggle="pill" data-bs-target="#pills-basic"
                        type="button" role="tab" aria-controls="pills-basic" aria-selected="true"> Basic </button></Link>
                    </li>
                  </SwiperSlide>

                  <SwiperSlide className="swiper-slide1">
                    <li className="nav-item" role="presentation">
                      <Link to='/second-Kundali'>   <button className="nav-link " id="pills-kundli-tab" data-bs-toggle="pill" data-bs-target="#pills-kundli" type="button"
                        role="tab" aria-controls="pills-kundli" aria-selected="false">
                        Kundli
                      </button></Link>
                    </li>
                  </SwiperSlide>

                  <SwiperSlide className="swiper-slide1">
                    <li className="nav-item" role="presentation">
                      <Link to='/kp-Kundali'>   <button className="nav-link " id="pills-kp-tab" data-bs-toggle="pill" data-bs-target="#pills-kp"
                        type="button" role="tab" aria-controls="pills-kp" aria-selected="false">
                        KP
                      </button></Link>
                    </li>
                  </SwiperSlide>

                  <SwiperSlide className="swiper-slide1">
                    <li className="nav-item" role="presentation">
                      <Link to="/free-ashtkvarga">
                        <button className="nav-link" id="pills-ashtakvarga-tab" data-bs-toggle="pill" data-bs-target="#pills-ashtakvarga"
                          type="button" role="tab" aria-controls="pills-ashtakvarga" aria-selected="false">
                          Ashtakvarga
                        </button></Link>
                    </li>
                  </SwiperSlide>

                  <SwiperSlide className="swiper-slide1">
                    <li className="nav-item" role="presentation">
                      <Link to="/free-chart">
                        <button className="nav-link" id="pills-charts-tab" data-bs-toggle="pill" data-bs-target="#pills-charts"
                          type="button" role="tab" aria-controls="pills-charts" aria-selected="false">
                          Charts
                        </button></Link>
                    </li>
                  </SwiperSlide>

                  <SwiperSlide className="swiper-slide1">
                    <li className="nav-item" role="presentation">
                      <Link to="/free-dasha">
                        <button className="nav-link " id="pills-dasha-tab" data-bs-toggle="pill" data-bs-target="#pills-dasha"
                          type="button" role="tab" aria-controls="pills-dasha" aria-selected="false">
                          Dasha
                        </button></Link>
                    </li>
                  </SwiperSlide>

                  <SwiperSlide className="swiper-slide1">
                    <li className="nav-item free-nav" role="presentation">
                      <Link to={'/free_Report'}>
                        <button className="nav-link active" id="pills-report-tab" data-bs-toggle="pill" data-bs-target="#pills-report"
                          type="button" role="tab" aria-controls="pills-report" aria-selected="false">
                          Free Report
                        </button>
                      </Link>
                    </li>
                  </SwiperSlide>
                </Swiper>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className='FreeReport my-4'>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Nav variant="pills" className="nav Dasha_navtab nav-pills tab-list justify-content-center  mb-2" role="tablist">
                    <Nav.Item className="nav-item" role="presentation">
                      <Nav.Link eventKey="first" className="nav-link ">General</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item ">
                      <Nav.Link eventKey="second" className="nav-link">Remedies</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item ">
                      <Nav.Link eventKey="third" className="nav-link">Dasha</Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <div className="main-heading mt-1">
                    <div className="line me-1"></div>
                    <div className="square"></div>
                    <div className="line ms-1"></div>
                  </div>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <div className="General_sidepane">
                        <div className="row">
                          <div className="col-12">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first1">
                              <Nav variant="pills" className="nav sideBoxShadow flex-column nav-pills">
                                <Swiper
                                  modules={[Navigation, Autoplay]}
                                  pagination={false}
                                  navigation={false}
                                  loop={true}
                                  breakpoints={{
                                    0: {
                                      slidesPerView: 2,
                                      spaceBetween: 10,
                                    },
                                    420: {
                                      slidesPerView: 3,
                                      spaceBetween: 10,
                                    },
                                    567: {
                                      slidesPerView: 3,
                                      spaceBetween: 10,
                                    },
                                    640: {
                                      slidesPerView: 3,
                                      spaceBetween: 10,
                                    },
                                    768: {
                                      slidesPerView: 4,
                                      spaceBetween: 10,
                                    },

                                  }}

                                  className="" >
                                  <SwiperSlide className='day-swiper'>
                                    <Nav.Item >
                                      <Nav.Link eventKey="first1" className="nav-link text-center pt-3">
                                        General
                                      </Nav.Link>
                                    </Nav.Item>
                                  </SwiperSlide>

                                  <SwiperSlide className='day-swiper'>
                                    <Nav.Item >
                                      <Nav.Link eventKey="second1" className="nav-link text-center pt-3">
                                        Planetary
                                      </Nav.Link>
                                    </Nav.Item>
                                  </SwiperSlide>

                                  <SwiperSlide className='day-swiper'>
                                    <Nav.Item >
                                      <Nav.Link eventKey="third1" className="nav-link text-center pt-3">
                                        Vimshottari Dasha
                                      </Nav.Link>
                                    </Nav.Item>
                                  </SwiperSlide>

                                  <SwiperSlide className='day-swiper'>
                                    <Nav.Item >
                                      <Nav.Link eventKey="four1" className="nav-link text-center pt-3">
                                        Yoga
                                      </Nav.Link>
                                    </Nav.Item>
                                  </SwiperSlide>


                                </Swiper>
                              </Nav>
                              <Tab.Content>
                                <Tab.Pane eventKey="first1">
                                  <div className="GeneralfirstTab mt-2 p-3">
                                    <h6 className="mb-2">Description</h6>
                                    <p className="mb-2 paragraph-holder">
                                      Ascendant is one of the most sought concepts in
                                      astrology when it comes to predicting the minute
                                      events in your life. At <br /> the time of
                                      birth, the sign that rises in the sky is the
                                      person's ascendant. It helps in making
                                      predictions about the minute <br /> events,
                                      unlike your Moon or Sun sign that help in making
                                      weekly, monthly or yearly predictions for you.{" "}
                                    </p>
                                    <p>Your ascendant is Capricorn</p>
                                  </div>

                                  <div className="GeneralfirstTab mt-4 p-3">
                                    <h6 className="mb-2">Personality</h6>
                                    <p className="mb-2 paragraph-holder">
                                      Ascendant is one of the most sought concepts in
                                      astrology when it comes to predicting the minute
                                      events in your life. At <br /> the time of
                                      birth, the sign that rises in the sky is the
                                      person's ascendant. It helps in making
                                      predictions about the minute <br /> events,
                                      unlike your Moon or Sun sign that help in making
                                      weekly, monthly or yearly predictions for you.{" "}
                                    </p>
                                    <p>Your ascendant is Capricorn</p>
                                  </div>

                                  <div className="GeneralfirstTab mt-4 p-3">
                                    <h6 className="mb-2">Physical </h6>
                                    <p className="mb-2 paragraph-holder">
                                      Ascendant is one of the most sought concepts in
                                      astrology when it comes to predicting the minute
                                      events in your life. At <br /> the time of
                                      birth, the sign that rises in the sky is the
                                      person's ascendant. It helps in making
                                      predictions about the minute <br /> events,
                                      unlike your Moon or Sun sign that help in making
                                      weekly, monthly or yearly predictions for you.{" "}
                                    </p>
                                    <p>Your ascendant is Capricorn</p>
                                  </div>

                                  <div className="GeneralfirstTab mt-4 p-3">
                                    <h6 className="mb-2">Health</h6>
                                    <p className="mb-2 paragraph-holder">
                                      Ascendant is one of the most sought concepts in
                                      astrology when it comes to predicting the minute
                                      events in your life. At <br /> the time of
                                      birth, the sign that rises in the sky is the
                                      person's ascendant. It helps in making
                                      predictions about the minute <br /> events,
                                      unlike your Moon or Sun sign that help in making
                                      weekly, monthly or yearly predictions for you.{" "}
                                    </p>
                                    <p>Your ascendant is Capricorn</p>
                                  </div>

                                  <div className="GeneralfirstTab mt-4 p-3">
                                    <h6 className="mb-2">Career</h6>
                                    <p className="mb-2 paragraph-holder">
                                      Ascendant is one of the most sought concepts in
                                      astrology when it comes to predicting the minute
                                      events in your life. At <br /> the time of
                                      birth, the sign that rises in the sky is the
                                      person's ascendant. It helps in making
                                      predictions about the minute <br /> events,
                                      unlike your Moon or Sun sign that help in making
                                      weekly, monthly or yearly predictions for you.{" "}
                                    </p>
                                    <p>Your ascendant is Capricorn</p>
                                  </div>

                                  <div className="GeneralfirstTab mt-4 p-3">
                                    <h6 className="mb-2">Relationship</h6>
                                    <p className="mb-2 paragraph-holder">
                                      Ascendant is one of the most sought concepts in
                                      astrology when it comes to predicting the minute
                                      events in your life. At <br /> the time of
                                      birth, the sign that rises in the sky is the
                                      person's ascendant. It helps in making
                                      predictions about the minute <br /> events,
                                      unlike your Moon or Sun sign that help in making
                                      weekly, monthly or yearly predictions for you.{" "}
                                    </p>
                                    <p>Your ascendant is Capricorn</p>
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second1">
                                  <div className="GeneralfirstTab mt-2 p-4">
                                    <h6 className="mb-2">Sun Consideration</h6>
                                    <p className="mb-2 paragraph-holder">
                                      Sun is in the fourth house of your natal chart
                                      with the Aries <br /> sign. The presence of the
                                      Sun in conjunction with Aries suggests that the
                                      native finds wealth. You will possess secret
                                      wealth which can give you the financial freedom
                                      you had
                                      <br /> always desired. There can be some
                                      negativity in family matters. You might suffer
                                      from losses in your mother’s house and face a
                                      lot of distress when it comes to your father's
                                      house.
                                    </p>

                                    <p className='paragraph-holder'>
                                      When it comes to your professional life, you
                                      might experience weakness in government and
                                      society. With the presence of the Sun with
                                      Aries, you will, however, spend your daily life
                                      in pleasure. Further, you might be somewhat
                                      lazy, which is why you are inactive in your work
                                      most of the time. Your inactivity can make you
                                      procrastinate and you might end up doing your
                                      job in haste.
                                    </p>
                                    <p>Your ascendant is Capricorn</p>
                                  </div>

                                  <div className="GeneralfirstTab mt-4 p-4">
                                    <h6 className="mb-2">Moon Consideration</h6>
                                    <p className="mb-2 paragraph-holder">
                                      Sun is in the fourth house of your natal chart
                                      with the Aries <br /> sign. The presence of the
                                      Sun in conjunction with Aries suggests that the
                                      native finds wealth. You will possess secret
                                      wealth which can give you the financial freedom
                                      you had
                                      <br /> always desired. There can be some
                                      negativity in family matters. You might suffer
                                      from losses in your mother’s house and face a
                                      lot of distress when it comes to your father's
                                      house.
                                    </p>

                                    <p className='paragraph-holder'>
                                      When it comes to your professional life, you
                                      might experience weakness in government and
                                      society. With the presence of the Sun with
                                      Aries, you will, however, spend your daily life
                                      in pleasure. Further, you might be somewhat
                                      lazy, which is why you are inactive in your work
                                      most of the time. Your inactivity can make you
                                      procrastinate and you might end up doing your
                                      job in haste.
                                    </p>
                                    <p>Your ascendant is Capricorn</p>
                                  </div>

                                  <div className="GeneralfirstTab mt-4 p-4">
                                    <h6 className="mb-2">Mercury Consideration</h6>
                                    <p className="mb-2 paragraph-holder">
                                      Sun is in the fourth house of your natal chart
                                      with the Aries <br /> sign. The presence of the
                                      Sun in conjunction with Aries suggests that the
                                      native finds wealth. You will possess secret
                                      wealth which can give you the financial freedom
                                      you had
                                      <br /> always desired. There can be some
                                      negativity in family matters. You might suffer
                                      from losses in your mother’s house and face a
                                      lot of distress when it comes to your father's
                                      house.
                                    </p>

                                    <p className='paragraph-holder'>
                                      When it comes to your professional life, you
                                      might experience weakness in government and
                                      society. With the presence of the Sun with
                                      Aries, you will, however, spend your daily life
                                      in pleasure. Further, you might be somewhat
                                      lazy, which is why you are inactive in your work
                                      most of the time. Your inactivity can make you
                                      procrastinate and you might end up doing your
                                      job in haste.
                                    </p>
                                    <p>Your ascendant is Capricorn</p>
                                  </div>

                                  <div className="GeneralfirstTab mt-4 p-4">
                                    <h6 className="mb-2">Venus Consideration</h6>
                                    <p className="mb-2 paragraph-holder">
                                      Sun is in the fourth house of your natal chart
                                      with the Aries <br /> sign. The presence of the
                                      Sun in conjunction with Aries suggests that the
                                      native finds wealth. You will possess secret
                                      wealth which can give you the financial freedom
                                      you had
                                      <br /> always desired. There can be some
                                      negativity in family matters. You might suffer
                                      from losses in your mother’s house and face a
                                      lot of distress when it comes to your father's
                                      house.
                                    </p>

                                    <p className='paragraph-holder'>
                                      When it comes to your professional life, you
                                      might experience weakness in government and
                                      society. With the presence of the Sun with
                                      Aries, you will, however, spend your daily life
                                      in pleasure. Further, you might be somewhat
                                      lazy, which is why you are inactive in your work
                                      most of the time. Your inactivity can make you
                                      procrastinate and you might end up doing your
                                      job in haste.
                                    </p>
                                    <p>Your ascendant is Capricorn</p>
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third1">
                                  <div className="GeneralfirstTab mb-4 mt-2 p-4">
                                    <h6 className="date_keto">
                                      (14-11-1996 - 15-11-2003)
                                    </h6>
                                    <h6 className="mb-2">Ketu Mahadasha</h6>
                                    <p className="mb-2 paragraph-holder">
                                      If Ketu is camping in the third house during the
                                      birth of the native, then he or she will be
                                      blessed with extremely fortuitous circumstances.
                                      They will successfully conquer their enemies and
                                      succeed in undertakings. Their income will also
                                      receive a considerable boost. However, there are
                                      a few downsides to Ketu’s presence in the first
                                      house. These could present themselves in the
                                      native’s life in the form of disputes and
                                      disagreements with the siblings. Owing to his
                                      troubles, he or she might rely on occult
                                      sciences and/or philosophy.
                                    </p>

                                    <p className='paragraph-holder'>
                                      The planet Ketu is in the Pisces sign of the
                                      Kundli. This placement bodes well to the native
                                      to some degree. The placement of Ketu in Pisces
                                      indicates that you will be bestowed with honour
                                      and happiness during this Dasha period. You will
                                      influence properties such as land and vehicles.
                                      You will also have a grip over various
                                      attendants. However, the- se are liable to be
                                      taken away towards the end of the Dasha period
                                      if you are not careful or acquire greed. You
                                      will have a child, probably a boy, during this
                                      period if you are married. The Dasha period,
                                      however, wants you to be thankful for all that
                                      you garner. Doing charity during this period
                                      will be further helpful for you.
                                    </p>
                                  </div>

                                  <div className="GeneralfirstTab mb-4 mt-2 p-4">
                                    <h6 className="date_keto">
                                      (14-11-1996 - 15-11-2003)
                                    </h6>
                                    <h6 className="mb-2">Venus Mahadasha</h6>
                                    <p className="mb-2 paragraph-holder">
                                      If Ketu is camping in the third house during the
                                      birth of the native, then he or she will be
                                      blessed with extremely fortuitous circumstances.
                                      They will successfully conquer their enemies and
                                      succeed in undertakings. Their income will also
                                      receive a considerable boost. However, there are
                                      a few downsides to Ketu’s presence in the first
                                      house. These could present themselves in the
                                      native’s life in the form of disputes and
                                      disagreements with the siblings. Owing to his
                                      troubles, he or she might rely on occult
                                      sciences and/or philosophy.
                                    </p>

                                    <p className='paragraph-holder'>
                                      The planet Ketu is in the Pisces sign of the
                                      Kundli. This placement bodes well to the native
                                      to some degree. The placement of Ketu in Pisces
                                      indicates that you will be bestowed with honour
                                      and happiness during this Dasha period. You will
                                      influence properties such as land and vehicles.
                                      You will also have a grip over various
                                      attendants. However, the- se are liable to be
                                      taken away towards the end of the Dasha period
                                      if you are not careful or acquire greed. You
                                      will have a child, probably a boy, during this
                                      period if you are married. The Dasha period,
                                      however, wants you to be thankful for all that
                                      you garner. Doing charity during this period
                                      will be further helpful for you.
                                    </p>
                                  </div>

                                  <div className="GeneralfirstTab mb-4 mt-2 p-4">
                                    <h6 className="date_keto">
                                      (14-11-1996 - 15-11-2003)
                                    </h6>
                                    <h6 className="mb-2">Sun Mahadasha</h6>
                                    <p className="mb-2 paragraph-holder">
                                      If Ketu is camping in the third house during the
                                      birth of the native, then he or she will be
                                      blessed with extremely fortuitous circumstances.
                                      They will successfully conquer their enemies and
                                      succeed in undertakings. Their income will also
                                      receive a considerable boost. However, there are
                                      a few downsides to Ketu’s presence in the first
                                      house. These could present themselves in the
                                      native’s life in the form of disputes and
                                      disagreements with the siblings. Owing to his
                                      troubles, he or she might rely on occult
                                      sciences and/or philosophy.
                                    </p>

                                    <p className='paragraph-holder'>
                                      The planet Ketu is in the Pisces sign of the
                                      Kundli. This placement bodes well to the native
                                      to some degree. The placement of Ketu in Pisces
                                      indicates that you will be bestowed with honour
                                      and happiness during this Dasha period. You will
                                      influence properties such as land and vehicles.
                                      You will also have a grip over various
                                      attendants. However, the- se are liable to be
                                      taken away towards the end of the Dasha period
                                      if you are not careful or acquire greed. You
                                      will have a child, probably a boy, during this
                                      period if you are married. The Dasha period,
                                      however, wants you to be thankful for all that
                                      you garner. Doing charity during this period
                                      will be further helpful for you.
                                    </p>
                                  </div>

                                  <div className="GeneralfirstTab mb-4 mt-2 p-4">
                                    <h6 className="date_keto">
                                      (14-11-1996 - 15-11-2003)
                                    </h6>
                                    <h6 className="mb-2">Moon Mahadasha</h6>
                                    <p className="mb-2 paragraph-holder">
                                      If Ketu is camping in the third house during the
                                      birth of the native, then he or she will be
                                      blessed with extremely fortuitous circumstances.
                                      They will successfully conquer their enemies and
                                      succeed in undertakings. Their income will also
                                      receive a considerable boost. However, there are
                                      a few downsides to Ketu’s presence in the first
                                      house. These could present themselves in the
                                      native’s life in the form of disputes and
                                      disagreements with the siblings. Owing to his
                                      troubles, he or she might rely on occult
                                      sciences and/or philosophy.
                                    </p>

                                    <p className='paragraph-holder'>
                                      The planet Ketu is in the Pisces sign of the
                                      Kundli. This placement bodes well to the native
                                      to some degree. The placement of Ketu in Pisces
                                      indicates that you will be bestowed with honour
                                      and happiness during this Dasha period. You will
                                      influence properties such as land and vehicles.
                                      You will also have a grip over various
                                      attendants. However, the- se are liable to be
                                      taken away towards the end of the Dasha period
                                      if you are not careful or acquire greed. You
                                      will have a child, probably a boy, during this
                                      period if you are married. The Dasha period,
                                      however, wants you to be thankful for all that
                                      you garner. Doing charity during this period
                                      will be further helpful for you.
                                    </p>
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="four1">
                                  <div className="GeneralfirstTab mb-4 mt-2 p-4">
                                    <h6 className="mb-3">Sunapha Yoga</h6>
                                    <h6 className="mb-3">
                                      Any planets, except Sun, in the second house
                                      from the Moon.
                                    </h6>

                                    <p className='paragraph-holder'>
                                      Sun apha Yoga indicates that you will be the
                                      proud owner of several properties which will be
                                      earned by you through your perseverance and good
                                      decisions. You will be extremely rich and have
                                      the luxury of living the life of a king. When it
                                      comes to your personality, you are intelligent
                                      and rarely make bad decisions. Your reputation
                                      will be on par with that of a reputed ruler.
                                    </p>
                                  </div>

                                  <div className="GeneralfirstTab mb-4 mt-2 p-4">
                                    <h6 className="mb-3">Anapha Yoga</h6>
                                    <h6 className="mb-3">
                                      Any planets, except Sun, in the second house
                                      from the Moon.{" "}
                                    </h6>

                                    <p className='paragraph-holder'>
                                      Sun apha Yoga indicates that you will be the
                                      proud owner of several properties which will be
                                      earned by you through your perseverance and good
                                      decisions. You will be extremely rich and have
                                      the luxury of living the life of a king. When it
                                      comes to your personality, you are intelligent
                                      and rarely make bad decisions. Your reputation
                                      will be on par with that of a reputed ruler.
                                    </p>
                                  </div>

                                  <div className="GeneralfirstTab mb-4 mt-2 p-4">
                                    <h6 className="mb-3">
                                      Planets on either side of Moon
                                    </h6>
                                    <h6 className="mb-3">
                                      Any planets, except Sun, in the second house
                                      from the Moon.{" "}
                                    </h6>

                                    <p className='paragraph-holder'>
                                      Sun apha Yoga indicates that you will be the
                                      proud owner of several properties which will be
                                      earned by you through your perseverance and good
                                      decisions. You will be extremely rich and have
                                      the luxury of living the life of a king. When it
                                      comes to your personality, you are intelligent
                                      and rarely make bad decisions. Your reputation
                                      will be on par with that of a reputed ruler.
                                    </p>
                                  </div>

                                  <div className="GeneralfirstTab mb-4 mt-2 p-4">
                                    <h6 className="mb-3">Vasumathi Yoga</h6>
                                    <h6 className="mb-3">
                                      Any planets, except Sun, in the second house
                                      from the Moon.
                                    </h6>

                                    <p className='paragraph-holder'>
                                      Sun apha Yoga indicates that you will be the
                                      proud owner of several properties which will be
                                      earned by you through your perseverance and good
                                      decisions. You will be extremely rich and have
                                      the luxury of living the life of a king. When it
                                      comes to your personality, you are intelligent
                                      and rarely make bad decisions. Your reputation
                                      will be on par with that of a reputed ruler.
                                    </p>
                                  </div>

                                  <div className="GeneralfirstTab mb-4 mt-2 p-4">
                                    <h6 className="mb-3">Budha Aditya Yoga</h6>
                                    <h6 className="mb-3">
                                      Any planets, except Sun, in the second house
                                      from the Moon.{" "}
                                    </h6>

                                    <p className='paragraph-holder'>
                                      Sun apha Yoga indicates that you will be the
                                      proud owner of several properties which will be
                                      earned by you through your perseverance and good
                                      decisions. You will be extremely rich and have
                                      the luxury of living the life of a king. When it
                                      comes to your personality, you are intelligent
                                      and rarely make bad decisions. Your reputation
                                      will be on par with that of a reputed ruler.
                                    </p>
                                  </div>
                                </Tab.Pane>
                              </Tab.Content>
                            </Tab.Container>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="second">
                      <Tab.Container id="left-tabs-example" defaultActiveKey="first2" >
                        <Nav variant="pills" className="nav Remedies_navtab_section  nav-pills justify-content-center mt-3" >
                          <Nav.Item className="Remedies_navtab  nav-pills pb-0">
                            <Nav.Link eventKey="first2" className="Rudraksha_txt nav-link text-center pt-2">
                              Rudraksha
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="Remedies_navtab nav nav-pills pb-0">
                            <Nav.Link eventKey="second1" className="Gemstones_txt nav-link text-center pt-2">
                              Gemstones
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>


                        <Tab.Content>
                          <Tab.Pane eventKey="first2">
                            <div className="GeneralfirstTab mt-3">
                              <h6 className="mb-3">Rudraksha Suggestion Report</h6>
                              <p className="mb-4">
                                This Rudraksha suggestion report aims to help you choose
                                the most suitable Rudraksha that you can wear to have the
                                blessings of Lord Shiva. This report considers the
                                Nakshatra that you were born in to suggest the most
                                appropriate Rudraksha for you. Wearing the recommended
                                Rudraksha shall shield you against the negative energies
                                and help retain positivity in your life.
                              </p>
                              <h6 className="mb-3">Rudraksha & its importance</h6>
                              <p className="mb-2">
                                Rudraksha beads are produced by the 'Rudraksha Tree'
                                scientifically called Elaeocarpus. The tree grows at a
                                certain altitude in the mountains, especially the
                                Himalayas - the home to Lord Shiva. The seeds are known to
                                have very unique vibrations that aid the spiritual and
                                physical growth of the wearer. In Hindu scriptures, it is
                                mentioned that the one who wears Rudraksha is blessed with
                                the virtues of Dharma, Artha, Kama, and Moksha. Wearing
                                Rudraksha, in fact, allows the wearer to connect with Lord
                                Shiva and his divine energies.
                              </p>

                              <p className="mb-2">
                                It is a belief that the wearer of Rudraksha not only
                                pleases Lord Shiva but also Goddess Durga, Lord Indra,
                                Lord Brahma, Lord Vishnu, Lord Ganesh, Lord Kartikeya,
                                Lord Aditya, and many other deities. If you want to
                                condemn your sins or faults and purify your life, a
                                Rudraksha is a good tool to begin with. It is said that a
                                personâ€™s sins burn down to ashes by merely looking at a
                                Rudraksha. On the other hand, wearing a Rudraksha helps in
                                the fulfilment of the desires of the wearer.
                              </p>

                              <p className="mb-2 pb-4">
                                The number of faces a Rudraksha has varies from 1-Mukhi to
                                21-Mukhi, all of which are used for different purposes.
                                Henceforth, it is not advisable to buy any Rudraksha of
                                your choice, wear it, and expect it to work. In fact,
                                wearing the wrong Rudraksha can disturb a nativeâ€™s life
                                by exposing him to wrong energies or simply overpowering a
                                certain planet or leaving it strengthless. Therefore, it
                                is very important that you get a consultation from an
                                astrologer to astrologically decide the most suitable
                                Rudraksha for yourself.
                              </p>
                            </div>

                            <div className="GeneralfirstTab mt-4">
                              <h6 className="mb-3">Recommendation</h6>
                              <p className="pb-3">
                                Having been born in the Mula nakshatra, the most suitable
                                Rudraksha for you is the 8-Mukhi Rudraksha. As per
                                astrology, the 8 Mukhi is ruled by the planet Ketu and
                                influenced by Lord Ganesha. Before opting for this
                                Rudraksha, it is recommended that you consult an
                                astrologer as there might be planetary combinations in
                                your chart in the current scenario based on which the
                                Rudraksha recommendation might change for you.
                              </p>
                            </div>

                            <button className="btn mukhi_btn mt-4 ms-2">8-Mukhi</button>
                            <div className="GeneralfirstTab mt-4">
                              <h6 className="mb-3">Details</h6>
                              <p className="mb-3">
                                8-Mukhi Rudraksha is the symbol of Lord Ganesha who is the
                                God of knowledge, wisdom, and wealth. Lord Ganesha
                                facilitates new beginnings and is the remover of obstacles
                                (Vighna Harta). Hence, if the wearer wears the eight-Mukhi
                                Rudraksha after conducting proper rituals, it will help
                                him remove obstacles across various aspects of life such
                                as marriage, love, career and more.
                              </p>

                              <p className="pb-3">
                                The Aath-Mukhi Rudraksha enhances the willpower of the
                                wearer. Hence, whenever starting something new, you must
                                at least see an eight-Mukhi Rudraksha. The eight-Mukhi
                                Rudraksha is used by many business professionals as it
                                aids business growth and lessens hurdles in professional
                                life. When it comes to physical benefits, the daily Dharan
                                of eight-Mukhi Rudraksha aids blood circulation and
                                controls negative thoughts.
                              </p>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="second1">

                            <div className="GeneralfirstTab mt-3">
                              <h5 className="Life_Stone_txt ">Life Stone</h5>
                              {/* <div className="border-dot mb-2"></div> */}
                              <h6 className="mb-2 Fortune">Life stone for Capricorn (Makara Lagna)</h6>
                              <p className="mb-2">
                                A life stone is a gem for the Lagna lord, which the native can wear throughout his or her life. A life stone collectively influences everything that makes your self-image, i.e. your wealth, education, health, business, spouse, intellect, etc. The lord of the Capricorn ascendant/Lagna is Saturn, and to please Saturn, the person born with Capricorn Ascendant (Makara Lagna) must wear Blue Sapphire.
                              </p>
                              <div className=" row">
                                <div className="Gemstones row ">
                                  <div className="col-lg-6">
                                    <p className="Capricorn ">Life stone for Capricorn (Makara Lagna)</p>
                                  </div>
                                  <div className="col-lg-6">
                                    <p>Blue Sapphire (Neelam)</p>
                                  </div>
                                </div>
                                <div className="Gemstones row mt-2">
                                  <div className="col-lg-6">
                                    <p className="Capricorn ">How to wear</p>
                                  </div>
                                  <div className="col-lg-6">
                                    <p>Gold, on middle finger</p>
                                  </div>
                                </div>
                                <div className="Gemstones row mt-2 mb-3">
                                  <div className="col-lg-6">
                                    <p className="Capricorn ">Mantra</p>
                                  </div>
                                  <div className="col-lg-6">
                                    <p>Om pram prim praum sah shanaisharaya namah</p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="GeneralfirstTab mt-4">
                              <h5 className="Life_Stone_txt ">Lucky Stone</h5>
                              {/* <div className="border-dot mb-2"></div> */}
                              <h6 className="mb-2 Fortune">Lucky Gemstone for Capricorn (Makara)</h6>
                              <p className="mb-2">
                                A lucky gemstone is worn to enhance the native's luck and open new doors to success for him. An individual's lucky stone is one that keeps luck ticking for him while ensuring the blessing of favourable planets upon him. As Venus and Mercury are beneficial planets for Capricorn, hence the Lucky gemstone for the Capricorn Ascendant is: Diamond (Heera)
                              </p>
                              <div className=" row">
                                <div className="Gemstones row mt-2">
                                  <div className="col-lg-6">
                                    <p className="Capricorn ">Life stone for Capricorn (Makara Lagna)</p>
                                  </div>
                                  <div className="col-lg-6">
                                    <p>Diamond (Heera)</p>
                                  </div>
                                </div>
                                <div className="Gemstones row mt-2">
                                  <div className="col-lg-6">
                                    <p className="Capricorn ">How to wear</p>
                                  </div>
                                  <div className="col-lg-6">
                                    <p>Gold or silver on middle finger</p>
                                  </div>
                                </div>
                                <div className="Gemstones row mt-2 mb-3">
                                  <div className="col-lg-6">
                                    <p className="Capricorn ">Mantra</p>
                                  </div>
                                  <div className="col-lg-6">
                                    <p>Om dram drim draum sah shukraya namah</p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="GeneralfirstTab mt-4">
                              <h5 className="Life_Stone_txt ">Fortune Stone</h5>
                              {/* <div className="border-dot mb-2"></div> */}
                              <h6 className="mb-2 Fortune">Fortune Stone for Capricorn (Makara)</h6>
                              <p className="mb-2">
                                The Bhagya stone is suggested by the astrologers based on the Lord governing the 9th house of the native's birth chart. The Bhagya stone helps the native attract fortune when s/he needs it the most. Wearing Bhagya stone as per ascendant sign helps in fighting obstacles and enhances prosperity both in personal and professional life
                              </p>
                              <div className=" row">
                                <div className="Gemstones row mt-2">
                                  <div className="col-lg-6">
                                    <p className="Capricorn ">Life stone for Capricorn (Makara Lagna)</p>
                                  </div>
                                  <div className="col-lg-6">
                                    <p>Emerald (Panna)</p>
                                  </div>
                                </div>
                                <div className="Gemstones row mt-2">
                                  <div className="col-lg-6">
                                    <p className="Capricorn ">How to wear</p>
                                  </div>
                                  <div className="col-lg-6">
                                    <p>Gold, on ring or little finger</p>
                                  </div>
                                </div>
                                <div className="Gemstones row mt-2 mb-3">
                                  <div className="col-lg-6">
                                    <p className="Capricorn ">Mantra</p>
                                  </div>
                                  <div className="col-lg-6">
                                    <p>Om bram brim braum sah budhaya namah</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>


                      </Tab.Container>

                    </Tab.Pane>

                    <Tab.Pane eventKey="third">
                      <Tab.Container id="left-tabs-example" defaultActiveKey="Manglik" >
                        <Nav variant="pills" className="nav Remedies_navtab_section  nav-pills justify-content-center mt-3" >
                          <Nav.Item className="Remedies_navtab  nav-pills pb-0">
                            <Nav.Link eventKey="Manglik" className="Rudraksha_txt nav-link text-center pt-2">
                              Manglik
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="Remedies_navtab nav nav-pills pb-0">
                            <Nav.Link eventKey="Kalsarpa" className="Gemstones_txt nav-link text-center pt-2">
                              Kalsarpa
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="Remedies_navtab nav nav-pills pb-0">
                            <Nav.Link eventKey="Sadesati" className="Gemstones_txt nav-link text-center pt-2">
                              Sadesati
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>


                        <Tab.Content>
                          <Tab.Pane eventKey="Manglik">
                            <div className="GeneralfirstTab mt-4">
                              <h6 className="">Manglik Analysis</h6>
                              <div className="underline"></div>
                              <div className="row">
                                <div className="d-flex mb-2">
                                  <div className='name-holder1 me-1 mt-2'>
                                    <p className='name-identity'>No</p>
                                  </div>
                                  <h6 className=" name-first">Shubham Kumar</h6>
                                </div>
                                <p className="mb-2 paragraph-holder">
                                  Since mars is in eigth house and in leo sign
                                  person is Non Manglik.
                                </p>
                                <p className="paragraph-holder">
                                  However the person's Mangal dosha has been
                                  cancelled due to following reasons:
                                </p>
                                <p className="paragraph-holder"> ■ Mars
                                  is in eigth house and Leo sign.</p>
                                <p className="py-4 paragraph-holder">
                                  [This is a computer generated result. Please
                                  consult an Astrologer to confirm &
                                  understand this in detail.]
                                </p>


                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="Kalsarpa">
                            <div className="GeneralfirstTab mt-4 pb-3">
                              <h6 className="">Kalsarpa Analysis</h6>
                              <div className="underline"></div>
                              <div className="d-flex mb-2">
                                <div className='name-holder1 me-1 mt-2'>
                                  <p className='name-identity'>No</p>
                                </div>
                                <h6 className=" name-first">Kundli Is Free From Kalsharpa Dosha.</h6>
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="Sadesati">
                            <div className="GeneralfirstTab mt-4 pb-3">
                              <h6 className="">Sadesati Analysis</h6>
                              <div className="underline"></div>
                              <div className="d-flex mb-2">
                                <div className='name-holder1 me-1 mt-2'>
                                  <p className='name-identity'>No</p>
                                </div>
                                <div>
                                  <h6 className=" name-first">Current Sadesati Status.</h6>
                                  <p>
                                    Your next sadesati starts from 11-Dec-2043
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="table-responsive mt-5">
                                <table className="table table-bordered  text-center">
                                  <thead>
                                    <tr>
                                      <th>Start</th>
                                      <th>End</th>
                                      <th>Sign Name</th>
                                      <th>Type</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    <tr className="table-warning1">
                                      <td>2-11-2014</td>
                                      <td>26-1-2017</td>
                                      <td>Scorpio</td>
                                      <td>Rising</td>
                                    </tr>
                                    <tr>
                                      <td>2-11-2014</td>
                                      <td>26-1-2017</td>
                                      <td>Scorpio</td>
                                      <td>Rising</td>
                                    </tr>
                                    <tr className="table-warning1">
                                      <td>2-11-2014</td>
                                      <td>26-1-2017</td>
                                      <td>Scorpio</td>
                                      <td>Rising</td>
                                    </tr>
                                    <tr>
                                      <td>2-11-2014</td>
                                      <td>26-1-2017</td>
                                      <td>Scorpio</td>
                                      <td>Rising</td>
                                    </tr>
                                    <tr className="table-warning1">
                                      <td>2-11-2014</td>
                                      <td>26-1-2017</td>
                                      <td>Scorpio</td>
                                      <td>Rising</td>
                                    </tr>
                                    <tr>
                                      <td>2-11-2014</td>
                                      <td>26-1-2017</td>
                                      <td>Scorpio</td>
                                      <td>Rising</td>
                                    </tr>
                                    <tr className="table-warning1">
                                      <td>2-11-2014</td>
                                      <td>26-1-2017</td>
                                      <td>Scorpio</td>
                                      <td>Rising</td>
                                    </tr>
                                    <tr>
                                      <td>2-11-2014</td>
                                      <td>26-1-2017</td>
                                      <td>Scorpio</td>
                                      <td>Rising</td>
                                    </tr>
                                    <tr className="table-warning1">
                                      <td>2-11-2014</td>
                                      <td>26-1-2017</td>
                                      <td>Scorpio</td>
                                      <td>Rising</td>
                                    </tr>
                                    <tr>
                                      <td>2-11-2014</td>
                                      <td>26-1-2017</td>
                                      <td>Scorpio</td>
                                      <td>Rising</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>

                              <div className="GeneralfirstTab1 mb-4 p-2">

                                <h6 className="mb-2">Shani Sade Sati: Rising Phase</h6>
                                <p className="mb-2 paragraph-holder">
                                  With Saturn transiting in the 12th house of your Kundli
                                  from the Moon, the movement marks the beginning of the
                                  Shani Sade Sati phase for you. During this period, you
                                  need to be cautious of instances that can lead to
                                  financial losses, accidents, relationship woes, bad health
                                  and even deprivation. During the Shani Sade Sati phase,
                                  you need to rein your actions and words, especially at
                                  your workplace, or else you will have to face challenges
                                  in adjusting to the professional scenario. On the domestic
                                  front, family and finance-related tensions may prevail. If
                                  trying to get married, Saturn's nature of dealy may
                                  overpower that desire of yours. In such instances, being
                                  patient will come to your emotional aid. Consider the
                                  Shani Sade Sati phase for working hard towards your
                                  dreams, as Shani rewards you based on your karma and hard
                                  work. You are advised not to take high risks in business
                                  matters in this period.
                                </p>
                              </div>

                              <div className="GeneralfirstTab1 mb-4 p-2">
                                <h6 className="mb-2">Shani Sade Sati: Peak Phase</h6>
                                <p className="mb-2 paragraph-holder">
                                  As you tread into the peak phase of Shani Sade Sati,
                                  things might get a bit tough for you hereafter. Yet,
                                  acknowledging the tough as a challenge is advised. Saturn
                                  transiting over the natal Moon from here may complicate
                                  certain prevalent health woes. Your immune system may show
                                  unprecedented weakness. Hence, do follow a proper diet and
                                  an active lifestyle. A teen or working adult may suffer
                                  from depression, unknown fear or phobia, especially of
                                  losing their source of income during this phase. You also
                                  need to be cautious about keeping your societal image
                                  intact during this phase. On the bright side, the peak
                                  Shani Sade Sati period will incline you towards your
                                  spiritual side. You will feel more peaceful if you
                                  surrender to God. Take spiritual tours during this period
                                  with your spouse.
                                </p>
                              </div>

                              <div className="GeneralfirstTab1  p-2">
                                <h6 className="mb-2">Shani Sade Sati: Setting Phase</h6>
                                <p className="mb-2 paragraph-holder">
                                  The setting period of Shani's Sade Sati has begun for you
                                  with Saturn moving into the 2nd house from the natal Moon.
                                  The period marks financial and domestic woes but less
                                  severe in form. However, professional decisions should be
                                  made after careful consultation with expert stakeholders.
                                  Also, you need to keep your hands tight when it comes to
                                  spending money, as expenses might soar for you in this
                                  phase of Shani Sade Sati. Sudden financial losses and fear
                                  of theft are also a possibility. A lot of attention needs
                                  to be diverted towards family and your own self, as if you
                                  don't do so, small problems will turn into bigger
                                  problems. There will be some delay in getting the results
                                  you desire, but good things will surely happen for you. If
                                  possible, give up any bad habits such as smoking and
                                  drinking to keep Saturn happy.
                                </p>
                              </div>
                            </div>

                          </Tab.Pane>
                        </Tab.Content>


                      </Tab.Container>

                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
          <div className="row">
            <ConnectAstrologer />
          </div>



        </div>
      </section>
    </>
  );
};

export default Free_Report;
