import React from 'react'
import { Link } from 'react-router-dom'
import HoroscopeSlider from '../HoroscopeSlider/HoroscopeSlider'

const Weekly_Horoscope = () => {
  return (
    <>
      <section className="Horoscope2023 desk-top-view-horoscope">
        <div className="container-fluid">
          <div className='row'>
            <div className='col-xxl-9 col-xl-10 col-lg-11 col-md-11 mx-auto'>
              <div className="row">
                <div className="col-md-12">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mt-4">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        <Link >Weekly Horoscope</Link>
                      </li>
                    </ol>
                  </nav>


                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    {/* <div className="card-body"> */}
                    <div className="Horoscope_info p-4">
                      <div className="Horoscope_heading mb-2">
                        Weekly Horoscope
                        <span className='Monthly-txt'>
                          (Know Your Weekly Horoscope)

                        </span>
                      </div>
                      <p>
                        Looking for your weekly horoscope? Astrotalk can help you put an end to your search. On Astrotalk, the astrologers, on a regular basis, horoscopes, including weekly horoscopes, that are prepared after considering the movement of the planets in Vedic astrology. This movement of the Vedic planets can be both positive and negative for the native, and the work of the horoscopes for the week ahead is to highlight their influences, their effect, and the associated remedies that one can adopt to ensure that you are safeguarded from the negative effect of the planets
                      </p>

                      <p>
                        The weekly horoscope, besides a dose of insights into the good and bad of the week, also brings you tips and tricks that you can consider to make your happening. These tips, yet again, are suggested by expert astrologers and practising them, in some way or the other, will help you in making the best use of your week. With the weekly horoscope, we make sure you are well aware of what's going to come for you in the future so that you are well prepared for the uncertainties and can take actions that only better your life.


                      </p>


                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <HoroscopeSlider />
      </section>
      {/* mobile-view-start */}
      <section className="Horoscope2023 mobile-view-horoscope">
        <div className="container-fluid">
          <div className='row'>
            <div className="col-md-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mt-3">
                  <li className="breadcrumb-item">
                    <Link to="/">Home {'>'}</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link >Weekly Horoscope</Link>
                  </li>
                </ol>
              </nav>
            </div>

            {/* <div className="row"> */}
            <div className="col-md-12">
              <div className="card mt-3">
                {/* <div className="card-body"> */}
                <div className="Horoscope_info p-2">
                  <div className="Horoscope_heading my-2">
                    Weekly Horoscope
                    <span className='Monthly-txt'>
                      (Know Your Weekly Horoscope)

                    </span>
                  </div>
                  <p>
                    Looking for your weekly horoscope? Astrotalk can help you put an end to your search. On Astrotalk, the astrologers, on a regular basis, horoscopes, including weekly horoscopes, that are prepared after considering the movement of the planets in Vedic astrology. This movement of the Vedic planets can be both positive and negative for the native, and the work of the horoscopes for the week ahead is to highlight their influences, their effect, and the associated remedies that one can adopt to ensure that you are safeguarded from the negative effect of the planets
                  </p>

                  <p>
                    The weekly horoscope, besides a dose of insights into the good and bad of the week, also brings you tips and tricks that you can consider to make your happening. These tips, yet again, are suggested by expert astrologers and practising them, in some way or the other, will help you in making the best use of your week. With the weekly horoscope, we make sure you are well aware of what's going to come for you in the future so that you are well prepared for the uncertainties and can take actions that only better your life.


                  </p>

                </div>
                {/* </div> */}
              </div>
            </div>
            {/* </div> */}

          </div>
        </div>
        <HoroscopeSlider />
      </section>
    </>
  )
}

export default Weekly_Horoscope