import React from 'react'
import { Link } from 'react-router-dom'
import HoroscopeSlider from '../HoroscopeSlider/HoroscopeSlider'

const Tomorrow_Horoscope = () => {
  return (
    <>
      <section className="Horoscope2023 desk-top-view-horoscope">
        <div className="container-fluid">
          <div className='row'>
            <div className='col-xxl-9 col-xl-10 col-lg-11 col-md-11 mx-auto'>
              <div className="row">
                <div className="col-md-12">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mt-4">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        <Link >Tomorrow Horoscope</Link>
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    {/* <div className="card-body"> */}
                    <div className="Horoscope_info p-4">
                      <div className="Horoscope_heading mb-2">
                        Tomorrow Horoscope
                        <span className='Monthly-txt'>
                          (Know Your Tomorrow Horoscope)</span>
                      </div>
                      <p>
                        It is not always bad to do things in advance and the same logic applies when it comes to reading your horoscope. If you seek to stay one step ahead in life, your Tomorrow's horoscope can help you in doing so. Reading your tomorrow's horoscope gives you a picture of what stars are planning for you in the days to come. Tomorrow's horoscope is the study of the Moon's movement and placement before it actually happens, and based on the same, it highlights what would or wouldn't change for you in the upcoming days.
                      </p>

                      <p>
                        Your tomorrow's horoscope is prepared by the expert astrologers at Astrotalk who have massive experience in astrology thus the predictions they are making for you are ought to be authentic. The learned astrologers, through the horoscope, highlight change across various aspects of your life such as love, career, marriage and thus allow you suggestions on how you can make the best use of these changes. The motive of tomorrow's horoscope is to change your life for good and warn you against the upcoming woes. And we feel you will be glad to have that information delivered right at your doorsteps. So, with that being said, read your tomorrow's horoscope today.
                      </p>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <HoroscopeSlider />
      </section>

      {/* mobile-view-start */}
      <section className="Horoscope2023 mobile-view-horoscope">
        <div className="container-fluid">
          <div className='row'>
            <div className="col-md-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mt-3">
                  <li className="breadcrumb-item">
                    <Link to="/">Home {'>'}</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link >Tomorrow Horoscope</Link>
                  </li>
                </ol>
              </nav>
            </div>
            {/* <div className="row"> */}
            <div className="col-md-12">
              <div className="card mt-3">
                {/* <div className="card-body"> */}
                <div className="Horoscope_info p-2">
                  <div className="Horoscope_heading my-2">
                    Tomorrow Horoscope
                    <span className='Monthly-txt'>
                      (Know Your Tomorrow Horoscope)</span>
                  </div>
                  <p>
                    It is not always bad to do things in advance and the same logic applies when it comes to reading your horoscope. If you seek to stay one step ahead in life, your Tomorrow's horoscope can help you in doing so. Reading your tomorrow's horoscope gives you a picture of what stars are planning for you in the days to come. Tomorrow's horoscope is the study of the Moon's movement and placement before it actually happens, and based on the same, it highlights what would or wouldn't change for you in the upcoming days.
                  </p>

                  <p>
                    Your tomorrow's horoscope is prepared by the expert astrologers at Astrotalk who have massive experience in astrology thus the predictions they are making for you are ought to be authentic. The learned astrologers, through the horoscope, highlight change across various aspects of your life such as love, career, marriage and thus allow you suggestions on how you can make the best use of these changes. The motive of tomorrow's horoscope is to change your life for good and warn you against the upcoming woes. And we feel you will be glad to have that information delivered right at your doorsteps. So, with that being said, read your tomorrow's horoscope today.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <HoroscopeSlider />
      </section>
    </>
  )
}

export default Tomorrow_Horoscope