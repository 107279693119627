import React from 'react'
import { Link } from 'react-router-dom'
import HoroscopeSlider from '../HoroscopeSlider/HoroscopeSlider'

const Yesterday_Horoscope = () => {
  return (
    <>
      <section className="Horoscope2023 desk-top-view-horoscope">
        <div className="container-fluid">
          <div className='row'>
            <div className='col-xxl-9 col-xl-10 col-lg-11 col-md-11 mx-auto'>
              <div className="row">
                <div className="col-md-12">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mt-4">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        <Link >Yesterday Horoscope</Link>
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    {/* <div className="card-body"> */}
                    <div className="Horoscope_info p-4">
                      <div className="Horoscope_heading mb-2">
                        Yesterday Horoscope
                        <span className='Monthly-txt'>
                          (Know Your Yesterday Horoscope)</span>
                      </div>
                      <p>
                        Why would anyone want to see their yesterday's horoscope? Well, people have their reasons, and we are no one to judge. All we plan to do is highlight your yesterday's horoscope so that you can give it a read, just in case you missed it the other day. Your yesterday's horoscope is catered, aka prepared, by the expert astrologers on Astrotalk after considering the planetary positions for utmost accuracy. The yesterday's horoscope on Astrotalk is based on your Moon sign and can allow you a peek into the various aspects of your life such as love, marriage, business, career, etc.
                      </p>

                      <p>
                        However, although all of this information would be less of use to you, as the horoscope is for the day that no longer exists, yet, there are instances wherein the Daily horoscope highlights what may or may not happen to you in the week to come. So, even if you missed your daily horoscope, you have no reasons to worry. Also, if yesterday's horoscope mentions any kind of upcoming life phase or dasha, you will have another chance to access that information through your yesterday's horoscope.
                      </p>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <HoroscopeSlider />
      </section>

      {/* mobile-view-start */}
      <section className="Horoscope2023 mobile-view-horoscope">
        <div className="container-fluid">
          <div className='row'>
            <div className="col-md-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mt-3">
                  <li className="breadcrumb-item">
                    <Link to="/">Home {'>'}</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link >Yesterday Horoscope</Link>
                  </li>
                </ol>
              </nav>
            </div>
            {/* <div className="row"> */}
            <div className="col-md-12">
              <div className="card mt-3">
                {/* <div className="card-body"> */}
                <div className="Horoscope_info p-2">
                  <div className="Horoscope_heading my-2">
                    Yesterday Horoscope<br />  <span className='Monthly-txt'>
                      (Know Your Yesterday Horoscope)</span>
                  </div>
                  <p>
                    Why would anyone want to see their yesterday's horoscope? Well, people have their reasons, and we are no one to judge. All we plan to do is highlight your yesterday's horoscope so that you can give it a read, just in case you missed it the other day. Your yesterday's horoscope is catered, aka prepared, by the expert astrologers on Astrotalk after considering the planetary positions for utmost accuracy. The yesterday's horoscope on Astrotalk is based on your Moon sign and can allow you a peek into the various aspects of your life such as love, marriage, business, career, etc.
                  </p>
                  <p>
                    However, although all of this information would be less of use to you, as the horoscope is for the day that no longer exists, yet, there are instances wherein the Daily horoscope highlights what may or may not happen to you in the week to come. So, even if you missed your daily horoscope, you have no reasons to worry. Also, if yesterday's horoscope mentions any kind of upcoming life phase or dasha, you will have another chance to access that information through your yesterday's horoscope.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <HoroscopeSlider />
      </section>
    </>
  )
}

export default Yesterday_Horoscope