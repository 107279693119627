import React from 'react'
import rightImg from "./Images/tarot-card.png";
import "./ChatSectiondisha.css";

import man_img from "./Images/man_img.png";

import onlineimg from '../../Astrologer-profile/Image/online.png';
import { Link } from "react-router-dom";
const ChatSecondScreendisha = () => {
    return (
        <>
            <section className="OurExpertChatSection pb-5 desk-top-view-chatsection">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-9 col-md-12">
                            <div className="row">
                                <div className="card mt-4 p-0">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="heading-astroler d-flex">
                                                <div className="heading-img">
                                                    <img src={man_img} className="man_img_1" />
                                                    <div className="online-icon">
                                                        <img src={onlineimg} className="online-img" />
                                                    </div>
                                                </div>
                                                <div>
                                                    <span className="name_txt p-3">
                                                        Disha (Astrologers)
                                                    </span>
                                                    <br />
                                                    <span className="active-txt p-3 ms-1">Active</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="text-end m-2">
                                                <button className="btn btn-danger endChat_btn" type="button" data-bs-toggle="modal" data-bs-target="#deleteModalchat1">
                                                    End Chat
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className=" second-chat-astro">
                                        <div className="row">
                                            <div className="col-xl-4 col-lg-2 col-md-3 "></div>
                                            <div className="col-xl-8 col-lg-10 col-md-9 ">
                                                <div className="row message_icon-section">
                                                    <div className="date-holder">
                                                        <span >23 Jul, 2023 20:40</span>
                                                    </div>
                                                    <div className="col-xl-11 col-lg-11 col-md-11 col-sm-11 col-11 chat_1">
                                                        <p>  Hi Paresh Kumar, <br />
                                                            Below are my details: <br />
                                                            Name: Shubham
                                                            <br />
                                                            Gender: Male <br />
                                                            DOB: 27-April-1997
                                                            <br />
                                                            TOB: 1:30 PM <br />
                                                            POB: Sultanpur, Uttar Pradesh, Sultanpur <br />
                                                            Marital Status: undefined</p>
                                                    </div>
                                                    <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1">
                                                        <img src={man_img} className="man_img" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-5">
                                            <div className="col-xl-4 col-lg-2 col-md-2 "></div>
                                            <div className="col-xl-8 col-lg-10 col-md-10 ">
                                                <div className="row message_icon-section">
                                                    <div className="date-holder">
                                                        <span >23 Jul, 2023 20:40</span>
                                                    </div>
                                                    <div className=" col-lg-11 col-md-11 col-sm-11 col-11 chat-2">
                                                        <p className="chat-2-txt my-2">
                                                            This is an automated message to confirm that chat
                                                            has started
                                                        </p>
                                                    </div>
                                                    <div className="col-lg-1 col-md-1 col-sm-1 col-1">
                                                        <img src={man_img} className="man_img" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="row mt-5">
                                            <div className="col-lg-10 col-md-11 col-sm-11">
                                                <div className="row message_icon-section">
                                                    <div className="col-lg-1  col-md-1 col-sm-1 col-1">
                                                        <img src={man_img} className="man_img_2" />
                                                    </div>

                                                    <div className="col-lg-11 col-md-10 col-sm-10 col-10 ">
                                                        <div className="date-holder text-end">
                                                            <span >23 Jul, 2023 20:40</span>
                                                        </div>
                                                        <div className="chat-3 p-1">
                                                            <p className="p-2">
                                                                Welcome to Astrotalk Consultant will take a minute
                                                                to analyse your details. You may ask  your
                                                                question in a meanwhile
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-md-1 col-sm-1"></div>
                                        </div>

                                        <div className="row mt-5">
                                            <div className="col-lg-10 col-md-8 ">
                                                <div className="row message_icon-section">
                                                    <div className="col-lg-1 col-md-2 col-sm-2 col-2">
                                                        <img src={man_img} className="man_img_2" />
                                                    </div>

                                                    <div className="col-lg-3 col-md-3 col-sm-4 ">
                                                        <div className="date-holder text-end">
                                                            <span >23 Jul, 2023 20:40</span>
                                                        </div>
                                                        <div className="chat-3 p-1">
                                                            <p className="p-2">
                                                                Hello
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-md-4"></div>
                                        </div>

                                        <div className="row mt-5">
                                            <div className="col-xl-4 col-lg-4 col-md-2"></div>
                                            <div className="col-xl-8 col-lg-8 col-md-10">
                                                <div className="row message_icon-section">

                                                    <div className="col-lg-11 col-md-11 col-sm-11 col-11 chat_1">
                                                        <p className=" my-2">
                                                            This is an automated message to confirm that chat
                                                            has started
                                                        </p>
                                                    </div>
                                                    <div className="col-lg-1 col-md-1 col-sm-1 col-1">
                                                        <img src={man_img} className="man_img" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-5">
                                            <div className="col-lg-10 col-md-8 ">
                                                <div className="row message_icon-section">
                                                    <div className="col-lg-1 col-md-2 col-sm-2 col-2">
                                                        <img src={man_img} className="man_img_2" />
                                                    </div>

                                                    <div className="col-lg-4 col-md-4 col-sm-4 ">
                                                        <div className="date-holder text-end">
                                                            <span >23 Jul, 2023 20:40</span>
                                                        </div>
                                                        <div className="chat-3 p-1">
                                                            <p className="p-2">
                                                                Kese ho aap
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-md-4"></div>
                                        </div>

                                        <div className="row mt-5">
                                            <div className="col-lg-11 col-md-11 col-sm-11">
                                                <div className="row message_icon-section">
                                                    <div className="col-lg-1  col-md-1 col-sm-1 col-1">
                                                        <img src={man_img} className="man_img_2" />
                                                    </div>

                                                    <div className="col-lg-11 col-md-10 col-sm-10 col-10 ">
                                                        <div className="date-holder text-end">
                                                            <span >23 Jul, 2023 20:40</span>
                                                        </div>
                                                        <div className="chat-3 p-1" type="button" data-bs-toggle="modal" data-bs-target="#talk1modal">
                                                            <p className="p-2 custo-support">
                                                                This is an automated message. Your chat ended as you ran out of free minutes.
                                                                You may recharge and start the chat again. For any issues, contact customer support  </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-1 col-sm-1"></div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 mx-auto">
                            <div className="side-img">
                                <img src={rightImg} className="rightSide-img" />
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="upper-txt">
                                            <p className="text-center">Vastu</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!--delete Modal start--> */}
            <section class="modal-delete">
                <div class="modal fade" id="deleteModalchat1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content modal-chat-content">
                            <div class="modal-header">
                                {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div class="modal-body">
                                <div className='delete-text text-center'>
                                    <h5>Are you sure you want to End this Chat</h5>
                                </div>
                                <div className='delete-button text-center my-3'>
                                    <button className='btn btn-cancle me-2'>Cancel</button>
                                    <button className='btn btn-ok'>Ok</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            {/* <!---delete-modal-end */}

            {/* <!--delete Modal start--> */}
            <section class="modal-talk">
                <div class="modal fade" id="talk1modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg">
                        <div class="modal-content modal-chat-content">
                            <div class="modal-header">
                                {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div class="modal-body">
                                <div className='d-flex'>
                                    <div className='delete-text mb-3'>
                                        <h5>Would you like to continue your chat at normal price of ₹10.0/min<br /> after this offer ends?</h5>
                                    </div>
                                    <div className='delete-button ms-auto mb-3'>
                                        <Link to='/wallet'>  <button className='btn btn-yes me-2'>Yes</button></Link>
                                        <Link to='/disha-review'>  <button className='btn btn-no'>No</button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!---delete-modal-end */}


            {/* desk-top-view-end */}
            {/* mobile-view-start */}

            <section className="OurExpertChatSection pb-5 mobile-view-chatsection">
                <div className="container-fluid">
                    <div className="row mt-4">
                        <div className="col-8 p-0">
                            <div className="heading-astroler d-flex">
                                <div className="heading-img">
                                    <img src={man_img} className="man_img_1 ms-2 mt-3" />
                                    <div className="online-icon">
                                        <img src={onlineimg} className="online-img" />
                                    </div>
                                </div>
                                <div>
                                    <p className="name_txt ms-2 mb-0 ">Disha (Astrologers)</p>
                                    <span className="active-txt">Active</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="text-end mt-2">
                                <button className="btn btn-danger endChat_btn" type="button" >End Chat </button>
                            </div>
                        </div>

                        {/* <div className="row"> */}
                        <div className="card-body">
                            <div className="row">
                                <div className="col-xl-4 col-lg-2 col-md-3 col-sm-3 col-3"></div>
                                <div className="col-xl-8 col-lg-10 col-md-9 col-sm-9 col-9">
                                    <div className="row message_icon-section">
                                        <div className="date-holder">
                                            <span >23 Jul, 2023 20:40</span>
                                        </div>
                                        <div className="col-xl-11 col-lg-11 col-md-11 col-sm-11 col-11 chat_1">
                                            <p>  Hi Paresh Kumar, <br />
                                                Below are my details: <br />
                                                Name: Shubham
                                                <br />
                                                Gender: Male <br />
                                                DOB: 27-April-1997
                                                <br />
                                                TOB: 1:30 PM <br />
                                                POB: Sultanpur, Uttar Pradesh, Sultanpur <br />
                                                Marital Status: undefined</p>
                                        </div>
                                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1">
                                            <img src={man_img} className="man_img" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-xl-4 col-lg-2 col-md-2 col-sm-3 col-1"></div>
                                <div className="col-xl-8 col-lg-10 col-md-10 col-sm-9 col-11 ">
                                    <div className="row message_icon-section">
                                        <div className="date-holder">
                                            <span >23 Jul, 2023 20:40</span>
                                        </div>
                                        <div className=" col-lg-11 col-md-11 col-sm-11 col-11 chat-2">
                                            <p className="chat-2-txt my-2">
                                                This is an automated message to confirm that chat
                                                has started
                                            </p>
                                        </div>
                                        <div className="col-lg-1 col-md-1 col-sm-1 col-1">
                                            <img src={man_img} className="man_img" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-lg-10 col-md-11 col-sm-11">
                                    <div className="row message_icon-section">
                                        <div className="col-lg-1  col-md-1 col-sm-1 col-1">
                                            <img src={man_img} className="man_img_2" />
                                        </div>

                                        <div className="col-lg-11 col-md-10 col-sm-11 col-11 ">
                                            <div className="date-holder text-end">
                                                <span >23 Jul, 2023 20:40</span>
                                            </div>
                                            <div className="chat-3">
                                                <p className="p-2">
                                                    Welcome to Astrotalk Consultant will take a minute
                                                    to analyse your details. You may ask  your
                                                    question in a meanwhile
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-1 col-sm-1"></div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-lg-10 col-md-8 col-sm-10 col-11">
                                    <div className="row message_icon-section">
                                        <div className="col-lg-1 col-md-2 col-sm-1 col-1">
                                            <img src={man_img} className="man_img_2" />
                                        </div>

                                        <div className="col-lg-3 col-md-3 col-sm-4 col-4">
                                            <div className="date-holder text-end">
                                                <span >23 Jul, 2023 20:40</span>
                                            </div>
                                            <div className="chat-3">
                                                <p className="p-2">
                                                    Hello
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4 col-sm-2 col-1"></div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-xl-4 col-lg-4 col-md-2 col-sm-2 col-2"></div>
                                <div className="col-xl-8 col-lg-8 col-md-10 col-sm-10 col-10">
                                    <div className="row message_icon-section">

                                        <div className="col-lg-11 col-md-11 col-sm-11 col-11 chat_1">
                                            <p className=" my-2">
                                                This is an automated message to confirm that chat
                                                has started
                                            </p>
                                        </div>
                                        <div className="col-lg-1 col-md-1 col-sm-1 col-1">
                                            <img src={man_img} className="man_img" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-lg-10 col-md-8 ">
                                    <div className="row message_icon-section">
                                        <div className="col-lg-1 col-md-2 col-sm-1 col-1">
                                            <img src={man_img} className="man_img_2" />
                                        </div>

                                        <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                                            <div className="date-holder text-end">
                                                <span >23 Jul, 2023 20:40</span>
                                            </div>
                                            <div className="chat-3">
                                                <p className="p-2">
                                                    Kese ho aap
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4"></div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-lg-11 col-md-11 col-sm-11">
                                    <div className="row message_icon-section">
                                        <div className="col-lg-1  col-md-1 col-sm-1 col-1">
                                            <img src={man_img} className="man_img_2" />
                                        </div>

                                        <div className="col-lg-11 col-md-10 col-sm-11 col-11 ">
                                            <div className="date-holder text-end">
                                                <span >23 Jul, 2023 20:40</span>
                                            </div>
                                            <div className="chat-3 p-1" type="button" data-bs-toggle="modal" data-bs-target="#talk1modalmob">
                                                <p className=" custo-support">
                                                    This is an automated message. Your chat ended as you ran out of free minutes.
                                                    You may recharge and start the chat again. For any issues, contact customer support  </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1 col-md-1 col-sm-1"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--talk Modal start-dektopview--> */}
            <section class="modal-talk">
                <div class="modal fade" id="talk1modalmob" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg">
                        <div class="modal-content modal-chat-content">
                            <div class="modal-header">
                                {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div class="modal-body">
                                <div className='row'>
                                    <div className='delete-text text-center mb-3'>
                                        <h5>Would you like to continue your chat at normal price of ₹10.0/min after this offer ends?</h5>
                                    </div>
                                    <div className='delete-button text-center mb-3'>
                                        <Link to='/wallet'>  <button className='btn btn-yes me-2'>Yes</button></Link>
                                        <Link to='/chat-astrologer'>  <button className='btn btn-no'>No</button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!---talk-modal-end */}
            {/* mobile-view-end */}
        </>
    )
}

export default ChatSecondScreendisha