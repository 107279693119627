import React from 'react'
import { Link } from 'react-router-dom'
import "./Chinese_Horoscope.css"
import ChineseSlider from './ChineseSlider';

const Chinese_Horoscope = () => {
  return (
    <>
      <section className="Horoscope2023 desk-top-view-horoscope">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mt-4">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link >Chinese Zodiac</Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                {/* <div className="card-body"> */}
                <div className="Horoscope_info p-4">
                  <div className="Horoscope_heading mb-2">
                    Find your Chinese Zodiac Sign
                  </div>
                  <p>
                    The Chinese animal zodiac, or shengxiao (/shnng-sshyaoww/ ‘born resembling’), is a repeating cycle of 12 years, with each year being represented by an animal and its reputed attributes. Traditionally these zodiac animals were used to date the years.
                  </p>
                  <p>
                    he Chinese Zodiac, known as Sheng Xiao, is based on a twelve-year cycle, each year in that cycle related to an animal sign. These signs are the rat, ox, tiger, rabbit, dragon, snake, horse, sheep, monkey, rooster, dog and pig. It is calculated according to Chinese lunar calendar. You can use the zodiac calculator to search for your Chinese zodiac animal sign. Select your Gregorian date of birth, and you'll reveal your date of birth according to the lunar calendar, together with your sign.
                  </p>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>

          <ChineseSlider />

          <div className='border-line'></div>
          <div className='below_section pt-4'>
            <h4 className='text-center'>Find your Chinese Animal Sign</h4>
            <p className='text-center my-3'>Choose your date of birth and find out your Chinese zodiac sign</p>
            <div className='d-flex justify-content-center'>
              <span className='birth me-1 pt-1'>Birthdate</span>
              <input type='number' placeholder='Day' className='form-control me-1' />
              <input type='number' placeholder='Month' className='form-control me-1' />
              <input type='number' placeholder='Year' className='form-control' />
            </div>
            <div className='submit-button text-center my-4'>
              <button className='btn btn-submit'>Submit</button>
            </div>
          </div>
        </div>
      </section>


      {/* mobile-view-start */}
      <section className="Horoscope2023 mobile-view-horoscope">
        <div className="container-fluid">
          <div className='row'>
            <div className="col-md-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mt-3" >
                  <li className="breadcrumb-item">
                    <Link to="/">Home {'>'}</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link >Chinese Zodiac</Link>
                  </li>
                </ol>
              </nav>
            </div>

            <div className="col-md-12">
              <div className="card mt-3">
                <div className="Horoscope_info p-2">
                  <div className="Horoscope_heading my-2">
                    Find your Chinese Zodiac Sign
                  </div>
                  <p>
                    The Chinese animal zodiac, or shengxiao (/shnng-sshyaoww/ ‘born resembling’), is a repeating cycle of 12 years, with each year being represented by an animal and its reputed attributes. Traditionally these zodiac animals were used to date the years.
                  </p>
                  <p>
                    he Chinese Zodiac, known as Sheng Xiao, is based on a twelve-year cycle, each year in that cycle related to an animal sign. These signs are the rat, ox, tiger, rabbit, dragon, snake, horse, sheep, monkey, rooster, dog and pig. It is calculated according to Chinese lunar calendar. You can use the zodiac calculator to search for your Chinese zodiac animal sign. Select your Gregorian date of birth, and you'll reveal your date of birth according to the lunar calendar, together with your sign.
                  </p>
                </div>
              </div>
            </div>

            <ChineseSlider />

            <div className='border-line mt-4 mb-3'></div>
            <div className='below_section'>
              <h4 className='text-center'>Find your Chinese Animal Sign</h4>
              <p className='text-center mb-2'>Choose your date of birth and find out your Chinese zodiac sign</p>
              <div className='d-flex justify-content-center'>
                <span className='birth me-1 pt-1'>Birthdate</span>
                <input type='number' placeholder='Day' className='form-control me-1' />
                <input type='number' placeholder='Month' className='form-control me-1' />
                <input type='number' placeholder='Year' className='form-control ' />
              </div>
              <div className='submit-button text-center mt-3 mb-5'>
                <button className='btn btn-submit'>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Chinese_Horoscope