import React from 'react'
import './Notification.css';
import { Link } from "react-router-dom";
const Notification = () => {
    return (
        <>
            <section className='notification'>
                <div className='container'>
                    <div className='row'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mt-3">
                                <li className="breadcrumb-item"><Link to='/'>Home {'>'}</Link></li>
                                <li className="breadcrumb-item"><Link to=''>Profile {'>'} </Link></li>
                                <li className="breadcrumb-item active" aria-current="page"> Notifications</li>
                            </ol>
                        </nav>
                    </div>
                    <div className='row my-4'>
                        <div className='col-md-11 mx-auto'>
                            <div className='card mb-3'>
                                <div className='card-body'>
                                    <div className='heading-holder my-2'>
                                        <h5>Prakaash Ji is a Prediction-man😋</h5>
                                        <p>Prakaash Ji is an award-winning Vedic & Numerology expert. With his ability to accurately predict the negativities well ahead they happen, we wouldn’t have been surprised had he predicted the FB and Insta outage for us😋 </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-11 mx-auto'>
                            <div className='card mb-3'>
                                <div className='card-body'>
                                    <div className='heading-holder my-2'>
                                        <h5>Insta, FB & Whatsapp - All down? 😱</h5>
                                        <p>Fikar not! Come join the live sessions of our astrologers & enjoy free predictions 🥰</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-11 mx-auto'>
                            <div className='card mb-3'>
                                <div className='card-body'>
                                    <div className='heading-holder my-2'>
                                        <h5>Your Weekly Horoscope is here 💖</h5>
                                        <p>Tap to find what this week has for you in terms of love, career, health, finance, and much more. 😍🙏🤞</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-11 mx-auto'>
                            <div className='card mb-5'>
                                <div className='card-body'>
                                    <div className='heading-holder my-2'>
                                        <h5>Lal Kitab, both a mystery & blessing for AmitG🤞</h5>
                                        <p>AmitG is a Lal Kitab specialist with an excellent grip over issues like Marriage, Career, Love & more. Apart from using the Kitab to help people, he is also keen about finding whether the Kitab was actually written by Ravana? As some mythology claim.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Notification