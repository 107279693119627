import React from "react";
import "../Horoscope.css";
import { Link } from "react-router-dom";
import HoroscopeSlider from "../HoroscopeSlider/HoroscopeSlider";
const Horoscope2023 = () => {
  return (
    <>
      <section className="Horoscope2023 desk-top-view-horoscope" >
        <div className="container-fluid">
          <div className='row'>
            <div className='col-xxl-9 col-xl-10 col-lg-11 col-md-11 mx-auto'>
              <div className="row">
                <div className="col-md-12">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mt-4">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        <Link >Yearly Horoscope 2023</Link>
                      </li>
                    </ol>
                  </nav>


                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    {/* <div className="card-body"> */}
                    <div className="Horoscope_info p-4">
                      <div className="Horoscope_heading mb-2">
                        Yearly Horoscope 2023
                      </div>
                      <p>
                        The New Year 2023 is finally here, and we have a lot to
                        look forward to. From setting new goals to reflecting on
                        the old ones, the New year brings us an opportunity to set
                        things right back on track, and to guide you on the entire
                        journey is your Horoscope 2023 by India’s best
                        astrologers.
                      </p>

                      <p>
                        When it comes to life, all of us have some goals to look
                        forward to. These goals could be as simple as starting
                        your own business in 2023 to finding love. As much as we
                        wish to achieve these goals for ourselves, the restraints
                        come from within and our surroundings. Clearly, not
                        everything in life goes according to our plan. And as much
                        as it is a good thing, the same, at times, is frustrating
                        as well. In such cases, we need to reflect on what we are
                        doing wrong or simply what is happening wrong with us. And
                        to help you in doing so is your yearly horoscope 2023.{" "}
                      </p>

                      <p>
                        The horoscopes are to make you aware and take you on the
                        right path in life. Similarly, the 2023 horoscope is about
                        sharing with you all the upcoming opportunities to help
                        you tread in the right direction. As per astrologers, it
                        is not always the things we do that impact our goals or
                        our relationships in life. At times, it is the energies of
                        planets, zodiac signs, etc., that pick on decisions for
                        us. And if one is unaware of those decisions, you are
                        bound to take the wrong steps in life. There are times
                        when we try to achieve a particular thing with all our
                        heart but eventually fail in doing so. It could either be
                        the result of your lack of trying or the impact of planets
                        or zodiac signs on you.
                      </p>
                      <p>
                        For example, you may be trying to attract love in life.
                        The task gets relatively easy when you have a strong Venus
                        in the Kundli. In fact, the task gets easier when Venus is
                        in the house of love in the Kundli with its friendly
                        zodiac signs or planets. On the other hand, attracting
                        love at a point in time will get hard, if Venus is weak in
                        your chart or in conjunction with enemy planets in the
                        Kundli. In such situations, despite trying hard, you may
                        not have for yourself what you want. Does this mean we
                        must stop trying? The yearly horoscope 2023 says No!!
                      </p>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <HoroscopeSlider />

      </section>

      {/* mobile-view-start */}
      <section className="Horoscope2023 mobile-view-horoscope" >
        <div className="container-fluid">
          <div className='row'>
            <div className="col-md-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mt-3">
                  <li className="breadcrumb-item">
                    <Link to="/">Home {'>'}</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link >Yearly Horoscope 2023</Link>
                  </li>
                </ol>
              </nav>
            </div>

            {/* <div className="row"> */}
            <div className="col-md-12">
              <div className="card mt-3">
                {/* <div className="card-body"> */}
                <div className="Horoscope_info p-2">
                  <div className="Horoscope_heading my-2">
                    Yearly Horoscope 2023
                  </div>
                  <p>
                    The New Year 2023 is finally here, and we have a lot to
                    look forward to. From setting new goals to reflecting on
                    the old ones, the New year brings us an opportunity to set
                    things right back on track, and to guide you on the entire
                    journey is your Horoscope 2023 by India’s best
                    astrologers.
                  </p>

                  <p>
                    When it comes to life, all of us have some goals to look
                    forward to. These goals could be as simple as starting
                    your own business in 2023 to finding love. As much as we
                    wish to achieve these goals for ourselves, the restraints
                    come from within and our surroundings. Clearly, not
                    everything in life goes according to our plan. And as much
                    as it is a good thing, the same, at times, is frustrating
                    as well. In such cases, we need to reflect on what we are
                    doing wrong or simply what is happening wrong with us. And
                    to help you in doing so is your yearly horoscope 2023.{" "}
                  </p>

                  <p>
                    The horoscopes are to make you aware and take you on the
                    right path in life. Similarly, the 2023 horoscope is about
                    sharing with you all the upcoming opportunities to help
                    you tread in the right direction. As per astrologers, it
                    is not always the things we do that impact our goals or
                    our relationships in life. At times, it is the energies of
                    planets, zodiac signs, etc., that pick on decisions for
                    us. And if one is unaware of those decisions, you are
                    bound to take the wrong steps in life. There are times
                    when we try to achieve a particular thing with all our
                    heart but eventually fail in doing so. It could either be
                    the result of your lack of trying or the impact of planets
                    or zodiac signs on you.
                  </p>
                  <p>
                    For example, you may be trying to attract love in life.
                    The task gets relatively easy when you have a strong Venus
                    in the Kundli. In fact, the task gets easier when Venus is
                    in the house of love in the Kundli with its friendly
                    zodiac signs or planets. On the other hand, attracting
                    love at a point in time will get hard, if Venus is weak in
                    your chart or in conjunction with enemy planets in the
                    Kundli. In such situations, despite trying hard, you may
                    not have for yourself what you want. Does this mean we
                    must stop trying? The yearly horoscope 2023 says No!!
                  </p>
                </div>
                {/* </div> */}
              </div>
            </div>
            {/* </div> */}

          </div>
        </div>
        <HoroscopeSlider />
      </section>
     
    </>
  );
};

export default Horoscope2023;
