import React from 'react'
import { Link } from 'react-router-dom'
import HoroscopeSlider from '../HoroscopeSlider/HoroscopeSlider'

const Daily_Horoscope = () => {
  return (
    <>
      <section className="Horoscope2023 desk-top-view-horoscope">
        <div className="container-fluid">
          <div className='row'>
            <div className='col-xxl-9 col-xl-10 col-lg-11 col-md-11 mx-auto'>
              <div className="row">
                <div className="col-md-12">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mt-4">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        <Link >Daily Horoscope</Link>
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    {/* <div className="card-body"> */}
                    <div className="Horoscope_info p-4">
                      <div className="Horoscope_heading mb-2">
                        Daily Horoscope
                        <span className='Monthly-txt'>
                          (Know Your Daily Horoscope)</span>
                      </div>
                      <p>
                        Looking for today's horoscope? Or yesterday's? Well, on Astrotalk we have covered everything right from Daily horoscope to we to monthly horoscope. What is the use of one's Daily horoscope you may ask? Well, as per our astrologers, the daily horoscope is one of the ways that you can use to plan out your day. As per astrology, our day to day life is influenced by the movements of planets, as they constantly shift their positions from one sign another. The movement can bring both positive and negative influences in one's life, and you knowing such things in advance helps you in safeguarding yourself from the uncertainties.
                      </p>
                      <p>
                        So reading Today's horoscope is like a healthy habit one can consider adopting as it shall help in changing the outcomes of life. The daily horoscope on Astrotalk is prepared by expert astrologers and thus is very insightful. The daily horoscope not only tells you what's coming for you in the future but also allows you remedies that you can adopt to tackle anything negative. The Today's horoscope is one of the ways that can help you in steering your life in the right direction. So make sure you give it a read.
                      </p>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <HoroscopeSlider />
      </section>

      {/* mobile-view-start */}
      <section className="Horoscope2023 mobile-view-horoscope">
        <div className="container-fluid">
          <div className='row'>
            <div className="col-md-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mt-3">
                  <li className="breadcrumb-item">
                    <Link to="/">Home {'>'}</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link >Daily Horoscope</Link>
                  </li>
                </ol>
              </nav>
            </div>
            {/* <div className="row"> */}
            <div className="col-md-12">
              <div className="card mt-3">
                {/* <div className="card-body"> */}
                <div className="Horoscope_info p-2">
                  <div className="Horoscope_heading my-2">
                    Daily Horoscope
                    <span className='Monthly-txt'>
                      (Know Your Daily Horoscope)</span>
                  </div>
                  <p>
                    Looking for today's horoscope? Or yesterday's? Well, on Astrotalk we have covered everything right from Daily horoscope to we to monthly horoscope. What is the use of one's Daily horoscope you may ask? Well, as per our astrologers, the daily horoscope is one of the ways that you can use to plan out your day. As per astrology, our day to day life is influenced by the movements of planets, as they constantly shift their positions from one sign another. The movement can bring both positive and negative influences in one's life, and you knowing such things in advance helps you in safeguarding yourself from the uncertainties.
                  </p>
                  <p>
                    So reading Today's horoscope is like a healthy habit one can consider adopting as it shall help in changing the outcomes of life. The daily horoscope on Astrotalk is prepared by expert astrologers and thus is very insightful. The daily horoscope not only tells you what's coming for you in the future but also allows you remedies that you can adopt to tackle anything negative. The Today's horoscope is one of the ways that can help you in steering your life in the right direction. So make sure you give it a read.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <HoroscopeSlider />
      </section>
    </>
  )
}

export default Daily_Horoscope