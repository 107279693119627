import React from "react";
import stars from "../image/star.png";
import person_img_1 from "../image/Ellipse 242.png"
import person_img_2 from "../image/Ellipse 258.png"
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/pagination";
import 'swiper/css/autoplay';
import { Pagination, Autoplay } from "swiper/modules"
import commaimg from '../image/inverted.png';
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
library.add(fas);
const ClientReview = () => {
    const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
    return (
        <>
            <section className="Client-Review">
                <div className="container">
                    <div className="row client-review-desktop-view">
                        <div className='heading-holder text-center pt-5'>
                            <h2>Clients<span className='ms-3'>Reviews</span> </h2>
                        </div>
                        <div className="main-heading mt-2">
                            <div class="line me-2"></div>
                            <div class="square"></div>
                            <div class="line ms-2"></div>
                        </div>

                        <div className="text-center question-txt">
                            What Our Customers Say
                        </div>
                        <Swiper
                            // install Swiper modules
                            modules={[Pagination, Autoplay,]}
                            spaceBetween={10}
                            slidesPerView={1}
                            navigation={false}
                            pagination={{ clickable: true }}

                            onSwiper={(swiper) => console.log(swiper)}
                            onSlideChange={() => console.log('slide change')}
                        // autoplay={{
                        //     delay: 2500,
                        //     disableOnInteraction: false,
                        //     loop: true,
                        //     pauseOnMouseEnter: true,
                        // }}
                        >



                            <SwiperSlide>

                                <div className=" ">
                                    <div className="row mt-4">
                                        <div className="col-md-6 col-sm-6">
                                            <div className="section_main-1 mb-5">
                                                <div class="chat-container">
                                                    <div class="chat-bubble">
                                                        <div class="dotted-box">
                                                            <p className="paragraph-with-image">
                                                                An artists dream. Catch the muses and write
                                                                inspiring music, art and poetry. Blends into the
                                                                landscape and receptive to the flow of inspiration
                                                                from the collective
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="triangle"></div>
                                                </div>
                                            </div>
                                            <div className="row main-sec">
                                                <div className=" col-xl-7 col-lg-9 col-md-11 ms-auto">
                                                    <div className="card-end-1 my-4">
                                                        <div className="card-section-1">
                                                            <div className="card">
                                                                <div className="card-body ">
                                                                    <div className="card-3">
                                                                        <div className="text-center card_name">
                                                                            Shubham Kumar
                                                                        </div>
                                                                        <div className="stars-sec text-center">
                                                                            <img src={stars} alt="star_img" className="stars me-1" />
                                                                            <img src={stars} alt="star_img" className="stars me-1" />
                                                                            <img src={stars} alt="star_img" className="stars me-1" />
                                                                            <img src={stars} alt="star_img" className="stars me-1" />
                                                                            <img src={stars} alt="star_img" className="stars me-1" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="person_img_1">
                                                            <img src={person_img_2} alt="person_img" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 ">
                                            <div className="section_main-2 mb-5">
                                                <div class="chat-container-1">
                                                    <div class="chat-bubble-1">
                                                        <div class="dotted-box-1">
                                                            <p className="paragraph-with-image">
                                                                An artists dream. Catch the muses and write
                                                                inspiring music, art and poetry. Blends into the
                                                                landscape and receptive to the flow of inspiration
                                                                from the collective
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="triangle-1"></div>
                                                </div>
                                            </div>
                                            <div className="row main-sec">
                                                <div className="col-xl-7 col-lg-9 col-md-11">
                                                    <div className="card-end-2 my-4">
                                                        <div className="card-section-2">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <div className="card-4">
                                                                        <div className="text-center card_name">
                                                                            Paresh Kumar
                                                                        </div>
                                                                        <div className="stars-sec text-center">
                                                                            <img src={stars} alt="star_img" className="stars me-1" />
                                                                            <img src={stars} alt="star_img" className="stars me-1" />
                                                                            <img src={stars} alt="star_img" className="stars me-1" />
                                                                            <img src={stars} alt="star_img" className="stars me-1" />
                                                                            <img src={stars} alt="star_img" className="stars me-1" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="person_img_2">
                                                            <img src={person_img_1} alt="person_img" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>

                                <div className=" ">

                                    <div className="row mt-4">
                                        <div className="col-md-6 col-sm-6">
                                            <div className="section_main-1 mb-5">
                                                <div class="chat-container">
                                                    <div class="chat-bubble">
                                                        <div class="dotted-box">
                                                            <p className="paragraph-with-image">
                                                                An artists dream. Catch the muses and write
                                                                inspiring music, art and poetry. Blends into the
                                                                landscape and receptive to the flow of inspiration
                                                                from the collective
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="triangle"></div>
                                                </div>
                                            </div>
                                            <div className="row main-sec">
                                                <div className=" col-xl-7 col-lg-9 col-md-11 ms-auto">
                                                    <div className="card-end-1 my-4">
                                                        <div className="card-section-1">
                                                            <div className="card">
                                                                <div className="card-body ">
                                                                    <div className="card-3">
                                                                        <div className="text-center card_name">
                                                                            Shubham Kumar
                                                                        </div>
                                                                        <div className="stars-sec text-center">
                                                                            <img src={stars} alt="star_img" className="stars me-1" />
                                                                            <img src={stars} alt="star_img" className="stars me-1" />
                                                                            <img src={stars} alt="star_img" className="stars me-1" />
                                                                            <img src={stars} alt="star_img" className="stars me-1" />
                                                                            <img src={stars} alt="star_img" className="stars me-1" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="person_img_1">
                                                            <img src={person_img_2} alt="person_img" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 ">
                                            <div className="section_main-2 mb-5">
                                                <div class="chat-container-1">
                                                    <div class="chat-bubble-1">
                                                        <div class="dotted-box-1">
                                                            <p className="paragraph-with-image">
                                                                An artists dream. Catch the muses and write
                                                                inspiring music, art and poetry. Blends into the
                                                                landscape and receptive to the flow of inspiration
                                                                from the collective
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="triangle-1"></div>
                                                </div>
                                            </div>
                                            <div className="row main-sec">
                                                <div className="col-xl-7 col-lg-9 col-md-11">
                                                    <div className="card-end-2 my-4">
                                                        <div className="card-section-2">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <div className="card-4">
                                                                        <div className="text-center card_name">
                                                                            Paresh Kumar
                                                                        </div>
                                                                        <div className="stars-sec text-center">
                                                                            <img src={stars} alt="star_img" className="stars me-1" />
                                                                            <img src={stars} alt="star_img" className="stars me-1" />
                                                                            <img src={stars} alt="star_img" className="stars me-1" />
                                                                            <img src={stars} alt="star_img" className="stars me-1" />
                                                                            <img src={stars} alt="star_img" className="stars me-1" />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>

                                                        <div className="person_img_2">
                                                            <img src={person_img_1} alt="person_img" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </SwiperSlide>

                        </Swiper>
                    </div>
                </div>
            </section>


            {/* mobile-view-app start*/}
            <section className="Client-Review ">
                <div className="container">
                    <div className="row client-review-mobile-view">
                        <div className='heading-holder text-center pt-4'>
                            <h2>Clients<span className='ms-2'>Reviews</span> </h2>
                        </div>
                        <div className="main-heading ">
                            <div class="line me-2"></div>
                            <div class="square"></div>
                            <div class="line ms-2"></div>
                        </div>

                        <div className="text-center question-txt">
                            What Our Customers Say
                        </div>
                        <Swiper
                            // install Swiper modules
                            modules={[Pagination, Autoplay,]}
                            spaceBetween={10}
                            slidesPerView={1}
                            navigation={false}
                            pagination={{ clickable: true }}

                            onSwiper={(swiper) => console.log(swiper)}
                            onSlideChange={() => console.log('slide change')}
                        // autoplay={{
                        //     delay: 2500,
                        //     disableOnInteraction: false,
                        //     loop: true,
                        //     pauseOnMouseEnter: true,
                        // }}
                        >



                            <SwiperSlide>
                                <div className=" ">
                                    <div className="section_main-1 mb-5">
                                        <div class="chat-container">
                                            <div class="chat-bubble">
                                                <div class="dotted-box">
                                                    <div className="d-flex">
                                                        <div className="comma-img">
                                                            <img src={commaimg} className="" />
                                                        </div>
                                                        <div className="message-para">
                                                            <p>
                                                                An artists dream. Catch the muses and write
                                                                inspiring music, art and poetry. Blends into the
                                                                landscape and receptive to the flow of inspiration
                                                                from the collective
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="triangle"></div>
                                        </div>
                                    </div>

                                    <div className="col-sm-8 col-8 ms-auto me-2">
                                        <div className="card-end-1 mb-5">
                                            <div className="card-section-1">
                                                <div className="card">
                                                    <div className="card-body ">
                                                        <div className="card-3">
                                                            <div className="text-center card_name">
                                                                Shubham Kumar
                                                            </div>
                                                            <div className="stars-sec text-center ">
                                                                <img src={stars} alt="star_img" className="stars me-1" />
                                                                <img src={stars} alt="star_img" className="stars me-1" />
                                                                <img src={stars} alt="star_img" className="stars me-1" />
                                                                <img src={stars} alt="star_img" className="stars me-1" />
                                                                <img src={stars} alt="star_img" className="stars me-1" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="person_img_1">
                                                <img src={person_img_2} alt="person_img " />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className=" ">
                                    <div className="section_main-2 mb-5">
                                        <div class="chat-container-1">
                                            <div class="chat-bubble-1">
                                                <div class="dotted-box">
                                                    <div className="d-flex">
                                                        <div className="comma-img">
                                                            <img src={commaimg} className="" />
                                                        </div>
                                                        <div className="message-para">
                                                            <p>
                                                                An artists dream. Catch the muses and write
                                                                inspiring music, art and poetry. Blends into the
                                                                landscape and receptive to the flow of inspiration
                                                                from the collective
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="triangle-1"></div>
                                        </div>
                                    </div>
                                    <div className="col-sm-8 col-8 me-auto">
                                        <div className="card-end-2 mb-5">
                                            <div className="card-section-2">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="card-4">
                                                            <div className="text-center card_name">
                                                                Paresh Kumar
                                                            </div>
                                                            <div className="stars-sec text-center ">
                                                                <img src={stars} alt="star_img" className="stars me-1" />
                                                                <img src={stars} alt="star_img" className="stars me-1" />
                                                                <img src={stars} alt="star_img" className="stars me-1" />
                                                                <img src={stars} alt="star_img" className="stars me-1" />
                                                                <img src={stars} alt="star_img" className="stars me-1" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                            <div className="person_img_2">
                                                <img src={person_img_1} alt="person_img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                            <div className=" ">
                                    <div className="section_main-1 mb-5">
                                        <div class="chat-container">
                                            <div class="chat-bubble">
                                                <div class="dotted-box">
                                                    <div className="d-flex">
                                                        <div className="comma-img">
                                                            <img src={commaimg} className="" />
                                                        </div>
                                                        <div className="message-para">
                                                            <p>
                                                                An artists dream. Catch the muses and write
                                                                inspiring music, art and poetry. Blends into the
                                                                landscape and receptive to the flow of inspiration
                                                                from the collective
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="triangle"></div>
                                        </div>
                                    </div>

                                    <div className="col-sm-8 col-8 ms-auto me-2">
                                        <div className="card-end-1 mb-5">
                                            <div className="card-section-1">
                                                <div className="card">
                                                    <div className="card-body ">
                                                        <div className="card-3">
                                                            <div className="text-center card_name">
                                                                Shubham Kumar
                                                            </div>
                                                            <div className="stars-sec text-center ">
                                                                <img src={stars} alt="star_img" className="stars me-1" />
                                                                <img src={stars} alt="star_img" className="stars me-1" />
                                                                <img src={stars} alt="star_img" className="stars me-1" />
                                                                <img src={stars} alt="star_img" className="stars me-1" />
                                                                <img src={stars} alt="star_img" className="stars me-1" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="person_img_1">
                                                <img src={person_img_2} alt="person_img " />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </section>

            <section className="green-top-icon">
                <div className="container">
                    <div className="row">
                      <div className="top-icon text-end">
                      <FontAwesomeIcon icon="fa-solid fa-chevron-up" className="fa-icon" onClick={scrollToTop}/>
                      </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ClientReview;