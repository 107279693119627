import React from 'react'
import rightImg from "./Images/tarot-card.png";
import "./ChatSection.css";
import send_icon from "./Images/Icon map-location-arrow.png";
import smile from "./Images/smile.png";
import man_img from "./Images/man_img.png";
import listimg from '../Image/format-list-bulleted.png';
import boldimg from '../Image/metro-bold.png';
import italicimg from '../Image/italic.png';
import attactmentimg from '../Image/attachment.png';
import onlineimg from '../Image/online.png';
import { Link } from "react-router-dom";
const ChatHistoryProfile = () => {
    return (
        <>
            <section className="OurExpertChatSection pb-5 desk-top-view-chatsection">
                <div className="container ">
                    <div className='row'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mt-3">
                                <li className="breadcrumb-item"><Link to='/'>Home {'>'}</Link></li>
                                <li className="breadcrumb-item"><Link to=''>Profile {'>'}</Link></li>
                                <li className="breadcrumb-item active" aria-current="page"> Chat History</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="row ">
                        <div className="col-lg-9 col-md-12 mx-auto">
                            <div className="row">
                                <div className="card mt-4 p-0">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="heading-astroler d-flex">
                                                <div className="heading-img">
                                                    <img src={man_img} className="man_img_1" />
                                                    <div className="online-icon">
                                                        <img src={onlineimg} className="online-img" />
                                                    </div>
                                                </div>
                                                <div>
                                                    <span className="name_txt p-3">
                                                        Paresh Kumar (Astrologers)
                                                    </span>
                                                    <br />
                                                    <span className="active-txt p-3 ms-1">Active</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-4">
                                        <div className="row">
                                            <div className="col-xl-4 col-lg-2 col-md-3 "></div>
                                            <div className="col-xl-8 col-lg-10 col-md-9 ">
                                                <div className="row message_icon-section">
                                                    <div className="date-holder">
                                                        <span >23 Jul, 2023 20:40</span>
                                                    </div>
                                                    <div className="col-xl-11 col-lg-10 col-md-10 col-sm-11 col-11 chat_1 mx-auto">
                                                        <p>  Hi Paresh Kumar, <br />
                                                            Below are my details: <br />
                                                            Name: Shubham
                                                            <br />
                                                            Gender: Male <br />
                                                            DOB: 27-April-1997
                                                            <br />
                                                            TOB: 1:30 PM <br />
                                                            POB: Sultanpur, Uttar Pradesh, Sultanpur <br />
                                                            Marital Status: undefined</p>
                                                    </div>
                                                    <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1">
                                                        <img src={man_img} className="man_img" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-5">
                                            <div className="col-xl-4 col-lg-2 col-md-2 "></div>
                                            <div className="col-xl-8 col-lg-10 col-md-10 ">
                                                <div className="row message_icon-section">
                                                    <div className="date-holder">
                                                        <span >23 Jul, 2023 20:40</span>
                                                    </div>
                                                    <div className="col-xl-11 col-lg-10 col-md-10 col-sm-11 col-11 chat-2 mx-auto">
                                                        <p className="chat-2-txt my-2">
                                                            This is an automated message to confirm that chat
                                                            has started
                                                        </p>
                                                    </div>
                                                    <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1">
                                                        <img src={man_img} className="man_img" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="row mt-5">
                                            <div className="col-lg-10 col-md-11 col-sm-11">
                                                <div className="row message_icon-section">
                                                    <div className="col-lg-1  col-md-1 col-sm-1 col-1">
                                                        <img src={man_img} className="man_img_2" />
                                                    </div>

                                                    <div className="col-xl-11 col-lg-10 col-md-10 col-sm-11 col-11 mx-auto">
                                                        <div className="date-holder text-end">
                                                            <span >23 Jul, 2023 20:40</span>
                                                        </div>
                                                        <div className="chat-3 p-1">
                                                            <p className="p-2">
                                                                Welcome to Astrotalk Consultant will take a minute
                                                                to analyse your details. You may ask  your
                                                                question in a meanwhile
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-md-1 col-sm-1"></div>
                                        </div>

                                        <div className="row mt-5">
                                            <div className="col-lg-10 col-md-8 col-sm-10 col-11">
                                                <div className="row message_icon-section">
                                                    <div className="col-lg-1 col-md-2 col-sm-1 col-1">
                                                        <img src={man_img} className="man_img_2" />
                                                    </div>

                                                    <div className="col-lg-4 col-md-6 col-sm-4 col-4 ">
                                                        <div className="date-holder text-end">
                                                            <span >23 Jul, 2023 20:40</span>
                                                        </div>
                                                        <div className="chat-3 ms-3">
                                                            <p className="p-2">
                                                                Hello
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-md-4 col-sm-2 col-1"></div>
                                        </div>

                                        <div className="row mt-5">
                                            <div className="col-xl-4 col-lg-4 col-md-2 col-sm-2 col-2"></div>
                                            <div className="col-xl-8 col-lg-8 col-md-10 col-sm-10 col-10">
                                                <div className="row message_icon-section">
                                                <div className="date-holder">
                                                        <span >23 Jul, 2023 20:40</span>
                                                    </div>
                                                    <div className="col-xl-11 col-lg-10 col-md-10 col-sm-11 col-11 chat_1 mx-auto">
                                                        <p className=" my-2">
                                                            This is an automated message to confirm that chat
                                                            has started
                                                        </p>
                                                    </div>
                                                    <div className="col-lg-1 col-md-1 col-sm-1 col-1">
                                                        <img src={man_img} className="man_img" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-5">
                                            <div className="col-lg-10 col-md-11 col-sm-11 ">
                                                <div className="row message_icon-section">
                                                <div className="col-lg-1  col-md-1 col-sm-1 col-1">
                                                        <img src={man_img} className="man_img_2" />
                                                    </div>

                                                    <div className="col-xl-11 col-lg-10 col-md-10 col-sm-11 col-11 mx-auto">
                                                        <div className="date-holder text-end">
                                                            <span >23 Jul, 2023 20:40</span>
                                                        </div>
                                                        <div className="chat-3 p-1">
                                                            <p className="p-2">
                                                                Welcome to Astrotalk Consultant will take a minute
                                                                to analyse your details. You may ask  your
                                                                question in a meanwhile
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-md-1 col-sm-1"></div>
                                        </div>

                                    </div>
                                    <div className="row p-4">
                                        <div className="search-bar input-group mt-3">
                                            <input
                                                type="text"
                                                className="form-control input-field"
                                                placeholder="Type Here"
                                            />
                                            <div className="search-icons">
                                                <span>
                                                    <img src={smile} className="smile-icon ms-2" />
                                                </span>

                                                <span>
                                                    <img src={listimg} className="attachment-icon" />

                                                </span>
                                                <span>
                                                    <img src={boldimg} className="bullet_list-icon" />
                                                </span>
                                                <span>
                                                    <img src={italicimg} className="bullet_list-icon" />

                                                </span>
                                                <span>
                                                    <img src={attactmentimg} className="attachment-icon" />
                                                </span>
                                                <span>
                                                    <Link to='/astrologer-profile'> <button className="send-btn"> Send  <img src={send_icon} className="send-btn-icon" /> </button></Link>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            {/* mobile-view-start */}
            <section className="OurExpertChatSection pb-5 mobile-view-chatsection">
                <div className="container-fluid">
                    <div className='row'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mt-3">
                                <li className="breadcrumb-item"><Link to='/'>Home {'>'}</Link></li>
                                <li className="breadcrumb-item"><Link to=''>Profile {'>'}</Link></li>
                                <li className="breadcrumb-item active" aria-current="page"> Chat History</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="row mt-4">
                        <div className="col-8 p-0">
                            <div className="heading-astroler d-flex justify-content-center">
                                <div className="heading-img">
                                    <img src={man_img} className="man_img_1 ms-2 mt-3" />
                                    <div className="online-icon">
                                        <img src={onlineimg} className="online-img" />
                                    </div>
                                </div>
                                <div className="ps-2">
                                    <p className="name_txt mt-2 "> Paresh Kumar (Astrologers)</p>
                                    <p className="active-txt">Active</p>
                                </div>
                            </div>
                        </div>


                        {/* <div className="row"> */}
                        <div className="card-body">
                            <div className="row">
                                <div className="col-xl-4 col-lg-2 col-md-3 col-sm-3 col-3"></div>
                                <div className="col-xl-8 col-lg-10 col-md-9 col-sm-9 col-9">
                                    <div className="row message_icon-section">
                                        <div className="date-holder">
                                            <span >23 Jul, 2023 20:40</span>
                                        </div>
                                        <div className="col-xl-11 col-lg-11 col-md-11 col-sm-11 col-11 chat_1">
                                            <p>  Hi Paresh Kumar, <br />
                                                Below are my details: <br />
                                                Name: Shubham
                                                <br />
                                                Gender: Male <br />
                                                DOB: 27-April-1997
                                                <br />
                                                TOB: 1:30 PM <br />
                                                POB: Sultanpur, Uttar Pradesh, Sultanpur <br />
                                                Marital Status: undefined</p>
                                        </div>
                                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1">
                                            <img src={man_img} className="man_img" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-xl-4 col-lg-2 col-md-2 col-sm-3 col-1"></div>
                                <div className="col-xl-8 col-lg-10 col-md-10 col-sm-9 col-11 ">
                                    <div className="row message_icon-section">
                                        <div className="date-holder">
                                            <span >23 Jul, 2023 20:40</span>
                                        </div>
                                        <div className=" col-lg-11 col-md-11 col-sm-11 col-11 chat-2">
                                            <p className="chat-2-txt my-2">
                                                This is an automated message to confirm that chat
                                                has started
                                            </p>
                                        </div>
                                        <div className="col-lg-1 col-md-1 col-sm-1 col-1">
                                            <img src={man_img} className="man_img" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-lg-10 col-md-11 col-sm-11">
                                    <div className="row message_icon-section">
                                        <div className="col-lg-1  col-md-1 col-sm-1 col-1">
                                            <img src={man_img} className="man_img_2" />
                                        </div>

                                        <div className="col-lg-11 col-md-10 col-sm-11 col-11 ">
                                            <div className="date-holder text-end">
                                                <span >23 Jul, 2023 20:40</span>
                                            </div>
                                            <div className="chat-3">
                                                <p className="p-2">
                                                    Welcome to Astrotalk Consultant will take a minute
                                                    to analyse your details. You may ask  your
                                                    question in a meanwhile
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-1 col-sm-1"></div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-lg-10 col-md-8 col-sm-10 col-11">
                                    <div className="row message_icon-section">
                                        <div className="col-lg-1 col-md-2 col-sm-1 col-1">
                                            <img src={man_img} className="man_img_2" />
                                        </div>

                                        <div className="col-lg-3 col-md-3 col-sm-4 col-4">
                                            <div className="date-holder text-end">
                                                <span >23 Jul, 2023 20:40</span>
                                            </div>
                                            <div className="chat-3">
                                                <p className="p-2">
                                                    Hello
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4 col-sm-2 col-1"></div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-xl-4 col-lg-4 col-md-2 col-sm-2 col-2"></div>
                                <div className="col-xl-8 col-lg-8 col-md-10 col-sm-10 col-10">
                                    <div className="row message_icon-section">

                                        <div className="col-lg-11 col-md-11 col-sm-11 col-11 chat_1">
                                            <p className=" my-2">
                                                This is an automated message to confirm that chat
                                                has started
                                            </p>
                                        </div>
                                        <div className="col-lg-1 col-md-1 col-sm-1 col-1">
                                            <img src={man_img} className="man_img" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-lg-10 col-md-8 ">
                                    <div className="row message_icon-section">
                                        <div className="col-lg-1 col-md-2 col-sm-1 col-1">
                                            <img src={man_img} className="man_img_2" />
                                        </div>

                                        <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                                            <div className="date-holder text-end">
                                                <span >23 Jul, 2023 20:40</span>
                                            </div>
                                            <div className="chat-3">
                                                <p className="p-2">
                                                    Kese ho aap
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4"></div>
                            </div>
                        </div>
                        {/* <div className="row "> */}
                        <div className="col-12">
                            <div className="search-bar input-group mt-3">
                                <input
                                    type="text"
                                    className="form-control input-field"
                                    placeholder="Type Here"
                                />
                                <div className="search-icons">
                                    <span>
                                        <img src={smile} className="smile-icon me-2" />
                                    </span>

                                    <span>
                                        <img src={listimg} className="attachment-icon  me-2" />

                                    </span>
                                    <span>
                                        <img src={boldimg} className="bullet_list-icon  me-2" />
                                    </span>
                                    <span>
                                        <img src={italicimg} className="bullet_list-icon me-2" />

                                    </span>
                                    <span>
                                        <img src={attactmentimg} className="attachment-icon  me-2" />
                                    </span>
                                    <span>
                                        <Link to='/astrologer-profile'> <button className="send-btn"> Send  <img src={send_icon} className="send-btn-icon" /> </button></Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                        {/* </div> */}
                        {/* </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}

export default ChatHistoryProfile