import React from 'react'
import './wallettranctionprofile.css';
import deleteimg from './image/delete-sweep.png';
import { Link } from "react-router-dom";
const WalletTranctionProfile = () => {
    return (
        <>
            <section className='wallet desk-top-view-wallet'>
                <div className='container'>
                    <div className='row'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mt-3">
                                <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                                <li className="breadcrumb-item"><Link to='/wallet'>Wallet</Link></li>
                                <li className="breadcrumb-item active" aria-current="page"> Wallet Transactions</li>
                            </ol>
                        </nav>
                    </div>
                    <div className='row'>
                        <div className='d-flex justify-content-end mt-4 mb-5'>
                            <div className='amount-details pt-2'>
                                <p>Available Transactions : ₹ 100</p>
                            </div>
                            <div className='button-recharge ms-3'>
                                <Link to='/wallet'>   <button className='btn btn-recharge'>Recharge</button></Link>
                            </div>
                        </div>
                    </div>
                    <div className='row mb-5'>
                        <div className="table-responsive">
                            <table className="table   wallet-table">
                                <thead>
                                    <tr >
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>Transaction Amount</th>
                                        <th>Delete</th>

                                    </tr>
                                </thead>
                                <tbody>

                                    <tr >
                                        <td>Aug 15,2023</td>
                                        <td>03:45 PM</td>
                                        <td>0</td>
                                        <td>
                                            <img src={deleteimg} className='delete-img' type="button" data-bs-toggle="modal"
                                                data-bs-target="#deleteModal1" />
                                        </td>

                                    </tr>
                                    <tr >
                                        <td>Aug 15,2023</td>
                                        <td>03:45 PM</td>
                                        <td>500</td>
                                        <td>
                                            <img src={deleteimg} className='delete-img' />
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>



            {/* <!--delete Modal start--> */}
            <section class="modal-delete">
                <div class="modal fade" id="deleteModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div class="modal-body">
                                <div className='delete-text text-center'>
                                    <h5>Are you sure you want to permanently delete this history?</h5>
                                </div>
                                <div className='delete-button text-center my-3'>
                                    <button className='btn btn-cancle me-2'>Cancel</button>
                                    <button className='btn btn-ok'>Ok</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            {/* <!---delete-modal-end */}

            {/* mobile-view-start */}
            <section className='wallet mobile-top-view-wallet'>
                <div className='container'>
                    <div className='row'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mt-3">
                                <li className="breadcrumb-item"><Link to='/'>Home {'>'}</Link></li>
                                <li className="breadcrumb-item"><Link to='/wallet'>Wallet {'>'}</Link></li>
                                <li className="breadcrumb-item active" aria-current="page"> Wallet Transactions</li>
                            </ol>
                        </nav>
                    </div>
                    <div className='row my-3' >
                        <div className='col-8 '>
                            <div className='amount-details pt-2'>
                                <p>Available Transactions : ₹ 100</p>
                            </div>
                        </div>
                        <div className='col-4'>
                            <div className='button-recharge text-end'>
                                <Link to='/wallet'>   <button className='btn btn-recharge'>Recharge</button></Link>
                            </div>
                        </div>
                    </div>
                    <div className='row mb-5'>
                        <div className="table-responsive">
                            <table className="table   wallet-table">
                                <thead>
                                    <tr >
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th className='transaction-heading'>Transaction Amount</th>
                                        <th>Delete</th>

                                    </tr>
                                </thead>
                                <tbody>

                                    <tr >
                                        <td>Aug 15,2023</td>
                                        <td>03:45 PM</td>
                                        <td>0</td>
                                        <td>
                                            <img src={deleteimg} className='delete-img' type="button" data-bs-toggle="modal"
                                                data-bs-target="#deleteModal1" />
                                        </td>

                                    </tr>
                                    <tr >
                                        <td>Aug 15,2023</td>
                                        <td>03:45 PM</td>
                                        <td>500</td>
                                        <td>
                                            <img src={deleteimg} className='delete-img' />
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default WalletTranctionProfile