import React from 'react'
import '../wallet.css'
const CoupenCard = () => {
    return (
        <>

            <div class="coupon-div">
                <div class="row">
                    <div class="col-6">
                        <input class="input-coupon" placeholder="" value="ABCDEEE" />
                    </div>
                    <div class="col-6">
                        <div className='apply-button text-end'>
                            <button className='btn btn-apply'>APPLY</button>
                        </div>
                    </div>
                </div>
                <h6 class="flat-p">Flat Rs 100 OFF</h6>
                <p class="plat-p-subtext">Lorem ipsum dolor sit amet</p>
            </div>

        </>
    )
}

export default CoupenCard