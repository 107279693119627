import React from 'react'
import './CallModalAstro.css'
import CallLotteyastro from './call-lottey/CallLotteyastro';
import soundimg from '../Image/laoud.png';
import recordimg from '../Image/audio.png';
import circleimg from '../Image/circle.png';
import callingimg from '../Image/calling-icon.png'
const CallModalAstro = () => {
    return (
        <>
            <section className='call-modal-astro'>
                <div class="modal fade" id="callModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-fullscreen">
                        <div class="modal-content">
                            <div class="modal-header">
                                {/* <h1 class="modal-title fs-5" id="exampleModalLabel">Modal title</h1> */}
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div className='call-identity'>
                                    <CallLotteyastro />
                                    <div className='name-holder text-center my-3'>
                                        <p>Paresh</p>
                                    </div>
                                    <div className='time-holder text-center my-3'>
                                        <p>10:14</p>
                                    </div>
                                    <div className='recording-icon text-center my-3'>
                                        <div className='img-holder'>
                                            <img src={soundimg} className='img-record me-4'/>
                                            <img src={recordimg} className='img-record me-4'/>
                                            <img src={circleimg} className='img-record'/>
                                        </div>
                                    </div>
                                    <div className='recording-icon text-center mt-5'>
                                        <div className='img-holder '>
                                            
                                            <img src={callingimg} className='img-calling'/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CallModalAstro