import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import '../home.css'

const Faq = () => {
  return (
    <>


      <section className="Faq desk-top-vew-faq">
        <div className="container-fluid">
          <div className="row">

            <div className="col-xxl-12 col-xl-11 col-lg-11 col-md-11  mx-auto bg-black-img ">
              <div className='heading-holder text-center my-4'>
                <h2>FAQ'S<span className='ms-2'>About Astrology</span> </h2>
              </div>
              <div className="main-heading mt-1">
                <div className="line me-2"></div>
                <div className="square"></div>
                <div className="line ms-2"></div>
              </div>

              <div className="question-txt">
                Why Is Astrology So Accurate?
              </div>
              <div className="lorem-txt mt-3">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren.
              </div>

              <div className="card-section ">
                <div className="row">
                  <Swiper

                    spaceBetween={10}
                    modules={[Navigation]}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    // navigation={{ clickable: true }}
                    // modules={[Autoplay, Navigation]}
                    navigation={true}
                    breakpoints={{
                      640: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                      },
                      768: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                      },
                      1024: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                      },
                    }}
                  >
                    <SwiperSlide  >
                      <div className='row justify-content-center padd-set'>
                        <div className='col-lg-6 '>
                          <div className="card-main-1">
                            <div className="card">
                              <div className="card-body card-1">
                                <div className="card-head">
                                  <p>Is Astrology Prediction True?</p>
                                </div>
                                <div className="card-txt">
                                  Lorem ipsum dolor sit amet, consetetur
                                  sadipscing elitr, sed diam nonumy eirmod
                                  tempor invidunt ut labore et dolore magna
                                  aliquyam erat, sed diam voluptua. At vero eos
                                  et accusam et justo duo dolores et ea rebum.
                                  Stet clita kasd
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-6 mobile-hide-card'>
                          <div className="card-main-1">
                            <div className="card">
                              <div className="card-body card-1">
                                <div className="card-head">
                                  <p>Is Astrology Prediction True?</p>
                                </div>
                                <div className="card-txt">
                                  Lorem ipsum dolor sit amet, consetetur
                                  sadipscing elitr, sed diam nonumy eirmod
                                  tempor invidunt ut labore et dolore magna
                                  aliquyam erat, sed diam voluptua. At vero eos
                                  et accusam et justo duo dolores et ea rebum.
                                  Stet clita kasd
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>



                    </SwiperSlide>

                    <SwiperSlide  >

                      <div className='row justify-content-center padd-set'>
                        <div className='col-lg-6 '>
                          <div className="card-main-1">
                            <div className="card">
                              <div className="card-body card-1">
                                <div className="card-head">
                                  <p>Is Astrology Prediction True?</p>
                                </div>
                                <div className="card-txt">
                                  Lorem ipsum dolor sit amet, consetetur
                                  sadipscing elitr, sed diam nonumy eirmod
                                  tempor invidunt ut labore et dolore magna
                                  aliquyam erat, sed diam voluptua. At vero eos
                                  et accusam et justo duo dolores et ea rebum.
                                  Stet clita kasd
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-6 mobile-hide-card'>
                          <div className="card-main-1">
                            <div className="card">
                              <div className="card-body card-1">
                                <div className="card-head">
                                  <p>Is Astrology Prediction True?</p>
                                </div>
                                <div className="card-txt">
                                  Lorem ipsum dolor sit amet, consetetur
                                  sadipscing elitr, sed diam nonumy eirmod
                                  tempor invidunt ut labore et dolore magna
                                  aliquyam erat, sed diam voluptua. At vero eos
                                  et accusam et justo duo dolores et ea rebum.
                                  Stet clita kasd
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </SwiperSlide>

                    <SwiperSlide >

                      <div className='row justify-content-center padd-set'>
                        <div className='col-lg-6 '>
                          <div className="card-main-1">
                            <div className="card">
                              <div className="card-body card-1">
                                <div className="card-head">
                                  <p>Is Astrology Prediction True?</p>
                                </div>
                                <div className="card-txt">
                                  Lorem ipsum dolor sit amet, consetetur
                                  sadipscing elitr, sed diam nonumy eirmod
                                  tempor invidunt ut labore et dolore magna
                                  aliquyam erat, sed diam voluptua. At vero eos
                                  et accusam et justo duo dolores et ea rebum.
                                  Stet clita kasd
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-6 mobile-hide-card'>
                          <div className="card-main-1">
                            <div className="card">
                              <div className="card-body card-1">
                                <div className="card-head">
                                  <p>Is Astrology Prediction True?</p>
                                </div>
                                <div className="card-txt">
                                  Lorem ipsum dolor sit amet, consetetur
                                  sadipscing elitr, sed diam nonumy eirmod
                                  tempor invidunt ut labore et dolore magna
                                  aliquyam erat, sed diam voluptua. At vero eos
                                  et accusam et justo duo dolores et ea rebum.
                                  Stet clita kasd
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </SwiperSlide>

                  </Swiper>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>


      <section className="Faq mobile-view-faq">
        <div className="container-fluid">
          <div className="row">

            <div className=" col-md-11  mx-auto bg-black-img ">
              <div className='heading-holder text-center '>
                <h2>FAQ'S<span className='ms-2'>About Astrology</span> </h2>
              </div>
              <div className="main-heading">
                <div className="line me-2"></div>
                <div className="square"></div>
                <div className="line ms-2"></div>
              </div>

              <div className="question-txt">
                Why Is Astrology So Accurate?
              </div>
              <div className="lorem-txt my-3">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum.
              </div>


              <div className="row">
                <Swiper

                  spaceBetween={10}
                  modules={[Navigation]}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  // navigation={{ clickable: true }}
                  // modules={[Autoplay, Navigation]}
                  navigation={true}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    1024: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                  }}
                >
                  <SwiperSlide  >


                    <div className="card">
                      <div className="card-body card-1">
                        <div className="card-head">
                          <p>Is Astrology Prediction True?</p>
                        </div>
                        <div className="card-txt">
                          Lorem ipsum dolor sit amet, consetetur
                          sadipscing elitr, sed diam nonumy eirmod
                          tempor invidunt ut labore et dolore magna
                          aliquyam erat, sed diam voluptua.
                        </div>
                      </div>
                    </div>

                  </SwiperSlide>

                  <SwiperSlide>

                    <div className="card">
                      <div className="card-body card-1">
                        <div className="card-head">
                          <p>Is Astrology Prediction True?</p>
                        </div>
                        <div className="card-txt ">
                          Lorem ipsum dolor sit amet, consetetur
                          sadipscing elitr, sed diam nonumy eirmod
                          tempor invidunt ut labore et dolore magna
                          aliquyam erat, sed diam voluptua.
                        </div>
                      </div>
                    </div>

                  </SwiperSlide>

                  <SwiperSlide >

                    <div className="card">
                      <div className="card-body card-1">
                        <div className="card-head">
                          <p>Is Astrology Prediction True?</p>
                        </div>
                        <div className="card-txt ">
                          Lorem ipsum dolor sit amet, consetetur
                          sadipscing elitr, sed diam nonumy eirmod
                          tempor invidunt ut labore et dolore magna
                          aliquyam erat, sed diam voluptua.
                        </div>
                      </div>
                    </div>

                  </SwiperSlide>
                </Swiper>
              </div>
            </div>


          </div>
        </div>
      </section>
    </>
  );
};

export default Faq;
