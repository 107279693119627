import React from 'react'
import { Link } from "react-router-dom";
import arrowimg from '../image/arrow-icon.png';
const JuneFestival = () => {
    return (
        <>
            <section className='festival-calender mobile-view-festival-calender'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mt-3">
                                    <li className="breadcrumb-item"><Link to='/'>Home {'>'}</Link></li>
                                    <li className="breadcrumb-item"><Link to='/festival-calender'> Festival Calendar {'>'}</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page"> June Festival 2023</li>
                                </ol>
                            </nav>
                        </div>
                        <div className='col-sm-12 '>
                            <div className='text-holder text-center mt-3 mb-5'>
                                <h6>Festivals & Holidays Calendar</h6>
                            </div>

                            <div className='card mb-3'>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-12 '>
                                            <div className='button-calender1  text-center mb-5'>
                                                <div className='btn btn-calender'>JUNE FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='text-holder '>
                                                <h5>Day and Date</h5>
                                                <p>Monday, June 20</p>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='text-holder '>
                                                <h5>Festivals </h5>
                                                <p>Jagganath Rath Yatra</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default JuneFestival