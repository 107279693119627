import React, { useEffect } from 'react'
import './Festival.css';
import { Link } from "react-router-dom";
import arrowimg from './image/arrow-icon.png';
import topimg from './image/arrow-up-circle.png'
const Festival = () => {
    const scrollToTop = () => window.scrollTo({ top: 200, behavior: "smooth" });

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        console.log(section);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <>
            <section className='festival-calender desktop-view-festival-calender'>
                <div className='container'>
                    <div className='row'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mt-3">
                                <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page"> Festival Calendar</li>
                            </ol>
                        </nav>
                    </div>
                    <div className='row'>
                        <div className='col-md-11 mx-auto'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='heading-holder my-2'>
                                        <h5>Indian Festivals & Holidays</h5>
                                        <p>India is known for celebrating a wide range of festivals the entire year. People have their roots in all these cultural festivals
                                            connected to different religions, deities, beliefs, and old traditions. While some even hold mythological, historical, and social significance, all of them are celebrated with joy and happiness. Moreover, to seek blessings from the deities associated with some of these festivals, people observe fast, while for some they perform charity and prayers. However it may be, each Indian festival is considered an auspicious occasion.
                                            And, to mark all the festivals and occasions in one place, there is the Indian calendar 2023. </p>
                                        <p>To make it easy and accessible for you, Astrotalk has prepared a list of occasions, festivals, and celebrations for 2023, where not only will you find the upcoming holidays and festivals, but also different types of calendars,
                                            prepared precisely on the basis of the planetary changes, tithi, Purnima, Amavasya, and much more.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row  mt-5'>
                        <div className='col-xl-3 col-lg-4 col-md-6'>
                            <div className='button-calender text-center mb-4'
                            >
                                <div className='btn btn-calender'>JANUARY FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                            </div>
                        </div>
                        <div className='col-xl-3 col-lg-4 col-md-6'>
                            <div className='button-calender text-center mb-4' onClick={() => scrollToSection('section1')}>
                                <div className='btn btn-calender'>FEBRUARY FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                            </div>
                        </div>
                        <div className='col-xl-3 col-lg-4 col-md-6'>
                            <div className='button-calender text-center mb-4' onClick={() => scrollToSection('section2')}>
                                <div className='btn btn-calender'>MARCH FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                            </div>
                        </div>
                        <div className='col-xl-3 col-lg-4 col-md-6'>
                            <div className='button-calender text-center mb-4' onClick={() => scrollToSection('section3')}>
                                <div className='btn btn-calender'>APRIL FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                            </div>
                        </div>
                        <div className='col-xl-3 col-lg-4 col-md-6'>
                            <div className='button-calender text-center mb-4' onClick={() => scrollToSection('section4')}>
                                <div className='btn btn-calender'>MAY FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                            </div>
                        </div>
                        {/* </div>
                    <div className='row festival-row'> */}
                        <div className='col-xl-3 col-lg-4 col-md-6'>
                            <div className='button-calender text-center mb-4' onClick={() => scrollToSection('section5')}>
                                <div className='btn btn-calender'>JUNE FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                            </div>
                        </div>
                        <div className='col-xl-3 col-lg-4 col-md-6'>
                            <div className='button-calender text-center mb-4' onClick={() => scrollToSection('section6')}>
                                <div className='btn btn-calender'>JULY FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                            </div>
                        </div>
                        <div className='col-xl-3 col-lg-4 col-md-6' >
                            <div className='button-calender text-center mb-4' onClick={() => scrollToSection('section7')}>
                                <div className='btn btn-calender'>AUGUST FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                            </div>
                        </div>
                        <div className='col-xl-3 col-lg-4 col-md-6'>
                            <div className='button-calender text-center mb-4' onClick={() => scrollToSection('section8')}>
                                <div className='btn btn-calender'>SEPTEMBER FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                            </div>
                        </div>
                        <div className='col-xl-3 col-lg-4 col-md-6'>
                            <div className='button-calender  text-center mb-4' onClick={() => scrollToSection('section9')}>
                                <div className='btn btn-calender'>OCTOBER FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                            </div>
                        </div>
                        {/* </div>
                    <div className='row justify-content-center'> */}
                        <div className='col-xl-3 col-lg-4 col-md-6 '>
                            <div className='button-calender text-center mb-5' onClick={() => scrollToSection('section10')}>
                                <div className='btn btn-calender '>NOVEMBER FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                            </div>
                        </div>
                        <div className='col-xl-3 col-lg-4 col-md-6'>
                            <div className='button-calender text-center mb-5' onClick={() => scrollToSection('section11')}>
                                <div className='btn btn-calender'>DECEMBER FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div> <img src={topimg} alt="top-img" className="top_image" onClick={scrollToTop} type="button" />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-10 mx-auto'>
                            <div className='text-holder text-center mb-5'>
                                <h6>Festivals & Holidays Calendar</h6>
                            </div>

                            <div className='card mb-3' id='section1' >
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-lg-4 col-md-12'>
                                            <div className='button-calender1  text-center'>
                                                <div className='btn btn-calender'>JANUARY FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                            <div className='text-holder '>
                                                <h5>Day and Date</h5>
                                                <p>Monday, January 10</p>
                                                <p>Friday, January 14</p>
                                                <p>Saturday, January 15</p>
                                                <p>Friday, January 21</p>
                                                <p>Wednesday, January 26</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                            <div className='text-holder '>
                                                <h5>Festivals </h5>
                                                <p>Sakat Chauth</p>
                                                <p>Lohri</p>
                                                <p>Makar Sankranti, Pongal, Uttrayan</p>
                                                <p>Mauni Amavasya</p>
                                                <p>Republic Day</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card mb-3 ' id='section2'>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-lg-4 col-md-12'>
                                            <div className='button-calender-december  text-center'>
                                                <div className='btn btn-calender'>FEBRUARY FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                            <div className='text-holder '>
                                                <h5>Day and Date</h5>
                                                <p>Monday, February 14</p>
                                                <p>Friday, February 18</p>

                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                            <div className='text-holder '>
                                                <h5>Festivals </h5>
                                                <p>Valentine’s Day</p>
                                                <p>Mahashivratri</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card mb-3' id='section3'>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-lg-4 col-md-12'>
                                            <div className='button-calender1  text-center'>
                                                <div className='btn btn-calender'>MARCH FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6 '>
                                            <div className='text-holder '>
                                                <h5>Day and Date</h5>
                                                <p>Monday, March 7</p>
                                                <p>Wednesday, March 9</p>
                                                <p>Monday, March 21</p>
                                                <p>Tuesday, March 22</p>
                                                <p>Tuesday, March 22</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6 '>
                                            <div className='text-holder '>
                                                <h5>Festivals </h5>
                                                <p>Holika Dahan (Choti Holi)</p>
                                                <p>Bhai Dooj</p>
                                                <p>Parsi New Year</p>
                                                <p>Ugadi, Gudi Padva,</p>
                                                <p>Chaitra Navratri— Pratipada (Day 1)</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card mb-3' id='section4'>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-lg-4 col-md-12'>
                                            <div className='button-calender1  text-center'>
                                                <div className='btn btn-calender'>APRIL FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                            <div className='text-holder '>
                                                <h5>Day and Date</h5>
                                                <p>Monday, April 4</p>
                                                <p>Wednesday, April 6</p>
                                                <p>Thursday, April 14</p>
                                                <p>Thursday April 14</p>
                                                <p>Friday, April 22</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                            <div className='text-holder '>
                                                <h5>Festivals </h5>
                                                <p>Mahavir Jayanti</p>
                                                <p>Hanuman Jayanti</p>
                                                <p>Solar New Year</p>
                                                <p>Baisakhi</p>
                                                <p>Akshaya Tritiya</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card mb-3' id='section5'>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-lg-4 col-md-12'>
                                            <div className='button-calender-may  text-center'>
                                                <div className='btn btn-calender'>MAY FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                            <div className='text-holder '>
                                                <h5>Day and Date</h5>
                                                <p>Thursday, May 5</p>
                                                <p>Thursday, May 19</p>
                                                <p>Monday, May 30</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                            <div className='text-holder '>
                                                <h5>Festivals </h5>
                                                <p>Buddha Purnima</p>
                                                <p>Shani Jayanti</p>
                                                <p>Ganga Dussehra</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card mb-3' id='section6'>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-lg-4 col-md-12'>
                                            <div className='button-calender-june  text-center'>
                                                <div className='btn btn-calender'>JUNE FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                            <div className='text-holder '>
                                                <h5>Day and Date</h5>
                                                <p>Monday, June 20</p>


                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                            <div className='text-holder '>
                                                <h5>Festivals </h5>
                                                <p>Jagganath Rath Yatra</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card mb-3' id='section7'>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-lg-4 col-md-12 '>
                                            <div className='button-calender-june  text-center'>
                                                <div className='btn btn-calender'>JULY FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                            <div className='text-holder '>
                                                <h5>Day and Date</h5>
                                                <p>Sunday, July 3</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                            <div className='text-holder '>
                                                <h5>Festivals </h5>
                                                <p>Guru Purnima</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card mb-3' id='section8'>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-lg-4 col-md-12'>
                                            <div className='button-calender1  text-center'>
                                                <div className='btn btn-calender'>AUGUST FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                            <div className='text-holder '>
                                                <h5>Day and Date</h5>
                                                <p>Monday, August 15</p>
                                                <p>Thursday, August 18</p>
                                                <p>Friday, August 19</p>
                                                <p>Tuesday, August 30</p>

                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                            <div className='text-holder '>
                                                <h5>Festivals </h5>
                                                <p>Independence Day</p>
                                                <p>Malayalam New Year</p>
                                                <p>Hariyali Teej</p>
                                                <p>Rakshabandhan3</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card mb-3' id='section9'>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-lg-4 col-md-12'>
                                            <div className='button-calender-sept  text-center'>
                                                <div className='btn btn-calender'>SEPTEMBER FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                            <div className='text-holder '>
                                                <h5>Day and Date</h5>
                                                <p>Friday, September 2</p>
                                                <p>Tuesday, September 6</p>
                                                <p>Wednesday, September 7</p>
                                                <p>Monday, September 19</p>
                                                <p>Tuesday, September 20</p>
                                                <p>Wednesday, September 28</p>
                                                <p>Wednesday, September 28</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                            <div className='text-holder '>
                                                <h5>Festivals </h5>
                                                <p>Kajri Teej</p>
                                                <p>Krishna Janmashtami</p>
                                                <p>Dahi Handi</p>
                                                <p>Ganesh Chaturthi</p>
                                                <p>Rishi Panchami</p>
                                                <p>Ganesh Visarjan</p>
                                                <p>Anant Chaturdashi</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card mb-3' id='section10'>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-lg-4 col-md-12'>
                                            <div className='button-calender1  text-center'>
                                                <div className='btn btn-calender'>OCTOBER FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                            <div className='text-holder '>
                                                <h5>Day and Date</h5>
                                                <p>Saturday, October 15</p>
                                                <p>Monday, October 24</p>
                                                <p>Monday, October 24</p>
                                                <p>Monday, October 24</p>

                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                            <div className='text-holder '>
                                                <h5>Festivals </h5>
                                                <p>Sharad Navratri</p>
                                                <p>Durga Visarjan</p>
                                                <p>Dussehra (Vijayadashmi)</p>
                                                <p>Buddha Jayanti</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card mb-3' id='section11'>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-lg-4 col-md-12'>
                                            <div className='button-calender-nov  text-center'>
                                                <div className='btn btn-calender'>NOVEMBER FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                            <div className='text-holder '>
                                                <h5>Day and Date</h5>
                                                <p>Tuesday, November 1</p>
                                                <p>Saturday, November 12</p>
                                                <p>Monday, November 14</p>
                                                <p>Monday, November 14</p>
                                                <p>Saturday, November 19</p>
                                                <p>Sunday, November 27</p>

                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                            <div className='text-holder '>
                                                <h5>Festivals </h5>
                                                <p>Karwa Chauth</p>
                                                <p>Diwali (Lakshmi Pooja)</p>
                                                <p>Goverdhan Pooja</p>
                                                <p>Bhai Dooj</p>
                                                <p>Chhath Pooja</p>
                                                <p>Guru Nanak Jayanti</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card mb-5' id='section12'>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-lg-4 col-md-12'>
                                            <div className='button-calender-december  text-center'>
                                                <div className='btn btn-calender'>DECEMBER FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                            <div className='text-holder '>
                                                <h5>Day and Date</h5>
                                                <p>Sunday, December 25</p>
                                                <p>Monday, December 26</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                            <div className='text-holder'>
                                                <h5>Festivals </h5>
                                                <p>Christmas</p>
                                                <p>Annapurna Jayanti</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* mobile-view-start */}

            <section className='festival-calender mobile-view-festival-calender'>
                <div className='container'>
                    <div className='row'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mt-3">
                                <li className="breadcrumb-item"><Link to='/'>Home {'>'}</Link></li>
                                <li className="breadcrumb-item active" aria-current="page"> Festival Calendar</li>
                            </ol>
                        </nav>
                    </div>
                    <div className='row'>
                        <div className='col-md-11 mx-auto'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='heading-holder my-2'>
                                        <h5>Indian Festivals & Holidays</h5>
                                        <p>India is known for celebrating a wide range of festivals the entire year. People have their roots in all these cultural festivals
                                            connected to different religions, deities, beliefs, and old traditions. While some even hold mythological, historical, and social significance, all of them are celebrated with joy and happiness. Moreover, to seek blessings from the deities associated with some of these festivals, people observe fast, while for some they perform charity and prayers. However it may be, each Indian festival is considered an auspicious occasion.
                                            And, to mark all the festivals and occasions in one place, there is the Indian calendar 2023. </p>
                                        <p>To make it easy and accessible for you, Astrotalk has prepared a list of occasions, festivals, and celebrations for 2023, where not only will you find the upcoming holidays and festivals, but also different types of calendars,
                                            prepared precisely on the basis of the planetary changes, tithi, Purnima, Amavasya, and much more.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row festival-row mt-5'>
                        <div className='col-12'>
                            <Link to='/festival-january'>
                                <div className='button-calender text-center mb-3' >
                                    <div className='btn btn-calender'>JANUARY FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                                </div></Link>
                        </div>
                        <div className='col-12'>
                            <Link to='/festival-february'>
                                <div className='button-calender text-center mb-3' onClick={() => scrollToSection('section1')}>
                                    <div className='btn btn-calender'>FEBRUARY FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                                </div></Link>
                        </div>
                        <div className='col-12'>
                            <Link to='/festival-march'>
                                <div className='button-calender text-center mb-3' onClick={() => scrollToSection('section2')}>
                                    <div className='btn btn-calender'>MARCH FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                                </div></Link>
                        </div>
                        <div className='col-12'>
                            <Link to='/festival-april'>
                                <div className='button-calender text-center mb-3' onClick={() => scrollToSection('section3')}>
                                    <div className='btn btn-calender'>APRIL FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                                </div></Link>
                        </div>
                        <div className='col-12'>
                            <Link to='/festival-may'>
                                <div className='button-calender text-center mb-3' onClick={() => scrollToSection('section4')}>
                                    <div className='btn btn-calender'>MAY FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                                </div></Link>
                        </div>
                    </div>

                    <div className='col-12'>
                        <Link to='/festival-june'>
                            <div className='button-calender text-center mb-3' onClick={() => scrollToSection('section5')}>
                                <div className='btn btn-calender'>JUNE FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                            </div></Link>
                    </div>
                    <div className='col-12'>
                        <Link to='/festival-july'>
                            <div className='button-calender text-center mb-3' onClick={() => scrollToSection('section6')}>
                                <div className='btn btn-calender'>JULY FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                            </div></Link>
                    </div>
                    <div className='col-12' >
                        <Link to='/festival-august'>
                            <div className='button-calender text-center mb-3' onClick={() => scrollToSection('section7')}>
                                <div className='btn btn-calender'>AUGUST FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                            </div></Link>
                    </div>
                    <div className='col-12'>
                        <Link to='/festival-september'>
                            <div className='button-calender text-center mb-3' onClick={() => scrollToSection('section8')}>
                                <div className='btn btn-calender'>SEPTEMBER FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                            </div></Link>
                    </div>
                    <div className='col-12'>
                        <Link to='/festival-october'>
                            <div className='button-calender text-center mb-3' onClick={() => scrollToSection('section9')}>
                                <div className='btn btn-calender'>OCTOBER FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                            </div></Link>
                    </div>

                    <div className='row justify-content-center'>
                        <div className='col-12'>
                            <Link to='/festival-november'>
                                <div className='button-calender text-center mb-3' onClick={() => scrollToSection('section10')}>
                                    <div className='btn btn-calender '>NOVEMBER FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                                </div></Link>
                        </div>
                        <div className='col-12'>
                            <Link to='/festival-december'>
                                <div className='button-calender text-center mb-5' onClick={() => scrollToSection('section11')}>
                                    <div className='btn btn-calender'>DECEMBER FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                                </div></Link>
                        </div>
                    </div>


                </div>
            </section>
        </>
    )
}

export default Festival