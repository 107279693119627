import React from 'react'
import HoroscopeSlider from '../HoroscopeSlider/HoroscopeSlider'
import { Link } from 'react-router-dom'

const Monthly_Horoscope = () => {
  return (
    <>
      <section className="Horoscope2023 desk-top-view-horoscope">
        <div className="container-fluid">
          <div className='row'>
            <div className='col-xxl-9 col-xl-10 col-lg-11 col-md-11 mx-auto'>
              <div className="row">
                <div className="col-md-12">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mt-4">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        <Link >Monthly Horoscope</Link>
                      </li>
                    </ol>
                  </nav>
                 </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    {/* <div className="card-body"> */}
                    <div className="Horoscope_info p-4">
                      <div className="Horoscope_heading mb-2">
                        Monthly Horoscope
                        <span className='Monthly-txt'>
                          (Know Your Monthly Horoscope)
                        </span>
                      </div>
                      <p>
                        The monthly horoscope at Astrotalk is prepared by expert astrologers with experience of 20+ years, and therefore it ought to be the best prediction that you can get to prepare for the month ahead of you. As per astrology, the movement of the planet Sun is considered one of the most influential and impacting movements when it comes to Kundli predictions. And the monthly horoscope is something that keeps a track of this movement. By doing so, the astrologers aim to give the monthly horoscope readers an insight into what's ahead of them; so he or she can make the best choices for himself or herself.
                      </p>

                      <p>
                        Once you take a look at the Monthly horoscope, you just don't find the solutions to the upcoming hiccups in life but also the opportunities that await you ahead. Moreover, the horoscope also spares the native with suggestions and remedies that he or she can adapt to better the outcomes from the month for themselves. By doing so, astrologers ensure the holistic development of the native so that they can feel confident about the choices they are afraid to make. The monthly horoscope is really a life saviour, and one of the ways that can help you in making your life easier.
                      </p>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <HoroscopeSlider />
      </section>

      {/* mobile-view-start */}
      <section className="Horoscope2023 mobile-view-horoscope">
        <div className="container-fluid">
          <div className='row'>
            <div className="col-md-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mt-3">
                  <li className="breadcrumb-item">
                    <Link to="/">Home {'>'}</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link >Monthly Horoscope</Link>
                  </li>
                </ol>
              </nav>
            </div>

            {/* <div className="row"> */}
            <div className="col-md-12">
              <div className="card mt-3">
                {/* <div className="card-body"> */}
                <div className="Horoscope_info p-2">
                  <div className="Horoscope_heading my-2">
                    Monthly Horoscope
                    <span className='Monthly-txt'>
                      (Know Your Monthly Horoscope)
                    </span>
                  </div>
                  <p>
                    The monthly horoscope at Astrotalk is prepared by expert astrologers with experience of 20+ years, and therefore it ought to be the best prediction that you can get to prepare for the month ahead of you. As per astrology, the movement of the planet Sun is considered one of the most influential and impacting movements when it comes to Kundli predictions. And the monthly horoscope is something that keeps a track of this movement. By doing so, the astrologers aim to give the monthly horoscope readers an insight into what's ahead of them; so he or she can make the best choices for himself or herself.
                  </p>

                  <p>
                    Once you take a look at the Monthly horoscope, you just don't find the solutions to the upcoming hiccups in life but also the opportunities that await you ahead. Moreover, the horoscope also spares the native with suggestions and remedies that he or she can adapt to better the outcomes from the month for themselves. By doing so, astrologers ensure the holistic development of the native so that they can feel confident about the choices they are afraid to make. The monthly horoscope is really a life saviour, and one of the ways that can help you in making your life easier.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <HoroscopeSlider />
      </section>
    </>
  )
}

export default Monthly_Horoscope