import React from 'react'
import { Link } from "react-router-dom";
import './ConnectAsrologer.css'
const ConnectAstrologer = () => {
    return (
        <>
            <div className='col-md-10 col-12  mx-auto'>
                <div className='connnect-astrologer py-4'>
                    <div className='astrology-heading text-center'>
                        <h6>Connect with an Astrologer on Call or Chat for more personalised detailed predictions.</h6>
                    </div>
                    <div className='talk-chat-button text-center mt-3'>
                        <Link to='/chat-astrologer'>  <button className='btn btn-chat me-md-3 me-1 '>Chat to Astrologers</button></Link>
                        <Link to='/talk-astrologer'>  <button className='btn btn-talk '>Talk to Astrologers</button></Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ConnectAstrologer