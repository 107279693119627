import React from 'react'
import { Link } from 'react-router-dom'
const TodayPanchang = () => {
    return (
        <>
            <section className="Horoscope2023 desk-top-view-horoscope">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mt-4">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active">
                                        <Link >Today's Panchang</Link>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <div className="card mb-5">
                                {/* <div className="card-body"> */}
                                <div className="Horoscope_info p-4">
                                    <div className="Horoscope_heading mb-2"> Find your Chinese Zodiac Sign </div>
                                    <p> Panchang is the astrological daily calendar based on the Indian calendar.Daily panchang is one of the most sought Vedic astrology concepts,
                                        which conceptualise the day's planetary position to determine auspicious tithis, timing, festivals, vrats, etc. Using this information,
                                        a person can choose whether or not to begin or accomplish a particular task after carefully studying the favourability or unfavorability of
                                        the planets ruling the particular task. It is a belief among people that doing a thing or two as per Today's panchang timings brings good luck
                                        and helps in the accomplishment of the tasks with ease.
                                    </p>
                                    <p> At Astrotalk, a Panchang calendar is produced by a team of the best astrologers of India.
                                        The Daily Panchang details the various astrological elements of the day and based on the same,
                                        the level of auspiciousness and inauspiciousness is decided. Right from Today's sunrise time to today's sunset time,
                                        Panchang helps you find all of it as per your convenience. </p>

                                    <p>Majorly, Panchang is used to get detail of five aspects. These five aspects are - day of the week (vaar); tithi or the lunar day;
                                        nakshatra or the constellation; yoga; and karan. With the change in time and date, all of these compartments that make the panchang also change.
                                        And thus it’s necessary to keep oneself updated about them for various reasons.Select to cop</p>

                                </div>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* mobile-view-start */}
            <section className="Horoscope2023 mobile-view-horoscope">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mt-3">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Home {'>'}</Link>
                                    </li>
                                    <li className="breadcrumb-item active">
                                        <Link >Today's Panchang</Link>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <div className="card mt-3 mb-5">
                                {/* <div className="card-body"> */}
                                <div className="Horoscope_info p-2">
                                    <div className="Horoscope_heading mt-3 mb-2"> Find your Chinese Zodiac Sign </div>
                                    <p> Panchang is the astrological daily calendar based on the Indian calendar.Daily panchang is one of the most sought Vedic astrology concepts,
                                        which conceptualise the day's planetary position to determine auspicious tithis, timing, festivals, vrats, etc. Using this information,
                                        a person can choose whether or not to begin or accomplish a particular task after carefully studying the favourability or unfavorability of
                                        the planets ruling the particular task. It is a belief among people that doing a thing or two as per Today's panchang timings brings good luck
                                        and helps in the accomplishment of the tasks with ease.
                                    </p>
                                    <p> At Astrotalk, a Panchang calendar is produced by a team of the best astrologers of India.
                                        The Daily Panchang details the various astrological elements of the day and based on the same,
                                        the level of auspiciousness and inauspiciousness is decided. Right from Today's sunrise time to today's sunset time,
                                        Panchang helps you find all of it as per your convenience. </p>

                                    <p>Majorly, Panchang is used to get detail of five aspects. These five aspects are - day of the week (vaar); tithi or the lunar day;
                                        nakshatra or the constellation; yoga; and karan. With the change in time and date, all of these compartments that make the panchang also change.
                                        And thus it’s necessary to keep oneself updated about them for various reasons.Select to cop</p>

                                </div>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TodayPanchang