import React, { useState } from "react";
import facebook from "../image/facebook (15).png";
import instagram from "../image/Group 19824.png";
import linkedln from "../image/linkdin.png";
import twitter from "../image/Layer 2.png";
import sendimg from "../image/paper-plane.png";
import { Link } from "react-router-dom";
import Footer1 from "./Animation/Footer_js/Footer1";
import Footer2 from "./Animation/Footer1_js/Footer2";
import search_img from "../image/search-icon1.png";
import wallet_img from '../image/wallet-icon.png';
import home_img from '../image/home-icon.png';
import hindi_img from '../image/hindi-eng.png';
import searchicon from '../image/Icon-awesome-search.png';
import hinditimg from "../image/hindi.png";
import marathitimg from "../image/marathi.png";
const Footer = () => {
  const [showIcons, setShowIcons] = useState(false);
  const [icon, setIcon] = useState('+');
  const toggleIcons = () => {

    if (searchToggled == true) {
      setIsToggled(false);
    } else {
      setShowIcons(!showIcons);
      setIcon(icon === '+' ? 'x' : '+');
    }

  };

  const [searchToggled, setIsToggled] = useState(false);
  // Function to toggle the state
  const toggleClick = () => {
    setIsToggled(!searchToggled);
  };
  return (
    <>
      <section className="Footer ">
        <div className="container-fluid">
          <div className="row footer-section ms-0 me-0">
            <div className="col-md-12 p-0">
              <div className="newsletter-text mt-5">Newsletter</div>
              <div className="main mt-2">
                <div className="line me-2"></div>
                <div className="square"></div>
                <div className="line  ms-2"></div>
              </div>

              <div className="text mt-2">
                <p>
                  Signup our newsletter to get update information, news, insight
                  or promotions.
                </p>
              </div>

              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-6 col-8 mx-auto">
                  <div className="input-group mt-2">
                    <input
                      type="Email"
                      className="form-control"
                      placeholder="Email"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                    <span className="input-group-text" id="basic-addon1">
                      <img src={sendimg} className="send-img" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-10 mx-auto">
                <div className="contact-section">
                  <div className="row contact-sub-section">
                    <div className="col-lg-4 col-md-4">
                      <div className="email-section ">
                        <div className="email-txt">Email us</div>
                        <div className="support-email-txt pe-lg-0 pe-md-4">
                          Support@yourdomain.com
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                      <div className="call-section">
                        <div className="email-txt">Call us</div>
                        <div className="support-email-txt">+6145.2158.2586</div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                      <div className="social_media-section">
                        <div className="email-txt">Follow on Social</div>
                        <div className="text-center">
                          <img
                            src={facebook}
                            className="facebook_image me-2"
                          ></img>
                          <img
                            src={instagram}
                            className="instagram_image me-2"
                          ></img>
                          <img
                            src={linkedln}
                            className="linkedln_image me-2"
                          ></img>
                          <img
                            src={twitter}
                            className="twitter_image me-2"
                          ></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="copyright textcenter mt-3">
                © 2022 All Rights Reserved Terms Of Use | Privacy Policy Powered
                by Astrology
              </div>
            </div>
          </div>

          <div className="footer_lottie-i">
            <Footer1 />
          </div>

          <div className="footer_lottie-ii">
            <Footer2 />
          </div>
        </div>
      </section>

      {/*Footer For mobile view start*/}
      <section className="Mobile_footer">
        <div className="container-fluid p-0">

          <div className="row ">
            <div className="col-md-12 ms-0 me-0">
              <div className={`circle_rect_sec ${showIcons ? 'show-icons' : ''}`}>
                <div className="circle" onClick={toggleIcons}>
                  <div className="plus-icon mt-1">{icon}</div>

                </div>

                {showIcons && (
                  <div className="icons">
                    <img src={search_img} className="footer_icon_img me-3" onClick={toggleClick} />
                    <Link to='/wallet'><img src={wallet_img} className="footer_icon_img me-4" /></Link>
                    <Link to='/'><img src={home_img} className="footer_icon_img ms-5 me-3" /></Link>
                    <img src={hindi_img} className="footer_icon_img" data-bs-toggle="modal" data-bs-target="#englishModal" />

                    {searchToggled && (
                      <div className="input-group mb-3">
                        <input type="text" className="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />
                        <span className="input-group-text" id="basic-addon1"><img src={searchicon} className="search-icon" /></span>
                      </div>
                    )
                    }
                  </div>
                )}
              </div>

            </div>
          </div>
        </div>
      </section>
      {/*Footer For mobile view ends*/}

      {/* <!-- hindi Modal --> */}
      <div className="footer-mob">
        <div class="modal fade" id="englishModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              {/* <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div> */}
              <div className="modal-body">

                <div className="mb-3 mt-2 ms-5">
                  <img src={hinditimg} className="lang-img me-2 " /><span>Hindi</span>
                </div>

                <div className="ms-5">
                  <img src={marathitimg} className="lang-img me-2" /><span>Marathi</span>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
      {/* <!--hindi section end--> */}
    </>
  );
};

export default Footer;