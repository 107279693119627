import React from 'react'
import './FreeAshtakvarga.css';
import { Link } from "react-router-dom";
import nashkimg from '../Free_Report/Images/image-1.png'
import nashkimg1 from '../Free_Report/Images/image-2.png'
import ConnectAstrologer from '../../Kundali/connect-astrologer/ConnectAstrologer';
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
const FreeAshtakvarga = () => {
    return (
        <>
            <section className="Free_Kundli desktop-view-free-kundali">
                <div className="container">
                    <div className="row ">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                                <li className="breadcrumb-item"><Link to='/freekundali'>Free Kundali </Link></li>
                                <li className="breadcrumb-item active" ><Link to=''>Astakvarga</Link></li>
                            </ol>
                        </nav>
                        <div className="col-lg-12   mt-4">
                            <ul className="nav nav-pills mb-3 " id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <Link to='/Basic-Kundali'><button className="nav-link " id="pills-basic-tab" data-bs-toggle="pill" data-bs-target="#pills-basic"
                                        type="button" role="tab" aria-controls="pills-basic" aria-selected="true"> Basic </button></Link>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <Link to='/second-Kundali'> <button className="nav-link " id="pills-kundli-tab" data-bs-toggle="pill" data-bs-target="#pills-kundli" type="button"
                                        role="tab" aria-controls="pills-kundli" aria-selected="false">
                                        Kundli
                                    </button></Link>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <Link to='/kp-Kundali'> <button className="nav-link " id="pills-kp-tab" data-bs-toggle="pill" data-bs-target="#pills-kp"
                                        type="button" role="tab" aria-controls="pills-kp" aria-selected="false">
                                        KP
                                    </button></Link>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <Link to="/free-ashtkvarga">
                                        <button className="nav-link active" id="pills-ashtakvarga-tab" data-bs-toggle="pill" data-bs-target="#pills-ashtakvarga"
                                            type="button" role="tab" aria-controls="pills-ashtakvarga" aria-selected="false">
                                            Ashtakvarga
                                        </button></Link>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <Link to="/free-chart">
                                        <button className="nav-link" id="pills-charts-tab" data-bs-toggle="pill" data-bs-target="#pills-charts"
                                            type="button" role="tab" aria-controls="pills-charts" aria-selected="false">
                                            Charts
                                        </button></Link>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <Link to="/free-dasha">
                                        <button className="nav-link" id="pills-dasha-tab" data-bs-toggle="pill" data-bs-target="#pills-dasha"
                                            type="button" role="tab" aria-controls="pills-dasha" aria-selected="false">
                                            Dasha
                                        </button></Link>
                                </li>
                                <li className="nav-item free-nav" role="presentation">
                                    <Link to={"/free_Report"}>
                                        <button className="nav-link" id="pills-report-tab" data-bs-toggle="pill" data-bs-target="#pills-report"
                                            type="button" role="tab" aria-controls="pills-report" aria-selected="false">
                                            Free Report
                                        </button> </Link>
                                </li>
                            </ul>
                        </div>

                        <div className='col-md-12'>
                            <div className='heading-holder text-center mb-3'>
                                <h2>Ashtakvarga Chart </h2>
                            </div>
                            <div className="main-heading mt-1">
                                <div className="line me-2"></div>
                                <div className="square"></div>
                                <div className="line ms-2"></div>
                            </div>
                            <p className='paragraph text-center my-4'>Ashtakvarga is used to assess the strength and patterns that are present in a birth chart. The Ashtakvarga or Ashtakavarga is a numerical quantification or score of each planet placed in the chart with reference to the other 7 planets and the Lagna.
                                In Sarva Ashtaka Varga the total scores of all the BAVs are overlaid and then totalled. This makes the SAV of the chart. The total of all the scores should be 337.</p>
                        </div>
                    </div>
                    <div className='row ashtakvarga-kundali mt-5'>
                        <div className='col-xl-2 col-lg-4 col-md-6 '>
                            <div class="main-kundali mx-auto">
                                <div class="sub"></div>
                                <div class="line1"></div>
                                <div class="line2"></div>

                                <p class="p1">01</p>
                                <p class="p2">02</p>
                                <p class="p3">03</p>
                                <p class="p4">04</p>
                                <p class="p5">05</p>
                                <p class="p6">06</p>
                                <p class="p7">07</p>
                                <p class="p8">08</p>
                                <p class="p9">09</p>
                                <p class="p10">10</p>
                                <p class="p11">11</p>
                                <p class="p12">12</p>
                            </div>
                            <div className='name-holder text-center mt-3 mb-5'>
                                <h5>Sav</h5>
                            </div>
                        </div>
                        <div className='col-xl-2 col-lg-4 col-md-6'>
                            <div class="main-kundali mx-auto">
                                <div class="sub"></div>
                                <div class="line1"></div>
                                <div class="line2"></div>

                                <p class="p1">01</p>
                                <p class="p2">02</p>
                                <p class="p3">03</p>
                                <p class="p4">04</p>
                                <p class="p5">05</p>
                                <p class="p6">06</p>
                                <p class="p7">07</p>
                                <p class="p8">08</p>
                                <p class="p9">09</p>
                                <p class="p10">10</p>
                                <p class="p11">11</p>
                                <p class="p12">12</p>
                            </div>
                            <div className='name-holder text-center mt-3 mb-5'>
                                <h5>Asc</h5>
                            </div>
                        </div>
                        <div className='col-xl-2 col-lg-4 col-md-6'>
                            <div class="main-kundali mx-auto">
                                <div class="sub"></div>
                                <div class="line1"></div>
                                <div class="line2"></div>

                                <p class="p1">01</p>
                                <p class="p2">02</p>
                                <p class="p3">03</p>
                                <p class="p4">04</p>
                                <p class="p5">05</p>
                                <p class="p6">06</p>
                                <p class="p7">07</p>
                                <p class="p8">08</p>
                                <p class="p9">09</p>
                                <p class="p10">10</p>
                                <p class="p11">11</p>
                                <p class="p12">12</p>
                            </div>
                            <div className='name-holder text-center mt-3 mb-5'>
                                <h5>Jupiter</h5>
                            </div>
                        </div>
                        <div className='col-xl-2 col-lg-4 col-md-6'>
                            <div class="main-kundali mx-auto">
                                <div class="sub"></div>
                                <div class="line1"></div>
                                <div class="line2"></div>

                                <p class="p1">01</p>
                                <p class="p2">02</p>
                                <p class="p3">03</p>
                                <p class="p4">04</p>
                                <p class="p5">05</p>
                                <p class="p6">06</p>
                                <p class="p7">07</p>
                                <p class="p8">08</p>
                                <p class="p9">09</p>
                                <p class="p10">10</p>
                                <p class="p11">11</p>
                                <p class="p12">12</p>
                            </div>
                            <div className='name-holder text-center mt-3 mb-5'>
                                <h5>Mars</h5>
                            </div>
                        </div>
                        <div className='col-xl-2 col-lg-4 col-md-6'>
                            <div class="main-kundali mx-auto">
                                <div class="sub"></div>
                                <div class="line1"></div>
                                <div class="line2"></div>

                                <p class="p1">01</p>
                                <p class="p2">02</p>
                                <p class="p3">03</p>
                                <p class="p4">04</p>
                                <p class="p5">05</p>
                                <p class="p6">06</p>
                                <p class="p7">07</p>
                                <p class="p8">08</p>
                                <p class="p9">09</p>
                                <p class="p10">10</p>
                                <p class="p11">11</p>
                                <p class="p12">12</p>
                            </div>
                            <div className='name-holder text-center mt-3 mb-5'>
                                <h5>Mercury</h5>
                            </div>
                        </div>
                    </div>

                    <div className='row ashtakvarga-kundali '>
                        <div className='col-xl-2 col-lg-4 col-md-6'>
                            <div class="main-kundali mx-auto">
                                <div class="sub"></div>
                                <div class="line1"></div>
                                <div class="line2"></div>

                                <p class="p1">01</p>
                                <p class="p2">02</p>
                                <p class="p3">03</p>
                                <p class="p4">04</p>
                                <p class="p5">05</p>
                                <p class="p6">06</p>
                                <p class="p7">07</p>
                                <p class="p8">08</p>
                                <p class="p9">09</p>
                                <p class="p10">10</p>
                                <p class="p11">11</p>
                                <p class="p12">12</p>
                            </div>
                            <div className='name-holder text-center mt-3 mb-5'>
                                <h5>Moon</h5>
                            </div>
                        </div>
                        <div className='col-xl-2 col-lg-4 col-md-6'>
                            <div class="main-kundali mx-auto">
                                <div class="sub"></div>
                                <div class="line1"></div>
                                <div class="line2"></div>

                                <p class="p1">01</p>
                                <p class="p2">02</p>
                                <p class="p3">03</p>
                                <p class="p4">04</p>
                                <p class="p5">05</p>
                                <p class="p6">06</p>
                                <p class="p7">07</p>
                                <p class="p8">08</p>
                                <p class="p9">09</p>
                                <p class="p10">10</p>
                                <p class="p11">11</p>
                                <p class="p12">12</p>
                            </div>
                            <div className='name-holder text-center mt-3 mb-5'>
                                <h5>Saturn</h5>
                            </div>
                        </div>
                        <div className='col-xl-2 col-lg-4 col-md-6'>
                            <div class="main-kundali mx-auto">
                                <div class="sub"></div>
                                <div class="line1"></div>
                                <div class="line2"></div>

                                <p class="p1">01</p>
                                <p class="p2">02</p>
                                <p class="p3">03</p>
                                <p class="p4">04</p>
                                <p class="p5">05</p>
                                <p class="p6">06</p>
                                <p class="p7">07</p>
                                <p class="p8">08</p>
                                <p class="p9">09</p>
                                <p class="p10">10</p>
                                <p class="p11">11</p>
                                <p class="p12">12</p>
                            </div>
                            <div className='name-holder text-center mt-3 mb-5'>
                                <h5>Sun</h5>
                            </div>
                        </div>
                        <div className='col-xl-2 col-lg-4 col-md-6'>
                            <div class="main-kundali mx-auto">
                                <div class="sub"></div>
                                <div class="line1"></div>
                                <div class="line2"></div>

                                <p class="p1">01</p>
                                <p class="p2">02</p>
                                <p class="p3">03</p>
                                <p class="p4">04</p>
                                <p class="p5">05</p>
                                <p class="p6">06</p>
                                <p class="p7">07</p>
                                <p class="p8">08</p>
                                <p class="p9">09</p>
                                <p class="p10">10</p>
                                <p class="p11">11</p>
                                <p class="p12">12</p>
                            </div>
                            <div className='name-holder text-center mt-3 mb-5'>
                                <h5>Venus</h5>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <div className="connnect-astrologer py-4">
                                <div className="astrology-heading text-center">
                                    <h6>
                                        Connect with an Astrologer on Call or Chat for more
                                        personalised detailed predictions.
                                    </h6>
                                </div>
                                <div className="talk-chat-button text-center mt-3">
                                    <Link to='/chat-astrologer'> <button className="btn btn-chat me-2 mb-2" > Chat to Astrologers </button></Link>
                                    <Link to='/talk-astrologer'> <button className="btn btn-talk mb-2"> Talk to Astrologers</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="image-naskhtra">
                        <img src={nashkimg} className="nakshtra-img" />
                    </div>
                    <div className="image-naskhtra2">
                        <img src={nashkimg1} className="nakshtra-img" />
                    </div>

                </div>
            </section>

            {/* mobile-view-start */}
            <section className="Free_Kundli mobile-view-free-kundali">
                <div className="container-fluid">
                    <div className="row ">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb my-3">
                                <li className="breadcrumb-item"><Link to='/'>Home {'>'} </Link></li>
                                <li className="breadcrumb-item"><Link to='/freekundali'>Free Kundali {'>'} </Link></li>
                                <li className="breadcrumb-item active" ><Link to=''>Astakvarga</Link></li>
                            </ol>
                        </nav>
                        <div className="col-lg-12 p-0">
                            <ul className="nav nav-pills  " id="pills-tab" role="tablist">
                                <Swiper
                                    modules={[Navigation, Autoplay]}
                                    pagination={false}
                                    navigation={false}
                                    // loop={true}
                                    breakpoints={{
                                        0: {
                                            slidesPerView: 3,
                                            // spaceBetween: 10,
                                        },
                                        360: {
                                            slidesPerView: 3,
                                            // spaceBetween: 10,
                                        },
                                        567: {
                                            slidesPerView: 4,
                                            // spaceBetween: 10,
                                        },
                                        640: {
                                            slidesPerView: 4,
                                            // spaceBetween: 10,
                                        },
                                        768: {
                                            slidesPerView: 4,
                                            // spaceBetween: 10,
                                        },

                                    }}

                                    className="suportres-slider" >
                                    <SwiperSlide className="swiper-slide1">
                                        <li className="nav-item " role="presentation">
                                            <Link to='/Basic-Kundali'> <button className="nav-link " id="pills-basic-tab" data-bs-toggle="pill" data-bs-target="#pills-basic"
                                                type="button" role="tab" aria-controls="pills-basic" aria-selected="true"> Basic </button></Link>
                                        </li>
                                    </SwiperSlide>

                                    <SwiperSlide className="swiper-slide1">
                                        <li className="nav-item" role="presentation">
                                            <Link to='/second-Kundali'>   <button className="nav-link " id="pills-kundli-tab" data-bs-toggle="pill" data-bs-target="#pills-kundli" type="button"
                                                role="tab" aria-controls="pills-kundli" aria-selected="false">
                                                Kundli
                                            </button></Link>
                                        </li>
                                    </SwiperSlide>

                                    <SwiperSlide className="swiper-slide1">
                                        <li className="nav-item" role="presentation">
                                            <Link to='/kp-Kundali'>   <button className="nav-link " id="pills-kp-tab" data-bs-toggle="pill" data-bs-target="#pills-kp"
                                                type="button" role="tab" aria-controls="pills-kp" aria-selected="false">
                                                KP
                                            </button></Link>
                                        </li>
                                    </SwiperSlide>

                                    <SwiperSlide className="swiper-slide1">
                                        <li className="nav-item" role="presentation">
                                            <Link to="/free-ashtkvarga">
                                                <button className="nav-link active" id="pills-ashtakvarga-tab" data-bs-toggle="pill" data-bs-target="#pills-ashtakvarga"
                                                    type="button" role="tab" aria-controls="pills-ashtakvarga" aria-selected="false">
                                                    Ashtakvarga
                                                </button></Link>
                                        </li>
                                    </SwiperSlide>

                                    <SwiperSlide className="swiper-slide1">
                                        <li className="nav-item" role="presentation">
                                            <Link to="/free-chart">
                                                <button className="nav-link" id="pills-charts-tab" data-bs-toggle="pill" data-bs-target="#pills-charts"
                                                    type="button" role="tab" aria-controls="pills-charts" aria-selected="false">
                                                    Charts
                                                </button></Link>
                                        </li>
                                    </SwiperSlide>

                                    <SwiperSlide className="swiper-slide1">
                                        <li className="nav-item" role="presentation">
                                            <Link to="/free-dasha">
                                                <button className="nav-link" id="pills-dasha-tab" data-bs-toggle="pill" data-bs-target="#pills-dasha"
                                                    type="button" role="tab" aria-controls="pills-dasha" aria-selected="false">
                                                    Dasha
                                                </button></Link>
                                        </li>
                                    </SwiperSlide>

                                    <SwiperSlide className="swiper-slide1">
                                        <li className="nav-item free-nav" role="presentation">
                                            <Link to={'/free_Report'}>
                                                <button className="nav-link" id="pills-report-tab" data-bs-toggle="pill" data-bs-target="#pills-report"
                                                    type="button" role="tab" aria-controls="pills-report" aria-selected="false">
                                                    Free Report
                                                </button>
                                            </Link>
                                        </li>
                                    </SwiperSlide>
                                </Swiper>
                            </ul>
                        </div>

                        <div className='col-md-12'>
                            <div className='heading-holder text-center mb-3 mt-5' >
                                <h2>Ashtakvarga Chart </h2>
                            </div>
                            <div className="main-heading mt-1">
                                <div className="line me-1"></div>
                                <div className="square"></div>
                                <div className="line ms-1"></div>
                            </div>
                            <p className='paragraph text-center my-3'>Ashtakvarga is used to assess the strength and patterns that are present in a birth chart. The Ashtakvarga or Ashtakavarga is a numerical quantification or score of each planet placed in the chart with reference to the other 7 planets and the Lagna.
                                In Sarva Ashtaka Varga the total scores of all the BAVs are overlaid and then totalled. This makes the SAV of the chart. The total of all the scores should be 337.</p>
                        </div>
                    </div>

                    <div className='row ashtakvarga-kundali'>
                        <Swiper
                            modules={[Pagination, Autoplay]}
                            pagination={true}
                            navigation={false}
                            // loop={true}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                    // spaceBetween: 10,
                                },
                                420: {
                                    slidesPerView: 1,
                                    // spaceBetween: 10,
                                },
                                567: {
                                    slidesPerView: 2,
                                    // spaceBetween: 10,
                                },
                                640: {
                                    slidesPerView: 2,
                                    // spaceBetween: 10,
                                },
                                768: {
                                    slidesPerView: 2,
                                    // spaceBetween: 10,
                                },

                            }}

                            className="suportres-slider" >
                            <SwiperSlide className="">
                                <div class="main-kundali mx-auto">
                                    <div class="sub"></div>
                                    <div class="line1"></div>
                                    <div class="line2"></div>

                                    <p class="p1">01</p>
                                    <p class="p2">02</p>
                                    <p class="p3">03</p>
                                    <p class="p4">04</p>
                                    <p class="p5">05</p>
                                    <p class="p6">06</p>
                                    <p class="p7">07</p>
                                    <p class="p8">08</p>
                                    <p class="p9">09</p>
                                    <p class="p10">10</p>
                                    <p class="p11">11</p>
                                    <p class="p12">12</p>
                                </div>
                                <div className='name-holder text-center mb-5 mt-4'>
                                    <h5>Sav</h5>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide className="">
                                <div class="main-kundali mx-auto">
                                    <div class="sub"></div>
                                    <div class="line1"></div>
                                    <div class="line2"></div>

                                    <p class="p1">01</p>
                                    <p class="p2">02</p>
                                    <p class="p3">03</p>
                                    <p class="p4">04</p>
                                    <p class="p5">05</p>
                                    <p class="p6">06</p>
                                    <p class="p7">07</p>
                                    <p class="p8">08</p>
                                    <p class="p9">09</p>
                                    <p class="p10">10</p>
                                    <p class="p11">11</p>
                                    <p class="p12">12</p>
                                </div>
                                <div className='name-holder text-center  mb-5 mt-4'>
                                    <h5>Asc</h5>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide className="">
                                <div class="main-kundali mx-auto">
                                    <div class="sub"></div>
                                    <div class="line1"></div>
                                    <div class="line2"></div>

                                    <p class="p1">01</p>
                                    <p class="p2">02</p>
                                    <p class="p3">03</p>
                                    <p class="p4">04</p>
                                    <p class="p5">05</p>
                                    <p class="p6">06</p>
                                    <p class="p7">07</p>
                                    <p class="p8">08</p>
                                    <p class="p9">09</p>
                                    <p class="p10">10</p>
                                    <p class="p11">11</p>
                                    <p class="p12">12</p>
                                </div>
                                <div className='name-holder text-center  mb-5 mt-4'>
                                    <h5>Jupiter</h5>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide className="">
                                <div class="main-kundali mx-auto">
                                    <div class="sub"></div>
                                    <div class="line1"></div>
                                    <div class="line2"></div>

                                    <p class="p1">01</p>
                                    <p class="p2">02</p>
                                    <p class="p3">03</p>
                                    <p class="p4">04</p>
                                    <p class="p5">05</p>
                                    <p class="p6">06</p>
                                    <p class="p7">07</p>
                                    <p class="p8">08</p>
                                    <p class="p9">09</p>
                                    <p class="p10">10</p>
                                    <p class="p11">11</p>
                                    <p class="p12">12</p>
                                </div>
                                <div className='name-holder text-center  mb-5 mt-4'>
                                    <h5>Mars</h5>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide className="">
                                <div class="main-kundali mx-auto">
                                    <div class="sub"></div>
                                    <div class="line1"></div>
                                    <div class="line2"></div>

                                    <p class="p1">01</p>
                                    <p class="p2">02</p>
                                    <p class="p3">03</p>
                                    <p class="p4">04</p>
                                    <p class="p5">05</p>
                                    <p class="p6">06</p>
                                    <p class="p7">07</p>
                                    <p class="p8">08</p>
                                    <p class="p9">09</p>
                                    <p class="p10">10</p>
                                    <p class="p11">11</p>
                                    <p class="p12">12</p>
                                </div>
                                <div className='name-holder text-center  mb-5 mt-4'>
                                    <h5>Mercury</h5>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide className="">
                                <div class="main-kundali mx-auto">
                                    <div class="sub"></div>
                                    <div class="line1"></div>
                                    <div class="line2"></div>

                                    <p class="p1">01</p>
                                    <p class="p2">02</p>
                                    <p class="p3">03</p>
                                    <p class="p4">04</p>
                                    <p class="p5">05</p>
                                    <p class="p6">06</p>
                                    <p class="p7">07</p>
                                    <p class="p8">08</p>
                                    <p class="p9">09</p>
                                    <p class="p10">10</p>
                                    <p class="p11">11</p>
                                    <p class="p12">12</p>
                                </div>
                                <div className='name-holder text-center mb-5 mt-4'>
                                    <h5>Moon</h5>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide className="">
                                <div class="main-kundali mx-auto">
                                    <div class="sub"></div>
                                    <div class="line1"></div>
                                    <div class="line2"></div>

                                    <p class="p1">01</p>
                                    <p class="p2">02</p>
                                    <p class="p3">03</p>
                                    <p class="p4">04</p>
                                    <p class="p5">05</p>
                                    <p class="p6">06</p>
                                    <p class="p7">07</p>
                                    <p class="p8">08</p>
                                    <p class="p9">09</p>
                                    <p class="p10">10</p>
                                    <p class="p11">11</p>
                                    <p class="p12">12</p>
                                </div>
                                <div className='name-holder text-center  mb-5 mt-4'>
                                    <h5>Saturn</h5>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide className="">
                                <div class="main-kundali mx-auto">
                                    <div class="sub"></div>
                                    <div class="line1"></div>
                                    <div class="line2"></div>

                                    <p class="p1">01</p>
                                    <p class="p2">02</p>
                                    <p class="p3">03</p>
                                    <p class="p4">04</p>
                                    <p class="p5">05</p>
                                    <p class="p6">06</p>
                                    <p class="p7">07</p>
                                    <p class="p8">08</p>
                                    <p class="p9">09</p>
                                    <p class="p10">10</p>
                                    <p class="p11">11</p>
                                    <p class="p12">12</p>
                                </div>
                                <div className='name-holder text-center  mb-5 mt-4'>
                                    <h5>Sun</h5>
                                </div>

                            </SwiperSlide>
                            <SwiperSlide className="">
                                <div class="main-kundali mx-auto">
                                    <div class="sub"></div>
                                    <div class="line1"></div>
                                    <div class="line2"></div>

                                    <p class="p1">01</p>
                                    <p class="p2">02</p>
                                    <p class="p3">03</p>
                                    <p class="p4">04</p>
                                    <p class="p5">05</p>
                                    <p class="p6">06</p>
                                    <p class="p7">07</p>
                                    <p class="p8">08</p>
                                    <p class="p9">09</p>
                                    <p class="p10">10</p>
                                    <p class="p11">11</p>
                                    <p class="p12">12</p>
                                </div>
                                <div className='name-holder text-center  mb-5 mt-4'>
                                    <h5>Venus</h5>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>


                    <div className="row">
                        <ConnectAstrologer />
                    </div>

                    <div className="image-naskhtra">
                        <img src={nashkimg} className="nakshtra-img" />
                    </div>
                    <div className="image-naskhtra2">
                        <img src={nashkimg1} className="nakshtra-img" />
                    </div>

                </div>
            </section >
        </>
    )
}

export default FreeAshtakvarga