import React from 'react'
import Lottie from "react-lottie";
import circle_json from "./animation_ll0jl2jh.json"
import profileimg from "../../../Astrologer-profile/Image/girl-profile.png"
import "./callLotteyAstro.css"
const CallLotteyastro = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: circle_json,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>

      <div className='callToAstrolger-lottie mx-auto'>
        <Lottie
          options={defaultOptions} />
        <div class="second-img ">
          <img src={profileimg} class="profile-img" />

        </div>
      </div>

    </>
  )
}

export default CallLotteyastro