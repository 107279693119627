import React, { useState } from 'react'
import '../../chat-astrologer/filter-modal/FilterModal.css';
import futureimg from '../../chat-astrologer/image/future.png';
import languageimg from '../../chat-astrologer/image/language.png';
import genderimg from '../../chat-astrologer/image/toilet.png';
import countryimg from '../../chat-astrologer/image/coronavirus.png';
import offerimg from '../../chat-astrologer/image/discount.png';
import topimg from '../../chat-astrologer/image/badge.png';
const FilterModalTalk = () => {


    const [checkboxes, setCheckboxes] = useState([
        { id: 1, text: 'Face Reading', isChecked: true },
        { id: 2, text: 'Kp', isChecked: false },
        { id: 3, text: 'Life Coach', isChecked: false },
        { id: 4, text: ' Nadi', isChecked: false },
        { id: 5, text: 'Palmistry', isChecked: false },
        { id: 6, text: ' Prashana', isChecked: false },
        { id: 7, text: 'Psychic', isChecked: false },
        { id: 8, text: 'Tarot', isChecked: false },
        { id: 9, text: 'Vastu', isChecked: false },
        { id: 9, text: 'Vedic', isChecked: false }
    ]);

    const [content, setContent] = useState([
        { id: 1, text: 'English', isChecked: false },
        { id: 2, text: 'Gujrathi', isChecked: false },
        { id: 3, text: 'Hindi', isChecked: false },
        { id: 4, text: ' Bangali', isChecked: false },
        { id: 5, text: 'Marathi', isChecked: false },
        { id: 6, text: ' Kannada', isChecked: false },
        { id: 7, text: 'Punjabi', isChecked: false },
        { id: 8, text: 'Tamil', isChecked: false },
        { id: 9, text: 'Telgu', isChecked: false },
        { id: 9, text: 'Urdu', isChecked: false }
    ])
    const [gender, setGender] = useState([
        { id: 1, text: 'Male', isChecked: false },
        { id: 2, text: 'Female', isChecked: false },
    ])
    const [country, setCountry] = useState([
        { id: 1, text: 'India', isChecked: false },
        { id: 2, text: 'Outside India', isChecked: false },
    ])
    const [choice, setChoice] = useState([
        { id: 1, text: 'All', isChecked: false },
        { id: 2, text: 'Must Try', isChecked: false },
        { id: 3, text: 'Top Choice', isChecked: false },
    ])

    const [offer, setOffer] = useState([
        { id: 1, text: 'Active', isChecked: false },
        { id: 2, text: 'Non-Active', isChecked: false },

    ])

    const toggleCheckbox = (id) => {
        setCheckboxes((prevState) =>
            prevState.map((checkbox) =>
                checkbox.id === id ? { ...checkbox, isChecked: !checkbox.isChecked } : checkbox
            )
        );
    };
    const handleCheckbox = (id) => {
        setContent((prevState) =>
            prevState.map((checkbox) =>
                checkbox.id === id ? { ...checkbox, isChecked: !checkbox.isChecked } : checkbox
            )
        );
    };
    const genderCheckbox = (id) => {
        setGender((prevState) =>
            prevState.map((checkbox) =>
                checkbox.id === id ? { ...checkbox, isChecked: !checkbox.isChecked } : checkbox
            )
        );
    };
    const countryCheckbox = (id) => {
        setCountry((prevState) =>
            prevState.map((checkbox) =>
                checkbox.id === id ? { ...checkbox, isChecked: !checkbox.isChecked } : checkbox
            )
        );
    };

    const choiceCheckbox = (id) => {
        setChoice((prevState) =>
            prevState.map((checkbox) =>
                checkbox.id === id ? { ...checkbox, isChecked: !checkbox.isChecked } : checkbox
            )
        );
    };
    const offereCheckbox = (id) => {
        setOffer((prevState) =>
            prevState.map((checkbox) =>
                checkbox.id === id ? { ...checkbox, isChecked: !checkbox.isChecked } : checkbox
            )
        );
    };
    return (
        <>
            {/* <!-- Modal --> */}
            <div className='filtermodal'>
                <div className="modal fade" id="filterModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header">
                                {/* <h1 className="modal-title fs-5" id="exampleModalLabel">Modal title</h1> */}
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body pb-0">
                                <div className='row'>
                                    <div className='col-sm-6 col-6'>
                                        <div className='sort mb-3'>
                                            <h5 className='ps-4 pb-1'>Filters</h5>
                                        </div>
                                        <div className="nav flex-column nav-pills ms-sm-4 ms-0 me-sm-3 me-1" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                            <button className="nav-link mb-sm-3 mb-2 active" id="v-pills-Skill-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Skill" type="button" role="tab" aria-controls="v-pills-Skill" aria-selected="true"> <div className="triangle-right"></div><img src={futureimg} className='future-img me-2' />Skill</button>
                                            <button className="nav-link mb-sm-3 mb-2" id="v-pills-Language-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Language" type="button" role="tab" aria-controls="v-pills-Language" aria-selected="false"> <div className="triangle-right"></div><img src={languageimg} className='future-img me-2' />Language</button>
                                            <button className="nav-link mb-sm-3 mb-2" id="v-pills-Gender-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Gender" type="button" role="tab" aria-controls="v-pills-Gender" aria-selected="false" > <div className="triangle-right"></div><img src={genderimg} className='future-img me-2' />Gender</button>
                                            <button className="nav-link mb-sm-3 mb-2" id="v-pills-Country-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Country" type="button" role="tab" aria-controls="v-pills-Country" aria-selected="false"> <div className="triangle-right"></div><img src={countryimg} className='future-img me-2' />Country</button>
                                            <button className="nav-link mb-sm-3 mb-2" id="v-pills-Offer-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Offer" type="button" role="tab" aria-controls="v-pills-Offer" aria-selected="false"> <div className="triangle-right"></div><img src={offerimg} className='future-img me-2' />Offer</button>
                                            <button className="nav-link mb-sm-3 mb-2" id="v-pills-Top-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Top" type="button" role="tab" aria-controls="v-pills-Top" aria-selected="false"> <div className="triangle-right"></div><img src={topimg} className='future-img me-2' />Top Choice</button>
                                        </div>
                                    </div>
                                    <div className='col-sm-6 col-6'>
                                        <div className='radio-icon mb-md-3 mb-sm-3 mb-0'>
                                            <div className="form-check me-1">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked />
                                                <label className="form-check-label" for="flexRadioDefault1">
                                                    Select all
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                                <label className="form-check-label" for="flexRadioDefault2">
                                                    Clear all
                                                </label>
                                            </div>
                                        </div>
                                        <div className="tab-content" id="v-pills-tabContent">
                                            <div className="tab-pane fade show active" id="v-pills-Skill" role="tabpanel" aria-labelledby="v-pills-Skill-tab" tabindex="0">
                                                {/* <div className='check-icon-start1 ps-3'>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked />
                                                        <label className="form-check-label" for="flexCheckDefault">
                                                            Face Reading
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked1" />
                                                        <label className="form-check-label" for="flexCheckChecked1">
                                                            Kp
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked2" />
                                                        <label className="form-check-label" for="flexCheckChecked2">
                                                            Life Coach
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked3" />
                                                        <label className="form-check-label" for="flexCheckChecked3">
                                                            Nadi
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked4" />
                                                        <label className="form-check-label" for="flexCheckChecked4">
                                                            Numerology
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked5" />
                                                        <label className="form-check-label" for="flexCheckChecked5">
                                                            Palmistry
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked6" />
                                                        <label className="form-check-label" for="flexCheckChecked6">
                                                            Prashana
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked7" />
                                                        <label className="form-check-label" for="flexCheckChecked7">
                                                            Psychic
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked8" />
                                                        <label className="form-check-label" for="flexCheckChecked8">
                                                            Tarot
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked9" />
                                                        <label className="form-check-label" for="flexCheckChecked9">
                                                            Vastu
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked10" />
                                                        <label className="form-check-label" for="flexCheckChecked10">
                                                            Vedic
                                                        </label>
                                                    </div>
                                                </div> */}
                                                <div className='check-icon-start1 ps-3'>
                                                    {checkboxes.map((checkbox) => (
                                                        <div key={checkbox.id} className="mb-sm-2 mb-0 mt-0">
                                                            <label
                                                                className={`form-check-label checkbox-label ${checkbox.isChecked ? 'checked' : ''}`}
                                                                onClick={() => toggleCheckbox(checkbox.id)}>
                                                                <input type="checkbox" checked={checkbox.isChecked} className="form-check-input me-2" readOnly />
                                                                {checkbox.text}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="v-pills-Language" role="tabpanel" aria-labelledby="v-pills-Language-tab" tabindex="0">
                                                {/* <div className='check-icon-start1 ps-3'>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked />
                                                        <label className="form-check-label" for="flexCheckDefault">
                                                            English
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked18" />
                                                        <label className="form-check-label" for="flexCheckChecked18">
                                                            Gujarati
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked19" />
                                                        <label className="form-check-label" for="flexCheckChecked19">
                                                            Hindi
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked20" />
                                                        <label className="form-check-label" for="flexCheckChecked20">
                                                            Bengali
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked21" />
                                                        <label className="form-check-label" for="flexCheckChecked21">
                                                            Marathi
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked22" />
                                                        <label className="form-check-label" for="flexCheckChecked22">
                                                            Kannada
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked23" />
                                                        <label className="form-check-label" for="flexCheckChecked23">
                                                            Punjabi
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked24" />
                                                        <label className="form-check-label" for="flexCheckChecked24">
                                                            Tamil
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked25" />
                                                        <label className="form-check-label" for="flexCheckChecked25">
                                                            Telugu
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked26" />
                                                        <label className="form-check-label" for="flexCheckChecked26">
                                                            Urdu
                                                        </label>
                                                    </div>

                                                </div> */}

                                                <div className='check-icon-start1 ps-3'>
                                                    {content.map((checkbox) => (
                                                        <div key={checkbox.id} className="mb-sm-2 mb-0">
                                                            <label
                                                                className={`form-check-label checkbox-label ${checkbox.isChecked ? 'checked' : ''}`}
                                                                onClick={() => handleCheckbox(checkbox.id)}>
                                                                <input type="checkbox" checked={checkbox.isChecked} className="form-check-input me-2" readOnly />
                                                                {checkbox.text}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="v-pills-Gender" role="tabpanel" aria-labelledby="v-pills-Gender-tab" tabindex="0">
                                                {/* <div className='check-icon-start1 ps-3'>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefaul28" checked />
                                                        <label className="form-check-label" for="flexCheckDefaul28">
                                                            Male
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked27" />
                                                        <label className="form-check-label" for="flexCheckChecked27">
                                                            Female
                                                        </label>
                                                    </div>
                                                </div> */}
                                                <div className='check-icon-start1 ps-3'>
                                                    {gender.map((checkbox) => (
                                                        <div key={checkbox.id} className=" mb-sm-2 mb-0">
                                                            <label
                                                                className={`form-check-label checkbox-label ${checkbox.isChecked ? 'checked' : ''}`}
                                                                onClick={() => genderCheckbox(checkbox.id)}>
                                                                <input type="checkbox" checked={checkbox.isChecked} className="form-check-input me-2" readOnly />
                                                                {checkbox.text}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="v-pills-Country" role="tabpanel" aria-labelledby="v-pills-Country-tab" tabindex="0">
                                                {/* <div className='check-icon-start1 ps-3'>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault29" checked />
                                                        <label className="form-check-label" for="flexCheckDefault29">
                                                            India
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked30" />
                                                        <label className="form-check-label" for="flexCheckChecked30">
                                                            Outside India
                                                        </label>
                                                    </div>
                                                </div> */}
                                                <div className='check-icon-start1 ps-3'>
                                                    {country.map((checkbox) => (
                                                        <div key={checkbox.id} className="mb-sm-2 mb-0 ">
                                                            <label
                                                                className={`form-check-label checkbox-label ${checkbox.isChecked ? 'checked' : ''}`}
                                                                onClick={() => countryCheckbox(checkbox.id)}>
                                                                <input type="checkbox" checked={checkbox.isChecked} className="form-check-input me-2" readOnly />
                                                                {checkbox.text}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="v-pills-Offer" role="tabpanel" aria-labelledby="v-pills-Offer-tab" tabindex="0">
                                                {/* <div className='check-icon-start1 ps-3'>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault31" checked />
                                                        <label className="form-check-label" for="flexCheckDefault31">
                                                            Active
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked32" />
                                                        <label className="form-check-label" for="flexCheckChecked32">
                                                            Non Active
                                                        </label>
                                                    </div>
                                                </div> */}
                                                <div className='check-icon-start1 ps-3'>
                                                    {offer.map((checkbox) => (
                                                        <div key={checkbox.id} className="mb-sm-2 mb-0">
                                                            <label
                                                                className={`form-check-label checkbox-label ${checkbox.isChecked ? 'checked' : ''}`}
                                                                onClick={() => offereCheckbox(checkbox.id)}>
                                                                <input type="checkbox" checked={checkbox.isChecked} className="form-check-input me-2" readOnly />
                                                                {checkbox.text}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="v-pills-Top" role="tabpanel" aria-labelledby="v-pills-Top-tab" tabindex="0">
                                                {/* <div className='check-icon-start1 ps-3'>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault33" checked />
                                                        <label className="form-check-label" for="flexCheckDefault33">
                                                           All
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked34" />
                                                        <label className="form-check-label" for="flexCheckChecked34">
                                                           Must Try
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked35" />
                                                        <label className="form-check-label" for="flexCheckChecked35">
                                                        Top Choice
                                                        </label>
                                                    </div>
                                            </div> */}
                                                <div className='check-icon-start1 ps-3'>
                                                    {choice.map((checkbox) => (
                                                        <div key={checkbox.id} className="mb-sm-2 mb-0 ">
                                                            <label
                                                                className={`form-check-label checkbox-label ${checkbox.isChecked ? 'checked' : ''}`}
                                                                onClick={() => choiceCheckbox(checkbox.id)}>
                                                                <input type="checkbox" checked={checkbox.isChecked} className="form-check-input me-2" readOnly />
                                                                {checkbox.text}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer text-start pt-0 me-md-4 me-sm-2 me-2">
                                <button type="button" className="btn btn-apply me-md-3 me-sm-2 me-1">Apply</button>
                                <button type="button" className="btn btn-apply">Reset</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FilterModalTalk