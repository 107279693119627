import React from 'react'
import { Link } from 'react-router-dom'
import './compability.css'
import { Autoplay, Navigation } from 'swiper/modules'
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import Ariesimg from './image/aries.png'
import { Swiper, SwiperSlide } from "swiper/react"
import taurusimg from './image/taurus.png';
import geminiimg from './image/gemini.png';
import cancerimg from './image/cancre.png'
const CompabilitySlider = () => {
    return (
        <>
            <section className="CompilitySlider desktop-view-CompilitySlider">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xxl-9 col-xl-10 col-lg-11 col-md-12 mx-auto ">

                            <div className="row">
                                <div className='col-md-12'>
                                    <Swiper
                                        modules={[Navigation, Autoplay]}
                                        spaceBetween={10}
                                        slidesPerView={6}
                                        pagination={false}
                                        navigation={true}
                                        breakpoints={{
                                            0: {
                                                slidesPerView: 1,
                                                spaceBetween: 10,
                                            },
                                            640: {
                                                slidesPerView: 2,
                                                spaceBetween: 10,
                                            },
                                            768: {
                                                slidesPerView: 3,
                                                spaceBetween: 40,
                                            },
                                            1024: {
                                                slidesPerView: 3,
                                                spaceBetween: 40,
                                            },
                                            1200: {
                                                slidesPerView: 4,
                                                spaceBetween: 40,
                                            },
                                            1400: {
                                                slidesPerView: 4,
                                                spaceBetween: 40,
                                            },
                                            1700: {
                                                slidesPerView: 6,
                                                spaceBetween: 40,
                                            },
                                        }}
                                        onSwiper={(swiper) => console.log(swiper)}
                                        onSlideChange={() => console.log("slide change")}

                                        className="suportres-slider"  >
                                        <SwiperSlide>
                                            <div className="card">
                                                <img src={Ariesimg} className='aries-img mx-auto' />
                                                <div className="card-body">
                                                    <div className="card-title text-center">
                                                        <h5>ARIES</h5>
                                                        <div className='underline1'></div>
                                                        <p className='date-holder pt-3'>Mar 21 - Apr 25</p>
                                                    </div>

                                                </div>
                                            </div>
                                        </SwiperSlide>

                                        <SwiperSlide>
                                            <div className="card">
                                                <img src={taurusimg} className='aries-img mx-auto' />
                                                <div className="card-body">
                                                    <div className="card-title text-center">
                                                        <h5>TAURUS</h5>
                                                        <div className='underline1'></div>
                                                        <p className='date-holder pt-3'>Mar 21 - Apr 25</p>
                                                    </div>

                                                </div>
                                            </div>
                                        </SwiperSlide>

                                        <SwiperSlide>
                                            <div className="card">
                                                <img src={geminiimg} className='aries-img mx-auto' />
                                                <div className="card-body">
                                                    <div className="card-title text-center">
                                                        <h5>GEMINI</h5>
                                                        <div className='underline1'></div>
                                                        <p className='date-holder pt-3'>Mar 21 - Apr 25</p>
                                                    </div>

                                                </div>
                                            </div>
                                        </SwiperSlide>

                                        <SwiperSlide>
                                            <div className="card">
                                                <img src={cancerimg} className='aries-img mx-auto' />
                                                <div className="card-body">
                                                    <div className="card-title text-center">
                                                        <h5> CANCER</h5>
                                                        <div className='underline1'></div>
                                                        <p className='date-holder pt-3'>Mar 21 - Apr 25</p>

                                                    </div>

                                                </div>
                                            </div>
                                        </SwiperSlide>

                                        <SwiperSlide>
                                            <div className="card">
                                                <img src={Ariesimg} className='aries-img mx-auto' />
                                                <div className="card-body">
                                                    <div className="card-title text-center">
                                                        <h5>ARIES</h5>
                                                        <div className='underline1'></div>
                                                        <p className='date-holder pt-3'>Mar 21 - Apr 25</p>
                                                    </div>

                                                </div>
                                            </div>
                                        </SwiperSlide>

                                        <SwiperSlide>
                                            <div className="card">
                                                <img src={taurusimg} className='aries-img mx-auto' />
                                                <div className="card-body">
                                                    <div className="card-title text-center">
                                                        <h5> TAURUS</h5>
                                                        <div className='underline1'></div>
                                                        <p className='date-holder pt-3'>Mar 21 - Apr 25</p>
                                                    </div>

                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="card">
                                                <img src={geminiimg} className='aries-img mx-auto' />
                                                <div className="card-body">
                                                    <div className="card-title text-center">
                                                        <h5>GEMINI</h5>
                                                        <div className='underline1'></div>
                                                        <p className='date-holder pt-3'>Mar 21 - Apr 25</p>
                                                    </div>

                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="card">
                                                <img src={cancerimg} className='aries-img mx-auto' />
                                                <div className="card-body">
                                                    <div className="card-title text-center">
                                                        <h5> CANCER</h5>
                                                        <div className='underline1'></div>
                                                        <p className='date-holder pt-3'>Mar 21 - Apr 25</p>
                                                    </div>

                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* mobile-view-start */}
            <section className="CompilitySlider  mobile-view-CompilitySlider">
                <div className="container-fluid">
                    <div className="row">
                        <div className='col-12'>
                            <Swiper
                                modules={[Navigation, Autoplay]}
                                spaceBetween={20}
                                slidesPerView={2}
                                pagination={false}
                                navigation={true}
                                loop={true}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 2,
                                        spaceBetween: 10,
                                    },
                                    360: {
                                        slidesPerView: 3,
                                        spaceBetween: 10,
                                    },
                                    486: {
                                        slidesPerView: 3,
                                        spaceBetween: 10,
                                    },
                                    567: {
                                        slidesPerView: 4,
                                        spaceBetween: 20,
                                    },
                                    640: {
                                        slidesPerView: 4,
                                        spaceBetween: 20,
                                    },
                                    768: {
                                        slidesPerView: 4,
                                        spaceBetween: 20,
                                    },

                                }}
                                // onSwiper={(swiper) => console.log(swiper)}
                                // onSlideChange={() => console.log("slide change")}
                                // autoplay={{
                                //   delay: 2500,
                                //   disableOnInteraction: false,
                                //   loop: true,
                                //   pauseOnMouseEnter: true,
                                // }}
                                className="suportres-slider mt-3" >
                                <SwiperSlide>
                                    <div className="card mb-3">
                                        <img src={Ariesimg} className='aries-img mx-auto' />
                                        <div className="card-body p-0">
                                            <div className="card-title text-center">
                                                <h5>ARIES</h5>
                                                <div className='underline1'></div>
                                                <p className='date-holder py-2'>Mar 21 - Apr 25</p>
                                            </div>

                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="card mb-3">
                                        <img src={taurusimg} className='aries-img mx-auto' />
                                        <div className="card-body p-0">
                                            <div className="card-title text-center">
                                                <h5>TAURUS</h5>
                                                <div className='underline1'></div>
                                                <p className='date-holder py-2'>Mar 21 - Apr 25</p>
                                            </div>

                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="card mb-3">
                                        <img src={geminiimg} className='aries-img mx-auto' />
                                        <div className="card-body p-0">
                                            <div className="card-title text-center">
                                                <h5>GEMINI</h5>
                                                <div className='underline1'></div>
                                                <p className='date-holder py-2'>Mar 21 - Apr 25</p>
                                            </div>

                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="card mb-3">
                                        <img src={cancerimg} className='aries-img mx-auto' />
                                        <div className="card-body p-0">
                                            <div className="card-title text-center">
                                                <h5> CANCER</h5>
                                                <div className='underline1'></div>
                                                <p className='date-holder py-2'>Mar 21 - Apr 25</p>

                                            </div>

                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="card mb-3">
                                        <img src={Ariesimg} className='aries-img mx-auto' />
                                        <div className="card-body p-0">
                                            <div className="card-title text-center">
                                                <h5>ARIES</h5>
                                                <div className='underline1'></div>
                                                <p className='date-holder py-2'>Mar 21 - Apr 25</p>
                                            </div>

                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="card mb-3">
                                        <img src={taurusimg} className='aries-img mx-auto' />
                                        <div className="card-body p-0">
                                            <div className="card-title text-center">
                                                <h5> TAURUS</h5>
                                                <div className='underline1'></div>
                                                <p className='date-holder py-2'>Mar 21 - Apr 25</p>
                                            </div>

                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="card mb-3">
                                        <img src={geminiimg} className='aries-img mx-auto' />
                                        <div className="card-body p-0">
                                            <div className="card-title text-center">
                                                <h5>GEMINI</h5>
                                                <div className='underline1'></div>
                                                <p className='date-holder py-2'>Mar 21 - Apr 25</p>
                                            </div>

                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="card mb-3">
                                        <img src={cancerimg} className='aries-img mx-auto' />
                                        <div className="card-body p-0">
                                            <div className="card-title text-center">
                                                <h5> CANCER</h5>
                                                <div className='underline1'></div>
                                                <p className='date-holder py-2'>Mar 21 - Apr 25</p>
                                            </div>

                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CompabilitySlider